export const enum EReportTags {
  REPORTS = 'reports',
  REPORT_CATEGORIES = 'reportCategories',
  REPORT_SUBCATEGORIES = 'reportSubcategories',
  REPORT_CATEGORY_CONFIG = 'reportCategoryConfig',
  SINGLE_REPORT_CONFIG = 'singleReportConfig',
  TYPE_REPORTS = 'typeReports',
  ADMIN_CATEGORIES = 'adminCategories',
  ADMIN_SUBCATEGORIES = 'adminSubcategories',
  DEFECT_UNUSED_SUBCATEGORIES = 'defectUnusedSubcategories',
  DEFAULT_STATUS_TYPE_REPORT = 'defaultStatusTypeReport',
  DEFECT_CONFIGURATION = 'defectConfiguration',
  REPORT_CONFIGS = 'reportConfigs',
  USER_SUBSCRIPTION = 'userSubscription',
  REPORT_STATUSES = 'reportStatuses',
  REPORT_SINGLE_SUBCATEGORIES = 'reportSingleSubcategories',
  ALL_REPORTS = 'allReports',
  OPTION_RESPONSES = 'optionResponses'
}

export type TReportsParams = {
  district: string;
};

export type TReportSubcategoriesParams = {
  district: string;
  categoryId: number;
};

export type TReportCategoryConfigParams = {
  prefix: string;
  configId: number;
};

export type TUserSubscriptionParams = {
  prefix: string;
  name_user: string;
  page: number;
  resultsPerPage: number;
  order: number;
  district: string;
};

export type TReportStatusesParams = {
  admin: boolean;
  page: number;
  resultsPerPage: number;
};

export type TReportSingleSubcategoriesParams = {
  district: string;
  categoryId: number;
};

export type TAllReportsParams = {
  district: string;
  data: Partial<{
    search: string[];
    fieldSearchs: string[];
    ordering: string;
    descOrdering: boolean;
    page: number;
    pageSize: number;
    createdStart: moment.Moment;
    createdEnd: moment.Moment;
    category: number;
    subcategory: number[];
    status: string;
    confirmed: number;
    type: string;
  }>;
};

export type TOptionReports = {
  district: string;
};
