import { createApi } from '@reduxjs/toolkit/query/react';
import { AxiosError } from 'axios';

import handleApiError from '@/utils/handleApiError';
import { endpointWithParams, parseResponseError } from '@/utils/lib';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  fetchCompositionRegistersBegin,
  fetchCompositionRegistersFailed,
  fetchCompositionRegistersSuccess
} from '@/store/actions/registersActions';
import { showError } from '@/store/actions/globalActions';

import buildUrlParams from '@/utils/buildUrlParams';

import {
  API_DISTRICT_COMPOSITION_REGISTERS,
  API_DISTRICT_REGISTERS,
  API_DISTRICT_REGISTERS_SINGLE
} from '../consts';

import { TCompositionRegistersResponse } from '@/types/responses/registers';
import {
  ERegistersTags,
  TCompositionRegistersParams,
  TRegistersParams
} from '@/types/api-types/registers';

export const registersApi = createApi({
  reducerPath: 'registersApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [ERegistersTags.compositionRegisters, ERegistersTags.registers],
  endpoints: builder => ({
    fetchCompositionRegisters: builder.query<
      TCompositionRegistersResponse[],
      TCompositionRegistersParams
    >({
      query: ({ district, compositionId }) => ({
        method: 'GET',
        url: endpointWithParams(API_DISTRICT_COMPOSITION_REGISTERS, {
          district,
          compositionId
        })
      }),
      onQueryStarted: async (
        { district, compositionId },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchCompositionRegistersBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(
            fetchCompositionRegistersSuccess({
              registers: data,
              district,
              compositionId
            })
          );
        } catch (error) {
          const axiosError = error as AxiosError;

          handleApiError(error, dispatch);
          dispatch(fetchCompositionRegistersFailed());
          dispatch(showError(parseResponseError(axiosError)));
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(singleRegister => ({
                type: ERegistersTags.compositionRegisters,
                id: singleRegister?.id ?? ''
              })),
              {
                type: ERegistersTags.compositionRegisters,
                id: ERegistersTags.compositionRegisters
              }
            ]
          : [
              {
                type: ERegistersTags.compositionRegisters,
                id: ERegistersTags.compositionRegisters
              }
            ]
    }),
    getRegisters: builder.query<
      TCompositionRegistersResponse[],
      TRegistersParams
    >({
      query: ({ district, queryParams }) => ({
        method: 'GET',
        url: buildUrlParams(
          endpointWithParams(API_DISTRICT_REGISTERS, { district }),
          queryParams
        )
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        dispatch(fetchCompositionRegistersBegin());

        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ERegistersTags.registers,
                id: id
              })),
              {
                type: ERegistersTags.registers,
                id: ERegistersTags.registers
              }
            ]
          : [{ type: ERegistersTags.registers, id: ERegistersTags.registers }]
    }),
    patchRegisters: builder.mutation<
      TCompositionRegistersResponse,
      TRegistersParams
    >({
      query: ({ district, id, data }) => ({
        method: 'PATCH',
        url: endpointWithParams(API_DISTRICT_REGISTERS_SINGLE, {
          district,
          id
        }),
        data
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      invalidatesTags: () => [ERegistersTags.registers]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchCompositionRegistersQuery,
  useGetRegistersQuery,
  usePatchRegistersMutation
} = registersApi;
