import React from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';

import { setWindowDimensions } from '../../store/actions/globalActions';

import { mobileRwdBreakpoint } from '../../config';

export const dimensionsTypes = {
  MOBILE: 'MOBILE',
  DESKTOP: 'DESKTOP'
};

export const DEBOUNCE_TIME = 100;

const WindowSizeObserver = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    const windowResizeHandler = debounce(() => {
      const windowWidth = window.innerWidth;
      const { MOBILE, DESKTOP } = dimensionsTypes;
      const dimensions = { width: windowWidth, height: window.innerHeight };
      const dimensionsType =
        windowWidth > mobileRwdBreakpoint ? DESKTOP : MOBILE;

      dispatch(setWindowDimensions(dimensions, dimensionsType));
    }, DEBOUNCE_TIME);

    windowResizeHandler();

    window.addEventListener('resize', windowResizeHandler);

    return () => {
      window.removeEventListener('resize', windowResizeHandler);
    };
  });

  return null;
};

export default WindowSizeObserver;
