import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

import { responseStatuses } from '../config';

import { dimensionsTypes } from '../components/WindowSizeObserver';

const { MOBILE, DESKTOP } = dimensionsTypes;

export const GlobalSettingsShape = ImmutablePropTypes.mapContains({
  system_title: PropTypes.string,
  system_second_title: PropTypes.string,
  system_logo: PropTypes.string,
  rpo_logo: PropTypes.string,
  default_defect_status: PropTypes.number,
  update_time: PropTypes.string,
  navigation_in_toolbar: PropTypes.bool,
  logo_link: PropTypes.string,
  enable_logo_link: PropTypes.bool,
  enable_shorten_url: PropTypes.bool,
  enable_users_to_create_accounts: PropTypes.bool,
  home_link: PropTypes.string,
  enable_defect_vote: PropTypes.bool,
  enable_links: PropTypes.bool,
  enable_home_link: PropTypes.bool,
  identity_administrator: PropTypes.string,
  enable_giap_logo: PropTypes.bool,
  enable_info_tool_description: PropTypes.bool,
  enable_info_tool_producer: PropTypes.bool,
  enable_social_share_compositions: PropTypes.bool,
  enable_unofficial_character_of_portal_info: PropTypes.bool,
  google_analytics: PropTypes.string,
  google_api_key: PropTypes.string,
  google_fonts_api_key: PropTypes.string,
  google_recaptcha_api_key: PropTypes.string,
  info_tool_description: PropTypes.string,
  matomo_site_id: PropTypes.number,
  mobile_app_name: PropTypes.string,
  mobile_app_version: PropTypes.string,
  show_districts_list_button: PropTypes.bool,
  show_map_portals_list_button: PropTypes.bool,
  system_loader: PropTypes.string,
  temporarily_unavailable_map_portal_text: PropTypes.string,
  home_page_is_districts_list: PropTypes.bool
});

export const ToolShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  short_name: PropTypes.string.isRequired,
  description: PropTypes.string,
  is_free: PropTypes.bool.isRequired,
  module: PropTypes.number
});

export const ResponseStatus = PropTypes.oneOf([
  responseStatuses.STARTED,
  responseStatuses.SUCCESS,
  responseStatuses.FAILED,
  false,
  null
]);

export const FetchingLegendShape = PropTypes.shape({
  status: ResponseStatus,
  id: PropTypes.number,
  endpoint: PropTypes.string,
  legendUrl: PropTypes.string
});

export const AppearanceShape = PropTypes.shape({
  bg_color: PropTypes.string.isRequired,
  danger_color: PropTypes.string.isRequired,
  danger_text_color: PropTypes.string.isRequired,
  dark_color: PropTypes.string.isRequired,
  dark_text_color: PropTypes.string.isRequired,
  district_border_color: PropTypes.string.isRequired,
  district: PropTypes.number.isRequired,
  first_color: PropTypes.string.isRequired,
  first_text_color: PropTypes.string.isRequired,
  found_polygon_color: PropTypes.string.isRequired,
  id: PropTypes.number.isRequired,
  light_color: PropTypes.string.isRequired,
  light_text_color: PropTypes.string.isRequired,
  map_marker_color: PropTypes.string.isRequired,
  no_base_map_color: PropTypes.string.isRequired,
  name: PropTypes.string,
  secondary_color: PropTypes.string.isRequired,
  secondary_text_color: PropTypes.string.isRequired,
  success_color: PropTypes.string.isRequired,
  success_text_color: PropTypes.string.isRequired,
  warning_color: PropTypes.string.isRequired,
  warning_text_color: PropTypes.string.isRequired
});

export const SearchableModelShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  model: PropTypes.string.isRequired
});

export const SearchableModelsShape = ImmutablePropTypes.listOf(
  SearchableModelShape
);

export const LanguageShape = PropTypes.shape({
  code: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
});

export const LanguagesShape = ImmutablePropTypes.listOf(LanguageShape);

export const WindowDimensionsShape = ImmutablePropTypes.mapContains({
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired
});

export const WindowDimensionsTypeShape = PropTypes.oneOf([
  null,
  MOBILE,
  DESKTOP
]);

export const PrintOrientationTypes = PropTypes.oneOf(['portrait', 'landscape']);

export const GeometryEditorToolbarButtonsTypes = {
  selectObjectProps: PropTypes.object,
  historyUndoProps: PropTypes.object,
  historyRedoProps: PropTypes.object,
  resetProps: PropTypes.object,
  deleteObjectProps: PropTypes.object,
  rotateObjectProps: PropTypes.object,
  toleranceProps: PropTypes.object,
  mergeObjectsProps: PropTypes.object,
  sliceObjectProps: PropTypes.object,
  dragObjectProps: PropTypes.object,
  statusRendererProps: PropTypes.object,
  copyObjectProps: PropTypes.object,
  pasteObjectProps: PropTypes.object,
  measureObjectsProps: PropTypes.object,
  symbolizationProps: PropTypes.object,
  labelingProps: PropTypes.object,
  snapObjectProps: PropTypes.object,
  linearGuidesProps: PropTypes.object
};

export const ObjectBufferSettingsPropsTypes = {
  onRadiusChange: PropTypes.func,
  onUnitChange: PropTypes.func
};
