import codeAmpersand from '../codeAmpersand';
import codeQuestionMark from '../codeQuestionMark';
import codeSlash from '../codeSlash';

/**
 * Koduje znaki specjalne występujące w URL-ach
 *
 * @param {string} url - url do zakodowania
 * @return {string}
 */
const codeUrl = url => codeSlash(codeQuestionMark(codeAmpersand(url)));

export default codeUrl;
