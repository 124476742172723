/**
 * Parsuje kod Base64 do obiektu Blob.
 *
 * @param {string} data - plik zapisany w formacie base64
 * @param {string} contentType - typ MIME (jeśli inny niż zawarty w przekazanym pliku)
 */

const b64ToBlob = (data, contentType) =>
  new Promise(async (resolve, reject) => {
    if (!data) reject('Base64 data is not defined!');

    const [type, ...file] = data.split(',');
    const base64 = `data:${contentType || type};base64,` + file;

    fetch(base64)
      .then(res => res.blob())
      .then(blob => {
        resolve(blob);
      })
      .catch(err => reject(err));
  });

export default b64ToBlob;
