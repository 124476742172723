const locales = {
  ar: 'ar_EG',
  bg: 'bg_BG',
  ca: 'ca_ES',
  cs: 'cs_CZ',
  da: 'da_DK',
  de: 'de_DE',
  el: 'el_GR',
  es: 'es_ES',
  et: 'et_EE',
  fa: 'fa_IR',
  fi: 'fi_FI',
  fr: 'fr_FR',
  he: 'he_IL',
  hi: 'hi_IN',
  hr: 'hr_HR',
  hu: 'hu_HU',
  id: 'id_ID',
  is: 'is_IS',
  it: 'it_IT',
  ja: 'ja_JP',
  kn: 'kn_IN',
  ko: 'ko_KR',
  lv: 'lv_LV',
  mn: 'mn_MN',
  nb: 'nb_NO',
  ne: 'ne_NP',
  nl: 'nl_NL',
  pl: 'pl_PL',
  pt: 'pt_PT',
  ro: 'ro_RO',
  ru: 'ru_RU',
  sk: 'sk_SK',
  sl: 'sl_SL',
  sr: 'sr_RS',
  sv: 'sv_SE',
  ta: 'ta_IN',
  th: 'th_TH',
  tr: 'tr_TR',
  uk: 'uk_UA',
  vi: 'vi_VN',
  zh: 'zh_CN'
};

export default locales;
