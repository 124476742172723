/**
 * Replaces placeholders in a URL with corresponding values from an object.
 * Placeholders in the URL are denoted by a colon (:) followed by a key name.
 *
 * @param {string} endpoint - url with placeholders
 * @param {object} params - params with values for placeholders
 * @return {string}
 */
const endpointWithParams = (endpoint, params) => {
  const regex = /:\w+:/g;

  const matches = endpoint.match(regex);

  matches?.forEach(match => {
    endpoint = endpoint.replace(
      match,
      params?.[match.slice(1, match.length - 1)]
    );
  });

  return endpoint;
};

export default endpointWithParams;
