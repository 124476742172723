import { Map, List } from 'immutable';

import {
  FETCH_ALL_REPORTS_BEGIN,
  FETCH_ALL_REPORTS_SUCCESS,
  FETCH_ALL_REPORTS_FAILED,
  FETCH_OPTIONS_REPORTS_BEGIN,
  FETCH_OPTIONS_REPORTS_SUCCESS,
  FETCH_OPTIONS_REPORTS_FAILED,
  FETCH_SINGLE_REPORT_BEGIN,
  FETCH_SINGLE_REPORT_SUCCESS,
  FETCH_SINGLE_REPORT_FAILED,
  FETCH_REPORT_CATEGORIES_BEGIN,
  FETCH_REPORT_CATEGORIES_SUCCESS,
  FETCH_REPORT_CATEGORIES_FAILED,
  FETCH_REPORT_SUBCATEGORIES_SUCCESS,
  FETCH_REPORT_SUBCATEGORIES_FAILED,
  FETCH_REPORT_SUBCATEGORIES_BEGIN,
  FETCH_REPORT_STATUSES_BEGIN,
  FETCH_REPORT_STATUSES_SUCCESS,
  FETCH_REPORT_STATUSES_FAILED,
  UPDATE_REPORT_BEGIN,
  UPDATE_REPORT_END,
  DELETE_REPORT_BEGIN,
  DELETE_REPORT_END,
  SET_REPORT_POINT,
  CLEAR_REPORT_POINT,
  CREATE_REPORT_BEGIN,
  CREATE_REPORT_SUCCESS,
  CREATE_REPORT_FAILED,
  UPLOAD_REPORT_ATTACHMENT_BEGIN,
  UPLOAD_REPORT_ATTACHMENT_SUCCESS,
  UPLOAD_REPORT_ATTACHMENT_FAILED,
  CREATE_REPORT_COMMENT_BEGIN,
  CREATE_REPORT_COMMENT_SUCCESS,
  CREATE_REPORT_COMMENT_FAILED,
  FETCH_REPORT_COMMENT_BEGIN,
  FETCH_REPORT_COMMENT_FAILED,
  FETCH_REPORT_COMMENT_SUCCESS,
  FETCH_REPORT_HISTORY_BEGIN,
  FETCH_REPORT_HISTORY_FAILED,
  FETCH_REPORT_HISTORY_SUCCESS,
  UPDATE_REPORT_COMMENT_BEGIN,
  UPDATE_REPORT_COMMENT_SUCCESS,
  UPDATE_REPORT_COMMENT_FAILED,
  FETCH_DISTRICT_REPORTS_FAILED,
  FETCH_DISTRICT_REPORTS_SUCCESS,
  FETCH_DISTRICT_REPORTS_BEGIN,
  DISPLAY_REPORTS,
  FETCH_SINGLE_REPORT_CATEGORY_BEGIN,
  FETCH_SINGLE_REPORT_CATEGORY_SUCCESS,
  FETCH_SINGLE_REPORT_CATEGORY_FAILED,
  FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED,
  FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS,
  FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN,
  FETCH_SINGLE_REPORT_STATUS_BEGIN,
  FETCH_SINGLE_REPORT_STATUS_SUCCESS,
  FETCH_SINGLE_REPORT_STATUS_FAILED,
  ADD_REPORT_VOTE_BEGIN,
  ADD_REPORT_VOTE_SUCCESS,
  ADD_REPORT_VOTE_FAILED,
  DELETE_REPORT_VOTE_BEGIN,
  DELETE_REPORT_VOTE_SUCCESS,
  DELETE_REPORT_VOTE_FAILED,
  DELETE_REPORT_COMMENT_FAILED,
  DELETE_REPORT_COMMENT_SUCCESS,
  DELETE_REPORT_COMMENT_BEGIN,
  DELETE_REPORT_ATTACHMENT,
  DELETE_REPORT_ATTACHMENT_SUCCESS,
  DELETE_REPORT_ATTACHMENT_FAILED,
  CREATE_ASSIGN_TO_CATEGORY,
  CREATE_ASSIGN_TO_CATEGORY_SUCCESS,
  CREATE_ASSIGN_TO_CATEGORY_FAILED,
  FETCH_USER_SUBSCRIPTIONS,
  FETCH_USER_SUBSCRIPTIONS_SUCCESS,
  FETCH_USER_SUBSCRIPTIONS_FAILED,
  DELETE_ASSIGN_TO_CATEGORY,
  DELETE_ASSIGN_TO_CATEGORY_SUCCESS,
  DELETE_ASSIGN_TO_CATEGORY_FAILED,
  UPDATE_ASSIGN_TO_CATEGORY,
  UPDATE_ASSIGN_TO_CATEGORY_SUCCESS,
  UPDATE_ASSIGN_TO_CATEGORY_FAILED,
  SET_SINGLE_REPORT,
  CREATE_ADMIN_CATEGORY_SUCCESS,
  CREATE_ADMIN_SUBCATEGORY_SUCCESS,
  FETCH_ALL_SUBCATEGORIES_SUCCESS,
  FETCH_ALL_SUBCATEGORIES_FAILED,
  SET_STATUS_FILTER,
  SET_CATEGORY_FILTER,
  ZOOM_TO_CATEGORY,
  DELETE_CATEGORY_FROM_DISTRICT_SUCCESS,
  UPDATE_ADMIN_SUBCATEGORIES,
  FETCH_ADMIN_CATEGORIES_SUCCESS,
  FETCH_REPORT_DATA_BEGIN,
  FETCH_REPORT_DATA_SUCCESS,
  FETCH_REPORT_DATA_FAILED,
  ENABLE_FILTERS,
  FETCH_REPORT_CATEGORY_CONFIG_SUCCESS,
  FETCH_REPORT_STATUS_CONFIG_SUCCESS,
  CREATE_REPORT_CONFIG_BEGIN,
  CREATE_REPORT_CONFIG_SUCCESS,
  CREATE_REPORT_CONFIG_FAILED,
  DELETE_REPORT_CONFIG_BEGIN,
  DELETE_REPORT_CONFIG_SUCCESS,
  DELETE_REPORT_CONFIG_FAILED,
  CRAETE_REPORT_CATEGORY_COLOR_SUCCESS,
  UPDATE_REPORT_CATEGORY_COLOR_SUCCESS,
  CREATE_REPORT_STATUS_COLOR_SUCCESS,
  UPDATE_REPORT_STATUS_COLOR_SUCCESS,
  UPDATE_REPORT_CONFIG_BEGIN,
  UPDATE_REPORT_CONFIG_SUCCESS,
  UPDATE_REPORT_CONFIG_FAILED,
  FETCH_REPORT_SINGLE_CONFIG_SUCCESS,
  FETCH_REPORT_SINGLE_CONFIG_FAILED,
  CLEAR_REPORT_COLORS,
  GET_TYPE_REPORTS_REQUEST,
  GET_TYPE_REPORTS_SUCCESS,
  GET_TYPE_REPORTS_FAILED,
  GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST,
  GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  GET_DEFAULT_STATUS_TYPE_REPORT_FAILED,
  UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS,
  SHOW_TYPE_REPORT,
  CREATE_TYPE_REPORT_REQUEST,
  CREATE_TYPE_REPORT_FAILED,
  CREATE_TYPE_REPORT_SUCCESS,
  DELETE_TYPE_REPORT_REQUEST,
  DELETE_TYPE_REPORT_SUCCESS,
  DELETE_TYPE_REPORT_FAILED,
  UPDATE_TYPE_REPORT_REQUEST,
  UPDATE_TYPE_REPORT_SUCCESS,
  UPDATE_TYPE_REPORT_FAILED,
  CREATE_UNREGISTERED_REPORT_BEGIN,
  CREATE_UNREGISTERED_REPORT_SUCCESS,
  CREATE_UNREGISTERED_REPORT_FAILED,
  FETCH_DEFECT_CONFIGURATION_SUCCESS,
  GET_CATEGORY_ATTRIBUTES_SUCCESS,
  GET_CATEGORY_ATTRIBUTES_FAILED,
  GET_CATEGORY_ATTRIBUTES_STARTED,
  UPDATE_CATEGORY_ATTRIBUTES_STARTED,
  DELETE_CATEGORY_ATTRIBUTES_STARTED,
  CREATE_ADMIN_CATEGORY_BEGIN,
  CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED,
  CLEAR_CATEGORY_ATTRIBUTES,
  UPDATE_REPORT_CONFIGS
} from '../constants/reportsActionTypes';

const initialState = Map({
  fetchingReports: false,
  reports: Map({}),
  fetchingOptionsReports: false,
  optionsReports: Map([]),
  fetchingSingleReport: false,
  singleReport: Map({}),
  fetchingReportCategories: false,
  reportCategories: List([]),
  fetchingReportSubcategories: false,
  reportSubcategories: List([]),
  updatingReport: false,
  deletingReport: false,
  fetchingReportStatuses: false,
  reportStatuses: List([]),
  reportPoint: Map({}),
  creatingReport: false,
  uploadingAttachment: false,
  creatingReportComment: false,
  reportComments: Map({}),
  fetchingReportHistory: false,
  reportHistory: Map({}),
  updatingReportComment: false,
  districtReports: List([]),
  fetchingDistrictReports: false,
  displayReports: false,
  fetchingSingleReportCategory: false,
  fetchingSingleReportSubcategory: false,
  fetchingSingleReportStatus: false,
  addingReportVote: false,
  deletingReportVote: false,
  deletingReportComment: false,
  deletingReportAttachment: false,
  creatingAssignToCategory: false,
  fetchingUserSubscriptons: false,
  usersSubscription: Map({}),
  deleteingAssignToCategory: false,
  updatingAssignToCategory: false,
  reportAllCategories: List([]),
  reportAllSubcategories: List([]),
  districtReportAllSubcategories: List([]),
  statusFilter: List([]),
  categoryFilter: List([]),
  subcategoryFilter: List([]),
  zoomToCategoryId: null,
  fetchingReportData: false,
  enabledFilter: false,
  reportConfigs: List([]),
  reportCategoryConfig: List([]),
  reportStatusConfig: List([]),
  addingReportConfig: false,
  updateingReportConfig: false,
  deleteingReportConfig: false,
  singleConfig: Map({}),
  typeReports: List([]),
  gettingTypeReports: false,
  defaultStatusTypeReport: List([]),
  showTypeReport: null,
  createingTypeReport: false,
  deleteingTypeReport: false,
  updateingTypeReport: false,
  defectConfiguration: Map({}),
  actualCategoryAttributes: List([]),
  categoryAttributesAreLoading: false,
  createAdminReportCategoryIsLoading: false
});

const fetchReportsBegin = state =>
  state.merge(
    Map({
      fetchingReports: true
    })
  );

const fetchReportsSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReports: false,
      reports: Map(action.reports)
    })
  );

const fetchReportsFailed = state =>
  state.merge(
    Map({
      fetchingReports: false
    })
  );

const fetchOptionsReportsBegin = state =>
  state.merge(
    Map({
      fetchingOptionsReports: true
    })
  );

const fetchOptionsReportsSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingOptionsReports: false,
      optionsReports: Map(action.optionsReports)
    })
  );

const fetchOptionsReportsFailed = state =>
  state.merge(
    Map({
      fetchingOptionsReports: false
    })
  );

const fetchSingleReportBegin = state =>
  state.merge(
    Map({
      fetchingSingleReport: true
    })
  );

const fetchSingleReportSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingSingleReport: false,
      singleReport: Map(action.singleReport)
    })
  );

const fetchSingleReportFailed = state =>
  state.merge(
    Map({
      fetchingSingleReport: false
    })
  );

const fetchReportCategoriesBegin = state =>
  state.merge(
    Map({
      fetchingReportCategories: true
    })
  );

const fetchReportCategoriesSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReportCategories: false,
      reportCategories: List(action.reportCategories)
    })
  );

const fetchReportCategoriesFailed = state =>
  state.merge(
    Map({
      fetchingReportCategories: false
    })
  );

const fetchReportSubcategoriesBegin = state =>
  state.merge(
    Map({
      fetchingReportSubcategories: true
    })
  );

const fetchReportSubcategoriesSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReportSubcategories: false,
      reportSubcategories: List(action.reportSubcategories)
    })
  );

const fetchReportSubcategoriesFailed = state =>
  state.merge(
    Map({
      fetchingReportSubcategories: false
    })
  );

const updateReportBegin = state =>
  state.merge(
    Map({
      updatingReport: true
    })
  );

const updateReportEnd = state =>
  state.merge(
    Map({
      updatingReport: false
    })
  );

const deleteReportBegin = state => state.set('deletingReport', true);

const deleteReportEnd = state => state.set('deletingReport', false);

const fetchReportStatusesBegin = state =>
  state.merge(
    Map({
      fetchingReportStatuses: true
    })
  );

const fetchReportStatusesSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReportStatuses: false,
      reportStatuses: List(action.reportStatuses)
    })
  );

const fetchReportStatusesFailed = state =>
  state.merge(
    Map({
      fetchingReportStatuses: false
    })
  );

const createReportStart = state => state.set('creatingReport', true);
const createReportEnd = state => state.set('creatingReport', false);

const upoadAttachmentBegin = state => state.set('uploadingAttachment', true);

const upoadAttachmentEnd = state => state.set('uploadingAttachment', false);

const setReportPoint = (state, action) =>
  state.merge(
    Map({
      reportPoint: Map({
        lat: action.reportPoint.lat,
        lng: action.reportPoint.lng
      })
    })
  );

const updateReportCommentBegin = state =>
  state.set('updatingReportComment', true);
const updateReportCommentEnd = state =>
  state.set('updatingReportComment', false);

const clearReportPoint = state => state.set('reportPoint', null);

const createReportCommentBegin = state =>
  state.set('creatingReportComment', true);
const createReportCommentEnd = state =>
  state.set('creatingReportComment', false);

const fetchReportCommentsBegin = state =>
  state.set('fetchingReportComments', true);
const fetchReportCommentsSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReportComments: false,
      reportComments: Map(action.reportComments)
    })
  );
const fetchReportCommentsFailed = state =>
  state.set('fetchingReportComments', true);

const fetchReportHistoryBegin = state =>
  state.set('fetchingReportHistory', true);
const fetchReportHistorySuccess = (state, action) =>
  state.merge(
    Map({
      fetchingReportHistory: false,
      reportHistory: Map(action.reportHistory)
    })
  );
const fetchReportHistoryFailed = state =>
  state.set('fetchingReportHistory', false);

const fetchDistrictReportsBegin = state =>
  state.set('fetchingDistrictReports', true);
const fetchDistrictReportsSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingDistrictReports: false,
      districtReports: List(action.districtReports)
    })
  );
const fetchDistrictReportsFailed = state =>
  state.set('fetchingDistrictReports', false);

const displayReports = (state, action) =>
  state.set('displayReports', action.displayReports);

const fetchSingleReportCategoryBegin = state =>
  state.set('fetchingSingleReportCategory', true);

const fetchSingleReportCategoryEnd = state =>
  state.set('fetchingSingleReportCategory', false);

const fetchSingleReportSubcategoryBegin = state =>
  state.set('fetchingSingleReportSubcategory', true);

const fetchSingleReportSubcategoryEnd = state =>
  state.set('fetchingSingleReportSubcategory', false);

const fetchSingleReportStatusBegin = state =>
  state.set('fetchingSingleReportStatus', true);

const fetchSingleReportStatusEnd = state =>
  state.set('fetchingSingleReportStatus', false);

const addReportVoteBegin = state => state.set('addingReportVote', true);
const addReportVoteEnd = state => state.set('addingReportVote', false);

const deleteReportVoteBegin = state => state.set('deletingReportVote', true);
const deleteReportVoteEnd = state => state.set('deletingReportVote', false);

const deleteReportCommentBegin = state =>
  state.set('deletingReportComment', true);
const deleteReportCommentEnd = state =>
  state.set('deletingReportComment', false);

const deleteReportAttachmentBegin = state =>
  state.set('deletingReportAttachment', true);

const deleteReportAttachmentEnd = state =>
  state.set('deletingReportAttachment', false);

const createAssignToCategoryBegin = state =>
  state.set('creatingAssignToCategory', true);

const createAssignToCategoryEnd = state =>
  state.set('creatingAssignToCategory', false);

const createAssignToCategorySuccess = (state, { userId, subscription }) => {
  const newState = createAssignToCategoryEnd(state);
  return newState.updateIn(['usersSubscriptions', userId], [], list => {
    const newList = [...list];
    newList.push(subscription);
    return newList;
  });
};

const fetchUserSubscriptionsBegin = state =>
  state.set('fetchingUserSubscriptons', true);

const fetchUserSubscriptionsEnd = state =>
  state.set('fetchingUserSubscriptons', false);

const fetchUserSubscriptionsSuccess = (state, { userId, subscriptions }) => {
  const newState = fetchUserSubscriptionsEnd(state);
  return newState.setIn(['usersSubscriptions', userId], subscriptions);
};

const deleteAssignToCategoryBegin = state =>
  state.set('deletingAssignToCategory', true);

const deleteAssignToCategoryEnd = state =>
  state.set('deletingAssignToCategory', false);

const deleteAssignToCategorySuccess = (
  state,
  { id: subscriptionId, userId }
) => {
  const newState = deleteAssignToCategoryEnd(state);
  return newState.updateIn(['usersSubscriptions', userId], [], list =>
    list.filter(({ id }) => id !== subscriptionId)
  );
};

const updateAssignToCategoryBegin = state =>
  state.set('updatingAssignToCategory', true);

const updateAssignToCategoryEnd = state =>
  state.set('updatingAssignToCategory', false);

const setSingleReport = (state, action) =>
  state.set('singleReport', Map(action.singleReport));

const fetchDistrictAllSubcategoriesSuccess = (state, action) =>
  state.merge(
    Map({
      districtReportAllSubcategories: List(action.payload)
    })
  );

const fetchDistrictAllSubcategoriesFailed = state =>
  state.set('districtReportAllSubcategories', List([]));

const setFilterStatus = (state, action) =>
  state.set('statusFilter', List(action.statusFilter));

const setFilterCategory = (state, action) =>
  state.set('categoryFilter', List(action.categoryFilter));

const zoomToCategory = (state, action) =>
  state.set('zoomToCategoryId', action.id);

const createAdminReportCategorySuccess = (state, action) =>
  state.update('reportCategories', reportCategories =>
    reportCategories.push(action.category)
  );

const createAdminReportCategoryStarted = state =>
  state.set('createAdminReportCategoryIsLoading', true);

const createAdminReportCategoryLoadingFinished = state =>
  state.set('createAdminReportCategoryIsLoading', false);

const createAdminReportSubcategorySuccess = (state, action) =>
  state.update('reportSubcategories', reportSubcategories =>
    reportSubcategories.push(action.subcategory)
  );

const deleteCategoryFromDistrictSuccess = (state, action) =>
  state.update('reportCategories', reportCategories =>
    reportCategories.filter(category => category.id !== action.categoryId)
  );

const updateAllSubcategories = (state, action) =>
  state.merge(
    Map({
      reportAllSubcategories: List(action.subcategories)
    })
  );

const fetchAdminCategoriesSuccess = (state, action) =>
  state.merge(
    Map({
      reportAllCategories: List(action.categories)
    })
  );

const fetchReportDataBeign = state => state.set('fetchingReportData', true);
const fetchReportDataEnd = state => state.set('fetchingReportData', false);

const enableFilter = (state, action) =>
  state.set('enabledFilter', action.payload);

const updateReportConfigs = (state, action) =>
  state.set('reportConfigs', List(action.payload));

const fetchReportCategoryConfigSuccess = (state, action) =>
  state.set('reportCategoryConfig', List(action.payload));

const fetchReportStatusConfigSuccess = (state, action) =>
  state.set('reportStatusConfig', List(action.payload));

const createReportConfigBegin = state => state.set('addingReportConfig', true);
const createReportConfigSuccess = (state, action) =>
  state
    .set('addingReportConfig', false)
    .update('reportConfigs', reportConfigs =>
      reportConfigs.push(action.payload)
    );
const createReportConfigFailed = state =>
  state.set('addingReportConfig', false);

const deleteReportConfigBegin = state =>
  state.set('deleteingReportConfig', true);
const deleteReportConfigSuccess = (state, action) =>
  state
    .set('deleteingReportConfig', false)
    .update('reportConfigs', reportConfigs =>
      reportConfigs.filter(config => config.id !== action.payload)
    );
const deleteReportConfigFailed = state =>
  state.set('deleteingReportConfig', false);

const createReportCategoryColor = (state, action) =>
  state.update('reportCategoryConfig', categoryConfig => {
    const config = [...categoryConfig.toJS()];
    const index = config.findIndex(v => v.id === action.payload.category);

    if (index > -1) {
      config[index].config_color = action.payload.color;
      config[index].config_id = action.payload.id;
    }

    return List(categoryConfig);
  });

const createReportStatusColor = (state, action) =>
  state.update('reportStatusConfig', statusConfig => {
    const config = [...statusConfig.toJS()];
    const index = config.findIndex(v => v.id === action.payload.status);

    if (index > -1) {
      config[index].config_color = action.payload.color;
      config[index].config_id = action.payload.id;
    }

    return List(config);
  });

const updateReportConfigRequest = state =>
  state.set('updateingReportConfig', true);
const updateReportConfigSuccess = (state, action) =>
  state
    .set('updateingReportConfig', false)
    .update('reportConfigs', reportConfigs => {
      const configs = [...reportConfigs.toJS()];
      const index = configs.findIndex(v => v.id === action.payload.id);
      if (index > -1) configs[index] = action.payload;

      return List(configs);
    })
    .set('singleConfig', Map(action.payload));
const updateReportConfigFailed = state =>
  state.set('updateingReportConfig', false);

const fetchReportSingleConfigSuccess = (state, action) =>
  state.set('singleConfig', Map(action.payload));

const fetchReportSingleConfigFailed = state =>
  state.set('singleConfig', Map({}));

const clearReportColors = state =>
  state
    .set('reportCategoryConfig', List([]))
    .set('reportStatusConfig', List([]));

const getTypeReportsRequest = state => state.set('gettingTypeReports', true);

const getTypeReportsSuccess = (state, action) =>
  state
    .set('gettingTypeReports', false)
    .set('typeReports', List(action.payload));

const getTypeReportsFailed = state =>
  state.set('gettingTypeReports', false).set('typeReports', List([]));

const getDefaultStatusTypeReportRequest = state =>
  state.set('defaultStatusTypeReport', List([]));

const getDefaultStatusTypeReportSuccess = (state, action) =>
  state.set('defaultStatusTypeReport', List(action.payload));

const getDefaultStatusTypeReportFailed = state =>
  state.set('defaultStatusTypeReport', List([]));

const createTypeReportRequest = state => state.set('createingTypeReport', true);

const createTypeReportSuccess = (state, action) =>
  state
    .set('createingTypeReport', false)
    .update('typeReports', typeReports => typeReports.push(action.payload));

const createTypeReportFailed = state => state.set('createingTypeReport', false);

const updateDefaultStatusTypeReportSuccess = (state, action) =>
  state.update('defaultStatusTypeReport', defaultStatuses => {
    const defaults = [...defaultStatuses.toJS()];
    const index = defaults.findIndex(v => v.id === action.payload.id);
    if (index > -1) defaults[index] = action.payload;

    return List(defaults);
  });

const setShowTypeReport = (state, action) =>
  state.set('showTypeReport', action.payload);

const deleteTypeReportRequest = state => state.set('deleteingTypeReport', true);

const deleteTypeReportSuccess = (state, action) =>
  state
    .set('deleteingTypeReport', false)
    .update('typeReports', typeReports =>
      typeReports.filter(v => v.id !== action.payload)
    );

const deleteTypeReportFailed = state => state.set('deleteingTypeReport', false);

const updateTypeReportRequest = state => state.set('updateingTypeReport', true);

const updateTypeReportSuccess = (state, action) =>
  state.set('updateingTypeReport', false).update('typeReports', typeReports => {
    const types = [...typeReports?.toJS()];
    const index = types.findIndex(v => v.id === action.payload.id);
    if (index > -1) {
      types[index] = action.payload;
    }

    return List(types);
  });

const updateTypeReportFailed = state => state.set('updateingTypeReport', false);

const fetchDefectConfiguration = (state, action) =>
  state.set('defectConfiguration', Map(action.payload));

const getCategoryAttributesBegin = state =>
  state.set('categoryAttributesAreLoading', true);

const getCategoryAttributesFailed = state =>
  state.set('categoryAttributesAreLoading', false);

const getCategoryAttributesSuccess = (state, action) => {
  const sortedAttributes = action.payload.sort((a, b) => {
    return a.index - b.index;
  });

  return state
    .set('actualCategoryAttributes', List(sortedAttributes))
    .set('categoryAttributesAreLoading', false);
};

const updateCategoryAttributeStarted = state =>
  state.set('categoryAttributesAreLoading', true);

const deleteCategoryAttributeStarted = state =>
  state.set('categoryAttributesAreLoading', true);

const clearCategoryAttributes = state =>
  state.set('actualCategoryAttributes', List([]));

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_ALL_REPORTS_BEGIN]: fetchReportsBegin,
      [FETCH_ALL_REPORTS_SUCCESS]: fetchReportsSuccess,
      [FETCH_ALL_REPORTS_FAILED]: fetchReportsFailed,
      [FETCH_OPTIONS_REPORTS_BEGIN]: fetchOptionsReportsBegin,
      [FETCH_OPTIONS_REPORTS_SUCCESS]: fetchOptionsReportsSuccess,
      [FETCH_OPTIONS_REPORTS_FAILED]: fetchOptionsReportsFailed,
      [FETCH_SINGLE_REPORT_BEGIN]: fetchSingleReportBegin,
      [FETCH_SINGLE_REPORT_SUCCESS]: fetchSingleReportSuccess,
      [FETCH_SINGLE_REPORT_FAILED]: fetchSingleReportFailed,
      [FETCH_REPORT_CATEGORIES_BEGIN]: fetchReportCategoriesBegin,
      [FETCH_REPORT_CATEGORIES_SUCCESS]: fetchReportCategoriesSuccess,
      [FETCH_REPORT_CATEGORIES_FAILED]: fetchReportCategoriesFailed,
      [FETCH_REPORT_SUBCATEGORIES_BEGIN]: fetchReportSubcategoriesBegin,
      [FETCH_REPORT_SUBCATEGORIES_SUCCESS]: fetchReportSubcategoriesSuccess,
      [FETCH_REPORT_SUBCATEGORIES_FAILED]: fetchReportSubcategoriesFailed,
      [UPDATE_REPORT_BEGIN]: updateReportBegin,
      [UPDATE_REPORT_END]: updateReportEnd,
      [DELETE_REPORT_BEGIN]: deleteReportBegin,
      [DELETE_REPORT_END]: deleteReportEnd,
      [FETCH_REPORT_STATUSES_BEGIN]: fetchReportStatusesBegin,
      [FETCH_REPORT_STATUSES_SUCCESS]: fetchReportStatusesSuccess,
      [FETCH_REPORT_STATUSES_FAILED]: fetchReportStatusesFailed,
      [SET_REPORT_POINT]: setReportPoint,
      [CLEAR_REPORT_POINT]: clearReportPoint,
      [CREATE_REPORT_BEGIN]: createReportStart,
      [CREATE_REPORT_SUCCESS]: createReportEnd,
      [CREATE_REPORT_FAILED]: createReportEnd,
      [CREATE_UNREGISTERED_REPORT_BEGIN]: createReportStart,
      [CREATE_UNREGISTERED_REPORT_SUCCESS]: createReportEnd,
      [CREATE_UNREGISTERED_REPORT_FAILED]: createReportEnd,
      [UPLOAD_REPORT_ATTACHMENT_BEGIN]: upoadAttachmentBegin,
      [UPLOAD_REPORT_ATTACHMENT_SUCCESS]: upoadAttachmentEnd,
      [UPLOAD_REPORT_ATTACHMENT_FAILED]: upoadAttachmentEnd,
      [CREATE_REPORT_COMMENT_BEGIN]: createReportCommentBegin,
      [CREATE_REPORT_COMMENT_SUCCESS]: createReportCommentEnd,
      [CREATE_REPORT_COMMENT_FAILED]: createReportCommentEnd,
      [FETCH_REPORT_COMMENT_BEGIN]: fetchReportCommentsBegin,
      [FETCH_REPORT_COMMENT_SUCCESS]: fetchReportCommentsSuccess,
      [FETCH_REPORT_COMMENT_FAILED]: fetchReportCommentsFailed,
      [FETCH_REPORT_HISTORY_BEGIN]: fetchReportHistoryBegin,
      [FETCH_REPORT_HISTORY_SUCCESS]: fetchReportHistorySuccess,
      [FETCH_REPORT_HISTORY_FAILED]: fetchReportHistoryFailed,
      [UPDATE_REPORT_COMMENT_BEGIN]: updateReportCommentBegin,
      [UPDATE_REPORT_COMMENT_SUCCESS]: updateReportCommentEnd,
      [UPDATE_REPORT_COMMENT_FAILED]: updateReportCommentEnd,
      [FETCH_DISTRICT_REPORTS_BEGIN]: fetchDistrictReportsBegin,
      [FETCH_DISTRICT_REPORTS_SUCCESS]: fetchDistrictReportsSuccess,
      [FETCH_DISTRICT_REPORTS_FAILED]: fetchDistrictReportsFailed,
      [DISPLAY_REPORTS]: displayReports,
      [FETCH_SINGLE_REPORT_CATEGORY_BEGIN]: fetchSingleReportCategoryBegin,
      [FETCH_SINGLE_REPORT_CATEGORY_SUCCESS]: fetchSingleReportCategoryEnd,
      [FETCH_SINGLE_REPORT_CATEGORY_FAILED]: fetchSingleReportCategoryEnd,
      [FETCH_SINGLE_REPORT_SUBCATEGORY_BEGIN]: fetchSingleReportSubcategoryBegin,
      [FETCH_SINGLE_REPORT_SUBCATEGORY_SUCCESS]: fetchSingleReportSubcategoryEnd,
      [FETCH_SINGLE_REPORT_SUBCATEGORY_FAILED]: fetchSingleReportSubcategoryEnd,
      [FETCH_SINGLE_REPORT_STATUS_BEGIN]: fetchSingleReportStatusBegin,
      [FETCH_SINGLE_REPORT_STATUS_SUCCESS]: fetchSingleReportStatusEnd,
      [FETCH_SINGLE_REPORT_STATUS_FAILED]: fetchSingleReportStatusEnd,
      [ADD_REPORT_VOTE_BEGIN]: addReportVoteBegin,
      [ADD_REPORT_VOTE_SUCCESS]: addReportVoteEnd,
      [ADD_REPORT_VOTE_FAILED]: addReportVoteEnd,
      [DELETE_REPORT_VOTE_BEGIN]: deleteReportVoteBegin,
      [DELETE_REPORT_VOTE_SUCCESS]: deleteReportVoteEnd,
      [DELETE_REPORT_VOTE_FAILED]: deleteReportVoteEnd,
      [DELETE_REPORT_COMMENT_BEGIN]: deleteReportCommentBegin,
      [DELETE_REPORT_COMMENT_SUCCESS]: deleteReportCommentEnd,
      [DELETE_REPORT_COMMENT_FAILED]: deleteReportCommentEnd,
      [DELETE_REPORT_ATTACHMENT]: deleteReportAttachmentBegin,
      [DELETE_REPORT_ATTACHMENT_SUCCESS]: deleteReportAttachmentEnd,
      [DELETE_REPORT_ATTACHMENT_FAILED]: deleteReportAttachmentEnd,
      [CREATE_ASSIGN_TO_CATEGORY]: createAssignToCategoryBegin,
      [CREATE_ASSIGN_TO_CATEGORY_SUCCESS]: createAssignToCategorySuccess,
      [CREATE_ASSIGN_TO_CATEGORY_FAILED]: createAssignToCategoryEnd,
      [FETCH_USER_SUBSCRIPTIONS]: fetchUserSubscriptionsBegin,
      [FETCH_USER_SUBSCRIPTIONS_SUCCESS]: fetchUserSubscriptionsSuccess,
      [FETCH_USER_SUBSCRIPTIONS_FAILED]: fetchUserSubscriptionsEnd,
      [DELETE_ASSIGN_TO_CATEGORY]: deleteAssignToCategoryBegin,
      [DELETE_ASSIGN_TO_CATEGORY_SUCCESS]: deleteAssignToCategorySuccess,
      [DELETE_ASSIGN_TO_CATEGORY_FAILED]: deleteAssignToCategoryEnd,
      [UPDATE_ASSIGN_TO_CATEGORY]: updateAssignToCategoryBegin,
      [UPDATE_ASSIGN_TO_CATEGORY_SUCCESS]: updateAssignToCategoryEnd,
      [UPDATE_ASSIGN_TO_CATEGORY_FAILED]: updateAssignToCategoryEnd,
      [SET_SINGLE_REPORT]: setSingleReport,
      [FETCH_ALL_SUBCATEGORIES_SUCCESS]: fetchDistrictAllSubcategoriesSuccess,
      [FETCH_ALL_SUBCATEGORIES_FAILED]: fetchDistrictAllSubcategoriesFailed,
      [SET_STATUS_FILTER]: setFilterStatus,
      [SET_CATEGORY_FILTER]: setFilterCategory,
      [ZOOM_TO_CATEGORY]: zoomToCategory,
      [CREATE_ADMIN_CATEGORY_BEGIN]: createAdminReportCategoryStarted,
      [CREATE_ADMIN_REPORT_CATEGORY_LOADING_FINISHED]: createAdminReportCategoryLoadingFinished,
      [CREATE_ADMIN_CATEGORY_SUCCESS]: createAdminReportCategorySuccess,
      [CREATE_ADMIN_SUBCATEGORY_SUCCESS]: createAdminReportSubcategorySuccess,
      [DELETE_CATEGORY_FROM_DISTRICT_SUCCESS]: deleteCategoryFromDistrictSuccess,
      [UPDATE_ADMIN_SUBCATEGORIES]: updateAllSubcategories,
      [FETCH_ADMIN_CATEGORIES_SUCCESS]: fetchAdminCategoriesSuccess,
      [FETCH_REPORT_DATA_BEGIN]: fetchReportDataBeign,
      [FETCH_REPORT_DATA_SUCCESS]: fetchReportDataEnd,
      [FETCH_REPORT_DATA_FAILED]: fetchReportDataEnd,
      [ENABLE_FILTERS]: enableFilter,
      [UPDATE_REPORT_CONFIGS]: updateReportConfigs,
      [FETCH_REPORT_CATEGORY_CONFIG_SUCCESS]: fetchReportCategoryConfigSuccess,
      [FETCH_REPORT_STATUS_CONFIG_SUCCESS]: fetchReportStatusConfigSuccess,
      [CREATE_REPORT_CONFIG_BEGIN]: createReportConfigBegin,
      [CREATE_REPORT_CONFIG_SUCCESS]: createReportConfigSuccess,
      [CREATE_REPORT_CONFIG_FAILED]: createReportConfigFailed,
      [DELETE_REPORT_CONFIG_BEGIN]: deleteReportConfigBegin,
      [DELETE_REPORT_CONFIG_SUCCESS]: deleteReportConfigSuccess,
      [DELETE_REPORT_CONFIG_FAILED]: deleteReportConfigFailed,
      [CRAETE_REPORT_CATEGORY_COLOR_SUCCESS]: createReportCategoryColor,
      [UPDATE_REPORT_CATEGORY_COLOR_SUCCESS]: createReportCategoryColor,
      [CREATE_REPORT_STATUS_COLOR_SUCCESS]: createReportStatusColor,
      [UPDATE_REPORT_STATUS_COLOR_SUCCESS]: createReportStatusColor,
      [UPDATE_REPORT_CONFIG_BEGIN]: updateReportConfigRequest,
      [UPDATE_REPORT_CONFIG_SUCCESS]: updateReportConfigSuccess,
      [UPDATE_REPORT_CONFIG_FAILED]: updateReportConfigFailed,
      [FETCH_REPORT_SINGLE_CONFIG_SUCCESS]: fetchReportSingleConfigSuccess,
      [FETCH_REPORT_SINGLE_CONFIG_FAILED]: fetchReportSingleConfigFailed,
      [CLEAR_REPORT_COLORS]: clearReportColors,
      [GET_TYPE_REPORTS_REQUEST]: getTypeReportsRequest,
      [GET_TYPE_REPORTS_SUCCESS]: getTypeReportsSuccess,
      [GET_TYPE_REPORTS_FAILED]: getTypeReportsFailed,
      [GET_DEFAULT_STATUS_TYPE_REPORT_REQUEST]: getDefaultStatusTypeReportRequest,
      [GET_DEFAULT_STATUS_TYPE_REPORT_SUCCESS]: getDefaultStatusTypeReportSuccess,
      [GET_DEFAULT_STATUS_TYPE_REPORT_FAILED]: getDefaultStatusTypeReportFailed,
      [UPDATE_DEFAULT_STATUS_TYPE_REPORT_SUCCESS]: updateDefaultStatusTypeReportSuccess,
      [SHOW_TYPE_REPORT]: setShowTypeReport,
      [CREATE_TYPE_REPORT_REQUEST]: createTypeReportRequest,
      [CREATE_TYPE_REPORT_SUCCESS]: createTypeReportSuccess,
      [CREATE_TYPE_REPORT_FAILED]: createTypeReportFailed,
      [DELETE_TYPE_REPORT_REQUEST]: deleteTypeReportRequest,
      [DELETE_TYPE_REPORT_SUCCESS]: deleteTypeReportSuccess,
      [DELETE_TYPE_REPORT_FAILED]: deleteTypeReportFailed,
      [UPDATE_TYPE_REPORT_REQUEST]: updateTypeReportRequest,
      [UPDATE_TYPE_REPORT_SUCCESS]: updateTypeReportSuccess,
      [UPDATE_TYPE_REPORT_FAILED]: updateTypeReportFailed,
      [FETCH_DEFECT_CONFIGURATION_SUCCESS]: fetchDefectConfiguration,
      [GET_CATEGORY_ATTRIBUTES_STARTED]: getCategoryAttributesBegin,
      [GET_CATEGORY_ATTRIBUTES_SUCCESS]: getCategoryAttributesSuccess,
      [GET_CATEGORY_ATTRIBUTES_FAILED]: getCategoryAttributesFailed,
      [UPDATE_CATEGORY_ATTRIBUTES_STARTED]: updateCategoryAttributeStarted,
      [DELETE_CATEGORY_ATTRIBUTES_STARTED]: deleteCategoryAttributeStarted,
      [CLEAR_CATEGORY_ATTRIBUTES]: clearCategoryAttributes
    }[action.type] || (s => s)
  )(state, action);
}
