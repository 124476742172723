import { List } from 'immutable';

const selectors = {
  getCompositionTopicalSearchTools: (state, district, compositionId) =>
    state.searchTools.getIn([
      'compositionSearchTools',
      district,
      compositionId
    ]) || List([]),
  getTopicalSearchToolColumns: (state, toolId) =>
    state.searchTools.getIn(['topicalSearchToolsColumns', toolId]),
  getTopicalSearchToolResultColumns: (state, toolId) =>
    state.searchTools.getIn(['topicalSearchToolsResultColumns', toolId]),
  getLayerLookupFields: (state, contentTypeId) =>
    state.searchTools.getIn(['layerLookupFields', contentTypeId]),
  getLayerResultFields: (state, contentTypeId) =>
    state.searchTools.getIn(['layerResultFields', contentTypeId]),
  getLayerFieldSearchTypes: (state, contentTypeId) =>
    state.searchTools.getIn(['layerFieldSearchTypes', contentTypeId]),
  getDistrictGeonetworkSearchToolAvailability: (state, district) =>
    state.searchTools.getIn(['geonetworkSearchToolAvailability', district]),
  getSearchNextPage: state =>
    state.searchTools.get('topicalSearchResultsNextPage'),
  getFetchingTopicalSearchResults: state =>
    state.searchTools.get('fetchingTopicalSearchResults'),
  getTopicalSearchResults: state =>
    state.searchTools.get('topicalSearchResults'),
  getTopicalSearchGeometryResults: state =>
    state.searchTools.get('topicalSearchGeometryResults'),
  getTopicalSearchGeometryAllResults: state =>
    state.searchTools.get('topicalSearchGeometryAllResults'),
  getIsTopicalSearchToolsFetched: (state, district, compositionId) =>
    state.searchTools.getIn([
      'isCompositionSearchToolsFetched',
      district,
      compositionId
    ]) || false,
  getTopicalSearchToolId: state =>
    state.searchTools.getIn(['searchedColVal', 'topicalSearchToolId']),
  getTopicalSearchCounter: state =>
    state.searchTools.get('topicalSearchCounter'),
  getTopicalSearchValues: state => state.searchTools.get('searchedColVal')
};

export default selectors;
