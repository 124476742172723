import { districtApi } from './district';
import { editDataApi } from './editData';
import { globalApi } from './global';
import { mapPortalApi } from './mapPortal';
import { modelsApi } from './modelsData';
import { parcelsApi } from './parcels';
import { popupApi } from './popup';
import { registersApi } from './registers';
import { reportApi } from './report';
import { searchToolApi } from './searchTool';
import { wmsApi } from './wms';
import { upulApi } from './upul';
import { finnApi } from './finn';
import { selectionApi } from './selection';
import { locationApi } from './location';

export const apiReducers = {
  [parcelsApi.reducerPath]: parcelsApi.reducer,
  [popupApi.reducerPath]: popupApi.reducer,
  [mapPortalApi.reducerPath]: mapPortalApi.reducer,
  [districtApi.reducerPath]: districtApi.reducer,
  [reportApi.reducerPath]: reportApi.reducer,
  [wmsApi.reducerPath]: wmsApi.reducer,
  [globalApi.reducerPath]: globalApi.reducer,
  [editDataApi.reducerPath]: editDataApi.reducer,
  [modelsApi.reducerPath]: modelsApi.reducer,
  [registersApi.reducerPath]: registersApi.reducer,
  [searchToolApi.reducerPath]: searchToolApi.reducer,
  [upulApi.reducerPath]: upulApi.reducer,
  [finnApi.reducerPath]: finnApi.reducer,
  [selectionApi.reducerPath]: selectionApi.reducer,
  [locationApi.reducerPath]: locationApi.reducer
};

export const apiMiddlewares = [
  parcelsApi.middleware,
  popupApi.middleware,
  mapPortalApi.middleware,
  districtApi.middleware,
  reportApi.middleware,
  wmsApi.middleware,
  globalApi.middleware,
  editDataApi.middleware,
  modelsApi.middleware,
  registersApi.middleware,
  searchToolApi.middleware,
  upulApi.middleware,
  finnApi.middleware,
  selectionApi.middleware,
  locationApi.middleware
];
