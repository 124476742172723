/**
 * Usuwa ewentualny znak / z początku przekazanej ścieżki,
 * czyli dla "/zawidow/portal/" zwróci "zawidow/portal/".
 *
 * @param {string} path
 * @return {string}
 */
const removeFirstSlashFromPath = path => {
  if (!path.startsWith('/')) {
    return path;
  }

  const newPath = [...path].join('');
  return newPath.slice(1);
};

export default removeFirstSlashFromPath;
