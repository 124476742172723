import { createContext, createRef } from 'react';
import { Map } from 'leaflet';

import { TLeafletMapContext } from '@/types/contexts';

const LeafletMapContext = createContext<TLeafletMapContext>({
  leafletMap: createRef(),
  getLeafletElement: () => null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  toggleGeolocation: (map: Map, coords?: unknown, zoom?: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  stopWatchPosition: () => {}
});

LeafletMapContext.displayName = 'LeafletMapContext';

export default LeafletMapContext;
