import { TSelectionLayerAttribute } from '../responses/selection-layer-attributes';

export enum ESelectionTags {
  SELECTION_TAGS = 'selectionTags'
}

export type TFetchLayerSelectionAttributesParams = {
  district: string;
  compositionId: number;
  layerId: number;
};

export type TUpdateLayerSelectionAttributesParams = TFetchLayerSelectionAttributesParams & {
  data: Partial<TSelectionLayerAttribute>[];
};
