import handleApiError from '@/utils/handleApiError';
import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  fetchHeaderLinkBegin,
  fetchHeaderLinkFailed,
  fetchHeaderLinkSuccess
} from '@/store/actions/globalActions';

import { EGlobalTags, THeaderLinksParams } from '@/types/api-types/global';
import { THeaderLinksResponse } from '@/types/header-links';

export const globalApi = createApi({
  reducerPath: 'globalApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EGlobalTags.HEADER_LINKS],
  endpoints: builder => ({
    fetchHeaderLinks: builder.query<THeaderLinksResponse[], THeaderLinksParams>(
      {
        query: ({ district, map_portal, map_portal_composition } = {}) => ({
          method: 'GET',
          url: `${district ? `${district}/` : ''}${
            map_portal ? `map_portal/${map_portal}/` : ''
          }${
            map_portal_composition
              ? `map_portal_composition/${map_portal_composition}/`
              : ''
          }header_links/`
        }),
        onQueryStarted: async (arg, { dispatch, getState, queryFulfilled }) => {
          dispatch(fetchHeaderLinkBegin());

          try {
            const { data } = await queryFulfilled;
            dispatch(fetchHeaderLinkSuccess(data));
          } catch (error) {
            dispatch(fetchHeaderLinkFailed());
            handleApiError(error, dispatch);
          }
        },
        providesTags: data =>
          data
            ? [
                ...data.map(({ id }: { id: number }) => ({
                  type: EGlobalTags.HEADER_LINKS,
                  id: id ?? ''
                })),
                {
                  type: EGlobalTags.HEADER_LINKS,
                  id: EGlobalTags.HEADER_LINKS
                }
              ]
            : [
                {
                  type: EGlobalTags.HEADER_LINKS,
                  id: EGlobalTags.HEADER_LINKS
                }
              ]
      }
    )
  }),
  keepUnusedDataFor: 1200
});

export const { useLazyFetchHeaderLinksQuery } = globalApi;
