const filterModelTranslationsByObjectId = (
  modelsTranslations,
  objectId,
  contentField
) => {
  const translations = {};

  if (!modelsTranslations) return translations;

  modelsTranslations.forEach(translation => {
    if (
      translation.object_id !== Number(objectId) ||
      (contentField ? translation.content_field !== contentField : false)
    ) {
      return;
    }
    translations[translation.language] = {
      id: translation.id,
      value: translation.translated_value
    };
  });

  return translations;
};

export default filterModelTranslationsByObjectId;
