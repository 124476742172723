import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import { showSuccess } from '@/store/actions/globalActions';

import handleApiError from '@/utils/handleApiError';

import { TSelectionLayerAttribute } from '@/types/responses/selection-layer-attributes';
import {
  ESelectionTags,
  TFetchLayerSelectionAttributesParams,
  TUpdateLayerSelectionAttributesParams
} from '@/types/api-types/selection';

export const selectionApi = createApi({
  reducerPath: 'selectionApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [ESelectionTags.SELECTION_TAGS],
  endpoints: builder => ({
    fetchLayerSelectionAttributes: builder.query<
      TSelectionLayerAttribute[],
      TFetchLayerSelectionAttributesParams
    >({
      query: ({ district, compositionId, layerId }) => ({
        method: 'GET',
        url: `${district}/map_portal_composition/${compositionId}/selection_layers_config/${layerId}/attributes/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ESelectionTags.SELECTION_TAGS,
                id: id ?? ''
              })),
              {
                type: ESelectionTags.SELECTION_TAGS,
                id: ESelectionTags.SELECTION_TAGS
              }
            ]
          : [
              {
                type: ESelectionTags.SELECTION_TAGS,
                id: ESelectionTags.SELECTION_TAGS
              }
            ]
    }),
    updateLayerSelectionAttributes: builder.mutation<
      TSelectionLayerAttribute[],
      TUpdateLayerSelectionAttributesParams
    >({
      query: ({ district, compositionId, layerId, data }) => ({
        method: 'PATCH',
        url: `${district}/map_portal_composition/${compositionId}/selection_layers_config/${layerId}/attributes/`,
        data
      }),
      onQueryStarted: async (
        { district, compositionId, layerId },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data: updatedData } = await queryFulfilled;

          // The following method is responsible for replacing the cached data
          // for the request with the same parameters, as the response contains
          // all the updated elements from the previously fetched list.
          dispatch(
            selectionApi.util.updateQueryData(
              'fetchLayerSelectionAttributes',
              {
                district,
                compositionId,
                layerId
              },
              () => updatedData
            )
          );

          dispatch(showSuccess('Pomyślnie zaktualizowano listę atrybutów'));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      }
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchLayerSelectionAttributesQuery,
  useUpdateLayerSelectionAttributesMutation
} = selectionApi;
