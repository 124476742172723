import { point } from 'leaflet';
import transformCoordinates from '../transformCoordinates';

import { invertedCoordsCrs } from '../../../mapConfig';

/**
 * Funkcja zwraca tablicę ze współrzędnymi wszystkich 4 krawędzi mapy.
 *
 * @param {Leaflet.Map} map - obiekt mapy.
 * @param {number} config.yOffset - opcjonalne przesunięcie względem osi Y.
 * @param {boolean} config.bboxString - ustala czy funkcja ma zwrócić BBOX string ("minX,minY,maxX,maxY").
 * @param {string} config.bboxStringCrs - oczekiwany układ współrzędnych BBOX string.
 * @returns {Leaflet.LatLng[]} - współrzędne wg. porządku: [NW, NE, SE, SW].
 */

const getBboxString = (NW, SE, crs) => {
  let northWest = [NW.lat, NW.lng];
  let southEast = [SE.lat, SE.lng];

  if (crs) {
    const isInvertedCoordsCrs = invertedCoordsCrs.includes(crs);

    if (isInvertedCoordsCrs) {
      northWest.reverse();
      southEast.reverse();
    }

    northWest = transformCoordinates('EPSG:4326', crs, northWest);
    southEast = transformCoordinates('EPSG:4326', crs, southEast);
  }

  const isEPSG2180 = crs === 'EPSG:2180';
  const latIndex = isEPSG2180 ? 1 : 0;
  const lngIndex = isEPSG2180 ? 0 : 1;

  const minLat = Math.min(northWest[latIndex], southEast[latIndex]);
  const minLng = Math.min(northWest[lngIndex], southEast[lngIndex]);
  const maxLat = Math.max(northWest[latIndex], southEast[latIndex]);
  const maxLng = Math.max(northWest[lngIndex], southEast[lngIndex]);

  return `${minLat},${minLng},${maxLat},${maxLng}`;
};

const getMapContainerBboxPolygon = (
  map,
  { yOffset = 0, bboxString = false, bboxStringCrs } = {}
) => {
  if (!map) return null;

  const { x: sizeX, ...size } = map.getSize();
  const sizeY = size.y + yOffset;

  const NW = map.containerPointToLatLng(point(0, yOffset));
  const NE = map.containerPointToLatLng(point(sizeX, yOffset));
  const SW = map.containerPointToLatLng(point(0, sizeY));
  const SE = map.containerPointToLatLng(point(sizeX, sizeY));

  if (bboxString) return getBboxString(NW, SE, bboxStringCrs);
  return [NW, NE, SE, SW];
};

export default getMapContainerBboxPolygon;
