/**
 * Zamienia specjalne znaki na te same znaki poprzedzając \\.
 * @param {string} baseString
 * @return {string}
 */
const replaceSpecialCharacters = baseString => {
  var regexSpecialCharacters = [
    '\\',
    '.',
    '+',
    '*',
    '?',
    '[',
    '^',
    ']',
    '$',
    '(',
    ')',
    '{',
    '}',
    '=',
    '!',
    '<',
    '>',
    '|',
    ':',
    '-'
  ];

  regexSpecialCharacters.forEach(
    rgxSpecChar =>
      (baseString = baseString.replace(
        new RegExp('\\' + rgxSpecChar, 'gm'),
        '\\' + rgxSpecChar
      ))
  );

  return baseString;
};

export default replaceSpecialCharacters;
