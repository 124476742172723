import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Progress } from 'antd';

import { districtSelectors, globalSelectors } from '../../store/selectors';

import useMapPortalMainLoader from '@/hooks/useMapPortalMainLoader';
import useGlobalSettings from '../../hooks/useGlobalSettings';
import useComponentsToRender from '../../hooks/useComponentsToRender';
import useTranslations from '@/hooks/useTranslations';

import { isCrestLogoType } from '@/utils/isCrestLogoType';

import PureBrandComponent from '../../components/Brand/PureBrandComponent';

import { translations } from './consts';

import { portalLoaderFontType, primaryAppColor } from '@/constants/styles';
import './ComponentsReadyListener.less';

const { getCrs } = globalSelectors;
const { getAppDistrictDetails } = districtSelectors;

export const ComponentsReadyListener = () => {
  const [onceComponentPercent, setOneComponentPercent] = useState(0);
  const [percent, setPercent] = useState(0);
  const [isAppReady, setIsAppReady] = useState(false);
  const componentsToRender = useComponentsToRender();

  const { loadingMainPortal, loadingInProgress } = useTranslations(
    translations
  );

  const crs = useSelector(getCrs);
  const renderedComponents = useSelector(state =>
    state.global.get('renderedComponents')
  );

  const extractSegments = () => {
    const { pathname } = window.location;
    const segments = pathname.split('/');

    return {
      prefix: segments[1] || null,
      shortName: segments[2] || null
    };
  };

  const { prefix, shortName } = extractSegments();

  const districtDetails = useSelector(state =>
    getAppDistrictDetails(state, prefix)
  );

  const mapPortal = useMapPortalMainLoader({
    shortName,
    prefix
  });

  const {
    unofficial_character_of_portal_info_text: unofficialCharacterOfPortalInfoText,
    system_second_title: systemSecondTitle,
    system_loader: systemlLoader,
    current_theme: currentGlobalTheme
  } = useGlobalSettings();

  const portalTitle = mapPortal?.get('name') || undefined;
  const portalSecondTitle = mapPortal?.get('second_title') || undefined;
  const logo = mapPortal?.get('logo') || null;

  const mainPortalLoaderStyle = {
    color: currentGlobalTheme
      ? currentGlobalTheme.first_color
      : primaryAppColor,
    fontFamily: portalLoaderFontType
  };

  const [loaderSrc, setLoaderSrc] = useState(
    localStorage?.getItem('system_loader')
  );

  React.useEffect(() => {
    if (
      componentsToRender.length &&
      (componentsToRender.length !== renderedComponents.size ||
        !renderedComponents.size)
    )
      setIsAppReady(false);
    setOneComponentPercent(100 / componentsToRender.length);
  }, [renderedComponents, componentsToRender]);

  React.useEffect(() => {
    checkComponentsToRender();
  }, [renderedComponents]);

  React.useEffect(() => {
    if (systemlLoader !== loaderSrc) {
      setLoaderSrc(systemlLoader);
    }
  }, [systemlLoader]);

  const checkComponentsToRender = () => {
    const numberRenderedComponents = componentsToRender.filter(component =>
      renderedComponents?.get(component)
    ).length;

    setPercent(parseInt(numberRenderedComponents * onceComponentPercent));

    if (numberRenderedComponents === componentsToRender.length && !!crs) {
      setIsAppReady(true);
    }
  };

  const loader_logo_graphics = districtDetails?.loader_logo_graphics;
  const loader_logo_type = districtDetails?.loader_logo_type;

  const settingsLogo = isCrestLogoType(loader_logo_type)
    ? logo
    : loader_logo_graphics;

  return (
    <div
      className={classNames('main-loader-bg', {
        hidden: isAppReady
      })}
    >
      <div className="main-loader">
        <div className="brand-border">
          <PureBrandComponent
            darkTitle
            settingsLogo={settingsLogo}
            settingsTitle={portalTitle ?? loadingMainPortal}
            settingsSubtitle={
              portalSecondTitle || (portalTitle ? systemSecondTitle : null)
            }
            isMainPortalLoader
            mainPortalLoaderStyle={mainPortalLoaderStyle}
          />
        </div>
        <div className="main-loader-border">
          <span>{unofficialCharacterOfPortalInfoText}</span>
          {loaderSrc && <img src={loaderSrc} alt={loadingInProgress} />}
        </div>
        <Progress percent={percent} />
      </div>
    </div>
  );
};

export default ComponentsReadyListener;
