import axios from 'axios';

const getDictionariesFromResponses = responses => {
  const dictionaries = {};

  responses.forEach(({ config, data }) => {
    const attributeId = Number(config.url.split('/')[4]);
    dictionaries[attributeId] = data;
  });

  return dictionaries;
};

// Tworzymy mapę, żeby później za każdym razem nie mapować
// słownika np. podczas renderowania elementów na liście.
const getDictionariesMap = dictionaries => {
  const dictionariesMap = {};

  Object.keys(dictionaries).forEach(attributeId => {
    dictionariesMap[attributeId] = dictionaries[attributeId].map(
      ({ value }) => value
    );
  });

  return dictionariesMap;
};

const getFormDictionaries = async ({ attributes, district, formId }) => {
  const promises = attributes.map(({ id }) =>
    axios.get(`${district}/layer_forms/${formId}/attrs/${id}/values/`)
  );

  const responses = await Promise.all(promises);
  const dictionaries = getDictionariesFromResponses(responses);

  return { dictionaries, dictionariesMap: getDictionariesMap(dictionaries) };
};

export default getFormDictionaries;
