import isLoginRedirectAllowed from '../isLoginRedirectAllowed';
import codeUrl from '../codeUrl';

import portalPaths from '../../../router/portal/paths';

const { login: loginPath } = portalPaths;

const goToLoginPage = (pathname, push) => {
  const redirectUrl = isLoginRedirectAllowed(pathname)
    ? `${loginPath}?redirect=${codeUrl(pathname)}`
    : loginPath;

  push(redirectUrl);
};

export default goToLoginPage;
