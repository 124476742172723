// Helper function to determine the priority of characters
const getCharPriority = (char: string): number => {
  if (/[a-zA-Z]/.test(char)) return 1; // Letters have the highest priority
  if (/[0-9]/.test(char)) return 2; // Digits have medium priority
  return 3; // Special characters have the lowest priority
};

/**
 * The function responsible for sorting the array of strings which includes
 * letters, digits and special characters
 */
const sortIds = (array: string[]): string[] => {
  return array.sort((a, b) => {
    // Compare the first characters of strings to determine their order
    for (let i = 0; i < Math.min(a.length, b.length); i++) {
      const priorityA = getCharPriority(a[i]);
      const priorityB = getCharPriority(b[i]);

      if (priorityA !== priorityB) return priorityA - priorityB;
      if (a[i].toLowerCase() !== b[i].toLowerCase()) {
        return a[i].toLowerCase() < b[i].toLowerCase() ? -1 : 1;
      }
    }

    // If all compared characters are the same, the shorter string has higher priority
    return a.length - b.length;
  });
};

export default sortIds;
