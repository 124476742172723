const getValuesFromObject = (
  reference: Record<string, string>,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>
): Record<string, unknown> => {
  const result: Record<string, unknown> = {};

  Object.keys(reference).forEach(key => {
    if (key in data) {
      result[key] = data[key];
    }
  });

  return result;
};

export default getValuesFromObject;
