// W niektórych modelach do współrzędnych [0, 0] dodawane są wartości po
// przecinku. Jest to efekt transformacji współrzędnych. Poniższa funkcja
// parsuje współrzędne do liczb calkowitych, tak aby mieć pewność, że dane X/Y
// oznaczają pustą geometrię.
const mapCoordsToIntegers = coords =>
  coords.map(value => {
    if (Array.isArray(value)) {
      return mapCoordsToIntegers(value);
    }

    if (typeof value === 'number') {
      return parseInt(value);
    }

    return value;
  });

export default mapCoordsToIntegers;
