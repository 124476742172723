export const JWT_CREATE_BEGIN = 'JWT_CREATE_BEGIN';
export const JWT_CREATE_FINISHED = 'JWT_CREATE_FINISHED';
export const JWT_CREATE = 'JWT_CREATE';
export const JWT_VERIFY = 'JWT_VERIFY';

export const CREATE_USER_BEGIN = 'CREATE_USER_BEGIN';
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS';
export const CREATE_USER_FAILED = 'CREATE_USER_FAILED';
export const CREATE_USER_RESET = 'CREATE_USER_RESET';
export const VERIFY_USER_EMAIL_BEGINS = 'VERIFY_USER_EMAIL_BEGINS';
export const VERIFY_USER_EMAIL_FINISHED = 'VERIFY_USER_EMAIL_FINISHED';
export const SET_LOGOUT_PROCESSING = 'SET_LOGOUT_PROCESSING';
export const USER_LOGIN_BEGINS = 'USER_LOGIN_BEGINS';
export const USER_LOGIN_FINISHED = 'USER_LOGIN_FINISHED';
export const USER_LOGOUT_FINISHED = 'USER_LOGOUT_FINISHED';
export const REGISTER_CLEAR = 'REGISTER_CLEAR';
export const FETCH_USER_DATA_FINISHED = 'FETCH_USER_DATA_FINISHED';
export const UPDATE_USER_DATA_FINISHED = 'UPDATE_USER_DATA_FINISHED';
export const FETCH_USER_DATA_BEGINS = 'FETCH_USER_DATA_BEGINS';
export const GROUPS_FETCH_BEGINS = 'GROUPS_FETCH_BEGINS';
export const GROUPS_FETCH_FINISHED = 'GROUPS_FETCH_FINISHED';
export const FETCH_USERS_BEGIN = 'FETCH_USERS_BEGIN';
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS';

export const FETCH_USER_PORTAL_DATA_BEGIN = 'FETCH_USER_PORTAL_DATA_BEGIN';
export const FETCH_USER_PORTAL_DATA_SUCCESS = 'FETCH_USER_PORTAL_DATA_SUCCESS';
export const FETCH_USER_PORTAL_DATA_FAILED = 'FETCH_USER_PORTAL_DATA_FAILED';

export const SAVE_USER_ROLE_BEGIN = 'SAVE_USER_ROLE_BEGIN';
export const SAVE_USER_ROLE_SUCCESS = 'SAVE_USER_ROLE_SUCCESS';
export const SAVE_USER_ROLE_FAILED = 'SAVE_USER_ROLE_FAILED';

export const DELETE_USER_ROLE_FROM_DISTRICT_BEGIN =
  'DELETE_USER_ROLE_FROM_DISTRICT_BEGIN';
export const DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS =
  'DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS';
export const DELETE_USER_ROLE_FROM_DISTRICT_FAILED =
  'DELETE_USER_ROLE_FROM_DISTRICT_FAILED';

export const CREATE_BASIC_ROLE_FOR_USER_BEGIN =
  'CREATE_BASIC_ROLE_FOR_USER_BEGIN';
export const CREATE_BASIC_ROLE_FOR_USER_SUCCESS =
  'CREATE_BASIC_ROLE_FOR_USER_SUCCESS';
export const CREATE_BASIC_ROLE_FOR_USER_FAILED =
  'CREATE_BASIC_ROLE_FOR_USER_FAILED';

export const FETCH_USER_IMAGE_BEGIN = 'FETCH_USER_IMAGE_BEGIN';
export const FETCH_USER_IMAGE_SUCCESS = 'FETCH_USER_IMAGE_SUCCESS';
export const FETCH_USER_IMAGE_FAILED = 'FETCH_USER_IMAGE_FAILED';

export const FETCH_USER_DATA_BEGIN = 'FETCH_USER_DATA_BEGIN';
export const FETCH_USER_DATA_SUCCESS = 'FETCH_USER_DATA_SUCCESS';
export const FETCH_USER_DATA_FAILED = 'FETCH_USER_DATA_FAILED';

export const FETCH_USER_BEGIN = 'FETCH_USER_BEGIN';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILED = 'FETCH_USER_FAILED';

export const ADD_USER_TO_LIST = 'ADD_USER_TO_LIST';

export const FETCH_AUTH_USER_DATA_SUCCESS = 'FETCH_AUTH_USER_DATA_SUCCESS';
export const FETCH_AUTH_USER_DATA_FAILED = 'FETCH_AUTH_USER_DATA_FAILED';

export const FETCH_ALL_USERS_BEGIN = 'FETCH_ALL_USERS_BEGIN';
export const FETCH_ALL_USERS_SUCCESS = 'FETCH_ALL_USERS_SUCCESS';
export const FETCH_ALL_USERS_FAILED = 'FETCH_ALL_USERS_FAILED';

export const PROMOTE_ADMIN_BEGIN = 'PROMOTE_ADMIN_BEGIN';
export const PROMOTE_ADMIN_SUCCESS = 'PROMOTE_ADMIN_SUCCESS';
export const PROMOTE_ADMIN_FAILED = 'PROMOTE_ADMIN_FAILED';

export const DEMOTE_ADMIN_BEGIN = 'DEMOTE_ADMIN_BEGIN';
export const DEMOTE_ADMIN_SUCCESS = 'DEMOTE_ADMIN_SUCCESS';
export const DEMOTE_ADMIN_FAILED = 'DEMOTE_ADMIN_FAILED';

export const FETCH_ADMINS_BEGIN = 'FETCH_ADMINS_BEGIN';
export const FETCH_ADMINS_SUCCESS = 'FETCH_ADMINS_SUCCESS';
export const FETCH_ADMINS_FAILED = 'FETCH_ADMINS_FAILED';

export const DEACTIVATE_USER_BEGIN = 'DEACTIVATE_USER_BEGIN';
export const DEACTIVATE_USER_SUCCESS = 'DEACTIVATE_USER_SUCCESS';
export const DEACTIVATE_USER_FAILED = 'DEACTIVATE_USER_FAILED';

export const SET_NOT_A_ROBOT = 'SET_NOT_A_ROBOT';

/*
TOKEN_CREATE
TOKEN_DESTROY
TOKEN_LOGIN
TOKEN_LOGOUT

CHANGE_USERNAME

JWT_CREATE
JWT_REFRESH
JWT_VERIFY

*/
