/**
 * Funkcja zwracająca listę z widocznymi warstwami w grupie
 * @param {object} group
 * @return {array}
 */
const visibleLayersInGroup = group => {
  const layers = [];
  for (const elem of group.entries) {
    if (elem.type === 'group') {
      return visibleLayersInGroup(elem);
    } else {
      if (elem.is_visible) {
        layers.push(elem);
      }
    }
  }
  return layers;
};

export default visibleLayersInGroup;
