const selectors = {
  getDistrictSearchableModels: (state, district) =>
    state.models.getIn(['districtSearchableModels', district]),
  getMapPortalModelsTranslations: (state, mapPortalId, filters) => {
    let translations = [];

    if (mapPortalId) {
      translations = state.models.getIn(['modelsTranslations', mapPortalId]);
    } else {
      translations = state.models.get('allModelsTranslations');
    }

    if (!filters || !translations) return translations;

    if (filters.contentType) {
      translations = translations.filter(
        ({ content_type }) => content_type === filters.contentType
      );
    }

    if (filters.language) {
      translations = translations.filter(
        ({ language }) => language === filters.language
      );
    }

    if (filters.objectId) {
      translations = translations.filter(
        ({ object_id }) => object_id === filters.objectId
      );
    }

    return translations;
  },
  getContentTypesLayers: state => state.models.get('contentTypesLayers'),
  getFetchingContentTypesLayers: state =>
    state.models.get('fetchingContentTypesLayers'),
  isFetchingNameModels: state => state.models.get('fetchingNamesModels'),
  nameModelsAreFetched: state => state.models.get('fetchedNamesModels'),
  nameModels: state => state.models?.get('namesModels'),
  isFetchingModelOptions: state => state.models.get('fetchingOptionsModels'),
  modelOptions: state => state.models.get('optionsModels'),
  isFetchingDataModel: state => state.models.get('fetchingDataModel'),
  getPlotSearchableModel: state =>
    state.models
      .get('searchableModels')
      .find(item => item.model === 'Działki ewidencyjne'),
  getRoadSearchableModel: state =>
    state.models.get('searchableModels').find(item => item.model === 'Drogi')
};

export default selectors;
