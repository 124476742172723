import axios from 'axios';
import { AxiosError } from 'axios';
import { BaseQueryFn } from '@reduxjs/toolkit/query';

import { getApiUrl } from '@/utils/lib';

import { TBaseQueryParams } from '@/types/axios-base-query-params';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { localStorageKeys } from '../config';

const { jwt } = localStorageKeys;

const axiosBaseQuery = (
  { baseUrl }: { baseUrl: string } = { baseUrl: `${getApiUrl()}/api/v1/` }
): BaseQueryFn<TBaseQueryParams, unknown, unknown> => async (
  { url, method, data, params, headers: additionalHeader, omitBaseUrl = false },
  { signal }
) => {
  const token = window.localStorage.getItem(jwt);

  let headers = { ...additionalHeader };

  if (token) {
    headers = { ...headers, Authorization: `JWT ${token}` };
  }

  const source = axios.CancelToken.source();

  signal.addEventListener('abort', () => {
    source.cancel('Query was cancelled by Redux Toolkit Query');
  });

  try {
    const result = await axios({
      url: omitBaseUrl ? url : baseUrl + url,
      method,
      data,
      params,
      headers,
      cancelToken: source.token
    });

    return { data: result.data, meta: result };
  } catch (axiosError) {
    const err = axiosError as AxiosError;

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err.message
      }
    };
  }
};

export default axiosBaseQuery;
