/**
 * Funkcja wyciągająca kolumny do tabeli ANTD z obiektu, ktory przyszedl z requestu OPTIONS
 *
 * @param {*} object
 */
const getColumns = (object, removeColumns = []) => {
  const columns = [];

  Object.keys(object).forEach((column, i) => {
    if (removeColumns.indexOf(column) >= 0) return;

    columns.push({
      dataIndex: column,
      title: object[column].label,
      key: i
    });
  });

  return columns;
};

export default getColumns;
