import mapPortalSelectors from './mapPortal';

const getDistrictAppearanceById = (state, prefix, appearanceId) => {
  const districtAppearances = state.district.getIn(['appearances', prefix]);

  if (!districtAppearances) {
    return null;
  }

  return districtAppearances.find(theme => theme.id === Number(appearanceId));
};

const selectors = {
  getDistricts: state => state.district.get('districts'),
  getAppDistrict: state => state.district.get('app_district'),
  getAppDistrictDetails: (state, prefix) =>
    state.district
      .get('app_district')
      .find(district => district.prefix === prefix),
  areDistrictsFetched: state => state.district.get('areDistrictsFetched'),
  areDistrictsBeingFetched: state =>
    state.district.get('areDistrictsBeingFetched'),
  getDistrictDetails: (state, prefix) => {
    if (prefix) {
      return state.district.get('districts_data').get(prefix, null);
    } else {
      const data = state.district.get('districts_data', null);

      if (data && state.district && state.district.toJS().districts) {
        // const districts = state.district.toJS().districts;
        // .isSubset(Object.keys(data.toJS()))
        return data;
      } else {
        return null;
      }
    }
  },
  getDistrictId: (state, prefix) => {
    const districtObject =
      state.district.get('districts_data') &&
      state.district.getIn(['districts_data', prefix]);

    if (!districtObject) {
      return;
    }

    return districtObject?.getIn(['data', 'id']);
  },
  getDistrictPrecints: (state, prefix) => {
    const district_data = state.district
      .get('districts_data')
      .get(prefix, null);
    if (district_data) {
      const precints = district_data.get('precints', null);
      return precints ? precints.toJS() : null;
    } else {
      return null;
    }
  },
  getDistrictParcels: (state, prefix) => {
    const district_data = state.district
      .get('districts_data')
      .get(prefix, null);
    if (district_data) {
      const parcels = district_data.get('parcels', null);
      return parcels ? parcels.toJS() : null;
    } else {
      return null;
    }
  },
  getDistrictSettings: state => state.district.get('districtSettings'),
  getDistrictsSettings: state => state.district.get('districtsSettings'),
  getGlobalSettings: state => state.district.get('globalDistrictSettings'),

  fetchedParcelsInPrecint: (state, prefix) =>
    state.district.getIn(['districts_data', prefix, 'fetchedParcelsInPrecint']),
  areAllParcelsListFetched: (state, prefix) =>
    state.district.getIn([
      'districts_data',
      prefix,
      'areAllParcelsListFetched'
    ]),
  getDistrictRoles: (state, prefix) =>
    state.district.getIn(['districtsRoles', prefix]),
  getIsFetchingDistrictRolesWithSuperUser: state =>
    state.district.get('isFetchingDistrictsRolesWithSuperUser'),
  getDistrictRolesWithSuperUser: (state, prefix) =>
    state.district.getIn(['districtsRolesWithSuperUser', prefix]),
  getDistrictBounds: (state, prefix) => {
    const districtProperties = state.district.getIn([
      'districts_data',
      prefix,
      'data',
      'properties'
    ]);

    if (!districtProperties.has('bounds')) {
      return null;
    }

    return districtProperties.get('bounds').toJS();
  },
  getIsFetchingGlobalThemes: state =>
    state.district.get('isFetchingGlobalThemes'),
  getGlobalThemeIsLoading: state => state.district.get('globalThemeLoading'),
  getAdminGlobalThemes: state => state.district.get('globalThemes'),
  getSingleGlobalTheme: state => state.district.get('singleGlobalTheme'),
  getGlobalThemesCount: state => state.district.get('globalThemesCount'),
  getDistrictAppearances: (state, prefix) =>
    state.district.getIn(['appearances', prefix]),
  getDistrictAppearancesCount: (state, prefix) =>
    state.district.getIn(['appearancesCount', prefix]),
  getDistrictAppearanceById,
  getPortalAppearance: (state, prefix, portalName) => {
    const mapPortal = mapPortalSelectors.getMapPortalByShortName(
      state,
      prefix,
      portalName
    );

    if (!mapPortal) return;

    const appearanceId = mapPortal.get('appearance');

    if (!appearanceId) return;

    return getDistrictAppearanceById(state, prefix, appearanceId);
  },
  getAppliedAppearance: state => state.district.get('appliedAppearance'),
  getDistrictAllTags: (state, prefix) => state.district.getIn(['tags', prefix]),
  getDistrictPortal3dSettings: (state, prefix) =>
    state.district.getIn(['portal3dSettings', prefix]),
  getDistrictOtherTopicalDataSettings: (state, prefix) =>
    state.district.getIn(['otherTopicalDataSettings', prefix]),
  getQRCodesConfig: (state, prefix) =>
    state.district.getIn(['qrCodesConfig', prefix]),
  getSearchInputValue: state => state.district.get('searchInputValue'),
  getAreAppDistricsFiltered: state =>
    state.district.get('areAppDistricsFiltered'),
  getDistrictExternalSitesList: state =>
    state.district.get('districtExternalSites'),
  getFetchingDistrictRoles: state =>
    state.district.get('fetchingDistrictRoles'),
  getIsSortingDistricts: state => state.district.get('isSortingDistricts')
};

export default selectors;
