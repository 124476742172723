const convertBytes = (bytesValue, unit) => {
  if (bytesValue < 0) {
    throw new Error('Negative bytes value is not allowed');
  }

  switch (unit) {
    case 'KB':
      return bytesValue / 1024;
    case 'MB':
      return bytesValue / Math.pow(1024, 2);
    case 'GB':
      return bytesValue / Math.pow(1024, 3);
    default:
      return bytesValue;
  }
};

export default convertBytes;
