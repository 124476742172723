const strictObjectProxy = require('./utils/strictObjectProxy');

// language can have the format 'en-EN' or 'en'
const defaultLang =
  navigator.language?.split('-')[0] ||
  navigator.userLanguage?.split('-')[0] ||
  'en';

const config = {
  defaultLang,
  defaultFontSize: 16,
  defaultAppTitle: 'GIS portal',
  responseStatuses: {
    STARTED: 'STARTED',
    SUCCESS: 'SUCCESS',
    FAILED: 'FAILED',
    SENT: 'SENT',
    PENDING: 'PENDING'
  },
  endpointPaths: {
    activationBase: 'accounts/activate/',
    resetPasswordEmail: 'auth/password/reset/',
    resetPasswordConfirm: 'auth/password/reset/confirm/',
    usersMe: 'auth/users/me/',
    userPermission: '/auth/user_permission/',
    register: '/register/',
    changePassword: 'auth/password/',
    deleteRole: '/delete_role_from_district/',
    wms: 'wms/',
    compositionSearch: '/composition/',
    qgsProjectSearch: '/qgis_project/'
  },
  roles: {
    basic: 'basic',
    basicPlus: 'basic_plus',
    advanced: 'advanced',
    administrator: 'administrator',
    superadmin: 'superadmin'
  },
  localStorageKeys: {
    jwt: 'jwt',
    refresh: 'refresh',
    cookiesAccepted: 'cookiesAccepted',
    savedMaps: 'savedMaps',
    savedMeasurements: 'savedCoordinates',
    savedSpotMeasurements: 'savedSpotMeasurements',
    savedObjects: 'savedObjects',
    filesModalPosition: 'filesModalPosition',
    filesModalSize: 'filesModalSize',
    wms: 'wms',
    highContrast: 'highContrast',
    popUpDetails: 'popUpDetails',
    currentCursorCrsCode: 'currentCursorCrsCode',
    convertCoordinatesToHDMS: 'convertCoordinatesToHDMS',
    userScales: 'userScales',
    notes: 'notes',
    hideCheckedLayersLimitWarn: 'hideCheckedLayersLimitWarn',
    isDisclaimerAccepted: 'isDisclaimerAccepted',
    acceptationDate: 'acceptationDate',
    sketches: 'sketches',
    calculations: 'calculations',
    compositionLayersConfig: 'compositionLayersConfig',
    multiSelectionMessage: 'multiSelectionMessage',
    ebudToken: 'ebudToken'
  },
  sessionStorageKeys: {
    language: 'language',
    objectsPopupPosition: 'objectsPopupPosition',
    objectsPopupSize: 'objectsPopupSize',
    reportModalPosition: 'reportModalPosition',
    topicalResultsModalPosition: 'topicalResultsModalPosition',
    topicalResultsModalSize: 'topicalResultsModalSize',
    offerModalPosition: 'offerModalPosition',
    userSketches: 'userSketches',
    registerDetailsPostion: 'registerDetailsPostion',
    wmsPopupPosition: 'wmsPopupPosition',
    legendModalPosition: 'legendModalPosition'
  },
  mobileRwdBreakpoint: 576,
  minPhoneNumberLength: 10,
  maxPhoneNumberLength: 15,
  sidebarTabsKeys: [
    'compositions',
    'layers',
    'search_tools',
    'informations',
    'defects'
  ],
  popupSources: {
    models: 'django_models',
    wms: 'wms_get_feature_info'
  },
  offerModelName: 'Oferty',
  mapGroupNames: {
    INVESTOR_LAYERGROUP_ID: 'investorLayerGroup',
    INVESTOR_DRAFT_LINE_LAYER_ID: 'draftInvestorLineLayer',
    INVESTOR_DRAFT_POLYGON_LAYER_ID: 'draftInvestorPolygonLayer',
    INVESTOR_LINE_LAYERS_GROUP_ID: 'investorLineLayersGroup',
    LENGTH_LABEL_CLASSNAME: 'length-label',
    VECTOR_LAYERS_TYPES: {
      polyline: 'polyline',
      polygon: 'polygon'
    }
  },
  acceptableProjectExtensions: ['qgs', 'qgz'],
  fileFormats: [
    {
      format: `application/vnd.google-earth.kml+xml`,
      extension: 'kml',
      name: 'KML'
    },
    {
      format: `application/x+shapefile`,
      extension: 'shp',
      name: 'SHP'
    },
    {
      format: `application/x+sqlite3`,
      extension: 'sqlite',
      name: 'SQLite'
    },
    {
      format: `application/gml+xml`,
      extension: 'gml',
      name: 'GML'
    },
    {
      format: `image/vnd.dxf`,
      extension: 'dxf',
      name: 'DXF'
    },
    {
      format: `text/csv`,
      extension: 'csv',
      name: 'CSV'
    },
    {
      format: '',
      extension: 'tif',
      name: 'raster'
    }
  ],
  matomoTrackerId: 'matomoTracker',
  gaTrackerId: 'gaTracker',
  sidebarPanelTransitionTime: 400,
  finnModels: {
    finnsprawy: {
      apiName: 'sprawa',
      parameterName: 'parametrySprawy',
      settingsApi: 'jrwa_settings',
      listApi: 'listaSpraw',
      redirectLink: true,
      additionalFields: 'additional_fields',
      dynamicAttr: 'dynamic_fields'
    },
    finndokumenty: {
      apiName: 'dokument',
      parameterName: 'parametryDokumentu',
      detailsAttr: 'detailed_fields',
      dynamicAttr: 'dynamic_fields'
    },
    finnpisma: {
      apiName: 'pismo',
      parameterName: 'parametryPisma',
      detailsAttr: 'detailed_fields',
      dynamicAttr: 'dynamic_fields'
    },
    finnpozycjerejestru: {
      apiName: 'rejestr',
      parameterName: 'parametryRejestru',
      settingsApi: 'register_settings',
      detailsAttr: 'detailed_fields',
      additionalFields: 'additional_fields',
      listApi: 'listaRejestrow',
      dynamicAttr: 'dynamic_fields'
    }
  },
  nestedTopicalSearches: {
    klasouzytki: {
      displayableName: 'Klasoużytki'
    },
    podmioty: {
      displayableName: 'Podmioty'
    }
  },
  registerComponentsNames: {
    registerTool: 'registerTool',
    econstructions: 'econstructions'
  },
  wmsServices: {
    getCapabilities: 'GetCapabilities',
    getFeatureInfo: 'GetFeatureInfo',
    getLegendGraphic: 'GetLegendGraphic'
  },
  serviceTypes: {
    wfs: 'WFS',
    wms: 'WMS',
    wmts: 'WMTS',
    wmtsRest: 'WMTS - REST'
  }
};

module.exports = strictObjectProxy(config, 'config');
