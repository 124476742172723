import { Map } from 'immutable';

import {
  FETCH_TARIFF_SUCCESS,
  FETCH_CONTROL_BOX_SUCCESS,
  CREATE_TARIFF_SUCCESS,
  DELETE_TARIFF_SUCCESS,
  CREATE_COST_CONFIG_SUCCESS,
  DELETE_COST_CONFIG_SUCCESS,
  FETCH_COST_CONFIG_SUCCESS,
  IS_LOADING_TARIFF,
  IS_LOADING_COST_CONFIG
} from '../constants/latarnikActionsTypes';

export const initialState = Map({
  fetchedTariffs: [],
  fetchedControlBox: [],
  fetchedControlConfig: [],
  isLoadingTarriffs: false,
  isLoadingControlConfig: false
});
const setLoadingTariffs = state => state.set('isLoadingTarriffs', true);
const setLoadingControlConfig = state =>
  state.set('isLoadingControlConfig', true);

const getFetchedTariffsSuccess = (state, { payload }) =>
  state.set('fetchedTariffs', payload).set('isLoadingTarriffs', false);

const getSavedTariffsSuccess = (state, { payload }) => {
  const savedTariffs = state.get('fetchedTariffs');
  const oldTariffs = savedTariffs.results;
  savedTariffs.results = [...oldTariffs, payload];
  return state
    .set('fetchedTariffs', savedTariffs)
    .set('isLoadingTarriffs', false);
};

const deletedTariffsSuccess = (state, { payload }) => {
  const savedTariffs = state.get('fetchedTariffs');
  const newTariffs = savedTariffs.results.filter(({ id }) => id !== payload);
  savedTariffs.results = [...newTariffs];
  return state
    .set('fetchedTariffs', savedTariffs)
    .set('isLoadingTarriffs', false);
};
const getFetchedControlBoxSuccess = (state, { payload }) =>
  state.set('fetchedControlBox', payload);

const getFetchedControlConfigSuccess = (state, { payload }) =>
  state
    .set('fetchedControlConfig', payload)
    .set('isLoadingControlConfig', false);

const getSavedControlConfigSuccess = (state, { payload }) => {
  const savedControlConfig = state.get('fetchedControlConfig');
  const oldConfig = savedControlConfig.results;
  savedControlConfig.results = [...oldConfig, payload];
  return state
    .set('fetchedControlConfig', savedControlConfig)
    .set('isLoadingControlConfig', false);
};

const deletedControlConfigSuccess = (state, { payload }) => {
  const savedControlConfig = state.get('fetchedControlConfig');
  const newTariffs = savedControlConfig.results.filter(
    ({ id }) => id !== payload
  );
  savedControlConfig.results = [...newTariffs];
  return state
    .set('fetchedControlConfig', savedControlConfig)
    .set('isLoadingControlConfig', false);
};

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_TARIFF_SUCCESS]: getFetchedTariffsSuccess,
      [IS_LOADING_TARIFF]: setLoadingTariffs,
      [IS_LOADING_COST_CONFIG]: setLoadingControlConfig,
      [FETCH_CONTROL_BOX_SUCCESS]: getFetchedControlBoxSuccess,
      [CREATE_TARIFF_SUCCESS]: getSavedTariffsSuccess,
      [DELETE_TARIFF_SUCCESS]: deletedTariffsSuccess,
      [CREATE_COST_CONFIG_SUCCESS]: getSavedControlConfigSuccess,
      [DELETE_COST_CONFIG_SUCCESS]: deletedControlConfigSuccess,
      [FETCH_COST_CONFIG_SUCCESS]: getFetchedControlConfigSuccess
    }[action.type] || (s => s)
  )(state, action);
}
