const translations = {
  mapPortal: {
    warningGroupTitle: {
      pl: 'Ostrzeżenie:',
      en: 'Warning:'
    },
    fetchDistrictCompositionsErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania kompozycji mapowych.',
      en: 'Error occurred while fetching map compositions.'
    },
    fetchDistrictCompositionsPermissionErrorMessage: {
      pl: 'Brak uprawnień do przeglądania danej kompozycji mapowej.',
      en: 'No permission to open this map composition.'
    },
    fetchSearchToolErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania narzędzia wyszukiwania.',
      en: 'Error occurred while fetching search tool.'
    },
    fetchDistrictMapPortalsErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania portali mapowych.',
      en: 'Error occurred while fetching map portals.'
    },
    fetchLegendErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania legendy mapy.',
      en: 'Error occurred while fetching map legend.'
    },
    fetchQgisProjectsErrorMessage: {
      pl: 'Wystąpił błąd poczas pobierania listy projektów.',
      en: 'Error occurred while fetching list of projects.'
    },
    fetchNetworkDriveStructureErrorMessage: {
      pl: 'Wystąpił błąd poczas pobierania struktury katalogów.',
      en: 'Error occurred while fetching catalogs structure.'
    },
    fetchNetworkDriveCatalogErrorMessage: {
      pl: 'Wystąpił błąd poczas pobierania katalogu.',
      en: 'Error occurred while fetching catalog.'
    },
    fetchMapPortalToolConfigurationSuccessMessage: {
      pl: 'Zaktualizowano tryb dla wybranego narzędzia.',
      en: 'Map tool configuration has been updated.'
    },
    fetchMapPortalToolConfigurationErrorMessage: {
      pl: 'Wystąpił błąd podczas aktualizacji trybu dla wybranego narzędzia.',
      en: 'Error occurred while updating map tool configuration.'
    },
    fetchMapPortalTools: {
      pl: 'Wystąpił błąd podczas pobierania narzędzi mapy',
      en: 'Error occurred while fetching portal tools.'
    }
  },
  espdp: {
    fetchSpdpModulesErrorMessage: {
      pl: 'Wystąpił błąd poczas pobierania modułów SPDP.',
      en: 'Error occurred while fetching SPDP modules.'
    },
    fetchSpdpModuleErrorMessage: {
      pl: 'Wystąpił błąd poczas pobierania modułu SPDP.',
      en: 'Error occurred while fetching SPDP module.'
    }
  },
  district: {
    fetchPrecinctErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania danych obrębu.',
      en: 'Error occurred while fetching precinct data.'
    },
    fetchParcelErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania danych obszaru.',
      en: 'Error occurred while fetching parcel data.'
    },
    fetchAvailablePortalsErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania listy dostępnych portali.',
      en: 'Error occurred while fetching list of available portals.'
    },
    fetchPortalDataErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania danych portalu.',
      en: 'Error occurred while fetching portal data.'
    },
    fetchDistrictRolesErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania uprawnień jednostki.',
      en: 'Error occurred while downloading district roles.'
    },
    fetchDistrictAppearancesErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania motywów portalu.',
      en: 'Error occurred while downloading portal themes.'
    },
    addDistrictAppearanceSuccessMessage: {
      pl: 'Motyw został dodany.',
      en: 'Theme has been added.'
    },
    addDistrictAppearanceErrorMessage: {
      pl: 'Wystąpił błąd podczas dodawania motywu.',
      en: 'Error occurred while adding the theme.'
    },
    editDistrictAppearanceSuccessMessage: {
      pl: 'Motyw został zapisany.',
      en: 'Theme has been saved.'
    },
    editDistrictAppearanceErrorMessage: {
      pl: 'Wystąpił błąd podczas zapisywania motywu.',
      en: 'Error occurred while saving the theme.'
    },
    deleteDistrictAppearanceSuccessMessage: {
      pl: 'Motyw został usunięty.',
      en: 'Theme has been deleted.'
    },
    deleteDistrictAppearanceErrorMessage: {
      pl: 'Wystąpił błąd podczas usuwania motywu.',
      en: 'Error occurred while deleting the theme.'
    },
    fetchPublicMapPortalAppearanceErrorMessage: {
      pl: 'Wystąpił błąd podczas pobierania motywu portalu.',
      en: 'Error occurred while fetching portal theme.'
    },
    fetchMapPortalTools: {
      pl: 'Wystąpił błąd podczas pobierania narzędzi mapy'
    }
  },
  searchTools: {
    deleteSearchToolSuccessMessage: {
      pl: 'Wyszukiwarka została usunięta.',
      en: 'Search tool has been removed.'
    }
  },
  forAll: {
    errorOccured: {
      pl: 'Wystąpił błąd!',
      en: 'Error!'
    }
  }
};

export function getTranslation(reducer) {
  return function _getTranslationFromReducer(key, lang = 'en') {
    return translations[reducer][key][lang];
  };
}

export default translations;
