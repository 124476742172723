const selectors = {
  isUserFetching: state => state.user.get('isUserFetching'),
  isUserLogged: state => !!state.user.get('userData'),
  userData: state => state.user && state.user.get('userData'),
  getUsersData: state => state.user.get('usersData'),
  isUserPortalDataFetched: state => state.user.get('isUserPortalDataFetched'),
  getAdminsData: state => state.user.get('adminsList'),
  getLogoutProcessing: state => state.user.get('isLogoutProcessing'),
  isNotARobot: state => state.user.get('notARobot'),
  isUserDataLoading: state => state.user?.get('isUserDataLoading'),
  getUserDistrictRole: (state, districtId) => {
    const userDataList = state.user.get('userData');

    if (!userDataList) return;

    const userData = userDataList.toJS();

    const isSuperAdmin = userData.is_superadmin;

    if (isSuperAdmin) return { name: 'superadmin', index: null };

    const { roles } = userData;
    const role = roles?.find(({ district }) => district === districtId);

    if (role) {
      const { name, index } = role;
      return { name, index };
    }
  },
  getUserGroups: state => {
    const userDataList = state.user.get('userData');

    if (!userDataList) return [];

    const userData = userDataList.toJS();

    if (!userData.groups) return [];
    return userData.groups;
  }
};

export default selectors;
