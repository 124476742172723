import { CancelToken } from 'axios';

export const enum EMapPortalTags {
  DISTRICT_MAP_PORTALS = 'districtMapPortals',
  MAP_PORTAL_TOOLS = 'mapPortalTools',
  MAP_PORTAL_COMPOSITION = 'mapPortalComposition',
  RASTER_TIMELINE = 'rasterTimeline',
  SINGLE_DISTRICT_MAP_PORTAL = 'singleDistrictMapPortal',
  SINGLE_MAP_PORTAL_COMPOSITIONS = 'singleMapPortalCompositions',
  MAP_PORTAL_VECTOR_TIMELINES = 'mapPortalVectorTimelines',
  MAP_PORTAL_TAGS = 'mapPortalTags',
  SPOT_MEASURE_CONFIG = 'spotMeasureConfig',
  IFRAME_PORTAL_DATA = 'iframePortalData'
}

export type TMapPortal = {
  id: number;
  name: string;
};

export type TFetchDistrictMapPortalsParams = {
  prefix: string;
  filterEmpty?: boolean;
  page?: number;
  pageSize?: number;
  search?: string;
  withoutPagination?: boolean;
  cancelToken?: CancelToken;
  mapPortalId?: number;
};

export type TFetchMapPortalToolsParams = {
  map_portal_id: number;
};

export type TFetchCompositionParams = {
  prefix: string;
  portal_id: number;
  id: string;
  forceFetch?: boolean;
};

export type TFetchRasterTimelineParams = {
  district: string;
  mapPortalId: number;
};

export type TFetchSingleDistrictMapPortalParams = {
  prefix: string;
  mapPortalId: number;
};

export type TFetchMapPortalDataParams = {
  district: string;
  mapPortalId: number;
};

export type TFetchCompositionError = {
  error: {
    status: number;
    data: { code: string; message: string };
  };
  meta: string;
};

export type TFetchHomeSiteIframeParams = {
  prefix: string;
  mapPortalId: string;
};
