export const FETCH_DISTRICT_MAP_PORTALS_BEGIN =
  'FETCH_DISTRICT_MAP_PORTALS_BEGIN';
export const FETCH_DISTRICT_MAP_PORTALS_SUCCESS =
  'FETCH_DISTRICT_MAP_PORTALS_SUCCESS';
export const SAVE_DISTRICT_MAP_PORTALS_NAMES =
  'SAVE_DISTRICT_MAP_PORTALS_NAMES';
export const CREATE_MAP_PORTAL_BEGIN = 'CREATE_MAP_PORTAL_BEGIN';
export const CREATE_MAP_PORTAL_SUCCESS = 'CREATE_MAP_PORTAL_SUCCESS';
export const CREATE_MAP_PORTAL_FAILED = 'CREATE_MAP_PORTAL_FAILED';
export const EDIT_MAP_PORTAL_BEGIN = 'EDIT_MAP_PORTAL_BEGIN';
export const CHANGE_MAP_PORTAL_SUCCESS = 'CHANGE_MAP_PORTAL_SUCCESS';
export const EDIT_MAP_PORTAL_FAILED = 'EDIT_MAP_PORTAL_FAILED';
export const DELETE_MAP_PORTAL_BEGIN = 'DELETE_MAP_PORTAL_BEGIN';
export const DELETE_MAP_PORTAL_SUCCESS = 'DELETE_MAP_PORTAL_SUCCESS';
export const DELETE_MAP_PORTAL_FAILED = 'DELETE_MAP_PORTAL_FAILED';
export const LOGIN_MODAL_OPEN = 'LOGIN_MODAL_OPEN';
export const LOGIN_MODAL_CLOSE = 'LOGIN_MODAL_CLOSE';

export const EDIT_MAP_PORTAL_TOOLS = 'EDIT_MAP_PORTAL_TOOLS';

export const UPDATE_CHECKED_MAPPORTALS_LAYERS =
  'UPDATE_CHECKED_MAPPORTALS_LAYERS';

export const DELETE_WMS_LAYER = 'DELETE_WMS_LAYER';
export const DELETE_CSV_FROM_COMPOSITION = 'DELETE_CSV_FROM_COMPOSITION';
export const DELETE_WMS_FROM_COMPOSITION = 'DELETE_WMS_FROM_COMPOSITION';

export const FETCH_COMPOSITION_BEGIN = 'FETCH_COMPOSITION_BEGIN';
export const FETCH_COMPOSITION_SUCCESS = 'FETCH_COMPOSITION_SUCCESS';
export const FETCH_COMPOSITION_FAILED = 'FETCH_COMPOSITION_FAILED';
export const SET_CURRENT_COMPOSITION = 'SET_CURRENT_COMPOSITION';
export const EDIT_MAP_PORTAL_COMPOSITION_BEGIN =
  'EDIT_MAP_PORTAL_COMPOSITION_BEGIN';
export const EDIT_MAP_PORTAL_COMPOSITION_SUCCESS =
  'EDIT_MAP_PORTAL_COMPOSITION_SUCCESS';
export const EDIT_MAP_PORTAL_COMPOSITION_FAILED =
  'EDIT_MAP_PORTAL_COMPOSITION_FAILED';

export const ADD_LAYERS_TO_LAYERS_MAP = 'ADD_LAYERS_TO_LAYERS_MAP';
export const ADD_LAYERS_TO_LAYERS_ID_MAP = 'ADD_LAYERS_TO_LAYERS_ID_MAP';

export const SHOW_LEGEND = 'SHOW_LEGEND';
export const HIDE_LEGEND = 'HIDE_LEGEND';

export const FETCH_LEGEND_BEGIN = 'FETCH_LEGEND_BEGIN';
export const FETCH_LEGEND_SUCCESS = 'FETCH_LEGEND_SUCCESS';
export const FETCH_LEGEND_FAILED = 'FETCH_LEGEND_FAILED';
export const CLEAR_FETCHING_LEGEND = 'CLEAR_FETCHING_LEGEND';

export const FETCH_DISTRICT_COMPOSITIONS_BEGIN =
  'FETCH_DISTRICT_COMPOSITIONS_BEGIN';
export const FETCH_DISTRICT_COMPOSITIONS_SUCCESS =
  'FETCH_DISTRICT_COMPOSITIONS_SUCCESS';

export const FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN =
  'FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN';
export const FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS =
  'FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS';
export const FETCH_MAP_PORTAL_COMPOSITIONS_FAILED =
  'FETCH_MAP_PORTAL_COMPOSITIONS_FAILED';

export const ADD_COMPOSITION_TO_MAP_PORTAL_BEGIN =
  'ADD_COMPOSITION_TO_MAP_PORTAL_BEGIN';
export const ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS =
  'ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS';
export const ADD_COMPOSITION_TO_MAP_PORTAL_FAILED =
  'ADD_COMPOSITION_TO_MAP_PORTAL_FAILED';

export const REMOVE_COMPOSITION_FROM_MAP_PORTAL_BEGIN =
  'REMOVE_COMPOSITION_FROM_MAP_PORTAL_BEGIN';
export const REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS =
  'REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS';
export const REMOVE_COMPOSITION_FROM_MAP_PORTAL_FAILED =
  'REMOVE_COMPOSITION_FROM_MAP_PORTAL_FAILED';

export const FETCH_QGIS_PROJECTS_BEGIN = 'FETCH_QGIS_PROJECTS_BEGIN';
export const FETCH_QGIS_PROJECTS_SUCCESS = 'FETCH_QGIS_PROJECTS_SUCCESS';

export const UPDATE_QGIS_PROJECT_DATA_BEGIN = 'UPDATE_QGIS_PROJECT_DATA_BEGIN';
export const UPDATE_QGIS_PROJECT_DATA_SUCCESS =
  'UPDATE_QGIS_PROJECT_DATA_SUCCESS';
export const UPDATE_QGIS_PROJECT_DATA_FAILED =
  'UPDATE_QGIS_PROJECT_DATA_FAILED';

export const DELETE_QGIS_PROJECT_BEGIN = 'DELETE_QGIS_PROJECT_BEGIN';
export const DELETE_QGIS_PROJECT_SUCCESS = 'DELETE_QGIS_PROJECT_SUCCESS';
export const DELETE_QGIS_PROJECT_FAILED = 'DELETE_QGIS_PROJECT_FAILED';

export const UPDATE_QGIS_PROJECT_BEGIN = 'UPDATE_QGIS_PROJECT_BEGIN';
export const UPDATE_QGIS_PROJECT_SUCCESS = 'UPDATE_QGIS_PROJECT_SUCCESS';
export const UPDATE_QGIS_PROJECT_FAILED = 'UPDATE_QGIS_PROJECT_FAILED';

export const FETCH_NETWORK_DRIVE_FOLDER_BEGIN =
  'FETCH_NETWORK_DRIVE_FOLDER_BEGIN';
export const FETCH_NETWORK_DRIVE_FOLDER_SUCCESS =
  'FETCH_NETWORK_DRIVE_FOLDER_SUCCESS';
export const FETCH_NETWORK_DRIVE_FOLDER_FAILED =
  'FETCH_NETWORK_DRIVE_FOLDER_FAILED';
export const FETCH_NETWORK_DRIVE_FILE_BEGIN = 'FETCH_NETWORK_DRIVE_FILE_BEGIN';
export const FETCH_NETWORK_DRIVE_FILE_SUCCESS =
  'FETCH_NETWORK_DRIVE_FILE_SUCCESS';
export const FETCH_NETWORK_DRIVE_FILE_FAILED =
  'FETCH_NETWORK_DRIVE_FILE_FAILED';
export const FETCHING_NETWORK_DRIVE_BEGIN = 'FETCHING_NETWORK_DRIVE_BEGIN';
export const FETCHING_NETWORK_DRIVE_SUCCESS = 'FETCHING_NETWORK_DRIVE_SUCCESS';
export const FETCHING_NETWORK_DRIVE_FAILED = 'FETCHING_NETWORK_DRIVE_FAILED';
export const SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN =
  'SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN';
export const SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS =
  'SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS';
export const SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED =
  'SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED';
export const SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS =
  'SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS';
export const CLEAR_SELECTING_ND_PROJECT_STATUS =
  'CLEAR_SELECTING_ND_PROJECT_STATUS';
export const GET_JOB_STATUS_FROM_ADDED_PROJECT =
  'GET_JOB_STATUS_FROM_ADDED_PROJECT';

export const CLEAR_MAP_PORTAL_REDUCER_STATE = 'CLEAR_MAP_PORTAL_REDUCER_STATE';

export const SET_PHOTOS_COMPOSITION = 'SET_PHOTOS_COMPOSITION';

export const FETCH_RASTER_TIMELINE_BEGIN = 'FETCH_RASTER_TIMELINE_BEGIN';
export const FETCH_RASTER_TIMELINE_SUCCESS = 'FETCH_RASTER_TIMELINE_SUCCESS';
export const FETCH_RASTER_TIMELINE_FAILED = 'FETCH_RASTER_TIMELINE_FAILED';

export const ADD_RASTER_TIMELINE_BEGIN = 'ADD_RASTER_TIMELINE_BEGIN';
export const ADD_RASTER_TIMELINE_SUCCESS = 'ADD_RASTER_TIMELINE_SUCCESS';
export const ADD_RASTER_TIMELINE_FAILED = 'ADD_RASTER_TIMELINE_FAILED';

export const UPDATE_RASTER_TIMELINE_BEGIN = 'UPDATE_RASTER_TIMELINE_BEGIN';
export const UPDATE_RASTER_TIMELINE_SUCCESS = 'UPDATE_RASTER_TIMELINE_SUCCESS';
export const UPDATE_RASTER_TIMELINE_FAILED = 'UPDATE_RASTER_TIMELINE_FAILED';

export const DELETE_RASTER_TIMELINE_BEGIN = 'DELETE_RASTER_TIMELINE_BEGIN';
export const DELETE_RASTER_TIMELINE_SUCCESS = 'DELETE_RASTER_TIMELINE_SUCCESS';
export const DELETE_RASTER_TIMELINE_FAILED = 'DELETE_RASTER_TIMELINE_FAILED';

export const GET_PUBLIC_WMS_BEGIN = 'GET_PUBLIC_WMS_BEGIN';
export const GET_PUBLIC_WMS_SUCCESS = 'GET_PUBLIC_WMS_SUCCESS';
export const GET_PUBLIC_WMS_FAILED = 'GET_PUBLIC_WMS_FAILED';

export const ADD_COMPOSITION_WMS_ENTRY = 'ADD_COMPOSITION_WMS_ENTRY';
export const UPDATE_COMPOSITION_ENTRIES_ORDER =
  'UPDATE_COMPOSITION_ENTRIES_ORDER';

export const INSERT_TO_ADDED_WMS_LIST = 'INSERT_TO_ADDED_WMS_LIST';
export const REMOVE_LAYER_FROM_ADDED_WMS_LIST =
  'REMOVE_LAYER_FROM_ADDED_WMS_LIST';
export const CLEAR_ADDED_WMS_LIST = 'CLEAR_ADDED_WMS_LIST';

export const SET_ACTIVE_TOOL = 'SET_ACTIVE_TOOL';
export const RESET_ACTIVE_TOOL = 'RESET_ACTIVE_TOOL';

export const FETCH_DISTRICT_MAP_PORTAL_BEGIN =
  'FETCH_DISTRICT_MAP_PORTAL_BEGIN';
export const FETCH_DISTRICT_MAP_PORTAL_FAILED =
  'FETCH_DISTRICT_MAP_PORTAL_FAILED';

export const FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS =
  'FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS';
export const FETCH_PORTAL_TAGS_BEGIN = 'FETCH_PORTAL_TAGS_BEGIN';
export const FETCH_PORTAL_TAGS_FAILED = 'FETCH_PORTAL_TAGS_FAILED';

export const EDIT_PORTAL_TAGS_BEGIN = 'EDIT_PORTAL_TAGS_BEGIN';
export const EDIT_PORTAL_TAGS_SUCCESS = 'EDIT_PORTAL_TAGS_SUCCESS';
export const EDIT_PORTAL_TAGS_FAILED = 'EDIT_PORTAL_TAGS_FAILED';

export const FETCH_VECTOR_TIMELINES_BEGIN = 'FETCH_VECTOR_TIMELINES_BEGIN';
export const FETCH_VECTOR_TIMELINES_SUCCESS = 'FETCH_VECTOR_TIMELINES_SUCCESS';
export const FETCH_VECTOR_TIMELINES_FAILED = 'FETCH_VECTOR_TIMELINES_FAILED';

export const ADD_VECTOR_TIMELINE_BEGIN = 'ADD_VECTOR_TIMELINE_BEGIN';
export const ADD_VECTOR_TIMELINE_SUCCESS = 'ADD_VECTOR_TIMELINE_SUCCESS';
export const ADD_VECTOR_TIMELINE_FAILED = 'ADD_VECTOR_TIMELINE_FAILED';

export const UPDATE_VECTOR_TIMELINE_BEGIN = 'UPDATE_VECTOR_TIMELINE_BEGIN';
export const UPDATE_VECTOR_TIMELINE_SUCCESS = 'UPDATE_VECTOR_TIMELINE_SUCCESS';
export const UPDATE_VECTOR_TIMELINE_FAILED = 'UPDATE_VECTOR_TIMELINE_FAILED';

export const DELETE_VECTOR_TIMELINE_BEGIN = 'DELETE_VECTOR_TIMELINE_BEGIN';
export const DELETE_VECTOR_TIMELINE_SUCCESS = 'DELETE_VECTOR_TIMELINE_SUCCESS';
export const DELETE_VECTOR_TIMELINE_FAILED = 'DELETE_VECTOR_TIMELINE_FAILED';

export const GET_PROJECT_LAYERS_BEGIN = 'GET_PROJECT_LAYERS_BEGIN';
export const GET_PROJECT_LAYERS_SUCCESS = 'GET_PROJECT_LAYERS_SUCCESS';
export const GET_PROJECT_LAYERS_FAILED = 'GET_PROJECT_LAYERS_FAILED';

export const GET_SPOT_MEASURE_CONFIG_BEGIN = 'GET_SPOT_MEASURE_CONFIG_BEGIN';
export const GET_SPOT_MEASURE_CONFIG_SUCCESS =
  'GET_SPOT_MEASURE_CONFIG_SUCCESS';
export const GET_SPOT_MEASURE_CONFIG_FAILED = 'GET_SPOT_MEASURE_CONFIG_FAILED';

export const ADD_SPOT_MEASURE_LAYER_BEGIN = 'ADD_SPOT_MEASURE_LAYER_BEGIN';
export const ADD_SPOT_MEASURE_LAYER_SUCCESS = 'ADD_SPOT_MEASURE_LAYER_SUCCESS';
export const ADD_SPOT_MEASURE_LAYER_FAILED = 'ADD_SPOT_MEASURE_LAYER_FAILED';

export const EDIT_SPOT_MEASURE_LAYER_BEGIN = 'EDIT_SPOT_MEASURE_LAYER_BEGIN';
export const EDIT_SPOT_MEASURE_LAYER_SUCCESS =
  'EDIT_SPOT_MEASURE_LAYER_SUCCESS';
export const EDIT_SPOT_MEASURE_LAYER_FAILED = 'EDIT_SPOT_MEASURE_LAYER_FAILED';

export const DELETE_SPOT_MEASURE_LAYER_BEGIN =
  'DELETE_SPOT_MEASURE_LAYER_BEGIN';
export const DELETE_SPOT_MEASURE_LAYER_SUCCESS =
  'DELETE_SPOT_MEASURE_LAYER_SUCCESS';
export const DELETE_SPOT_MEASURE_LAYER_FAILED =
  'DELETE_SPOT_MEASURE_LAYER_FAILED';
export const SET_ENABLE_GEOLOCATION = 'SET_ENABLE_GEOLOCATION';
export const SETTING_GEOLOCATION = 'SETTING_GEOLOCATION';

export const GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN =
  'GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN';
export const GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS =
  'GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS';
export const GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED =
  'GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED';

export const UPDATE_COMPOSITION_SIDEBAR_SETTINGS_BEGIN =
  'UPDATE_COMPOSITION_SIDEBAR_SETTINGS_BEGIN';
export const UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS =
  'UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS';
export const UPDATE_COMPOSITION_SIDEBAR_SETTINGS_FAILED =
  'UPDATE_COMPOSITION_SIDEBAR_SETTINGS_FAILED';

export const SET_PORTAL_COMPOSITION_TAGS_BEGIN =
  'SET_PORTAL_COMPOSITION_TAGS_BEGIN';
export const SET_PORTAL_COMPOSITION_TAGS_SUCCESS =
  'SET_PORTAL_COMPOSITION_TAGS_SUCCESS';
export const SET_PORTAL_COMPOSITION_TAGS_FAILED =
  'SET_PORTAL_COMPOSITION_TAGS_FAILED';

export const GET_PROJECT_UPDATE_LOGS_BEGIN = 'GET_PROJECT_UPDATE_LOGS_BEGIN';
export const GET_PROJECT_UPDATE_LOGS_SUCCESS =
  'GET_PROJECT_UPDATE_LOGS_SUCCESS';
export const GET_PROJECT_UPDATE_LOGS_FAILED = 'GET_PROJECT_UPDATE_LOGS_FAILED';

export const DELETE_PROJECT_UPDATE_LOG_BEGIN =
  'DELETE_PROJECT_UPDATE_LOG_BEGIN';
export const DELETE_PROJECT_UPDATE_LOG_SUCCESS =
  'DELETE_PROJECT_UPDATE_LOG_SUCCESS';
export const DELETE_PROJECT_UPDATE_LOG_FAILED =
  'DELETE_PROJECT_UPDATE_LOG_FAILED';

export const UPDATE_PROJECT_MAP_PROXY_SETTINGS_BEGIN =
  'UPDATE_PROJECT_MAP_PROXY_SETTINGS_BEGIN';
export const UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS =
  'UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS';
export const UPDATE_PROJECT_MAP_PROXY_SETTINGS_FAILED =
  'UPDATE_PROJECT_MAP_PROXY_SETTINGS_FAILED';

export const ADD_COMPOSITION_CSV_ENTRY = 'ADD_COMPOSITION_CSV_ENTRY';
export const INSERT_TO_ADDED_CSV_LIST = 'INSERT_TO_ADDED_CSV_LIST';

export const FETCH_LAYER_GEOJSON_BEGIN = 'FETCH_LAYER_GEOJSON_BEGIN';
export const FETCH_LAYER_GEOJSON_SUCCESS = 'FETCH_LAYER_GEOJSON_SUCCESS';
export const FETCH_LAYER_GEOJSON_FAILED = 'FETCH_LAYER_GEOJSON_FAILED';
export const ADD_TO_TOUCHED_GROUPS = 'ADD_TO_TOUCHED_GROUPS';
export const REMOVE_FROM_TOUCHED_GROUPS = 'REMOVE_FROM_TOUCHED_GROUPS';
export const CLEAR_TOUCHED_GROUPS = 'CLEAR_TOUCHED_GROUPS';

export const CLEAR_ADDED_CSV_LIST = 'CLEAR_ADDED_CSV_LIST';

export const UPDATE_LAYER_VISIBILITY_BEGIN = 'UPDATE_LAYER_VISIBILITY_BEGIN';
export const UPDATE_LAYER_VISIBILITY_SUCCESS =
  'UPDATE_LAYER_VISIBILITY_SUCCESS';
export const UPDATE_LAYER_VISIBILITY_FAILED = 'UPDATE_LAYER_VISIBILITY_FAILED';

export const FETCH_VOIVODESHIPS_BEGIN = 'FETCH_VOIVODESHIPS_BEGIN';
export const FETCH_VOIVODESHIPS_SUCCESS = 'FETCH_VOIVODESHIPS_SUCCESS';
export const FETCH_VOIVODESHIPS_FAILED = 'FETCH_VOIVODESHIPS_FAILED';

export const FETCH_COUNTIES_BEGIN = 'FETCH_COUNTIES_BEGIN';
export const FETCH_COUNTIES_SUCCESS = 'FETCH_COUNTIES_SUCCESS';
export const FETCH_COUNTIES_FAILED = 'FETCH_COUNTIES_FAILED';

export const FETCH_COMMUNES_BEGIN = 'FETCH_COMMUNES_BEGIN';
export const FETCH_COMMUNES_SUCCESS = 'FETCH_COMMUNES_SUCCESS';
export const FETCH_COMMUNES_FAILED = 'FETCH_COMMUNES_FAILED';

export const FETCH_REGIONS_BEGIN = 'FETCH_REGIONS_BEGIN';
export const FETCH_REGIONS_SUCCESS = 'FETCH_REGIONS_SUCCESS';
export const FETCH_REGIONS_FAILED = 'FETCH_REGIONS_FAILED';

export const DOWNLOAD_LAYER_ATTRIBUTES_BEGIN =
  'DOWNLOAD_LAYER_ATTRIBUTES_BEGIN';
export const DOWNLOAD_LAYER_ATTRIBUTES_SUCCESS =
  'DOWNLOAD_LAYER_ATTRIBUTES_SUCCESS';
export const DOWNLOAD_LAYER_ATTRIBUTES_FAILED =
  'DOWNLOAD_LAYER_ATTRIBUTES_FAILED';

export const SET_INVESTOR_DRAWING = 'SET_INVESTOR_DRAWING';

export const SHOW_SERVICES_MODAL = 'SHOW_SERVICES_MODAL';
export const HIDE_SERVICES_MODAL = 'HIDE_SERVICES_MODAL';
export const SHOW_PRIVACY_MODAL = 'SHOW_PRIVACY_MODAL';
export const HIDE_PRIVACY_MODAL = 'HIDE_PRIVACY_MODAL';

export const SET_SEARCH_INPUT_DATA = 'SET_SEARCH_INPUT_DATA';
export const SET_SEARCH_INPUT_OPTIONS = 'SET_SEARCH_INPUT_OPTIONS';
export const SET_SEARCH_INPUT_GUGIK_ADDRESS = 'SET_SEARCH_INPUT_GUGIK_ADDRESS';
export const SET_SEARCH_INPUT_GUGIK_DATA = 'SET_SEARCH_INPUT_GUGIK_DATA';
export const SET_SEARCH_INPUT_GUGIK_DISPLAY = 'SET_SEARCH_INPUT_GUGIK_DISPLAY';
export const RESET_SEARCH_INPUT = 'RESET_SEARCH_INPUT';
export const SET_ACTIVE_INDEX_TOOLBAR = 'SET_ACTIVE_INDEX_TOOLBAR';

export const DOWNLOAD_RASTER_LAYER_BEGIN = 'DOWNLOAD_RASTER_LAYER_BEGIN';
export const DOWNLOAD_RASTER_LAYER_SUCCESS = 'DOWNLOAD_RASTER_LAYER_SUCCESS';
export const DOWNLOAD_RASTER_LAYER_FAILED = 'DOWNLOAD_RASTER_LAYER_FAILED';

export const UPDATE_ORDER_MAP_PORTAL_BEGIN = 'UPDATE_ORDER_MAP_PORTAL_BEGIN';
export const UPDATE_ORDER_MAP_PORTAL_SUCCESS =
  'UPDATE_ORDER_MAP_PORTAL_SUCCESS';
export const UPDATE_ORDER_MAP_PORTAL_FAILED = 'UPDATE_ORDER_MAP_PORTAL_FAILED';

export const FETCH_SELECTION_METHODS_BEGIN = 'FETCH_SELECTION_METHODS_BEGIN';
export const FETCH_SELECTION_METHODS_SUCCESS =
  'FETCH_SELECTION_METHODS_SUCCESS';
export const FETCH_SELECTION_METHODS_FAILED = 'FETCH_SELECTION_METHODS_FAILED';

export const FETCH_SELECTION_TYPES_BEGIN = 'FETCH_SELECTION_TYPES_BEGIN';
export const FETCH_SELECTION_TYPES_SUCCESS = 'FETCH_SELECTION_TYPES_SUCCESS';
export const FETCH_SELECTION_TYPES_FAILED = 'FETCH_SELECTION_TYPES_FAILED';

export const FETCH_SELECTION_OPERATOR_BEGIN = 'FETCH_SELECTION_OPERATOR_BEGIN';
export const FETCH_SELECTION_OPERATOR_SUCCESS =
  'FETCH_SELECTION_OPERATOR_SUCCESS';
export const FETCH_SELECTION_OPERATOR_FAILED =
  'FETCH_SELECTION_OPERATOR_FAILED';

export const FETCH_AR_CONFIGS_BEGIN = 'FETCH_AR_CONFIGS_BEGIN';
export const FETCH_AR_CONFIGS_SUCCESS = 'FETCH_AR_CONFIGS_SUCCESS';
export const FETCH_AR_CONFIGS_FAILED = 'FETCH_AR_CONFIGS_FAILED';

export const ADD_AR_CONFIG_BEGIN = 'ADD_AR_CONFIG_BEGIN';
export const ADD_AR_CONFIG_SUCCESS = 'ADD_AR_CONFIG_SUCCESS';
export const ADD_AR_CONFIG_FAILED = 'ADD_AR_CONFIG_FAILED';

export const UPDATE_AR_CONFIG_BEGIN = 'UPDATE_AR_CONFIG_BEGIN';
export const UPDATE_AR_CONFIG_SUCCESS = 'UPDATE_AR_CONFIG_SUCCESS';
export const UPDATE_AR_CONFIG_FAILED = 'UPDATE_AR_CONFIG_FAILED';

export const DELETE_AR_CONFIG_BEGIN = 'DELETE_AR_CONFIG_BEGIN';
export const DELETE_AR_CONFIG_SUCCESS = 'DELETE_AR_CONFIG_SUCCESS';
export const DELETE_AR_CONFIG_FAILED = 'DELETE_AR_CONFIG_FAILED';

export const FETCH_AR_CONFIG_ATTRS_LIST_BEGIN =
  'FETCH_AR_CONFIG_ATTRS_LIST_BEGIN';
export const FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS =
  'FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS';
export const FETCH_AR_CONFIG_ATTRS_LIST_FAILED =
  'FETCH_AR_CONFIG_ATTRS_LIST_FAILED';

export const UPDATE_AR_CONFIG_ATTRS_LIST_BEGIN =
  'UPDATE_AR_CONFIG_ATTRS_LIST_BEGIN';
export const UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS =
  'UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS';
export const UPDATE_AR_CONFIG_ATTRS_LIST_FAILED =
  'UPDATE_AR_CONFIG_ATTRS_LIST_FAILED';

export const DELETE_AR_CONFIG_ATTRS_LIST = 'DELETE_AR_CONFIG_ATTRS_LIST';

export const FETCH_AR_COMPOSITION_CONFIG_BEGIN =
  'FETCH_AR_COMPOSITION_CONFIG_BEGIN';
export const FETCH_AR_COMPOSITION_CONFIG_SUCCESS =
  'FETCH_AR_COMPOSITION_CONFIG_SUCCESS';
export const FETCH_AR_COMPOSITION_CONFIG_FAILED =
  'FETCH_AR_COMPOSITION_CONFIG_FAILED';

export const UPDATE_AR_COMPOSITION_CONFIG_BEGIN =
  'UPDATE_AR_COMPOSITION_CONFIG_BEGIN';
export const UPDATE_AR_COMPOSITION_CONFIG_SUCCESS =
  'UPDATE_AR_COMPOSITION_CONFIG_SUCCESS';
export const UPDATE_AR_COMPOSITION_CONFIG_FAILED =
  'UPDATE_AR_COMPOSITION_CONFIG_FAILED';

export const FETCH_MAP_PORTAL_TOOLS_SUCCESS = 'FETCH_MAP_PORTAL_TOOLS_SUCCESS';
export const FETCH_MAP_PORTAL_TOOLS_FAILED = 'FETCH_MAP_PORTAL_TOOLS_FAILED';
export const FETCH_MAP_PORTAL_TOOLS_STARTED = 'FETCH_MAP_PORTAL_TOOLS_STARTED';

export const SET_DEFAULT_MODE = 'SET_DEFAULT_MODE';

export const UPDATE_MAP_PORTAL_TOOL_SUCCESS = 'UPDATE_MAP_PORTAL_TOOL_SUCCESS';
export const UPDATE_MAP_PORTAL_TOOL_FAILED = 'UPDATE_MAP_PORTAL_TOOL_FAILED';
export const UPDATE_MAP_PORTAL_TOOL_STARTED = 'UPDATE_MAP_PORTAL_TOOL_STARTED';

export const FETCH_DISTRICT_LOCALITIES_STARTED =
  'FETCH_DISTRICT_LOCALITIES_STARTED';
export const FETCH_DISTRICT_LOCALITIES_SUCCESS =
  'FETCH_DISTRICT_LOCALITIES_SUCCESS';
export const FETCH_DISTRICT_LOCALITIES_FAILED =
  'FETCH_DISTRICT_LOCALITIES_FAILED';

export const FETCH_LOCALITY_STREETS_STARTED = 'FETCH_LOCALITY_STREETS_STARTED';
export const FETCH_LOCALITY_STREETS_SUCCESS = 'FETCH_LOCALITY_STREETS_SUCCESS';
export const FETCH_LOCALITY_STREETS_FAILED = 'FETCH_LOCALITY_STREETS_FAILED';

export const GET_WMS_LEGEND_BEGIN = 'GET_WMS_LEGEND_BEGIN';
export const GET_WMS_LEGEND_SUCCESS = 'GET_WMS_LEGEND_SUCCESS';
export const GET_WMS_LEGEND_FAILED = 'GET_WMS_LEGEND_FAILED';
export const CLEAR_FETCHING_WMS_LEGEND = 'CLEAR_FETCHING_WMS_LEGEND';

export const CLEAR_CURRENT_COMPOSITION_ID = 'CLEAR_CURRENT_COMPOSITION_ID';

export const FETCH_MEASUREMENT_LAYER_UNITS_FINISHED =
  'FETCH_MEASUREMENT_LAYER_UNITS_FINISHED';

export const SHOW_SERVICE_INFO_MODAL = 'SHOW_SERVICE_INFO_MODAL';
export const HIDE_SERVICE_INFO_MODAL = 'HIDE_SERVICE_INFO_MODAL';

export const FETCH_HOME_SITE_IFRAME_BEGIN = 'FETCH_HOME_SITE_IFRAME_BEGIN';
export const FETCH_HOME_SITE_IFRAME_SUCCESS = 'FETCH_HOME_SITE_IFRAME_SUCCESS';
export const FETCH_HOME_SITE_IFRAME_FAILED = 'FETCH_HOME_SITE_IFRAME_FAILED';
