import { isObject } from '../';

/**
 * Próbuje uzyskać dostęp do określonej metody w przekazanej ścieżce obiektu,
 * zwracając wynik jej wywołania. W przypadku, gdy po drodze któraś z wartości
 * będzie fałszywa, zwróci undefined.
 * Przydatne np. w przypadku biblioteki Immutable.js i metody .toJS().
 *
 * Przykład:
 * Bez funkcji: this.props.val && this.props.val.toJS && this.props.val.toJS()
 * Z funkcją: nestedCaller(this.props, 'val', 'toJS')
 * Lub zagnieżdżony obiekt: nestedCaller(this, ['props', 'val'], 'toJS')
 *
 * @param {object} object
 * @param {string, array} path
 * @param {string} method
 * @returns {any}
 */
const nestedCaller = (object, path, method) => {
  const errorPrefix = 'nestedCaller:';

  if (!isObject(object)) {
    throw Error(`${errorPrefix}: first parameter must be a valid JS object!`);
  }

  if (!method || typeof method !== 'string') {
    throw Error(`${errorPrefix}: method name must be a string!`);
  }

  if (typeof path === 'string') {
    if (!object[path] || !object[path][method]) {
      return;
    }

    if (typeof object[path][method] !== 'function') {
      throw Error(`${errorPrefix} ${method} is not a function.`);
    }

    return object[path][method]();
  }

  if (Array.isArray(path)) {
    let current = object;
    let hasEmptyField = false;

    path.forEach(key => {
      if (hasEmptyField) return;

      if (!current[key]) {
        hasEmptyField = true;
        return;
      }

      current = current[key];
    });

    if (hasEmptyField || !current[method]) return;

    if (typeof current[method] !== 'function') {
      throw Error(`${errorPrefix} ${method} is not a function.`);
    }

    return current[method]();
  }

  throw Error(`${errorPrefix} path parameter must be a string or array!`);
};

export default nestedCaller;
