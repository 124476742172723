import { roles } from '../../../config';

// TODO: Przenieść do config.
const rolesHierarchy = [
  roles.basic,
  roles.basicPlus,
  roles.advanced,
  roles.administrator,
  roles.superadmin
];

export default rolesHierarchy;
