import { snakeCase } from 'lodash';

/**
 * A function for converting string to snake_case.
 * This modification keep '__' if occur in string.
 */
const customSnakeCase = (str: string): string =>
  str
    .split('__')
    .map((part: string) => snakeCase(part))
    .join('__');

export default customSnakeCase;
