/**
 * W JS undefined, false, liczba 0 oraz pusty string są wartościami fałszywymi.
 * Poniższa funkcja wykonuje porównanie stringa i jako wartości fałszywe traktuje
 * wyłącznie undefined oraz false.
 *
 * @param {string} firstValue
 * @param {string} secondValue
 * @return {string}
 */
const getConditionalStringValue = (firstValue, secondValue) =>
  firstValue === undefined || firstValue === false || firstValue === null
    ? secondValue
    : firstValue;

export default getConditionalStringValue;
