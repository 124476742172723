import b64toBlob from '../b64ToBlob';

/**
 * Parsuje kod Base64 do Blob URL.
 *
 * @param {string} data - plik zapisany w formacie base64
 * @param {string} contentType - typ MIME (jeśli inny niż zawarty w przekazanym pliku)
 */
const b64ToBlobUrl = async (data, contentType) => {
  const blob = await b64toBlob(data, contentType);
  return URL.createObjectURL(blob);
};

export default b64ToBlobUrl;
