import { createApi } from '@reduxjs/toolkit/query/react';

import { getAllLocationsSuccess } from '@/store/actions/locationActions';

import handleApiError from '@/utils/handleApiError';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  ELocationTags,
  TDefectLocationParams
} from '@/types/api-types/location';
import { TDefectLocationResponse } from '@/types/responses/location';

export const locationApi = createApi({
  reducerPath: 'locationApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [ELocationTags.DEFECT_LOCATION],
  endpoints: builder => ({
    fetchAllLocations: builder.query<
      TDefectLocationResponse,
      TDefectLocationParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/defect_location_limit/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(getAllLocationsSuccess(data));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.features.map(feature => ({
                type: ELocationTags.DEFECT_LOCATION,
                id: feature?.id ?? ''
              })),
              {
                type: ELocationTags.DEFECT_LOCATION,
                id: ELocationTags.DEFECT_LOCATION
              }
            ]
          : [
              {
                type: ELocationTags.DEFECT_LOCATION,
                id: ELocationTags.DEFECT_LOCATION
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const { useLazyFetchAllLocationsQuery } = locationApi;
