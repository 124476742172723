import round from 'lodash/round';

/**
 * Parsuje jednostkę długości w zależności od wielkości
 * przekazanej wartości. Dla liczb mniejszych od 1000mm
 * zwraca wartość w m, a dla większych w km.
 *
 * @param {number} length - długość w m
 * @param {number} unit - jednostka do wyświetlenia: m, km
 * @return {string}
 */
const parseLength = (length, unit) => {
  const value = round(length, 2);

  if (!unit) {
    return value < 1000 ? `${value} m` : `${round(value / 1000, 3)} km`;
  } else if (unit === 'm') {
    return `${value.toFixed(2)} m`;
  } else if (unit === 'km') {
    return `${round(value / 1000, 3)} km`;
  }
};

export default parseLength;
