import { Layout } from 'antd';
import { NavLink, useParams } from 'react-router-dom';
import classNames from 'classnames';

import useFooterGlobalLocalSettings from '@/hooks/useFooterGlobalLocalSettings';
import useTranslations from '@/hooks/useTranslations';

import mainRoutePaths from '../../router/portal/paths';
import { GLOBAL_FOOTER_TRANSLATIONS } from './consts';
import { FOOTER_ROW_TESTID } from '@/constants/data-testids';

import './GlobalFooter.less';

const { Footer } = Layout;

const GlobalFooter = () => {
  const { prefix } = useParams();

  const {
    showPrivacyPolicy,
    showAvailabilityStatement,
    showTermsAndConditions,
    showRpoLogo,
    rpoLogo,
    homePageFooterPosition
  } = useFooterGlobalLocalSettings();

  const isFooterEmbedded = homePageFooterPosition === 'embedded';

  const {
    TITLE_PROFILE_AVAILABILITY,
    TITLE_PROFILE_TERMS,
    TITLE_PROFILE_PRIVACY_POLICY
  } = useTranslations(GLOBAL_FOOTER_TRANSLATIONS);

  const footerClassName = classNames('footer-main', {
    'footer-embedded': isFooterEmbedded
  });

  const getLink = link => {
    if (prefix) return `/${prefix}${link}`;

    return link;
  };

  return (
    <Footer className={footerClassName}>
      <ul class="footer-row" data-testid={FOOTER_ROW_TESTID}>
        {showAvailabilityStatement && (
          <li class="footer-block">
            <NavLink to={getLink(mainRoutePaths.availability_statement)}>
              {TITLE_PROFILE_AVAILABILITY}
            </NavLink>
          </li>
        )}
        {showTermsAndConditions && (
          <li class="footer-block">
            <NavLink to={getLink(mainRoutePaths.terms)}>
              {TITLE_PROFILE_TERMS}
            </NavLink>
          </li>
        )}
        {showPrivacyPolicy && (
          <li class="footer-block">
            <NavLink to={getLink(mainRoutePaths.privacy)}>
              {TITLE_PROFILE_PRIVACY_POLICY}
            </NavLink>
          </li>
        )}
      </ul>
      {showRpoLogo && rpoLogo && (
        <div
          className="portal_list_rpo"
          style={{ backgroundImage: `url(${rpoLogo})` }}
        />
      )}
    </Footer>
  );
};

export default GlobalFooter;
