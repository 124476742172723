export const FETCH_REQUESTS_BY_TERYT_BEGIN = 'FETCH_REQUESTS_BY_TERYT_BEGIN';
export const FETCH_REQUESTS_BY_TERYT_SUCCESS =
  'FETCH_REQUESTS_BY_TERYT_SUCCESS';
export const FETCH_REQUESTS_BY_TERYT_FAILED = 'FETCH_REQUESTS_BY_TERYT_FAILED';

export const FETCH_REQUEST_DETAILS_BEGIN = 'FETCH_REQUEST_DETAILS_BEGIN';
export const FETCH_REQUEST_DETAILS_SUCCESS = 'FETCH_REQUEST_DETAILS_SUCCESS';
export const FETCH_REQUEST_DETAILS_FAILED = 'FETCH_REQUEST_DETAILS_FAILED';

export const OPEN_ECONSTRUCTION_DETAILS = 'OPEN_ECONSTRUCTION_DETAILS';
export const CLOSE_ECONSTRUCTION_DETAILS = 'CLOSE_ECONSTRUCTION_DETAILS';

export const FETCH_REQUEST_PDF_BEGIN = 'FETCH_REQUEST_PDF_BEGIN';
export const FETCH_REQUEST_PDF_SUCCESS = 'FETCH_REQUEST_PDF_SUCCESS';
export const FETCH_REQUEST_PDF_FAILED = 'FETCH_REQUEST_PDF_FAILED';

export const SAVE_AUTHORITY_NUMBER_BEGIN = 'SAVE_AUTHORITY_NUMBER_BEGIN';
export const SAVE_AUTHORITY_NUMBER_SUCCESS = 'SAVE_AUTHORITY_NUMBER_SUCCESS';
export const SAVE_AUTHORITY_NUMBER_FAILED = 'SAVE_AUTHORITY_NUMBER_FAILED';

export const SAVE_DECISION_NUMBER_BEGIN = 'SAVE_DECISION_NUMBER_BEGIN';
export const SAVE_DECISION_NUMBER_SUCCESS = 'SAVE_DECISION_NUMBER_SUCCESS';
export const SAVE_DECISION_NUMBER_FAILED = 'SAVE_DECISION_NUMBER_FAILED';
