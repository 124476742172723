import getSidebarPanelKey from '../getSidebarPanelKey';

const getSidebarPanelIndex = (tabName, tabsOrder) => {
  if (!tabName || !tabsOrder) return;

  const tabKey = getSidebarPanelKey(tabName);
  return tabsOrder.indexOf(tabKey);
};

export default getSidebarPanelIndex;
