import { List } from 'immutable';

import { mapPortalSelectors, userSelectors } from './';

const getMinMapZoomLevel = state => state.map.get('minMapZoomLevel');

const getMaxMapZoomLevel = (state, getMapPortalGridParams) => {
  if (getMapPortalGridParams) {
    const mapPortalGrid = mapPortalSelectors.getMapPortalGrid({
      state,
      ...getMapPortalGridParams
    });

    if (mapPortalGrid) return mapPortalGrid.scales.length - 1;
  }

  return state.map.get('maxMapZoomLevel');
};

const getUserZoomLevel = ({ state, rangePrefix, prefix, portal }) => {
  const mapPortal = mapPortalSelectors.getMapPortalByShortName(
    state,
    prefix,
    portal
  );

  const isZoomForNonLoggedEnabled = mapPortal?.get('zoom_for_nonlogged');
  const isAuthorized = userSelectors.isUserLogged(state);
  const defaultZoomLevel =
    rangePrefix === 'max'
      ? getMaxMapZoomLevel(state, { mapPortal })
      : getMinMapZoomLevel(state);

  if (!isAuthorized && isZoomForNonLoggedEnabled) {
    return mapPortal.get(`${rangePrefix}_zoom_nonlogged`) ?? defaultZoomLevel;
  }

  return mapPortal.get(`${rangePrefix}_zoom`) ?? defaultZoomLevel;
};

const selectors = {
  zoomLevel: state => state.map.get('zoomLevel'),
  getScalesDenominators: (state, getMapPortalGridParams) => {
    if (getMapPortalGridParams) {
      const mapPortalGrid = mapPortalSelectors.getMapPortalGrid({
        state,
        ...getMapPortalGridParams
      });

      if (mapPortalGrid)
        return List(
          mapPortalGrid.scales.map(({ scale_denom }) => +scale_denom)
        ).reverse();
    }

    return state.map.get('scalesDenominators');
  },
  getMinMapZoomLevel,
  getMaxMapZoomLevel,
  getUserZoomLevel,
  historyMap: state => state.map.get('historyMap'),
  numberHistory: state => state.map.get('numberHistory'),
  typeClick: state => state.map.get('typeClick'),
  getBaseMapLayer: state => state.map.get('baseMapLayer'),
  getCheckedLayers: state => state.map.get('checkedLayers'),
  getInvisibleLayers: state => state.map.get('invisibleLayers'),
  getSelectedParcel: state => state.map.get('selectedParcel'),
  getLayersZoom: state => state.map.get('layersZoom'),
  getCurrentCursorCrs: state => state.map.get('currentCursorCrs'),
  convertCoordinatesToHDMS: state => state.map.get('convertCoordinatesToHDMS'),
  getRenderedLayersNumber: state => state.map.get('renderedLayersNumber'),
  getStreetViewCoordinates: state => state.map.get('streetViewCoordinates'),
  getIsMapLoaded: state => state.map.get('isMapLoaded'),
  getMainMiniMapDraggingStatus: state => state.map.get('isMainMiniMapDragging'),
  isGeomLoadingOnMap: state => state.map.get('isGeomLoadingOnMap'),
  getActiveBackgroundTool: state => state.map.get('activeBackgroundTool'),
  getSelectedDefaultParcels: state => state.map.get('selectedDefaultParcels'),
  getSelectedNeighboringParcels: state =>
    state.map.get('selectedNeighboringParcel'),
  getBackgroundParcelColor: state => state.map.get('backgroundParcelColor'),
  getLayersStyle: state => state.map.get('layersStyle'),
  getIsMapUpdating: state => state.map.get('isMapUpdating'),
  getFetchedWfsExternal: state => state.map.get('fetchedWfsExternal'),
  getClosestObjectsSelectionCoordinates: state =>
    state.map.get('closestObjectsSelectionCoordinates')
};

export default selectors;
