/*
 * Funkcja pozwala na zapisanie na dysku użytkownika przekazanego pliku.
 *
 * @param {string} fileName - nazwa pliku
 * @param {string} file - plik jako ciąg znaków (base64/Blob/etc.)
 */
const downloadFile = (fileName, file) => {
  const link = document.createElement('a');
  link.download = fileName;
  link.href = file;

  // Dodajemy ukryty link do drzewa DOM, bo niektóre przeglądarki blokują
  // okno, które pojawia się po kliknięciu w odnośnik nie osadzony w dokumencie.
  link.style.display = 'none';

  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export default downloadFile;
