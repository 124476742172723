module.exports = {
  login: '/logowanie',
  registration: '/rejestracja',
  activation: '/aktywacja/:uid/:token',
  adminActivation: '/ustaw_haslo/:uid/:token',
  activateChangedEmail: '/zmien_email/:authUuid',
  profile: '/profil',
  profileDistricts: '/profil/jednostki',
  profilePassword: '/profil/haslo',
  profilePrivacy: '/profil/polityka',
  profileDelete: '/profil/usun',
  profileMapPortals: '/profil/portale',
  profileDefects: '/profil/zgloszenia',
  profileObjects: '/profil/obiekty',
  profileOfficialReports: '/profil/urzednik/zgloszenia',
  profileOfficialReportsEdits: '/profil/urzednik/edycja_obiektu',
  admin: '/admin',
  maintenancePage: '/maintenance',
  notFound: '/not_found',
  forbidden: '/forbidden',
  resetPassword: '/resetuj_haslo',
  setNewPassword: '/resetuj_haslo/:uid/:token/',
  popUpDetails: '/popup_details',
  districtList: '/lista_jednostek',

  availability_statement: '/availability-statement',
  terms: '/terms',
  privacy: '/cookies',

  homePage: '/'
};
