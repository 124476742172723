import { forwardRef } from 'react';

import { Button as AntButton } from 'antd';

import './Button.less';

const btnClassNames = {
  primary: 'btn-primary',
  secondary: 'btn-secondary',
  success: 'btn-success',
  warning: 'btn-warning',
  danger: 'btn-danger',
  bordered: 'btn-bordered',
  transparent: 'btn-transp',
  text: 'btn-transp btn-text',
  settingsDefault: 'btn-settings',
  link: 'btn-link',
  custom: ''
};

const Button = ({ variant, children, ...props }, ref) => {
  const btnVariant = variant || 'primary';

  return (
    <AntButton
      ref={ref}
      {...props}
      className={['btn', btnClassNames[btnVariant], props.className].join(' ')}
      variant={btnVariant}
    >
      {children}
    </AntButton>
  );
};

export default forwardRef(Button);
