const selectors = {
  getStartPointCoordinates: state =>
    state.routePlanning.get('startPointCoordinates'),
  getEndPointCoordinates: state =>
    state.routePlanning.get('endPointCoordinates'),
  getIntermediatePointsCoordinates: state =>
    state.routePlanning.get('intermediatePointsCoordinates')
};

export default selectors;
