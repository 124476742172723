import { Map, List, Set, fromJS } from 'immutable';
import cloneDeep from 'lodash/cloneDeep';

import { deepReplace, findEntryInComposition } from '../../utils/lib';

import {
  FETCH_DISTRICT_MAP_PORTALS_BEGIN,
  FETCH_DISTRICT_MAP_PORTALS_SUCCESS,
  SAVE_DISTRICT_MAP_PORTALS_NAMES,
  FETCH_DISTRICT_COMPOSITIONS_BEGIN,
  FETCH_DISTRICT_COMPOSITIONS_SUCCESS,
  FETCH_COMPOSITION_BEGIN,
  FETCH_COMPOSITION_SUCCESS,
  SHOW_LEGEND,
  HIDE_LEGEND,
  FETCH_LEGEND_BEGIN,
  FETCH_LEGEND_SUCCESS,
  FETCH_LEGEND_FAILED,
  CLEAR_FETCHING_LEGEND,
  UPDATE_CHECKED_MAPPORTALS_LAYERS,
  FETCH_QGIS_PROJECTS_BEGIN,
  FETCH_QGIS_PROJECTS_SUCCESS,
  UPDATE_QGIS_PROJECT_DATA_SUCCESS,
  DELETE_QGIS_PROJECT_BEGIN,
  DELETE_QGIS_PROJECT_SUCCESS,
  DELETE_QGIS_PROJECT_FAILED,
  FETCH_NETWORK_DRIVE_FOLDER_SUCCESS,
  FETCH_NETWORK_DRIVE_FILE_SUCCESS,
  FETCHING_NETWORK_DRIVE_BEGIN,
  FETCHING_NETWORK_DRIVE_SUCCESS,
  FETCHING_NETWORK_DRIVE_FAILED,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS,
  CLEAR_SELECTING_ND_PROJECT_STATUS,
  ADD_LAYERS_TO_LAYERS_MAP,
  ADD_LAYERS_TO_LAYERS_ID_MAP,
  SET_CURRENT_COMPOSITION,
  DELETE_MAP_PORTAL_SUCCESS,
  FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS,
  CHANGE_MAP_PORTAL_SUCCESS,
  DELETE_WMS_LAYER,
  DELETE_CSV_FROM_COMPOSITION,
  DELETE_WMS_FROM_COMPOSITION,
  CREATE_MAP_PORTAL_SUCCESS,
  CLEAR_MAP_PORTAL_REDUCER_STATE,
  EDIT_MAP_PORTAL_COMPOSITION_SUCCESS,
  ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS,
  REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS,
  FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN,
  FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS,
  FETCH_MAP_PORTAL_COMPOSITIONS_FAILED,
  GET_JOB_STATUS_FROM_ADDED_PROJECT,
  FETCH_COMPOSITION_FAILED,
  SET_PHOTOS_COMPOSITION,
  FETCH_RASTER_TIMELINE_SUCCESS,
  ADD_RASTER_TIMELINE_SUCCESS,
  DELETE_RASTER_TIMELINE_SUCCESS,
  UPDATE_RASTER_TIMELINE_SUCCESS,
  GET_PUBLIC_WMS_SUCCESS,
  UPDATE_COMPOSITION_ENTRIES_ORDER,
  ADD_COMPOSITION_WMS_ENTRY,
  INSERT_TO_ADDED_WMS_LIST,
  REMOVE_LAYER_FROM_ADDED_WMS_LIST,
  CLEAR_ADDED_WMS_LIST,
  CLEAR_ADDED_CSV_LIST,
  SET_ACTIVE_TOOL,
  RESET_ACTIVE_TOOL,
  ADD_VECTOR_TIMELINE_SUCCESS,
  FETCH_VECTOR_TIMELINES_SUCCESS,
  DELETE_VECTOR_TIMELINE_SUCCESS,
  GET_PROJECT_LAYERS_SUCCESS,
  GET_SPOT_MEASURE_CONFIG_SUCCESS,
  ADD_SPOT_MEASURE_LAYER_SUCCESS,
  EDIT_SPOT_MEASURE_LAYER_SUCCESS,
  DELETE_SPOT_MEASURE_LAYER_SUCCESS,
  SET_ENABLE_GEOLOCATION,
  SETTING_GEOLOCATION,
  GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  SET_PORTAL_COMPOSITION_TAGS_SUCCESS,
  GET_PROJECT_UPDATE_LOGS_SUCCESS,
  DELETE_PROJECT_UPDATE_LOG_SUCCESS,
  UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS,
  ADD_COMPOSITION_CSV_ENTRY,
  INSERT_TO_ADDED_CSV_LIST,
  FETCH_LAYER_GEOJSON_SUCCESS,
  ADD_TO_TOUCHED_GROUPS,
  REMOVE_FROM_TOUCHED_GROUPS,
  CLEAR_TOUCHED_GROUPS,
  UPDATE_LAYER_VISIBILITY_SUCCESS,
  FETCH_VOIVODESHIPS_SUCCESS,
  FETCH_COUNTIES_SUCCESS,
  FETCH_COMMUNES_SUCCESS,
  FETCH_REGIONS_SUCCESS,
  SET_INVESTOR_DRAWING,
  SHOW_SERVICES_MODAL,
  HIDE_SERVICES_MODAL,
  SHOW_PRIVACY_MODAL,
  HIDE_PRIVACY_MODAL,
  SET_SEARCH_INPUT_DATA,
  SET_SEARCH_INPUT_OPTIONS,
  SET_SEARCH_INPUT_GUGIK_ADDRESS,
  SET_SEARCH_INPUT_GUGIK_DATA,
  GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED,
  GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN,
  SET_SEARCH_INPUT_GUGIK_DISPLAY,
  RESET_SEARCH_INPUT,
  SET_ACTIVE_INDEX_TOOLBAR,
  UPDATE_ORDER_MAP_PORTAL_SUCCESS,
  FETCH_SELECTION_METHODS_BEGIN,
  FETCH_SELECTION_METHODS_SUCCESS,
  FETCH_SELECTION_METHODS_FAILED,
  FETCH_SELECTION_TYPES_FAILED,
  FETCH_SELECTION_TYPES_BEGIN,
  FETCH_SELECTION_TYPES_SUCCESS,
  FETCH_SELECTION_OPERATOR_BEGIN,
  FETCH_SELECTION_OPERATOR_SUCCESS,
  FETCH_SELECTION_OPERATOR_FAILED,
  FETCH_AR_CONFIGS_SUCCESS,
  ADD_AR_CONFIG_SUCCESS,
  UPDATE_AR_CONFIG_SUCCESS,
  DELETE_AR_CONFIG_SUCCESS,
  FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS,
  UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS,
  DELETE_AR_CONFIG_ATTRS_LIST,
  FETCH_AR_COMPOSITION_CONFIG_SUCCESS,
  UPDATE_AR_COMPOSITION_CONFIG_SUCCESS,
  FETCH_MAP_PORTAL_TOOLS_SUCCESS,
  FETCH_MAP_PORTAL_TOOLS_FAILED,
  FETCH_MAP_PORTAL_TOOLS_STARTED,
  SET_DEFAULT_MODE,
  UPDATE_MAP_PORTAL_TOOL_SUCCESS,
  UPDATE_MAP_PORTAL_TOOL_FAILED,
  UPDATE_MAP_PORTAL_TOOL_STARTED,
  FETCH_DISTRICT_LOCALITIES_STARTED,
  FETCH_DISTRICT_LOCALITIES_SUCCESS,
  FETCH_DISTRICT_LOCALITIES_FAILED,
  FETCH_LOCALITY_STREETS_STARTED,
  FETCH_LOCALITY_STREETS_SUCCESS,
  FETCH_LOCALITY_STREETS_FAILED,
  GET_WMS_LEGEND_SUCCESS,
  GET_WMS_LEGEND_BEGIN,
  GET_WMS_LEGEND_FAILED,
  CLEAR_FETCHING_WMS_LEGEND,
  CLEAR_CURRENT_COMPOSITION_ID,
  SHOW_SERVICE_INFO_MODAL,
  HIDE_SERVICE_INFO_MODAL,
  FETCH_HOME_SITE_IFRAME_SUCCESS
} from '../constants/mapPortalActionTypes';
import { ADD_GROUP_TO_MAP_PORTAL_SUCCESS } from '../constants/groupsActionTypes';

import { responseStatuses } from '../../config';

export const initialState = Map({
  fetchedDistrictMapPortals: List([]),
  fetchedDistrictQgisProjects: List([]),
  fetchedDistrictCompositions: List([]),
  fetchedComositions: List([]),
  fetchedLegends: Map({}),
  fetchingDistrictMapPortals: List([]),
  fetchingDistrictQgisProjects: List([]),
  fetchingDistrictCompositions: List([]),
  fetchingCompositions: List([]),
  fetchingLegend: Map({}),
  isLegendVisible: false,
  fetchingMapPortalCompositions: false,
  mapPortalsNames: List([]),
  compositions: Map(),
  isFetchingCompositions: false,
  isFetchedCompositions: false,
  mapPortalCompositions: Map({}),
  currentCompositionId: null,
  district_compositions: Map(),
  qgis_projects: Map({}),
  layersMap: Map({}),
  layersIdMap: Map({}),
  networkDriveFolders: List([]),
  networkDriveFiles: Map({}),
  fetchingNetworkDrive: false,
  selectingProjectFromNetworkDrive: false,
  deletingProjects: List([]),
  uuidJobFromAddedProject: '',
  errorJobFromAddProject: '',
  progressAddingProject: 0,
  isFetchingDistrictCompositions: false,
  rasterTimelines: List([]),
  addedWms: Map(),
  addedCsv: Map(),
  activeTool: null,
  filterEmpty: null,
  vectorTimelines: List([]),
  projectLayers: Map(),
  spotMeasureConfigs: Map(),
  enabledGeolocation: false,
  geolocationCoords: null,
  setingGeolocation: false,
  compositionsSidebarSettings: Map({}),
  projectsLogs: Map({}),
  fetchedLayerGeoJSON: Map({}),
  touchedGroups: Set([]),
  investorDrawing: false,
  isServicesModalVisible: false,
  servicePath: '',
  searchInputType: undefined,
  searchInputData: undefined,
  searchInputOptions: [],
  searchInputGugikAddress: null,
  searchInputGugikDisplay: undefined,
  searchInputGugikData: {
    voivodeship: undefined,
    county: undefined,
    commune: undefined,
    region: undefined,
    parcel: ''
  },
  activeToolIndex: null,
  mapPortalsCount: Map(),
  selectionMethods: List([]),
  fetchingSelectionMethods: false,
  selectionTypes: List([]),
  fetchingSelectionTypes: false,
  selectionOperators: List([]),
  fetchingSelectionOperators: false,
  isPrivacyModalVisible: false,
  arConfigs: Map(),
  arConfigsAttrs: Map(),
  arCompositionsConfigs: Map(),
  isFetchingMapPortalTools: false,
  mapPortalToolsConfiguration: List([]),
  initialSelectedRowKeys: List([]),
  defaultMode: 'informative',
  isUpdatingMapPortalTool: false,
  isFetchingDistrictLocalities: false,
  districtLocalities: Map(),
  isFetchingLocalityStreets: false,
  localityStreets: Map(),
  fetchedWmsLegends: Map({}),
  fetchingWmsLegend: Map({}),
  singleDistrictMapPortal: Map({}),
  isServiceInfoModalVisible: false,
  serviceLayer: null,
  iframePortalData: null
});

const fetchDistrictMapPortals = (state, action) =>
  state.update('fetchingDistrictMapPortals', fetchingDistrictMapPortals =>
    fetchingDistrictMapPortals.push(action.prefix)
  );
const fetchDistrictMapPortalsSuccess = (state, action) => {
  const currentPortals = state.get(action.prefix, List());
  const updatedPortalsSet = Set(currentPortals).concat(
    action.mapPortals.map(portal => Map(portal))
  );

  const updatedPortalsList = List(updatedPortalsSet);

  return state.merge(
    Map({
      fetchingDistrictMapPortals: state
        .get('fetchingDistrictMapPortals')
        .filter(prefix => prefix !== action.prefix),
      fetchedDistrictMapPortals: state
        .get('fetchedDistrictMapPortals')
        .push(action.prefix),
      [action.prefix]: updatedPortalsList,
      filterEmpty: action.filterEmpty,
      mapPortalsCount: action.mapPortalsCount
    })
  );
};

const saveDistrictMapPortalsNames = (state, { mapPortalsNames }) =>
  state.merge(
    Map({
      mapPortalsNames: List(mapPortalsNames)
    })
  );

const createDistrictMapPortalSuccess = (state, action) => {
  const { newMapPortalData, district } = action;
  const mapPortalName = newMapPortalData.name;

  function createMapPortalObject() {
    const mapPortals = state.get(district);
    return List([...mapPortals]).push(Map(newMapPortalData));
  }

  function createMapPortalName() {
    return state.get('mapPortalsNames').push(mapPortalName);
  }

  return state.withMutations(map => {
    map.set(district, createMapPortalObject());
    map.set('mapPortalsNames', createMapPortalName());
  });
};

const changeDistrictMapPortalSuccess = (state, action) => {
  const { editedMapPortalId, editedData, district } = action;
  if (state.get(district)) {
    const indexToEdit = state
      .get(district)
      .findIndex(
        mapPortal => mapPortal.get('id') === Number(editedMapPortalId)
      );

    if (indexToEdit === -1) return state;
    return state.mergeIn([district, indexToEdit], editedData);
  }

  return state.merge(
    Map({
      [district]: List().push(Map(editedData))
    })
  );
};

const updateOrderMapPortalSuccess = (state, action) => {
  const { indexData, district } = action;
  const mapPortals = state.get(district)?.toJS();
  if (!mapPortals) return state;
  mapPortals.forEach((element, index) => {
    if (element.id === indexData.id) {
      element.index = indexData.index;
    }
  });
  return state.setIn(
    [district],
    List(
      fromJS(
        mapPortals.sort(
          (firstItem, secondItem) => firstItem.index - secondItem.index
        )
      )
    )
  );
};

const fetchDistrictMissingMapPortalSucces = (state, action) => {
  const { editedData, district } = action;
  const mapPortals = state.get(district);
  return state.merge(
    Map({
      [district]: List(mapPortals).push(Map(editedData))
    })
  );
};

const deleteDistrictMapPortalSuccess = (state, action) => {
  const { mapPortalId, district } = action;

  function deleteMapPortalObject() {
    const districts = state.get(district);

    return List([...districts]).filter(
      mapPortal => mapPortal.get('id') !== Number(mapPortalId)
    );
  }

  function deleteMapPortalName() {
    const mapPortal = state
      .get(district)
      .find(mapPortal => mapPortal.get('id') === Number(mapPortalId));
    const mapPortalName = mapPortal && mapPortal.get('name');

    return state.get('mapPortalsNames').filter(name => name !== mapPortalName);
  }

  return state.withMutations(map => {
    map.set(district, deleteMapPortalObject());
    map.set('mapPortalsNames', deleteMapPortalName());
  });
};

const showLegend = state => state.set('isLegendVisible', true);

const hideLegend = state => state.set('isLegendVisible', false);

const fetchLegendBegin = (state, action) =>
  state.merge(
    Map({
      fetchingLegend: state.get('fetchingLegend').merge({
        id: action.id,
        endpoint: action.endpoint,
        status: responseStatuses.STARTED
      })
    })
  );

const fetchLegendSuccess = (state, action) =>
  state.merge(
    Map({
      fetchedLegends: state.get('fetchedLegends').merge({
        [action.payload.endpoint]: {
          url: action.payload.url,
          legendObject: action.payload.legendObject
        }
      }),
      fetchingLegend: state.get('fetchingLegend').merge({
        id: action.payload.id,
        legendUrl: action.payload.url,
        params: action.payload.params,
        status: responseStatuses.SUCCESS,
        legendObject: action.payload.legendObject
      })
    })
  );

const fetchLegendFailed = (state, action) =>
  state.merge(
    Map({
      fetchingLegend: state.get('fetchingLegend').merge({
        id: action.id,
        endpoint: action.endpoint,
        params: action.params,
        status: responseStatuses.FAILED
      })
    })
  );

const clearFetchingLegend = state =>
  state.merge(
    Map({
      fetchingLegend: state.get('fetchingLegend').clear()
    })
  );

const updateCheckedMapPortalsLayers = (state, action) => {
  return state.merge(
    Map({
      [action.prefix]: state.get(action.prefix).set(
        state
          .get(action.prefix)
          .findIndex(e => e.short_name === action.short_name),
        state
          .get(action.prefix)
          .get(
            state
              .get(action.prefix)
              .findIndex(e => e.short_name === action.short_name)
          )
          .set('legend', action.legend)
      )
    })
  );
};

const deleteWmsLayerFromComposition = (state, { wmsId, layerId }) => {
  const compositionId = state.get('currentCompositionId');
  const compositionEntriesPath = [
    'compositions',
    String(compositionId),
    'legend'
  ];
  const entries = state.getIn(compositionEntriesPath).toJS();

  const wmsGroupIndex = entries.findIndex(({ id }) => id === wmsId);
  const wmsGroup = cloneDeep(entries[wmsGroupIndex]);
  const newWmsGroupEntries = wmsGroup.entries.filter(
    ({ id }) => id !== layerId
  );

  wmsGroup.entries = newWmsGroupEntries;
  const newEntries = fromJS(entries).set(wmsGroupIndex, wmsGroup);
  const newState = state.setIn([...compositionEntriesPath], List(newEntries));

  const isWmsGroupEmpty = !wmsGroup.entries.length;
  // Jeśli grupa WMS-a nie ma żadnych warstw, jest nam zbędna.
  if (isWmsGroupEmpty) {
    return deleteWmsFromComposition(newState, { wmsId });
  }

  return newState;
};

const deleteWmsFromComposition = (state, { wmsId }) => {
  const compositionId = state.get('currentCompositionId');
  const compositionEntriesPath = [
    'compositions',
    String(compositionId),
    'legend'
  ];
  const entries = state.getIn(compositionEntriesPath);

  let newState = state;

  const newEntries = entries.toJS().filter(entry => {
    const { id } = entry;

    if (id === wmsId) {
      newState = removeGroupFromAddedWmsList(state, { entry });
    }

    return entry.id !== wmsId;
  });

  return newState.setIn([...compositionEntriesPath], fromJS(newEntries));
};

const deleteCsvFromComposition = (state, { csvId }) => {
  const compositionId = state.get('currentCompositionId');
  const compositionEntriesPath = [
    'compositions',
    String(compositionId),
    'legend'
  ];
  const entries = state.getIn(compositionEntriesPath);
  let newState = state;

  const newEntries = entries.toJS().filter(entry => {
    const { id } = entry;
    if (id === csvId) {
      newState = removeGroupFromAddedCsvList(state, { entry });
    }

    return id !== csvId;
  });

  return newState.setIn([...compositionEntriesPath], fromJS(newEntries));
};

const addLayersToLayersMap = (state, { layersList }) =>
  state.update('layersMap', Map(), map => map.merge(layersList));

const addLayersToLayersIdMap = (state, { layersList }) =>
  state.set('layersIdMap', Map(layersList));

const fetchCompositionBegin = (state, action) => {
  return state.merge(
    Map({
      fetchingCompositions: state.get('fetchingCompositions').push(action.id),
      isFetchingCompositions: true,
      isFetchedCompositions: false
    })
  );
};

const fetchCompositionSuccess = (state, action) => {
  return state.merge(
    Map({
      fetchedComositions: state.get('fetchedComositions').push(action.data.id),
      fetchingCompositions: state
        .get('fetchingCompositions')
        .filter(v => v !== action.id),
      compositions: state.get('compositions').merge({
        [action.data.id]: fromJS({
          ...action.data,
          portal_id: action.portal_id,
          prefix: action.prefix
        })
      }),
      isFetchingCompositions: false,
      isFetchedCompositions: true
    })
  );
};

const fetchCompositionFailed = (state, action) => {
  return state.merge(
    Map({
      isFetchingCompositions: false,
      isFetchedCompositions: false
    })
  );
};

const fetchMapPortalCompositionsBegin = state =>
  state.set('fetchingMapPortalCompositions', true);

const fetchMapPortalCompositionsEnd = state =>
  state.set('fetchingMapPortalCompositions', false);

const fetchMapPortalCompositionsSuccess = (
  state,
  { district, mapPortalId, compositions }
) => {
  const newState = state.setIn(
    ['mapPortalCompositions', district, mapPortalId],
    compositions
  );

  return fetchMapPortalCompositionsEnd(newState);
};

const addCompositionToMapPortalSuccess = (state, action) =>
  state.updateIn(
    ['mapPortalCompositions', action.district, action.mapPortalId],
    List(),
    list => {
      const newList = list;
      newList.push(action.composition);
      return newList;
    }
  );

const removeCompositionFromMapPortalSuccess = (state, action) => {
  const compositionIndex = state
    .getIn(['mapPortalCompositions', action.district, action.mapPortalId])
    .findIndex(composition => composition.id === action.compositionId);

  return state.updateIn(
    ['mapPortalCompositions', action.district, action.mapPortalId],
    List(),
    list => {
      const newList = [...list];
      newList.splice(compositionIndex, 1);
      return newList;
    }
  );
};

const setCurrentComposition = (state, { currentCompositionId }) => {
  return state.merge(
    Map({
      currentCompositionId
    })
  );
};

const clearCurrentCompositionId = state => {
  return state.merge(Map({ currentCompositionId: null }));
};

const editMapPortalCompositionSuccess = (state, action) => {
  const {
    mapPortalId,
    compositionId,
    compositionData: newCompositionData,
    district
  } = action;

  const compositionToEditIndex = state
    .get('mapPortalCompositions')
    .get(district)
    .get(mapPortalId)
    .findIndex(composition => composition.id === compositionId);

  const mapPortalCompositions = state
    .get('mapPortalCompositions')
    .get(district)
    .get(mapPortalId);
  const newMapPortalCompositions = [...mapPortalCompositions];
  newMapPortalCompositions[compositionToEditIndex] = newCompositionData;

  return state.setIn(
    ['mapPortalCompositions', district, mapPortalId],
    newMapPortalCompositions
  );
};

const fetchingDistrictCompositionsBegin = (state, action) => {
  return state.merge(
    Map({
      fetchingDistrictCompositions: state
        .get('fetchingDistrictCompositions')
        .push(action.prefix),
      isFetchingDistrictCompositions: true
    })
  );
};

const fetchingDistrictCompositionsSuccesss = (state, action) => {
  return state.merge(
    Map({
      isFetchingDistrictCompositions: false,
      fetchedDistrictCompositions: state
        .get('fetchedDistrictCompositions')
        .push(action.data.prefix),
      fetchingDistrictCompositions: state
        .get('fetchingDistrictCompositions')
        .filter(v => v !== action.prefix),
      district_compositions: state.get('district_compositions').merge({
        [action.prefix]: fromJS(action.data)
      })
    })
  );
};

const fetchQgisProjectsBegin = (state, action) =>
  state.update('fetchingDistrictQgisProjects', fetchingDistrictQgisProjects =>
    fetchingDistrictQgisProjects.push(action.prefix)
  );

const fetchQgisProjectsSuccess = (state, action) => {
  const state1 = state.merge(
    Map({
      fetchingDistrictQgisProjects: state
        .get('fetchingDistrictQgisProjects')
        .filter(prefix => prefix !== action.prefix),
      fetchedDistrictQgisProjects: state
        .get('fetchedDistrictQgisProjects')
        .push(action.prefix)
    })
  );
  const newState = state1.setIn(
    ['qgis_projects', action.prefix],
    fromJS(action.projects)
  );

  return newState;
};

const updateQgisProjectData = (state, { district, projectId, project }) => {
  const projectIndex = state
    .getIn(['qgis_projects', district])
    .findIndex(project => project.get('id') === Number(projectId));

  return state.updateIn(
    ['qgis_projects', district, projectIndex],
    Map(),
    prevProject => prevProject.merge(project)
  );
};

const deleteQgisProjectBegin = (state, action) =>
  state.merge(
    Map({
      deletingProjects: state.get('deletingProjects').push(action.id)
    })
  );

const deleteQgisProjectSuccess = (state, action) => {
  const deletingProjects = state.get('deletingProjects');
  const newDeletingProjects = deletingProjects.delete(
    deletingProjects.indexOf(action.id)
  );

  return state.merge(
    Map({
      qgis_projects: Map({
        [action.prefix]: state
          .get('qgis_projects')
          .get(action.prefix)
          .filter(project => project.toJS().id !== action.id)
      }),
      deletingProjects: newDeletingProjects
    })
  );
};

const deleteQgisProjectFailed = (state, action) => {
  const deletingProjects = state.get('deletingProjects');
  const newDeletingProjects = deletingProjects.delete(
    deletingProjects.indexOf(action.id)
  );

  return state.merge(
    Map({
      deletingProjects: newDeletingProjects
    })
  );
};

const fetchNetworkDriveFolderSuccess = (state, action) =>
  state.merge({
    networkDriveFolders: action.folders
  });

const fetchNetworkDriveFileSuccess = (state, action) =>
  state.merge(
    Map({
      networkDriveFiles: state.get('networkDriveFiles').merge({
        [action.folder]: action.files
      })
    })
  );

const fetchingNetworkDriveBegin = state =>
  state.merge({
    fetchingNetworkDrive: responseStatuses.STARTED
  });

const fetchingNetworkDriveSuccess = state =>
  state.merge({
    fetchingNetworkDrive: responseStatuses.SUCCESS
  });

const fetchingNetworkDriveFailed = state =>
  state.merge({
    fetchingNetworkDrive: responseStatuses.FAILED
  });

const selectingProjectFromNetworkDriveBegin = state =>
  state.merge({
    selectingProjectFromNetworkDrive: responseStatuses.STARTED
  });

const selectingProjectFromNetworkDriveSuccess = state =>
  state.merge({
    selectingProjectFromNetworkDrive: responseStatuses.SUCCESS,
    progressAddingProject: 100,
    uuidJobFromAddedProject: ''
  });

const selectingProjectFromNetworkDriveFailed = (state, action) =>
  state.merge({
    selectingProjectFromNetworkDrive: responseStatuses.FAILED,
    progressAddingProject: 100,
    uuidJobFromAddedProject: '',
    errorJobFromAddProject: action.error
  });

const selectingProjectFromNetworkDriveStatus = (state, action) =>
  state.merge({
    selectingProjectFromNetworkDrive: action.status,
    uuidJobFromAddedProject: action.uuidJob,
    progressAddingProject: action.progressAddingProject
  });

const getJobStatusFromAddedProject = (state, action) =>
  state.merge({
    selectingProjectFromNetworkDrive: action.status,
    progressAddingProject: action.progressAddingProject
  });

const clearSelectingNdProjectStatus = state =>
  state.merge({
    selectingProjectFromNetworkDrive: false,
    uuidJobFromAddedProject: ''
  });

const setEmptyPhotoComposition = (state, action) => {
  const { mapPortalId, compositionId, photos, district } = action;

  const compositionToEditIndex = state
    .get('mapPortalCompositions')
    .get(district)
    .get(mapPortalId)
    .findIndex(composition => composition.id === compositionId);

  const mapPortalCompositions = state
    .get('mapPortalCompositions')
    .get(district)
    .get(mapPortalId);
  const newMapPortalCompositions = [...mapPortalCompositions];
  newMapPortalCompositions[compositionToEditIndex].photos = photos;

  return state.setIn(
    ['mapPortalCompositions', district, mapPortalId],
    newMapPortalCompositions
  );
};

const fetchRasterTimelineSuccess = (state, action) =>
  state.merge({
    rasterTimelines: List(action.default)
  });

const addRasterTimelineSucces = (state, action) =>
  state.update('rasterTimelines', rasterTimelines =>
    rasterTimelines.push(action.default)
  );

const deleteRasterTimelineSucces = (state, action) =>
  state.update('rasterTimelines', rasterTimelines =>
    rasterTimelines.filter(
      rasterTimeline => rasterTimeline.id !== action.timelineId
    )
  );

const updateRasterTimelineSucces = (state, action) =>
  state.update('rasterTimelines', rasterTimelines =>
    rasterTimelines
      .filter(rasterTimeline => rasterTimeline.id !== action.payload.timelineId)
      .push(action.payload.data)
  );

const getPublicWMSSuccess = (state, { wms }) =>
  state.set('publicWMS', List(wms));

const addCompositionCsvEntry = (state, { entry }) => {
  const compositionId = state.get('currentCompositionId');
  let newEntries = state.getIn([
    'compositions',
    String(compositionId),
    'legend'
  ]);

  const entryToReplaceIndex = newEntries.findIndex(({ id }) => id === entry.id);

  // Jeśli już jest podgrupa danego WMS-a to podmieniamy ją
  if (entryToReplaceIndex !== -1) {
    newEntries = newEntries.set(entryToReplaceIndex, entry);
    // A jeśli nie to dodajemy nową
  } else {
    newEntries = newEntries.unshift(entry);
  }

  return addToTouchedGroups(state, { groupId: entry.id }).setIn(
    ['compositions', String(compositionId), 'legend'],
    List(newEntries)
  );
};

const addCompositionWmsEntry = (state, { entry }) => {
  const compositionId = state.get('currentCompositionId');

  let newEntries = cloneDeep(
    state.getIn(['compositions', String(compositionId), 'legend'])
  );

  const entryToReplaceIndex = newEntries.findIndex(({ id }) => id === entry.id);

  // Jeśli już jest podgrupa danego WMS-a to podmieniamy ją
  if (entryToReplaceIndex !== -1) {
    newEntries = newEntries.set(entryToReplaceIndex, entry);
    // A jeśli nie to dodajemy nową
  } else {
    newEntries = newEntries.unshift(entry);
  }

  return addToTouchedGroups(state, { groupId: entry.id }).setIn(
    ['compositions', String(compositionId), 'legend'],
    List(newEntries)
  );
};

const updateCompositionEntriesOrder = (
  state,
  { compositionId, newOrder, parentId }
) => {
  let newLegend = newOrder;

  // Jeśli parentId jest puste, oznacza to, że zmieniono porządek grup na głównym
  // poziomie, czyli nie musimy iterować wgłąb obiektu - wystarczy ustawić przekazaną
  // kolejność.
  // W przeciwnym razie tworzymy kopię obiektu kompozycji, podmieniamy tylko obiekt
  // grupy, która zmieniła kolejność i nadpisujemy kompozycję.
  if (parentId) {
    const compositionEntries = state
      .getIn(['compositions', String(compositionId), 'legend'])
      .toJS();
    newLegend = deepReplace({
      obj: compositionEntries,
      checkCondition: item => item && item.id === parentId,
      replace: item => ({ ...item, entries: newOrder })
    });
  }
  return state.setIn(
    ['compositions', String(compositionId), 'legend'],
    fromJS(newLegend)
  );
};

const insertToAddedCsvList = (state, { entry, compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');
  const csvList = [entry.id];

  return state.updateIn(['addedCsv', composition], List(), list =>
    List(new Set([...list.toJS(), ...csvList]))
  );
};

const insertToAddedWmsList = (state, { entry, compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');
  const wmsList = [entry.id, ...entry.entries.map(({ id }) => String(id))];

  return state.updateIn(['addedWms', composition], List(), list =>
    // Set usuwa duplikaty
    List(new Set([...list.toJS(), ...wmsList]))
  );
};

const removeGroupFromAddedWmsList = (state, { entry, compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');
  const wmsList = [entry.id, ...entry.entries.map(({ id }) => String(id))];

  return state.updateIn(['addedWms', composition], List(), list =>
    List(list.filter(item => !wmsList.includes(item)))
  );
};

const removeGroupFromAddedCsvList = (state, { entry, compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');

  return state.updateIn(['addedCsv', composition], List(), list =>
    List(list.filter(item => !entry.id.includes(item)))
  );
};

const removeLayerFromAddedWmsList = (state, { layerId, compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');

  return state.updateIn(['addedWms', composition], List(), list =>
    List(list.filter(item => item !== String(layerId)))
  );
};

const clearAddedWmsList = (state, { compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');
  return state.removeIn(['addedWms', composition]);
};

const clearAddedCSVList = (state, { compositionId }) => {
  const composition = compositionId || state.get('currentCompositionId');
  return state.removeIn(['addedCsv', composition]);
};

const setActiveTool = (state, { activeTool }) =>
  state.set('activeTool', activeTool);

const resetActiveTool = state => state.set('activeTool', null);

const fetchVectorTimelinesSuccess = (state, action) =>
  state.merge({
    vectorTimelines: List(action.default)
  });

const addVectorTimelineSucces = (state, action) =>
  state.update('vectorTimelines', vectorTimelines =>
    vectorTimelines.push(action.default)
  );

const deleteVectorTimelineSucces = (state, action) =>
  state.update('vectorTimelines', vectorTimelines =>
    vectorTimelines.filter(timeline => timeline.id !== action.timelineId)
  );

const fetchProjectLayersSuccess = (state, { projectId, projectLayers }) =>
  state.setIn(['projectLayers', projectId], projectLayers);

const setSpotMeasureConfigSuccess = (
  state,
  { prefix, mapPortalId, measureConfig }
) =>
  state.updateIn(['spotMeasureConfigs', prefix, mapPortalId], List(), list => {
    const array = [...list, ...measureConfig];
    const arrayUnique = [
      ...new Map(array.map(item => [item.id, item])).values()
    ];
    return arrayUnique;
  });

const addSpotMeasureLayerSuccess = (
  state,
  { prefix, mapPortalId, measureConfig }
) => {
  const mapPortal = state
    .get(prefix)
    .find(item => item.get('id') === Number(mapPortalId));

  const mapPortalIndex = state.get(prefix).indexOf(mapPortal);
  const newMapPortal = mapPortal.update('measurment_layers', list => [
    ...list,
    measureConfig.layer.id
  ]);

  const newState = setSpotMeasureConfigSuccess(state, {
    prefix,
    mapPortalId,
    measureConfig: [measureConfig]
  });

  return newState.setIn([prefix, mapPortalIndex], newMapPortal);
};

const editSpotMeasureLayerSuccess = (
  state,
  { prefix, mapPortalId, layerId, config }
) => {
  const configs = state.getIn(['spotMeasureConfigs', prefix, mapPortalId]);
  const currentConfigIndex = configs.findIndex(({ id }) => id === layerId);

  const newConfigs = [...configs];
  newConfigs[currentConfigIndex] = config;

  return state.setIn(['spotMeasureConfigs', prefix, mapPortalId], newConfigs);
};

const deleteSpotMeasureLayerSuccess = (
  state,
  { prefix, mapPortalId, layerId, assignedLayerId }
) => {
  const mapPortal = state
    .get(prefix)
    .find(item => item.get('id') === Number(mapPortalId));
  const mapPortalIndex = state.get(prefix).indexOf(mapPortal);
  const newMapPortal = mapPortal.update('measurment_layers', list =>
    list.filter(id => id !== assignedLayerId)
  );

  const newState = state.setIn([prefix, mapPortalIndex], newMapPortal);
  return newState.updateIn(['spotMeasureConfigs', prefix, mapPortalId], list =>
    list.filter(({ id }) => id !== layerId)
  );
};

const setEnableGeolocation = (state, action) =>
  state.merge(
    Map({
      enabledGeolocation: action.enable,
      geolocationCoords: action.coords
    })
  );

const setingGeolocation = (state, action) =>
  state.set('setingGeolocation', action.payload);

const getCompositionSidebarSettings = (
  state,
  { district, mapPortalCompositionId, settings }
) =>
  state
    .setIn(
      ['compositionsSidebarSettings', district, mapPortalCompositionId],
      Map(settings)
    )
    .set('gettingSidebarSettings', false);

const updateCompositionSidebarSettings = (
  state,
  { district, mapPortalCompositionId, settings }
) =>
  state.setIn(
    ['compositionsSidebarSettings', district, mapPortalCompositionId],
    Map(settings)
  );

const setPortalCompositionTags = (
  state,
  { prefix, mapPortalId, compositionId, tags }
) => {
  const mapPortalCompositions = state.getIn([
    'mapPortalCompositions',
    prefix,
    mapPortalId
  ]);
  const currentCompositionIndex = mapPortalCompositions.findIndex(
    ({ id }) => id === compositionId
  );

  if (currentCompositionIndex === -1) return state;

  mapPortalCompositions[currentCompositionIndex].tags = tags;

  return state.setIn(
    ['mapPortalCompositions', prefix, mapPortalId],
    mapPortalCompositions
  );
};

const getProjectUpdateLogs = (state, { district, projectId, logs }) =>
  state.setIn(['projectsLogs', district, projectId], logs);

const deleteProjectUpdateLog = (state, { district, projectId, logId }) => {
  const projectLogs = state.getIn(['projectsLogs', district, projectId]);
  const newProjectLogs = projectLogs.filter(({ id }) => id !== logId);
  return state.setIn(['projectsLogs', district, projectId], newProjectLogs);
};

const addGroupToMapPortal = (state, { district, mapPortalId, groupId }) => {
  const mapPortalIndex = state
    .get(district)
    .findIndex(item => item.get('id') === Number(mapPortalId));

  const mapPortal = state.getIn([district, mapPortalIndex]);
  const groups = mapPortal.get('groups') || [];

  return state.setIn(
    [district, mapPortalIndex, 'groups'],
    [...groups, groupId]
  );
};

const updateProjectMapProxySettings = (state, { compositionId, data }) => {
  const composition = state
    .getIn(['compositions', compositionId, 'legend'])
    ?.toJS();

  data.legend_layers.forEach(({ layerid, ...settings }) => {
    const layer = findEntryInComposition(composition, layerid, 'layerid');

    Object.keys(settings).forEach(key => {
      layer[key] = settings[key];
    });
  });

  return state.setIn(
    ['compositions', compositionId, 'legend'],
    fromJS(composition)
  );
};

const fetchLayerGeoJSONSuccess = (state, action) =>
  state.set('fetchedLayerGeoJSON', Map(action.payload));

const addToTouchedGroups = (state, { groupId }) => {
  const nextData = state.get('touchedGroups').add(groupId);
  return state.set('touchedGroups', nextData);
};

const removeFromTouchedGroups = (state, { groupId }) => {
  const nextData = state.get('touchedGroups').delete(groupId);
  return state.set('touchedGroups', nextData);
};

const clearTouchedGroups = state => state.set('touchedGroups', Set());

const updateLayerVisibility = (state, { data, uniqueId }) => {
  const { composition, type } = data;
  const compositionId = composition.toString();
  const idType = type === 'layer' ? 'layerid' : 'groupid';
  const compositionLegend = state
    .getIn(['compositions', compositionId, 'legend'])
    ?.toJS();
  const layer = findEntryInComposition(compositionLegend, uniqueId, idType);
  Object.keys(data).forEach(key => {
    layer[key] = data[key];
  });

  return state.setIn(
    ['compositions', compositionId, 'legend'],
    fromJS(compositionLegend)
  );
};

const fetchVoivodeshipsSuccess = (state, { voivodeships }) =>
  state.set('voivodeships', voivodeships);

const fetchCountiesSuccess = (state, { counties }) =>
  state.set('counties', counties);

const fetchCommunesSuccess = (state, { communes }) =>
  state.set('communes', communes);

const fetchRegionsSuccess = (state, { regions }) =>
  state.set('regions', regions);

const setInvestorDrawing = (state, { payload }) =>
  state.set('investorDrawing', payload);

const showServicesModal = (state, { path, capabilities }) =>
  state.merge(
    Map({
      isServicesModalVisible: true,
      servicePath: path,
      capabilities: capabilities
    })
  );
const hideServicesModal = state =>
  state.merge(
    Map({
      isServicesModalVisible: false,
      servicePath: ''
    })
  );

const showPrivacyModal = state => state.set('isPrivacyModalVisible', true);
const hidePrivacyModal = state => state.set('isPrivacyModalVisible', false);

const clearMapPortalReducerState = () => initialState;

const setSearchInputData = (state, { payload }) =>
  state.set('searchInputData', payload);

const setSearchInputOptions = (state, { payload }) =>
  state.set('searchInputOptions', payload);

const setSearchInputGugikAddress = (state, { payload }) =>
  state.set('searchInputGugikAddress', payload);

const setSearchInputGugikData = (state, { payload }) =>
  state.set('searchInputGugikData', payload);

const setSearchInputGugikDisplay = (state, { payload }) =>
  state.set('searchInputGugikDisplay', payload);

const getCompositionSidebarSettingsBegin = state =>
  state.set('gettingSidebarSettings', true);

const getCompositionSidebarSettingsFailed = state =>
  state.set('gettingSidebarSettings', false);

const resetSearchInput = state =>
  state.merge(
    Map({
      searchInputData: undefined,
      searchInputOptions: [],
      searchInputGugikAddress: undefined,
      searchInputGugikDisplay: undefined,
      searchInputGugikData: {
        voivodeship: undefined,
        county: undefined,
        commune: undefined,
        region: undefined,
        parcel: undefined
      }
    })
  );

const setActiveIndexToolbar = (state, { payload }) =>
  state.set('activeToolIndex', payload);

const fetchSelectionMethodsBegin = state =>
  state.merge({
    fetchingSelectionMethods: true
  });

const fetchSelectionMethodsSuccess = (state, action) =>
  state.merge({
    fetchingSelectionMethods: false,
    selectionMethods: List(action.payload)
  });

const fetchSelectionMethodsFailed = state =>
  state.merge({
    fetchingSelectionMethods: false
  });

const fetchSelectionTypesBegin = state =>
  state.merge({
    fetchingSelectionTypes: true
  });

const fetchSelectionTypesSuccess = (state, action) =>
  state.merge({
    fetchingSelectionTypes: false,
    selectionTypes: List(action.payload)
  });

const fetchSelectionTypesFailed = state =>
  state.merge({
    fetchingSelectionTypes: false
  });

const fetchSelectionOperatorsBegin = state =>
  state.merge({
    fetchingSelectionOperators: true
  });

const fetchSelectionOperatorsSuccess = (state, action) =>
  state.merge({
    fetchingSelectionOperators: false,
    selectionOperators: List(action.payload)
  });

const fetchSelectionOperatorsFailed = state =>
  state.merge({
    fetchingSelectionOperators: false
  });

const fetchArConfigsSuccess = (
  state,
  { payload: { district, mapPortalConpositionId, configs } }
) =>
  state.setIn(['arConfigs', district, mapPortalConpositionId], fromJS(configs));

const addArConfigSuccess = (
  state,
  { payload: { district, mapPortalConpositionId, config } }
) =>
  state.updateIn(
    ['arConfigs', district, mapPortalConpositionId],
    List(),
    list => list.push(Map(config))
  );

const updateArConfigSuccess = (
  state,
  { payload: { district, mapPortalCompositionId, configId, config } }
) =>
  state.updateIn(['arConfigs', district, mapPortalCompositionId], list => {
    const currentConfigIndex = list.findIndex(
      config => config.get('id') === configId
    );

    return currentConfigIndex === -1
      ? list
      : list.set(currentConfigIndex, Map(config));
  });

const deleteArConfigSuccess = (
  state,
  { payload: { district, mapPortalCompositionId, configId } }
) =>
  state.updateIn(['arConfigs', district, mapPortalCompositionId], list =>
    list.filter(config => config.get('id') !== configId)
  );

const updateArConfigAttrsListSuccess = (
  state,
  { payload: { district, mapPortalCompositionId, configId, attributesList } }
) =>
  state.setIn(
    ['arConfigsAttrs', district, mapPortalCompositionId, configId],
    fromJS(attributesList)
  );

const deleteArConfigAttrsList = (
  state,
  { payload: { district, mapPortalCompositionId, configId } }
) =>
  state.deleteIn([
    'arConfigsAttrs',
    district,
    mapPortalCompositionId,
    configId
  ]);

const setArCompositionConfig = (
  state,
  { payload: { district, mapPortalCompositionId, config } }
) =>
  state.setIn(
    ['arCompositionsConfigs', district, mapPortalCompositionId],
    Map(config)
  );
const fetchMapPortalToolsStarted = state =>
  state.set('isFetchingMapPortalTools', true);

const fetchMapPortalToolsSuccess = (state, { data }) =>
  state
    .set('mapPortalToolsConfiguration', data)
    .set(
      'initialSelectedRowKeys',
      data.map(({ id }) => id)
    )
    .set('isFetchingMapPortalTools', false);

const fetchMapPortalToolsFailed = state =>
  state.set('isFetchingMapPortalTools', false);

const setDefaultMode = (state, { payload }) =>
  state.set('defaultMode', payload);

const updateMapPortalToolStarted = state =>
  state.set('isUpdatingMapPortalTool', true);

const updateMapPortalToolSuccess = state =>
  state.set('isUpdatingMapPortalTool', false);

const updateMapPortalToolFailed = state =>
  state.set('isUpdatingMapPortalTool', false);

const fetchDistrictLocalitiesStarted = state =>
  state.set('isFetchingDistrictLocalities', true);

const fetchDistrictLocalitiesSuccess = (
  state,
  { payload: { district, namesOfLocalities } }
) =>
  state
    .setIn(['districtLocalities', district], namesOfLocalities)
    .set('isFetchingDistrictLocalities', false);

const fetchDistrictLocalitiesFailed = state =>
  state.set('isFetchingDistrictLocalities', false);

const fetchLocalityStreetsStarted = state =>
  state.set('isFetchingLocalityStreets', true);

const fetchLocalityStreetsSuccess = (
  state,
  { payload: { simc, localityStreets } }
) =>
  state
    .setIn(['localityStreets', simc], localityStreets)
    .set('isFetchingLocalityStreets', false);

const fetchLocalityStreetsFailed = state =>
  state.set('isFetchingLocalityStreets', false);

const getWmsLegendBegin = (state, action) =>
  state.merge(
    Map({
      fetchingWmsLegend: state.get('fetchingWmsLegend').merge({
        layer: action.layer,
        url: action.url,
        status: responseStatuses.STARTED
      })
    })
  );

const getWmsLegendSuccess = (state, action) =>
  state.merge(
    Map({
      fetchedWmsLegends: state.get('fetchedWmsLegends').merge({
        [action.payload.url]: {
          [action.payload.layer]: {
            url: action.payload.url,
            legendObject: action.payload.legend
          }
        }
      }),
      fetchingWmsLegend: state.get('fetchingWmsLegend').merge({
        layer: action.payload.layer,
        url: action.payload.url,
        status: responseStatuses.SUCCESS,
        legendObject: action.payload.legend
      })
    })
  );

const getWmsLegendFailed = (state, action) =>
  state.merge(
    Map({
      fetchingWmsLegend: state.get('fetchingWmsLegend').merge({
        layer: action.layer,
        url: action.url,
        status: responseStatuses.FAILED
      })
    })
  );

const clearFetchingWmsLegend = state =>
  state.merge(
    Map({
      fetchingWmsLegend: state.get('fetchingWmsLegend').clear()
    })
  );

const showServiceInfoModal = (state, { serviceLayer }) =>
  state.merge(
    Map({
      isServiceInfoModalVisible: true,
      serviceLayer: serviceLayer
    })
  );

const hideServiceInfoModal = state =>
  state.merge(
    Map({
      isServiceInfoModalVisible: false,
      serviceLayer: null
    })
  );

const fetchHomeSiteIframeSuccess = (state, action) =>
  state.set('iframePortalData', action.payload);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_DISTRICT_MAP_PORTALS_BEGIN]: fetchDistrictMapPortals,
      [FETCH_DISTRICT_MAP_PORTALS_SUCCESS]: fetchDistrictMapPortalsSuccess,
      [CREATE_MAP_PORTAL_SUCCESS]: createDistrictMapPortalSuccess,
      [CHANGE_MAP_PORTAL_SUCCESS]: changeDistrictMapPortalSuccess,
      [FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS]: fetchDistrictMissingMapPortalSucces,
      [DELETE_MAP_PORTAL_SUCCESS]: deleteDistrictMapPortalSuccess,
      [SAVE_DISTRICT_MAP_PORTALS_NAMES]: saveDistrictMapPortalsNames,
      [SHOW_LEGEND]: showLegend,
      [HIDE_LEGEND]: hideLegend,
      [FETCH_LEGEND_BEGIN]: fetchLegendBegin,
      [FETCH_LEGEND_SUCCESS]: fetchLegendSuccess,
      [FETCH_LEGEND_FAILED]: fetchLegendFailed,
      [CLEAR_FETCHING_LEGEND]: clearFetchingLegend,
      [UPDATE_CHECKED_MAPPORTALS_LAYERS]: updateCheckedMapPortalsLayers,
      [FETCH_COMPOSITION_BEGIN]: fetchCompositionBegin,
      [FETCH_COMPOSITION_SUCCESS]: fetchCompositionSuccess,
      [FETCH_COMPOSITION_FAILED]: fetchCompositionFailed,
      [FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN]: fetchMapPortalCompositionsBegin,
      [FETCH_MAP_PORTAL_COMPOSITIONS_FAILED]: fetchMapPortalCompositionsEnd,
      [FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS]: fetchMapPortalCompositionsSuccess,
      [ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS]: addCompositionToMapPortalSuccess,
      [REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS]: removeCompositionFromMapPortalSuccess,
      [SET_CURRENT_COMPOSITION]: setCurrentComposition,
      [EDIT_MAP_PORTAL_COMPOSITION_SUCCESS]: editMapPortalCompositionSuccess,
      [DELETE_WMS_LAYER]: deleteWmsLayerFromComposition,
      [DELETE_CSV_FROM_COMPOSITION]: deleteCsvFromComposition,
      [DELETE_WMS_FROM_COMPOSITION]: deleteWmsFromComposition,
      [ADD_LAYERS_TO_LAYERS_MAP]: addLayersToLayersMap,
      [ADD_LAYERS_TO_LAYERS_ID_MAP]: addLayersToLayersIdMap,
      [FETCH_QGIS_PROJECTS_BEGIN]: fetchQgisProjectsBegin,
      [FETCH_QGIS_PROJECTS_SUCCESS]: fetchQgisProjectsSuccess,
      [UPDATE_QGIS_PROJECT_DATA_SUCCESS]: updateQgisProjectData,
      [DELETE_QGIS_PROJECT_BEGIN]: deleteQgisProjectBegin,
      [DELETE_QGIS_PROJECT_SUCCESS]: deleteQgisProjectSuccess,
      [DELETE_QGIS_PROJECT_FAILED]: deleteQgisProjectFailed,
      [FETCH_DISTRICT_COMPOSITIONS_BEGIN]: fetchingDistrictCompositionsBegin,
      [FETCH_DISTRICT_COMPOSITIONS_SUCCESS]: fetchingDistrictCompositionsSuccesss,
      [FETCH_NETWORK_DRIVE_FOLDER_SUCCESS]: fetchNetworkDriveFolderSuccess,
      [FETCH_NETWORK_DRIVE_FILE_SUCCESS]: fetchNetworkDriveFileSuccess,
      [FETCHING_NETWORK_DRIVE_BEGIN]: fetchingNetworkDriveBegin,
      [FETCHING_NETWORK_DRIVE_SUCCESS]: fetchingNetworkDriveSuccess,
      [FETCHING_NETWORK_DRIVE_FAILED]: fetchingNetworkDriveFailed,
      [SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN]: selectingProjectFromNetworkDriveBegin,
      [SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS]: selectingProjectFromNetworkDriveSuccess,
      [SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED]: selectingProjectFromNetworkDriveFailed,
      [SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS]: selectingProjectFromNetworkDriveStatus,
      [CLEAR_SELECTING_ND_PROJECT_STATUS]: clearSelectingNdProjectStatus,
      [CLEAR_MAP_PORTAL_REDUCER_STATE]: clearMapPortalReducerState,
      [GET_JOB_STATUS_FROM_ADDED_PROJECT]: getJobStatusFromAddedProject,
      [SET_PHOTOS_COMPOSITION]: setEmptyPhotoComposition,
      [FETCH_RASTER_TIMELINE_SUCCESS]: fetchRasterTimelineSuccess,
      [ADD_RASTER_TIMELINE_SUCCESS]: addRasterTimelineSucces,
      [DELETE_RASTER_TIMELINE_SUCCESS]: deleteRasterTimelineSucces,
      [UPDATE_RASTER_TIMELINE_SUCCESS]: updateRasterTimelineSucces,
      [GET_PUBLIC_WMS_SUCCESS]: getPublicWMSSuccess,
      [ADD_COMPOSITION_WMS_ENTRY]: addCompositionWmsEntry,
      [ADD_COMPOSITION_CSV_ENTRY]: addCompositionCsvEntry,
      [UPDATE_COMPOSITION_ENTRIES_ORDER]: updateCompositionEntriesOrder,
      [INSERT_TO_ADDED_WMS_LIST]: insertToAddedWmsList,
      [INSERT_TO_ADDED_CSV_LIST]: insertToAddedCsvList,
      [REMOVE_LAYER_FROM_ADDED_WMS_LIST]: removeLayerFromAddedWmsList,
      [CLEAR_ADDED_CSV_LIST]: clearAddedCSVList,
      [CLEAR_ADDED_WMS_LIST]: clearAddedWmsList,
      [SET_ACTIVE_TOOL]: setActiveTool,
      [RESET_ACTIVE_TOOL]: resetActiveTool,
      [ADD_VECTOR_TIMELINE_SUCCESS]: addVectorTimelineSucces,
      [FETCH_VECTOR_TIMELINES_SUCCESS]: fetchVectorTimelinesSuccess,
      [DELETE_VECTOR_TIMELINE_SUCCESS]: deleteVectorTimelineSucces,
      [GET_PROJECT_LAYERS_SUCCESS]: fetchProjectLayersSuccess,
      [GET_SPOT_MEASURE_CONFIG_SUCCESS]: setSpotMeasureConfigSuccess,
      [ADD_SPOT_MEASURE_LAYER_SUCCESS]: addSpotMeasureLayerSuccess,
      [EDIT_SPOT_MEASURE_LAYER_SUCCESS]: editSpotMeasureLayerSuccess,
      [DELETE_SPOT_MEASURE_LAYER_SUCCESS]: deleteSpotMeasureLayerSuccess,
      [SET_ENABLE_GEOLOCATION]: setEnableGeolocation,
      [SETTING_GEOLOCATION]: setingGeolocation,
      [GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN]: getCompositionSidebarSettingsBegin,
      [GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS]: getCompositionSidebarSettings,
      [GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED]: getCompositionSidebarSettingsFailed,
      [UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS]: updateCompositionSidebarSettings,
      [SET_PORTAL_COMPOSITION_TAGS_SUCCESS]: setPortalCompositionTags,
      [GET_PROJECT_UPDATE_LOGS_SUCCESS]: getProjectUpdateLogs,
      [DELETE_PROJECT_UPDATE_LOG_SUCCESS]: deleteProjectUpdateLog,
      [ADD_GROUP_TO_MAP_PORTAL_SUCCESS]: addGroupToMapPortal,
      [UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS]: updateProjectMapProxySettings,
      [FETCH_LAYER_GEOJSON_SUCCESS]: fetchLayerGeoJSONSuccess,
      [ADD_TO_TOUCHED_GROUPS]: addToTouchedGroups,
      [REMOVE_FROM_TOUCHED_GROUPS]: removeFromTouchedGroups,
      [CLEAR_TOUCHED_GROUPS]: clearTouchedGroups,
      [UPDATE_LAYER_VISIBILITY_SUCCESS]: updateLayerVisibility,
      [FETCH_VOIVODESHIPS_SUCCESS]: fetchVoivodeshipsSuccess,
      [FETCH_COUNTIES_SUCCESS]: fetchCountiesSuccess,
      [FETCH_COMMUNES_SUCCESS]: fetchCommunesSuccess,
      [FETCH_REGIONS_SUCCESS]: fetchRegionsSuccess,
      [SET_INVESTOR_DRAWING]: setInvestorDrawing,
      [SHOW_SERVICES_MODAL]: showServicesModal,
      [HIDE_SERVICES_MODAL]: hideServicesModal,
      [SHOW_PRIVACY_MODAL]: showPrivacyModal,
      [HIDE_PRIVACY_MODAL]: hidePrivacyModal,
      [SET_SEARCH_INPUT_DATA]: setSearchInputData,
      [SET_SEARCH_INPUT_OPTIONS]: setSearchInputOptions,
      [SET_SEARCH_INPUT_GUGIK_ADDRESS]: setSearchInputGugikAddress,
      [SET_SEARCH_INPUT_GUGIK_DATA]: setSearchInputGugikData,
      [SET_SEARCH_INPUT_GUGIK_DISPLAY]: setSearchInputGugikDisplay,
      [RESET_SEARCH_INPUT]: resetSearchInput,
      [SET_ACTIVE_INDEX_TOOLBAR]: setActiveIndexToolbar,
      [UPDATE_ORDER_MAP_PORTAL_SUCCESS]: updateOrderMapPortalSuccess,
      [FETCH_SELECTION_METHODS_BEGIN]: fetchSelectionMethodsBegin,
      [FETCH_SELECTION_METHODS_SUCCESS]: fetchSelectionMethodsSuccess,
      [FETCH_SELECTION_METHODS_FAILED]: fetchSelectionMethodsFailed,
      [FETCH_SELECTION_TYPES_BEGIN]: fetchSelectionTypesBegin,
      [FETCH_SELECTION_TYPES_SUCCESS]: fetchSelectionTypesSuccess,
      [FETCH_SELECTION_TYPES_FAILED]: fetchSelectionTypesFailed,
      [FETCH_SELECTION_OPERATOR_BEGIN]: fetchSelectionOperatorsBegin,
      [FETCH_SELECTION_OPERATOR_SUCCESS]: fetchSelectionOperatorsSuccess,
      [FETCH_SELECTION_OPERATOR_FAILED]: fetchSelectionOperatorsFailed,
      [FETCH_AR_CONFIGS_SUCCESS]: fetchArConfigsSuccess,
      [ADD_AR_CONFIG_SUCCESS]: addArConfigSuccess,
      [UPDATE_AR_CONFIG_SUCCESS]: updateArConfigSuccess,
      [DELETE_AR_CONFIG_SUCCESS]: deleteArConfigSuccess,
      [FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS]: updateArConfigAttrsListSuccess,
      [UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS]: updateArConfigAttrsListSuccess,
      [DELETE_AR_CONFIG_ATTRS_LIST]: deleteArConfigAttrsList,
      [FETCH_AR_COMPOSITION_CONFIG_SUCCESS]: setArCompositionConfig,
      [UPDATE_AR_COMPOSITION_CONFIG_SUCCESS]: setArCompositionConfig,
      [FETCH_MAP_PORTAL_TOOLS_SUCCESS]: fetchMapPortalToolsSuccess,
      [FETCH_MAP_PORTAL_TOOLS_FAILED]: fetchMapPortalToolsFailed,
      [FETCH_MAP_PORTAL_TOOLS_STARTED]: fetchMapPortalToolsStarted,
      [SET_DEFAULT_MODE]: setDefaultMode,
      [UPDATE_MAP_PORTAL_TOOL_SUCCESS]: updateMapPortalToolSuccess,
      [UPDATE_MAP_PORTAL_TOOL_FAILED]: updateMapPortalToolFailed,
      [UPDATE_MAP_PORTAL_TOOL_STARTED]: updateMapPortalToolStarted,
      [FETCH_DISTRICT_LOCALITIES_STARTED]: fetchDistrictLocalitiesStarted,
      [FETCH_DISTRICT_LOCALITIES_SUCCESS]: fetchDistrictLocalitiesSuccess,
      [FETCH_DISTRICT_LOCALITIES_FAILED]: fetchDistrictLocalitiesFailed,
      [FETCH_LOCALITY_STREETS_STARTED]: fetchLocalityStreetsStarted,
      [FETCH_LOCALITY_STREETS_SUCCESS]: fetchLocalityStreetsSuccess,
      [FETCH_LOCALITY_STREETS_FAILED]: fetchLocalityStreetsFailed,
      [GET_WMS_LEGEND_SUCCESS]: getWmsLegendSuccess,
      [GET_WMS_LEGEND_BEGIN]: getWmsLegendBegin,
      [GET_WMS_LEGEND_FAILED]: getWmsLegendFailed,
      [CLEAR_FETCHING_WMS_LEGEND]: clearFetchingWmsLegend,
      [CLEAR_CURRENT_COMPOSITION_ID]: clearCurrentCompositionId,
      [SHOW_SERVICE_INFO_MODAL]: showServiceInfoModal,
      [HIDE_SERVICE_INFO_MODAL]: hideServiceInfoModal,
      [FETCH_HOME_SITE_IFRAME_SUCCESS]: fetchHomeSiteIframeSuccess
    }[action.type] || (s => s)
  )(state, action);
}
