import { Map } from 'immutable';

import {
  SET_START_POINT_COORDINATES,
  SET_END_POINT_COORDINATES,
  ADD_NEW_INTERMEDIATE_POINT,
  CHANGE_INTERMEDIATE_POINT_COORDINATES,
  REMOVE_INTERMEDIATE_POINT,
  CLEAR_ROUTE_PLANNING_STATE
} from '../constants/routePlanningActionTypes';

export const initialState = Map({
  startPointCoordinates: null,
  endPointCoordinates: null,
  intermediatePointsCoordinates: null
});

const getPointCoordinates = pointCoordinates =>
  pointCoordinates
    ? {
        lat: pointCoordinates?.coords?.latitude ?? pointCoordinates.lat,
        lng: pointCoordinates?.coords?.longitude ?? pointCoordinates.lng
      }
    : '';

const setStartPointCoordinates = (state, { pointCoordinates }) => {
  const newPointCoordinates = getPointCoordinates(pointCoordinates);
  return state.set('startPointCoordinates', newPointCoordinates);
};

const setEndPointCoordinates = (state, { pointCoordinates }) => {
  const newPointCoordinates = getPointCoordinates(pointCoordinates);
  return state.set('endPointCoordinates', newPointCoordinates);
};

const addNewIntermediatePoint = (state, { pointCoordinates }) => {
  const intermediatePointCoordinates = state.getIn([
    'intermediatePointsCoordinates'
  ]);

  const newCoordinates = intermediatePointCoordinates
    ? [...intermediatePointCoordinates, '']
    : [''];

  return state.set('intermediatePointsCoordinates', newCoordinates);
};

const changeIntermediatePointCoordinates = (
  state,
  { prevCoordinates, newCoordinates }
) => {
  const prevPointCoordinates = getPointCoordinates(prevCoordinates);
  const newPointCoordinates = getPointCoordinates(newCoordinates);
  const intermediatePointCoordinates = state.getIn([
    'intermediatePointsCoordinates'
  ]);

  const newIntermediatePointCoordinates = intermediatePointCoordinates.map(
    coordinates =>
      JSON.stringify(coordinates) === JSON.stringify(prevPointCoordinates)
        ? newPointCoordinates
        : coordinates
  );

  return state.set(
    'intermediatePointsCoordinates',
    newIntermediatePointCoordinates
  );
};

const removeNewIntermediatePoint = (state, { coordinates }) => {
  const pointCoordinates = getPointCoordinates(coordinates);
  const intermediatePointCoordinates = state.getIn([
    'intermediatePointsCoordinates'
  ]);

  const newIntermediatePointCoordinates = intermediatePointCoordinates.filter(
    coordinates =>
      JSON.stringify(coordinates) !== JSON.stringify(pointCoordinates)
  );

  return state.set(
    'intermediatePointsCoordinates',
    newIntermediatePointCoordinates
  );
};

const clearRoutePlanningCoordinates = state =>
  state
    .set('startPointCoordinates', null)
    .set('endPointCoordinates', null)
    .set('intermediatePointsCoordinates', null);

export default function reducer(state = initialState, action) {
  return (
    {
      [SET_START_POINT_COORDINATES]: setStartPointCoordinates,
      [SET_END_POINT_COORDINATES]: setEndPointCoordinates,
      [ADD_NEW_INTERMEDIATE_POINT]: addNewIntermediatePoint,
      [CHANGE_INTERMEDIATE_POINT_COORDINATES]: changeIntermediatePointCoordinates,
      [REMOVE_INTERMEDIATE_POINT]: removeNewIntermediatePoint,
      [CLEAR_ROUTE_PLANNING_STATE]: clearRoutePlanningCoordinates
    }[action.type] || (s => s)
  )(state, action);
}
