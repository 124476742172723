import axios from 'axios';

const useProxy = () => {
  return (url, params, headers) => {
    const filtersString = new URLSearchParams(params).toString();
    return axios.post('proxy/', { url: url + '?' + filtersString }, headers);
  };
};

export default useProxy;
