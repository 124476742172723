const proxyPolyfill = require('proxy-polyfill/src/proxy')();

module.exports = function strictObjectProxy(obj, objName) {
  const isIE = /*@cc_on!@*/ false || !!document.documentMode;
  const getFn = (target, key) => {
    if (key === '__esModule' || key === '$$typeof') return;
    if (!(key in target)) {
      throw new Error(
        `Property ${key} is not defined on the ${objName} object!`
      );
    }
    return target[key];
  };

  if (isIE)
    return new proxyPolyfill(obj, {
      get: getFn,
      set: () => false
    });

  return new Proxy(obj, {
    get: getFn,
    set: () => false,
    deleteProperty: () => false
  });
};
