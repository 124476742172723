export const FOOTER_GLOBAL_LOCAL_SETTINGS_VALUES = {
  privacy_policy_text: 'privacy_policy_text',
  privacy_policy_text_en: 'privacy_policy_text_en',
  availability_statement: 'availability_statement',
  availability_statement_en: 'availability_statement_en',
  terms_and_conditions: 'terms_and_conditions',
  terms_and_conditions_en: 'terms_and_conditions_en',
  show_privacy_policy: 'show_privacy_policy',
  show_availability_statement: 'show_availability_statement',
  show_terms_and_conditions: 'show_terms_and_conditions',
  show_rpo_logo: 'show_rpo_logo',
  rpo_logo: 'rpo_logo',
  home_page_footer_position: 'home_page_footer_position',
  global_footer_settings_enabled: 'global_footer_settings_enabled'
};
