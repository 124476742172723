import { union } from 'polygon-clipping';
import * as turfHelpers from '@turf/helpers';
import booleanPointOnLine from '@turf/boolean-point-on-line';

// Function to check if two Turf.js points are equal
const arePointsEqual = (point1, point2) =>
  point1[0] === point2[0] && point1[1] === point2[1];

// Function to find common points in Turf.js polygons
function findCommonPoints(polygon1, polygon2) {
  const commonPoints = [];

  for (const point1 of polygon1[0]) {
    for (const point2 of polygon2[0]) {
      const isPointAdded = !commonPoints.some(existingPoint =>
        arePointsEqual(existingPoint, point2)
      );

      if (arePointsEqual(point1, point2) && isPointAdded) {
        commonPoints.push(point2);
      }
    }
  }

  return commonPoints;
}

// Function to find index of point in array of points
const indexToRemove = (array, point) =>
  array.findIndex(arrayPoint => {
    const [x, y] = arrayPoint;
    const [x1, y1] = point;

    return x === x1 && y === y1;
  });

// Function to remove points which are on collinear line
function removePointsBelongingToCollinearLines(polygon, points) {
  const coordinates = polygon.geometry.coordinates[0];
  const coords = [...coordinates, coordinates[1]];
  const epsilon = 0.00000001; // turf tolerence if point isn't exactly on the line

  for (let i = 0; i < coords.length - 2; i++) {
    const pointOnLine = turfHelpers.point(coords[i + 1]);
    const line = turfHelpers.lineString([coords[i], coords[i + 2]]);
    const isPointOnLine = booleanPointOnLine(pointOnLine, line, {
      epsilon
    });
    const pointToCheck = coords[i + 1];
    const isPointToCheck = points.some(point =>
      arePointsEqual(point, pointToCheck)
    );

    if (isPointOnLine && isPointToCheck) {
      const index = indexToRemove(coordinates, coords[i + 1]);
      coordinates.splice(index, 1);
    }
  }

  return [coordinates];
}

// Function to return merged object without merge points on edges
const getMergedGeometry = (polygon1, polygon2, mergedPolygons) => {
  const commonPoints = findCommonPoints(polygon1, polygon2);

  return removePointsBelongingToCollinearLines(
    turfHelpers.polygon(...mergedPolygons),
    commonPoints
  );
};

const mergePolygons = (polygon1, polygon2) => {
  const mergedPolygons = union(polygon1, polygon2);

  const mergedGeometryWithoutPoints = getMergedGeometry(
    polygon1,
    polygon2,
    mergedPolygons
  );

  if (mergedPolygons.length === 1) return mergedGeometryWithoutPoints;

  return null;
};

export default mergePolygons;
