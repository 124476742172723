/**
 * Sprawdza czy w przekazanej grupie znajduje się przynajmniej jedna
 * DOMYŚLNIE zaznaczona warstwa.
 */

const hasGroupCheckedLayers = group => {
  const iterate = entry => {
    if (entry.type === 'group') return entry.entries.find(iterate);
    if (entry.type === 'layer' && entry.is_enabled) return true;
  };

  return !!group.entries.find(iterate);
};

export default hasGroupCheckedLayers;
