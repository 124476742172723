import * as L from 'leaflet';
import { CustomLogger } from '../../CustomLogger';
import { deleteLayer } from '../';

/**
 * Dodaje marker punktu do przekazanej mapy.
 *
 * @param {L.Map} map - obiekt mapy Leaflet
 * @param {Object} config - obiekt konfiguracyjny
 * @param {?string} config.id - ID markera
 * @param {Object} config.data - obiekt z danymi markera
 * @param {?latLng} config.latLng - gdy obiekt data nie zawiera współrzędnych
 *                                  można przekazać je parametrem config.latLng
 * @param {?fit} config.fit - przybliż widok mapy do wyrenderowanego markera
 *
 *
 * @return {L.Marker}
 */
const renderMarker = (map, config) => {
  if (!map) {
    return;
  }

  const {
    id,
    data,
    fit,
    latLng,
    icon,
    addressZoomLevel,
    maxZoom,
    addToMap = true
  } = config;
  const options = {};
  let coordinates;

  if (data && latLng) {
    CustomLogger.warn(
      'You should not pass to renderMarker function GeoJSON and LatLng together! Now LatLng is used.'
    );
  }

  if (data) {
    const { coordinates: dataCoordinates } = data.geometry;
    coordinates = dataCoordinates;
  }

  if (latLng) {
    coordinates = latLng;
  }

  if (map._layers[id]) {
    deleteLayer(map, id);
  }

  if (icon) {
    options.icon = icon;
  }

  const marker = L.marker(coordinates, options);

  if (id) {
    marker._leaflet_id = id;
  }

  if (fit) {
    const latLng = [marker.getLatLng()];
    const bounds = L.latLngBounds(latLng);

    map.fitBounds(bounds, {
      maxZoom
    });
  }

  if (addressZoomLevel) {
    const mapZoomLevel = map.getZoom();
    const zoomLevel =
      mapZoomLevel >= addressZoomLevel ? mapZoomLevel : addressZoomLevel;
    map.setView(coordinates, zoomLevel);
  }

  if (addToMap) marker.addTo(map);

  return marker;
};

export default renderMarker;
