import axios from 'axios';
import queryString from 'query-string';

import { isObject, getApiUrl } from '../lib';
import { showError } from '../../store/actions/globalActions';

import { localStorageKeys } from '../../config';

const { jwt, refresh } = localStorageKeys;

export const hasJwt = () => !!window.localStorage.getItem(jwt);

export const setAuthorization = () => {
  if (hasJwt()) {
    const token = window.localStorage.getItem(jwt);
    axios.defaults.headers.common.Authorization = `JWT ${token}`;
    return;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
  return;
};

export const parseError = error => {
  const errorMessage = Object.keys(error).reduce((prev, cur) => {
    const field = error[cur];
    if (typeof field === 'string') {
      return field;
    }

    if (Array.isArray(field)) {
      const fieldMsg = field.reduce((prevMsg, curMsg) => {
        if (isObject(curMsg)) {
          return `${prevMsg} ${curMsg.message.message}`;
        }

        return `${prevMsg} ${curMsg}`;
      }, '');
      return `${prev} ${fieldMsg}`;
    }

    if (isObject(field)) {
      return `${prev} ${field.message}`;
    }

    return field;
  }, '');
  return errorMessage;
};

const refreshToken = () => {
  const axiosInstance = axios.create();
  axiosInstance.defaults.baseURL = `${getApiUrl()}/api/v1/`;

  return axiosInstance.post('auth/jwt/refresh/', {
    refresh: localStorage.getItem(refresh)
  });
};

const setToken = token => {
  window.localStorage.setItem(jwt, token);
  axios.defaults.headers.common.Authorization = `JWT ${token}`;
};

export function configureInterceptors(store) {
  const handleError = async error => {
    if (error.response && error.response.data) {
      if (error.response.status === 429) {
        store.dispatch(showError(error.response.data.message));
      }

      // Obsługa przeterminowanego tokena.
      if (error.response.status === 401) {
        try {
          // Odświeżamy token.
          const {
            data: { access: token }
          } = await refreshToken();
          setToken(token);

          const request = error.config;

          // Jeśli nie został jeszcze ponownie wysłany request z nowym
          // tokenem - wysyłamy. Jeśli request został wysłany, ale po raz
          // kolejny został zwrócony błąd 401, zakładamy, że jest to jakiś
          // inny problem, niezwiązany z ważnością tokena.
          if (!request._retry) {
            request._retry = true;

            try {
              request.headers.Authorization = `JWT ${token}`;
              const retry = await axios(request);
              return Promise.resolve(retry);
            } catch (err) {
              return Promise.reject(err);
            }
          } else {
            throw error;
          }
        } catch {
          const urlParams = queryString.stringify({
            redirect: window.location.pathname
          });

          localStorage.removeItem(jwt);
          localStorage.removeItem(refresh);
          setAuthorization();
          store.dispatch(showError(parseError(error.response.data)));

          window.location.href = `/logowanie?${urlParams}`;
        }
      }
    }

    return Promise.reject(error);
  };

  axios.interceptors.response.use(
    response => response,
    async error => {
      const response = await handleError(error);
      return response;
    }
  );
}

export default function configureRequests() {
  const baseURL = `${getApiUrl()}/api/v1/`;

  setAuthorization();

  axios.defaults.baseURL = baseURL;
  axios.defaults.headers.common['Accept-Language'] = 'pl';
}
