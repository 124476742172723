import axios from 'axios';
import queryString from 'query-string';
import { v4 as uuid } from 'uuid';

import { showError } from './globalActions';

import { parseResponseError } from '../../utils/lib';

import { RESULTS_PER_PAGE_OPTIONS } from '../../utils/constants';

import {
  ADD_COMPOSITION_REGISTER_BEGIN,
  ADD_COMPOSITION_REGISTER_FAILED,
  ADD_COMPOSITION_REGISTER_SUCCESS,
  CHANGE_OPTION_ID,
  CHANGE_REGISTER_COMPOSITION,
  CLOSE_REGISTER_DETAILS,
  CLOSE_REGISTER_TOOL,
  DELETE_COMPOSITION_REGISTER_BEGIN,
  DELETE_COMPOSITION_REGISTER_FAILED,
  DELETE_COMPOSITION_REGISTER_SUCCESS,
  EDIT_COMPOSITION_REGISTER_BEGIN,
  EDIT_COMPOSITION_REGISTER_FAILED,
  EDIT_COMPOSITION_REGISTER_SUCCESS,
  EDIT_REGISTER_ATTRIBUTTE_BEGIN,
  EDIT_REGISTER_ATTRIBUTTE_FAILED,
  EDIT_REGISTER_ATTRIBUTTE_SUCCESS,
  FETCH_COMPOSITION_REGISTERS_BEGIN,
  FETCH_COMPOSITION_REGISTERS_FAILED,
  FETCH_COMPOSITION_REGISTERS_SUCCESS,
  FETCH_REGISTER_ATTRIBUTTES_BEGIN,
  FETCH_REGISTER_ATTRIBUTTES_FAILED,
  FETCH_REGISTER_ATTRIBUTTES_SUCCESS,
  GET_FINN_LIST_BEGIN,
  GET_FINN_LIST_FAILED,
  GET_FINN_LIST_SUCCESS,
  GET_FINN_OBJECT_BEGIN,
  GET_FINN_OBJECT_FAILED,
  GET_FINN_OBJECT_SUCCESS,
  GET_FINN_SETTINGS_BEGIN,
  GET_FINN_SETTINGS_FAILED,
  GET_FINN_SETTINGS_SUCCESS,
  OPEN_REGISTER_DETAILS,
  OPEN_REGISTER_TOOL,
  REGISTER_TOOL_COMPONENT,
  RESET_REGISTER_DETAILS,
  SELECTED_REGISTER_FEATURES,
  SELECTED_REGISTER_LAYER,
  SET_FILTERS_VALUES,
  SET_PAGINATION_VALUES,
  SET_REGISTER_QUERY,
  SET_REGISTER_TOOL_IN_SEPARATE_WINDOW,
  SET_SEARCH_VALUE,
  SET_SHOW_FILTERS,
  FETCH_RELATED_FINN_OBJECT_BEGIN,
  FETCH_RELATED_FINN_OBJECT_SUCCESS,
  FETCH_RELATED_FINN_OBJECT_FAILED,
  OPEN_CHANGE_HISTORY_TABLE,
  CLOSE_CHANGE_HISTORY_TABLE,
  FETCH_CHANGE_HISTORY_BEGIN,
  FETCH_CHANGE_HISTORY_SUCCESS,
  FETCH_CHANGE_HISTORY_FAILED,
  RESTORE_REGISTER_INITIAL_STATE,
  CHANGE_REGISTER_LAYER,
  IS_REGISTER_EDITION_ENABLED,
  FORM_EDITION_ID,
  REGISTER_FETCH_PARAMS,
  SET_EDITION_OBJECT_MODE,
  SET_LAYER_REGISTER_CONFIGURATIONS
} from '../constants/registersActionTypes';

//RTQ
export const fetchCompositionRegistersBegin = () => ({
  type: FETCH_COMPOSITION_REGISTERS_BEGIN
});

export const fetchCompositionRegistersSuccess = ({
  registers,
  district,
  compositionId
}) => ({
  type: FETCH_COMPOSITION_REGISTERS_SUCCESS,
  registers,
  district,
  compositionId
});

export const fetchCompositionRegistersFailed = () => ({
  type: FETCH_COMPOSITION_REGISTERS_FAILED
});

export const addCompositionRegisterBegin = () => ({
  type: ADD_COMPOSITION_REGISTER_BEGIN
});

export const addCompositionRegisterSuccess = ({
  register,
  district,
  compositionId
}) => ({
  type: ADD_COMPOSITION_REGISTER_SUCCESS,
  register,
  district,
  compositionId
});

export const addCompositionRegisterFailed = () => ({
  type: ADD_COMPOSITION_REGISTER_FAILED
});

export const addCompositionRegister = ({
  compositionId,
  district,
  contentType,
  registerName
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addCompositionRegisterBegin());

    const body = { displayable_name: registerName, content_type: contentType };

    return axios
      .post(`${district}/registers/${compositionId}/config/`, body)
      .then(({ data }) => {
        dispatch(
          addCompositionRegisterSuccess({
            register: data,
            district,
            compositionId
          })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(addCompositionRegisterFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const editCompositionRegisterBegin = () => ({
  type: EDIT_COMPOSITION_REGISTER_BEGIN
});

export const editCompositionRegisterSuccess = ({
  register,
  district,
  compositionId
}) => ({
  type: EDIT_COMPOSITION_REGISTER_SUCCESS,
  register,
  district,
  compositionId
});

export const editCompositionRegisterFailed = () => ({
  type: EDIT_COMPOSITION_REGISTER_FAILED
});

export const editCompositionRegister = ({
  compositionId,
  district,
  id,
  contentType,
  registerName,
  isEnabled,
  historyEnabled,
  isEditingEnabled,
  isDownloadingEnabled,
  isGeometryFilterEnabled,
  query
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editCompositionRegisterBegin());

    const body = {
      displayable_name: registerName,
      content_type: contentType,
      is_enabled: isEnabled,
      history_enabled: historyEnabled,
      map_portal_composition: compositionId,
      editing_enabled: isEditingEnabled,
      downloading_enabled: isDownloadingEnabled,
      filter_by_has_geom: isGeometryFilterEnabled,
      query
    };

    return axios
      .patch(`${district}/registers/${compositionId}/config/${id}/`, body)
      .then(({ data }) => {
        dispatch(
          editCompositionRegisterSuccess({
            register: data,
            district,
            compositionId
          })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(editCompositionRegisterFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteCompositionRegisterBegin = () => ({
  type: DELETE_COMPOSITION_REGISTER_BEGIN
});

export const deleteCompositionRegisterSuccess = ({
  registerId,
  district,
  compositionId
}) => ({
  type: DELETE_COMPOSITION_REGISTER_SUCCESS,
  registerId,
  district,
  compositionId
});

export const deleteCompositionRegisterFailed = () => ({
  type: DELETE_COMPOSITION_REGISTER_FAILED
});

export const deleteCompositionRegister = ({
  compositionId,
  district,
  id
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteCompositionRegisterBegin());

    return axios
      .delete(`${district}/registers/${compositionId}/config/${id}`)
      .then(response => {
        dispatch(
          deleteCompositionRegisterSuccess({
            registerId: id,
            district,
            compositionId
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(deleteCompositionRegisterFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const openRegisterTool = () => ({
  type: OPEN_REGISTER_TOOL
});

export const registerToolComponent = componentName => ({
  type: REGISTER_TOOL_COMPONENT,
  componentName
});

export const closeRegisterTool = () => ({
  type: CLOSE_REGISTER_TOOL
});

export const setRegisterInSeparateWindow = isInSeparateWindow => ({
  type: SET_REGISTER_TOOL_IN_SEPARATE_WINDOW,
  isInSeparateWindow
});

export const fetchRegisterAttributesBegin = () => ({
  type: FETCH_REGISTER_ATTRIBUTTES_BEGIN
});

export const fetchRegisterAttributesSuccess = ({
  attrs,
  registerId,
  district,
  compositionId
}) => ({
  type: FETCH_REGISTER_ATTRIBUTTES_SUCCESS,
  attrs,
  registerId,
  district,
  compositionId
});

export const fetchRegisterAttributesFailed = () => ({
  type: FETCH_REGISTER_ATTRIBUTTES_FAILED
});

export const fetchRegisterAttributes = ({
  compositionId,
  district,
  registerId
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchRegisterAttributesBegin());

    return axios
      .get(
        `${district}/registers/${compositionId}/config/${registerId}/attributes/`
      )
      .then(({ data }) => {
        dispatch(
          fetchRegisterAttributesSuccess({
            attrs: data,
            registerId,
            district,
            compositionId
          })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchRegisterAttributesFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const editRegisterAttributeBegin = () => ({
  type: EDIT_REGISTER_ATTRIBUTTE_BEGIN
});

export const editRegisterAttributeSuccess = ({
  attrs,
  registerId,
  district,
  compositionId
}) => ({
  type: EDIT_REGISTER_ATTRIBUTTE_SUCCESS,
  attrs,
  registerId,
  district,
  compositionId
});

export const editRegisterAttributeFailed = () => ({
  type: EDIT_REGISTER_ATTRIBUTTE_FAILED
});

export const editRegisterAttribute = ({
  compositionId,
  district,
  registerId,
  attributes
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editRegisterAttributeBegin());

    return axios
      .patch(
        `${district}/registers/${compositionId}/config/${registerId}/attributes/`,
        attributes
      )
      .then(({ data }) => {
        dispatch(
          editRegisterAttributeSuccess({
            attrs: data,
            registerId,
            district,
            compositionId
          })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(editRegisterAttributeFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const selectedRegisterLayer = layerId => ({
  type: SELECTED_REGISTER_LAYER,
  layerId
});

export const selectedRegisterFeatures = features => ({
  type: SELECTED_REGISTER_FEATURES,
  features
});

export const openRegisterDetails = ({ modelName, registerId }) => ({
  type: OPEN_REGISTER_DETAILS,
  modelName,
  registerId
});

export const closeRegisterDetails = () => ({
  type: CLOSE_REGISTER_DETAILS
});

export const getFinnObjectBegin = () => ({
  type: GET_FINN_OBJECT_BEGIN
});

export const getFinnObjectSuccess = ({ data }) => ({
  type: GET_FINN_OBJECT_SUCCESS,
  data
});

export const getFinnObjectFailed = () => ({
  type: GET_FINN_OBJECT_FAILED
});

export const getFinnObject = ({
  serviceName,
  attributes,
  errorMessage
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getFinnObjectBegin());

    return axios
      .post(`finn/${serviceName}/`, attributes)
      .then(({ data }) => {
        dispatch(getFinnObjectSuccess({ data }));
        resolve(data);
      })
      .catch(() => {
        dispatch(showError(errorMessage));
        dispatch(getFinnObjectFailed());
      });
  });

export const getFinnSettingsBegin = () => ({
  type: GET_FINN_SETTINGS_BEGIN
});

export const getFinnSettingsSuccess = ({ registerId, data }) => ({
  type: GET_FINN_SETTINGS_SUCCESS,
  registerId,
  data
});

export const getFinnSettingsFailed = () => ({
  type: GET_FINN_SETTINGS_FAILED
});

export const getFinnSettings = ({ serviceName, registerId }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getFinnSettingsBegin());

    return axios
      .get(`finn/${serviceName}/`, { params: { register_id: registerId } })
      .then(({ data }) => {
        dispatch(getFinnSettingsSuccess({ registerId, data }));
        resolve(data);
      })
      .catch(err => {
        dispatch(getFinnSettingsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const getFinnListBegin = () => ({
  type: GET_FINN_LIST_BEGIN
});

export const getFinnListSuccess = ({ registerId, data }) => ({
  type: GET_FINN_LIST_SUCCESS,
  registerId,
  data
});

export const getFinnListFailed = () => ({
  type: GET_FINN_LIST_FAILED
});

export const getFinnList = ({ serviceName, registerId }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getFinnListBegin());

    return axios
      .post(`finn/${serviceName}/`, {
        tylkoUdostepnione: true
      })
      .then(({ data }) => {
        dispatch(getFinnListSuccess({ registerId, data }));
        resolve(data);
      })
      .catch(err => {
        dispatch(getFinnListFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const setOptionId = ({ optionId }) => ({
  type: CHANGE_OPTION_ID,
  optionId
});

export const setRegisterFetchParams = registerParams => ({
  type: REGISTER_FETCH_PARAMS,
  registerParams
});

export const setSearchValue = ({ searchValue, page }) => ({
  type: SET_SEARCH_VALUE,
  searchValue,
  page
});

export const setShowFilters = ({ showFilters }) => ({
  type: SET_SHOW_FILTERS,
  showFilters
});

export const setRegisterQuery = ({ registerQuery }) => ({
  type: SET_REGISTER_QUERY,
  registerQuery
});

export const closeRegisterTable = () => ({
  type: RESET_REGISTER_DETAILS
});

export const setFiltersValues = ({ filtersValues }) => ({
  type: SET_FILTERS_VALUES,
  filtersValues
});

export const setPaginationValues = values => ({
  type: SET_PAGINATION_VALUES,
  values
});

export const changeRegisterComposition = () => ({
  type: CHANGE_REGISTER_COMPOSITION
});
export const changeRegisterLayer = () => ({
  type: CHANGE_REGISTER_LAYER
});

export const fetchRelatedFinnModelBegin = () => ({
  type: FETCH_RELATED_FINN_OBJECT_BEGIN
});

export const fetchRelatedFinnModelSuccess = ({ objects }) => ({
  type: FETCH_RELATED_FINN_OBJECT_SUCCESS,
  objects
});

export const fetchRelatedFinnModelFailed = () => ({
  type: FETCH_RELATED_FINN_OBJECT_FAILED
});

export const fetchRelatedFinnModel = ({
  compositionId,
  district
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchRelatedFinnModelBegin());

    return axios
      .get(`${district}/registers/${compositionId}/config/?filter_finn=true`)
      .then(({ data }) => {
        dispatch(
          fetchRelatedFinnModelSuccess({
            objects: data,
            district,
            compositionId
          })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchRelatedFinnModelFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const openChangeHistoryTable = ({ recordId }) => ({
  type: OPEN_CHANGE_HISTORY_TABLE,
  payload: { recordId }
});

export const closeChangeHistoryTable = () => ({
  type: CLOSE_CHANGE_HISTORY_TABLE
});

const fetchChangeHistoryBegin = () => ({
  type: FETCH_CHANGE_HISTORY_BEGIN
});

const fetchChangeHistorySuccess = ({ count, page, records }) => ({
  type: FETCH_CHANGE_HISTORY_SUCCESS,
  payload: { count, page, records }
});

const fetchChangeHistoryFailed = () => ({
  type: FETCH_CHANGE_HISTORY_FAILED
});

export const fetchChangeHistory = ({
  district,
  contentTypeId,
  objectId,
  page
}) => (dispatch, getState) => {
  const fetchedPages = getState()
    .registers.get('changeHistory')
    .toJS();

  if (page in fetchedPages) {
    return;
  }

  dispatch(fetchChangeHistoryBegin());

  const params = {
    page,
    page_size: RESULTS_PER_PAGE_OPTIONS[1]
  };

  axios
    .get(`${district}/history/${contentTypeId}/${objectId}/`, {
      params,
      paramsSerializer: params => queryString.stringify(params)
    })
    .then(({ data: { count, results } }) => {
      const records = results.reduce((acc, { changed_fields, edit_date }) => {
        const newRecords = Object.keys(changed_fields)?.map(key => ({
          id: uuid(),
          changedField: key,
          prevValue: changed_fields?.[key]?.before,
          newValue: changed_fields?.[key]?.after,
          dateOfChange: edit_date
        }));

        return [...acc, ...newRecords];
      }, []);

      dispatch(
        fetchChangeHistorySuccess({
          count,
          page,
          records: records
        })
      );
    })
    .catch(err => {
      dispatch(fetchChangeHistoryFailed());
      dispatch(showError(parseResponseError(err)));
    });
};

export const restoreRegisterInitialState = () => ({
  type: RESTORE_REGISTER_INITIAL_STATE
});

export const setIsRegisterEditionEnabled = ({ isRegisterEditionEnabled }) => ({
  type: IS_REGISTER_EDITION_ENABLED,
  isRegisterEditionEnabled
});

export const setFormEditionId = ({ formEditionId }) => ({
  type: FORM_EDITION_ID,
  formEditionId
});

export const setEditionObjectMode = ({ mode }) => ({
  type: SET_EDITION_OBJECT_MODE,
  mode
});

export const setLayerRegisterConfigurations = registerConfigurations => ({
  type: SET_LAYER_REGISTER_CONFIGURATIONS,
  registerConfigurations
});
