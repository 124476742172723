export enum ESearchToolTags {
  COMPOSITION_TOPICAL_SEARCH_TOOLS = 'compositionTopicalSearchTools',
  TOPICAL_SEARCH_TOOL_RESULT_COLUMNS = 'topicalSearchToolResultColumns',
  TOPICAL_SEARCH_TOOL_OPTIONS = 'TOPICAL_SEARCH_TOOL_OPTIONS',
  TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS = 'TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS'
}

export type TFetchTopicalSearchToolsParams = {
  compositionId: number;
  district: string;
};

export type TFetchTopicalSearchToolResultColumnsParams = {
  toolId: number;
};

export type TFetchTopicalSearchOptionsParams = {
  url: string;
  params: { search: string };
};
