import { Map, List } from 'immutable';
import {
  FETCH_ESERVICES_LIST_SUCCESS,
  GET_ASW_USER_INFO_SUCCESS
} from '../constants/eServicesTypes';

export const initialState = Map({
  eServices: List([]),
  userInfo: Map({})
});

const fetchEservicesListSuccess = (state, { eServicesList }) =>
  state.set('eServices', List(eServicesList));

const getAswUserInfoSuccess = (state, { userInfo }) =>
  state.set('userInfo', Map(userInfo));

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_ESERVICES_LIST_SUCCESS]: fetchEservicesListSuccess,
      [GET_ASW_USER_INFO_SUCCESS]: getAswUserInfoSuccess
    }[action.type] || (s => s)
  )(state, action);
}
