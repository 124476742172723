import proj4 from 'proj4';

/**
 * Transformuje przekazane współrzędne z jednego
 * systemu CRS do drugiego.
 *
 * @param {string} inputCrs - nazwa wejściowego systemu CRS, np. EPSG:2180
 * @param {string} outputCrs - nazwa wyjściowego systemu CRS, np. EPSG:3857
 * @param {array} coordinates - współrzędne do transformacji
 *
 * @return {array} - przetransformowane współrzędne
 */

const transformCoordinates = (inputCrs, outputCrs, coordinates) => {
  try {
    proj4(outputCrs);
  } catch (err) {
    throw new Error(
      `Definicja PROJ.4 dla układu ${outputCrs} jest niezdefiniowana lub błędna!`
    );
  }

  return proj4(new proj4(inputCrs), new proj4(outputCrs), coordinates);
};

export default transformCoordinates;
