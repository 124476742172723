import { isEmpty } from 'lodash';

import customSnakeCase from '../customSnakeCase';

import { TBuildUrlOParams } from './types';

/**
 * A function for building query params for requests.
 */
const buildUrlParams = (url: string, params: TBuildUrlOParams = {}): string => {
  const searchParams = new URLSearchParams();

  if (isEmpty(params)) {
    return url;
  }

  Object.keys(params).forEach(key => {
    searchParams.set(customSnakeCase(key), params[key].toString());
  });

  return `${url}?${searchParams}`;
};

export default buildUrlParams;
