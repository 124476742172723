export const OPEN_WMS_INFORMATION_MODAL = 'OPEN_WMS_INFORMATION_MODAL';
export const CLOSE_WMS_INFORMATION_MODAL = 'CLOSE_WMS_INFORMATION_MODAL';
export const SET_COORDINATES_TO_WMS_IDENTIFICATION =
  'SET_COORDINATES_TO_WMS_IDENTIFICATION';
export const CLEAR_COORDINATES_TO_WMS_IDENTIFICATION =
  'CLEAR_COORDINATES_TO_WMS_IDENTIFICATION';

export const GET_WMS_FEATURE_INFO_BEGIN = 'GET_WMS_FEATURE_INFO_BEGIN';
export const GET_WMS_FEATURE_INFO_SUCCESS = 'GET_WMS_FEATURE_INFO_SUCCESS';
export const GET_WMS_FEATURE_INFO_FAILED = 'GET_WMS_FEATURE_INFO_FAILED';
