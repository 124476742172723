/**
 * Funkcja do określania, w których zoom lvls
 * zawiera się skala warstwy podanej w parametrze.
 *
 * @param {Object} scalesDenominators - Tablica z mianownikami skal
 * @param {Object} minMapZoomLevel - Minimalny zoom mapy
 * @param {Object} maxMapZoomLevel - Maksymalny zoom mapy
 * @param {Object} entry - Obiekt z informacjami na temat warstwy
 *
 * @return {Object} - zawierający minimalny i maksymalny poziom wyświetlania
 *
 */

const getZoomLevelFromScale = ({
  scalesDenominators,
  minMapZoomLevel,
  maxMapZoomLevel,
  entry
}) => {
  let minZoom = minMapZoomLevel;
  let maxZoom = maxMapZoomLevel;

  if (entry.maximum_scale || entry.minimum_scale) {
    const givenLayerMinScale = Number(entry.minimum_scale);
    const givenLayerMaxScale = Number(entry.maximum_scale);
    const scaleInterval = scalesDenominators
      .keySeq()
      .toArray()
      .filter(level => {
        const zoomScale = scalesDenominators.get(level);

        return (
          zoomScale <= givenLayerMinScale && zoomScale >= givenLayerMaxScale
        );
      });

    minZoom = Math.min(...scaleInterval);
    maxZoom = Math.max(...scaleInterval);
  }

  // Odwracanie wyników gdy użytkownik podał min i max w złej kolejności.
  return {
    minZoom: minZoom > maxZoom ? maxZoom : minZoom,
    maxZoom: minZoom > maxZoom ? minZoom : maxZoom
  };
};

export default getZoomLevelFromScale;
