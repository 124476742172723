import { useIntl } from 'react-intl';

import { TTranslations, TUseTranslationProps } from './types';

// Example of argument which is passed to useTranslations hook
//   {
//     key: {
//       id: string,
//       defaultMessage: string
//     },
//   }

const useTranslations = (translations: TTranslations): TUseTranslationProps => {
  const { formatMessage } = useIntl();

  const translationKeys = {} as TUseTranslationProps;

  Object.keys(translations).forEach(key => {
    translationKeys[key] = formatMessage(
      {
        id: translations[key].id,
        defaultMessage: translations[key].defaultMessage
      },
      translations[key].values
    );
  });

  return translationKeys;
};

export default useTranslations;
