import { useState, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useMouseMove from '../../../hooks/useMouseMove';

import { hideLogoutCountdownTimer } from '../../../store/actions/globalActions';
import { globalSelectors } from '../../../store/selectors';

const { getUserSettings, isLogoutCountdownTimerOpen } = globalSelectors;

const useLogoutCountdownTimer = () => {
  const [time, setTime] = useState(300);

  const dispatch = useDispatch();
  const timer = useRef(null);

  const userSettings = useSelector(state => getUserSettings(state));
  const isLogoutCountdownTimesOpen = useSelector(state =>
    isLogoutCountdownTimerOpen(state)
  );

  const mouseMoveHandler = useCallback(() => {
    if (isLogoutCountdownTimesOpen) dispatch(hideLogoutCountdownTimer());
  }, [isLogoutCountdownTimesOpen]);

  useMouseMove({ handler: mouseMoveHandler });

  const clearTimer = useCallback(() => {
    clearInterval(timer.current);
    setTime(0);
  }, []);

  const setTimer = useCallback(() => {
    if (userSettings?.IDLE_TIMEOUT_MINUTES && isLogoutCountdownTimesOpen) {
      const minutesToLogout = parseInt(userSettings.IDLE_TIMEOUT_MINUTES);
      const idleMessageMinutes = parseInt(userSettings.IDLE_MESSAGE_MINUTES);
      const timeToLogout = (minutesToLogout - idleMessageMinutes) * 60;
      setTime(timeToLogout);
    }
  }, [userSettings, isLogoutCountdownTimesOpen, clearTimer]);

  useEffect(() => {
    setTimer();
  }, [setTimer]);

  const countdownToLogout = useCallback(() => {
    timer.current = setInterval(() => {
      if (time > 0) {
        setTime(prevState => prevState - 1);
      }
    }, 1000);
  }, []);

  useEffect(() => {
    countdownToLogout();

    return () => clearTimer();
  }, [countdownToLogout]);

  const convertToString = time => time.toString().padStart(2, 0);

  const minutes = Math.floor(time / 60);
  const seconds = time % 60;

  return {
    minutes: convertToString(minutes),
    seconds: convertToString(seconds),
    isLogoutCountdownTimesOpen
  };
};

export default useLogoutCountdownTimer;
