import { Map, List } from 'immutable';

import {
  FETCH_DISTRICTS_BEGIN,
  FETCH_DISTRICTS_SUCCESS,
  FETCH_DISTRICTS_FAILED,
  FETCH_DISTRICT_DETAILS_BEGIN,
  FETCH_DISTRICT_DETAILS_SUCCESS,
  FETCH_PRECINTS_BEGIN,
  FETCH_PRECINTS_SUCCESS,
  FETCH_PARCELS_LIST_BEGIN,
  FETCH_PARCELS_LIST_SUCCESS,
  FETCH_APP_DISTRICT_BEGIN,
  FETCH_APP_DISTRICT_SUCCESS,
  SET_DISTRICT_BOUNDS,
  CLEAR_DISTRICTS_DATA_REDUCER_STATE,
  FETCH_DISTRICT_ROLES_BEGIN,
  FETCH_DISTRICT_ROLES_SUCCESS,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS,
  FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED,
  FETCH_DISTRICT_ROLES_FAILED,
  FETCH_DISTRICT_APPEARANCES_SUCCESS,
  ADD_DISTRICT_APPEARANCE_SUCCESS,
  EDIT_DISTRICT_APPEARANCE_SUCCESS,
  DELETE_DISTRICT_APPEARANCE_SUCCESS,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN,
  FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED,
  UPDATE_APP_DISTRICT_BEGIN,
  UPDATE_APP_DISTRICT_SUCCESS,
  UPDATE_APP_DISTRICT_FAILED,
  SAVE_APPLIED_APPEARANCE,
  CLEAR_APPLIED_APPEARANCE,
  FETCH_DISTRICTS_SETTINGS_SUCCESS,
  FETCH_DISTRICT_SETTINGS_SUCCESS,
  UPDATE_DISTRICT_SETTINGS_SUCCESS,
  GET_ALL_DISTRICT_TAGS_SUCCESS,
  UPDATE_DISTRICT_DETAILS_SUCCESS,
  CLEAR_DISTRICT_APPEARANCES,
  UPDATE_DISTRICT_BORDER_BEGIN,
  UPDATE_DISTRICT_BORDER_FAILED,
  UPDATE_DISTRICT_BORDER_SUCCESS,
  FETCH_PORTAL_3D_SETTINGS_SUCCESS,
  FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS,
  GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS,
  GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS,
  CHANGE_SEARCH_INPUT_VALUE,
  FETCH_GLOBAL_THEMES_SUCCESS,
  GET_SINGLE_GLOBAL_THEME_SUCCESS,
  SET_FETCHING_GLOBAL_THEMES,
  FETCH_GLOBAL_THEMES_BEGIN,
  FETCH_GLOBAL_THEMES_FAILED,
  EDIT_SINGLE_GLOBAL_THEME_STARTED,
  ADD_GLOBAL_THEME_STARTED,
  SET_GLOBAL_THEME_LOADING,
  GET_DISTRICT_EXTERNAL_SITES_SUCCESS,
  ADD_DISTRICT_EXTERNAL_SITE_SUCCESS,
  DELETE_DISTRICT_EXTERNAL_SITE_SUCCESS,
  UPDATE_DISTRICT_EXTERNAL_SITES_SUCCESS,
  UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_SUCCESS,
  SORT_ALL_DISTRICTS_BEGIN,
  SORT_ALL_DISTRICTS_FINISHED
} from '../constants/districtActionTypes';

const initialState = Map({
  districts: List([]),
  districts_data: Map({}),
  app_district: List([]),
  fetchingDistrictRoles: false,
  districtsRoles: Map({}),
  isFetchingDistrictsRolesWithSuperUser: Map({}),
  districtsRolesWithSuperUser: Map({}),
  areDistrictsFetched: false,
  areDistrictsBeingFetched: false,
  appearances: Map({}),
  fetchedAppearance: false,
  appearancesCount: Map({}),
  updateingAppDistrict: false,
  appliedAppearance: null,
  tags: Map({}),
  updateingDistrictBorder: false,
  globalDistrictSettings: Map({}),
  globalThemes: List([]),
  globalThemesCount: 1,
  singleGlobalTheme: null,
  isFetchingGlobalThemes: false,
  gobalThemeLoading: false,
  areAppDistricsFiltered: false,
  districtExternalSites: List([]),
  isSortingDistricts: false
});

const district_initial = Map({
  isFetching: false,
  isFetched: false,
  arePrecintsFetching: false,
  arePrecintsFetched: false,
  areAllParcelsListFetched: false,
  precints: List([]),
  parcels: List([]),
  projects: Map({}),
  data: null,
  fetchingParcelsInPrecint: List([]),
  fetchedParcelsInPrecint: List([]),
  areAppDistrictBeingFetched: false,
  areAppDistrictFetched: false,
  searchInputValue: ''
});

const districtsFetchBegin = state =>
  state.merge(
    Map({
      areDistrictsBeingFetched: true
    })
  );

const districtsFetchSuccess = (state, action) =>
  state.merge(
    Map({
      districts: List(action.districts),
      areDistrictsBeingFetched: false,
      areDistrictsFetched: true
    })
  );

const districtsFetchFailed = state =>
  state.merge(
    Map({
      districts: List([]),
      areDistrictsBeingFetched: false,
      areDistrictsFetched: true
    })
  );

const fetchDistrictDetailsBegin = (state, action) =>
  state.merge(
    Map({
      districts_data: state.get('districts_data').merge({
        [action.prefix]: state
          .get('districts_data')
          .get(action.prefix, district_initial)
          .merge({
            isFetching: true,
            isFetched: false
          })
      })
    })
  );

const fetchDistrictDetailsSuccess = (state, action) =>
  state.merge(
    Map({
      districts_data: state.get('districts_data').merge({
        [action.prefix]: state
          .get('districts_data')
          .get(action.prefix, district_initial)
          .merge({
            isFetching: false,
            isFetched: true,
            data: action.data
          })
      })
    })
  );

const fetchPrecintsBegin = (state, action) =>
  state.merge(
    Map({
      districts_data: state.get('districts_data').merge({
        [action.prefix]: state
          .get('districts_data')
          .get(action.prefix, district_initial)
          .merge({
            arePrecintsFetching: true,
            arePrecintsFetched: false
          })
      })
    })
  );

const fetchPrecintsSuccess = (state, action) =>
  state.merge(
    Map({
      districts_data: state.get('districts_data').merge({
        [action.prefix]: state
          .get('districts_data')
          .get(action.prefix, district_initial)
          .merge({
            arePrecintsFetching: false,
            arePrecintsFetched: true,
            precints: state
              .getIn(['districts_data', action.prefix, 'precints'])
              .unshift(...action.precints)
          })
      })
    })
  );

const fetchPrecintParcelsBegin = state => state;

const fetchPrecintParcelsSuccess = (state, action) => {
  // Add new precint
  const updated = state
    .getIn(['districts_data', action.prefix, 'parcels'])
    .unshift(...action.parcels);

  let fetchedParcelsInPrecint = null;
  // If precint is null then we know that action fetched all parcels and
  // we have to collect all precints
  if (action.precint) {
    fetchedParcelsInPrecint = state
      .getIn(['districts_data', action.prefix, 'fetchedParcelsInPrecint'])
      .unshift(action.precint);
  } else {
    fetchedParcelsInPrecint = action.parcels.reduce(
      (previous, current, index) => {
        if (!previous.includes(current.properties.obreb)) {
          previous.push(current.properties.obreb);
        }
        return previous;
      },
      []
    );
  }

  const newState = state
    .setIn(['districts_data', action.prefix, 'parcels'], updated)
    .setIn(
      ['districts_data', action.prefix, 'fetchedParcelsInPrecint'],
      fetchedParcelsInPrecint
    )
    .setIn(
      ['districts_data', action.prefix, 'areAllParcelsListFetched'],
      action.precint ? false : true
    );
  return newState;
};

const appDistrictFetchBegin = state =>
  state.merge(
    Map({
      areAppDistrictBeingFetched: true
    })
  );

const appDistrictFetchSuccess = (state, action) =>
  state.merge(
    Map({
      app_district: List(action.app_district),
      areAppDistrictBeingFetched: false,
      areAppDistrictFetched: true,
      areAppDistricsFiltered: !!action.searchQuery
    })
  );

const fetchingDistrictRolesBegin = state =>
  state.set('fetchingDistrictRoles', true);

const fetchingDistrictRolesEnd = state =>
  state.set('fetchingDistrictRoles', false);

const fetchDistrictRolesSuccess = (state, action) =>
  state.setIn(['districtsRoles', action.district], action.roles);

const fetchDistrictRolesWithSuperUserStarted = (state, action) =>
  state.set('isFetchingDistrictsRolesWithSuperUser', true);

const fetchDistrictRolesWithSuperUserSuccess = (state, { district, roles }) =>
  state
    .setIn(['districtsRolesWithSuperUser', district], roles)
    .set('isFetchingDistrictsRolesWithSuperUser', false);

const fetchDistrictRolesWithSuperUserFailed = (state, action) =>
  state.set('isFetchingDistrictsRolesWithSuperUser', false);

const setDistrictBounds = (state, action) =>
  state.mergeDeep(
    Map({
      districts_data: Map({
        [action.districtName]: Map({
          data: Map({
            properties: Map({
              bounds: Map({ ...action.bounds })
            })
          })
        })
      })
    })
  );

const clearDistrictsDataReducerState = state =>
  state.merge(
    Map({
      districts_data: Map({})
    })
  );

const clearDistrictAppearances = state =>
  state.merge(
    Map({
      appearances: Map({}),
      appearancesCount: Map({})
    })
  );

const setIsFetchingGlobalThemes = (state, { isFetching }) =>
  state.set('isFetchingGlobalThemes', isFetching);

const getGlobalThemesBegin = state => state.set('isFetchingGlobalThemes', true);

const getGlobalThemesSuccess = (state, { themes, count }) =>
  state
    .setIn(['globalThemes'], List(themes))
    .set('globalThemesCount', count)
    .set('isFetchingGlobalThemes', false);

const getGlobalThemesFailed = state =>
  state.set('isFetchingGlobalThemes', false);

const getSingleGlobalThemeSuccess = (state, { singleTheme }) =>
  state.set('singleGlobalTheme', singleTheme);

const editGlobalThemeStarted = state => state.set('globalThemeLoading', true);

const addSingleGlobalThemeStarted = state =>
  state.set('globalThemeLoading', true);

const setGlobalThemeLoading = (state, { isLoading }) =>
  state.set('globalThemeLoading', isLoading);

const fetchDistrictAppearancesSuccess = (state, { district, appearances }) => {
  const existingAppearances = state.getIn(['appearances', district], List());

  const updatedAppearances = existingAppearances.concat(appearances.results);

  return state
    .setIn(['appearances', district], updatedAppearances)
    .set('fetchedAppearance', true)
    .setIn(['appearancesCount', district], appearances.count);
};

const addDistrictAppearanceSuccess = (state, action) =>
  state.updateIn(['appearances', action.district], List(), list =>
    List([...list, action.appearance])
  );

const editDistrictAppearanceSuccess = (state, action) =>
  state.updateIn(['appearances', action.district], List(), list => {
    const newList = [...list];
    const editedItemIndex = newList.findIndex(
      theme => theme.id === action.appearance.id
    );

    newList[editedItemIndex] = action.appearance;

    return List(newList);
  });

const deleteDistrictAppearanceSuccess = (state, action) =>
  state.updateIn(['appearances', action.district], List(), list =>
    List(list.filter(theme => theme.id !== action.appearanceId))
  );

const fetchingPublicMapPortalAppearanceBegin = state =>
  state.set('fetchedAppearance', false);

const fetchingPublicMapPortalAppearanceEnd = state =>
  state.set('fetchedAppearance', true);

const fetchPublicMapPortalAppearanceSuccess = (state, action) => {
  let newState = state.updateIn(
    ['appearances', action.district],
    List(),
    list => {
      const isAppearanceExist = list.find(theme => theme.id === action.id);
      return isAppearanceExist
        ? List(list)
        : List([...list, action.appearance]);
    }
  );

  newState = fetchingPublicMapPortalAppearanceEnd(newState);

  return newState;
};

const updateAppDistrictBegin = state => state.set('updateingAppDistrict', true);

const updateAppDistrictSuccess = state =>
  state.set('updateingAppDistrict', false);

const updateAppDistrictFailed = state =>
  state.set('updateingAppDistrict', false);

const saveAppliedAppearance = (state, { appearance }) =>
  state.set('appliedAppearance', Map(appearance));

const clearAppliedAppearance = state => state.set('appliedAppearance', null);

const fetchDistrictsSettingsSuccess = (state, action) =>
  state.merge(
    Map({
      districtsSettings: action.settings
    })
  );

const fetchDistrictSettingsSuccess = (state, action) =>
  state.merge(
    Map({
      districtSettings: action.settings
      // do przełączania między logikami popupa: desktop: 'wms_get_feature_info'; default: 'django_models'
      // districtSettings: {
      //   ...action.settings,
      //   popup_data_source: 'wms_get_feature_info'
      // }
    })
  );

const getGlobalDistrictSettingsSuccess = (
  state,
  { globalSettings: { data } }
) => {
  const {
    banner_logo: bannerLogo,
    unit_column_count: unitColumnCount,
    unit_view: unitView,
    unit_display_list_enabled: unitDisplayListEnabled,
    unit_display_map_enabled: unitDisplayMapEnabled,
    unit_display_map_max_scale: unitDisplayMapMaxScale,
    unit_display_map_min_scale: unitDisplayMapMinScale
  } = data;

  return state.merge(
    Map({
      globalDistrictSettings: {
        bannerLogo,
        unitColumnCount,
        unitView,
        unitDisplayListEnabled,
        unitDisplayMapEnabled,
        unitDisplayMapMaxScale,
        unitDisplayMapMinScale
      }
    })
  );
};

const fetchPortal3dSettingsSuccess = (state, { district, settings }) =>
  state.setIn(['portal3dSettings', district], List(settings));

const fetchOtherTopicalDataSettingsSuccess = (state, { district, settings }) =>
  state.setIn(['otherTopicalDataSettings', district], List(settings));

const getQRCodesConfigSuccess = (state, { district, qrCodesConfig }) =>
  state.setIn(['qrCodesConfig', district], List(qrCodesConfig));

const updateDistrictSettingsSuccess = (state, { settings }) => {
  const editedIndex = state
    .get('districtsSettings')
    .findIndex(({ id }) => id === settings.id);

  const newDistrictsSettings = [...state.get('districtsSettings')];
  newDistrictsSettings[editedIndex] = settings;

  return state.set('districtsSettings', newDistrictsSettings);
};

const getAllDistrictTagsSuccess = (state, { district, tags }) =>
  state.setIn(['tags', district], List(tags));

const updateDistrictDetailsSuccess = (state, action) =>
  state.merge(
    Map({
      districts_data: state.get('districts_data').merge({
        [action.prefix]: state
          .get('districts_data')
          .get(action.prefix, district_initial)
          .merge({
            data: action.data
          })
      })
    })
  );

const updateDistrictBorderStart = state =>
  state.set('updateingDistrictBorder', true);

const updateDistrictBorderEnd = state =>
  state.set('updateingDistrictBorder', false);

const changeSearchInputValue = (state, { value }) =>
  state.set('searchInputValue', value);

const getDistrictExternalSitesSuccess = (state, { districtExter }) =>
  state.setIn(['districtExternalSites'], districtExter);

const addDistrictExternalSiteSuccess = (state, { singleExter }) =>
  state.updateIn(['districtExternalSites'], list => [...list, singleExter]);

const deleteDistrictExternalSiteSuccess = (state, { singleExternalSiteId }) =>
  state.updateIn(['districtExternalSites'], list =>
    list.filter(item => item.id !== singleExternalSiteId)
  );

const updateDistrictExternalSiteSuccess = (state, { updatedExter }) =>
  state.updateIn(['districtExternalSites'], list =>
    list.map(item => (item.id === updatedExter.id ? updatedExter : item))
  );

const updateDistrictExternalSitesOrderSuccess = (state, { updatedExter }) => {
  const currentSites = state.get('districtExternalSites');

  const updatedSitesWithOriginalIcons = updatedExter.map(site => {
    const currentSite = currentSites.find(({ id }) => id === site.id);

    if (currentSite) {
      return { ...site, icon: currentSite.icon };
    }

    return site;
  });

  return state.updateIn(
    ['districtExternalSites'],
    () => updatedSitesWithOriginalIcons
  );
};

const sortAllDistrictsBegin = state => state.set('isSortingDistricts', true);

const sortAllDistrictsFinished = state =>
  state.set('isSortingDistricts', false);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_DISTRICTS_BEGIN]: districtsFetchBegin,
      [FETCH_DISTRICTS_SUCCESS]: districtsFetchSuccess,
      [FETCH_DISTRICTS_FAILED]: districtsFetchFailed,
      [FETCH_DISTRICT_DETAILS_BEGIN]: fetchDistrictDetailsBegin,
      [FETCH_DISTRICT_DETAILS_SUCCESS]: fetchDistrictDetailsSuccess,
      [FETCH_PRECINTS_BEGIN]: fetchPrecintsBegin,
      [FETCH_PRECINTS_SUCCESS]: fetchPrecintsSuccess,
      [FETCH_PARCELS_LIST_BEGIN]: fetchPrecintParcelsBegin,
      [FETCH_PARCELS_LIST_SUCCESS]: fetchPrecintParcelsSuccess,
      [FETCH_APP_DISTRICT_BEGIN]: appDistrictFetchBegin,
      [FETCH_APP_DISTRICT_SUCCESS]: appDistrictFetchSuccess,
      [FETCH_DISTRICT_ROLES_BEGIN]: fetchingDistrictRolesBegin,
      [FETCH_DISTRICT_ROLES_SUCCESS]: fetchingDistrictRolesEnd,
      [FETCH_DISTRICT_ROLES_FAILED]: fetchingDistrictRolesEnd,
      [FETCH_DISTRICT_ROLES_SUCCESS]: fetchDistrictRolesSuccess,
      [FETCH_DISTRICT_ROLES_WITH_SUPERUSER_BEGIN]: fetchDistrictRolesWithSuperUserStarted,
      [FETCH_DISTRICT_ROLES_WITH_SUPERUSER_SUCCESS]: fetchDistrictRolesWithSuperUserSuccess,
      [FETCH_DISTRICT_ROLES_WITH_SUPERUSER_FAILED]: fetchDistrictRolesWithSuperUserFailed,
      [SET_DISTRICT_BOUNDS]: setDistrictBounds,
      [CLEAR_DISTRICTS_DATA_REDUCER_STATE]: clearDistrictsDataReducerState,
      [CLEAR_DISTRICT_APPEARANCES]: clearDistrictAppearances,
      [FETCH_DISTRICT_APPEARANCES_SUCCESS]: fetchDistrictAppearancesSuccess,
      [FETCH_GLOBAL_THEMES_SUCCESS]: getGlobalThemesSuccess,
      [FETCH_GLOBAL_THEMES_BEGIN]: getGlobalThemesBegin,
      [FETCH_GLOBAL_THEMES_FAILED]: getGlobalThemesFailed,
      [ADD_DISTRICT_APPEARANCE_SUCCESS]: addDistrictAppearanceSuccess,
      [EDIT_DISTRICT_APPEARANCE_SUCCESS]: editDistrictAppearanceSuccess,
      [DELETE_DISTRICT_APPEARANCE_SUCCESS]: deleteDistrictAppearanceSuccess,
      [FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_BEGIN]: fetchingPublicMapPortalAppearanceBegin,
      [FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_SUCCESS]: fetchPublicMapPortalAppearanceSuccess,
      [FETCH_PUBLIC_MAP_PORTAL_APPEARANCE_FAILED]: fetchingPublicMapPortalAppearanceEnd,
      [UPDATE_APP_DISTRICT_BEGIN]: updateAppDistrictBegin,
      [UPDATE_APP_DISTRICT_SUCCESS]: updateAppDistrictSuccess,
      [UPDATE_APP_DISTRICT_FAILED]: updateAppDistrictFailed,
      [SAVE_APPLIED_APPEARANCE]: saveAppliedAppearance,
      [CLEAR_APPLIED_APPEARANCE]: clearAppliedAppearance,
      [FETCH_DISTRICTS_SETTINGS_SUCCESS]: fetchDistrictsSettingsSuccess,
      [FETCH_DISTRICT_SETTINGS_SUCCESS]: fetchDistrictSettingsSuccess,
      [UPDATE_DISTRICT_SETTINGS_SUCCESS]: updateDistrictSettingsSuccess,
      [GET_ALL_DISTRICT_TAGS_SUCCESS]: getAllDistrictTagsSuccess,
      [UPDATE_DISTRICT_DETAILS_SUCCESS]: updateDistrictDetailsSuccess,
      [UPDATE_DISTRICT_BORDER_BEGIN]: updateDistrictBorderStart,
      [UPDATE_DISTRICT_BORDER_SUCCESS]: updateDistrictBorderEnd,
      [UPDATE_DISTRICT_BORDER_FAILED]: updateDistrictBorderEnd,
      [FETCH_PORTAL_3D_SETTINGS_SUCCESS]: fetchPortal3dSettingsSuccess,
      [FETCH_OTHER_TOPICAL_DATA_SETTINGS_SUCCESS]: fetchOtherTopicalDataSettingsSuccess,
      [GET_GLOBAL_DISTRICT_SETTINGS_SUCCESS]: getGlobalDistrictSettingsSuccess,
      [GET_DISTRICT_QRCODES_CONFIGURATION_SUCCESS]: getQRCodesConfigSuccess,
      [CHANGE_SEARCH_INPUT_VALUE]: changeSearchInputValue,
      [GET_SINGLE_GLOBAL_THEME_SUCCESS]: getSingleGlobalThemeSuccess,
      [SET_FETCHING_GLOBAL_THEMES]: setIsFetchingGlobalThemes,
      [EDIT_SINGLE_GLOBAL_THEME_STARTED]: editGlobalThemeStarted,
      [ADD_GLOBAL_THEME_STARTED]: addSingleGlobalThemeStarted,
      [SET_GLOBAL_THEME_LOADING]: setGlobalThemeLoading,
      [GET_DISTRICT_EXTERNAL_SITES_SUCCESS]: getDistrictExternalSitesSuccess,
      [ADD_DISTRICT_EXTERNAL_SITE_SUCCESS]: addDistrictExternalSiteSuccess,
      [DELETE_DISTRICT_EXTERNAL_SITE_SUCCESS]: deleteDistrictExternalSiteSuccess,
      [UPDATE_DISTRICT_EXTERNAL_SITES_SUCCESS]: updateDistrictExternalSiteSuccess,
      [UPDATE_DISTRICT_EXTERNAL_SITES_ORDER_SUCCESS]: updateDistrictExternalSitesOrderSuccess,
      [SORT_ALL_DISTRICTS_BEGIN]: sortAllDistrictsBegin,
      [SORT_ALL_DISTRICTS_FINISHED]: sortAllDistrictsFinished
    }[action.type] || (s => s)
  )(state, action);
}
