import { CustomLogger } from '../CustomLogger';

const handleError = async err => {
  CustomLogger.error(err);

  if (process.env.NODE_ENV === 'production') {
    const { captureException } = await import('@sentry/browser');
    captureException(err);
  }
};

export default handleError;
