import { isObject } from '../';
import { CustomLogger } from '../../CustomLogger';

/**
 * Sprawdza czy obiekt jest pusty
 *
 * @param {string} obj - obiekt do sprawdzenia
 * @return {bool}
 */
const isEmptyObject = obj => {
  if (!isObject(obj)) {
    CustomLogger.error(
      'Parameter passed to isEmptyObject function is not an object!'
    );
    return;
  }

  return !Object.keys(obj).length;
};

export default isEmptyObject;
