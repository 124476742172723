export const FETCH_TOPICAL_SEARCH_TOOLS_BEGIN =
  'FETCH_TOPICAL_SEARCH_TOOLS_BEGIN';
export const FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS =
  'FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS';
export const FETCH_TOPICAL_SEARCH_TOOLS_FAILED =
  'FETCH_TOPICAL_SEARCH_TOOLS_FAILED';

export const FETCH_TOPICAL_SEARCH_TOOL_BEGIN =
  'FETCH_TOPICAL_SEARCH_TOOL_BEGIN';
export const FETCH_TOPICAL_SEARCH_TOOL_SUCCESS =
  'FETCH_TOPICAL_SEARCH_TOOL_SUCCESS';
export const FETCH_TOPICAL_SEARCH_TOOL_FAILED =
  'FETCH_TOPICAL_SEARCH_TOOL_FAILED';

export const ADD_TOPICAL_SEARCH_TOOL_BEGIN = 'ADD_TOPICAL_SEARCH_TOOL_BEGIN';
export const ADD_TOPICAL_SEARCH_TOOL_SUCCESS =
  'ADD_TOPICAL_SEARCH_TOOL_SUCCESS';
export const ADD_TOPICAL_SEARCH_TOOL_FAILED = 'ADD_TOPICAL_SEARCH_TOOL_FAILED';

export const EDIT_TOPICAL_SEARCH_TOOL_BEGIN = 'EDIT_TOPICAL_SEARCH_TOOL_BEGIN';
export const EDIT_TOPICAL_SEARCH_TOOL_SUCCESS =
  'EDIT_TOPICAL_SEARCH_TOOL_SUCCESS';
export const EDIT_TOPICAL_SEARCH_TOOL_FAILED =
  'EDIT_TOPICAL_SEARCH_TOOL_FAILED';

export const EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL =
  'EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL';

export const ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_BEGIN =
  'ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_BEGIN';
export const ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS =
  'ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS';
export const ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_FAILED =
  'ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_FAILED';

export const UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_BEGIN =
  'UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_BEGIN';
export const UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS =
  'UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS';
export const UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_FAILED =
  'UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_FAILED';

export const FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_BEGIN =
  'FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_BEGIN';
export const FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS =
  'FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS';
export const FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_FAILED =
  'FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_FAILED';

export const GET_TOPICAL_SEARCH_TOOL_COLUMNS_BEGIN =
  'GET_TOPICAL_SEARCH_TOOL_COLUMNS_BEGIN';
export const GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS =
  'GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS';
export const GET_TOPICAL_SEARCH_TOOL_COLUMNS_FAILED =
  'GET_TOPICAL_SEARCH_TOOL_COLUMNS_FAILED';

export const GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_BEGIN =
  'GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_BEGIN';
export const GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS =
  'GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS';
export const GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_FAILED =
  'GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_FAILED';

export const ADD_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN =
  'ADD_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN';
export const ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS =
  'ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS';
export const ADD_TOPICAL_SEARCH_TOOL_COLUMN_FAILED =
  'ADD_TOPICAL_SEARCH_TOOL_COLUMN_FAILED';

export const EDIT_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN =
  'EDIT_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN';
export const EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS =
  'EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS';
export const EDIT_TOPICAL_SEARCH_TOOL_COLUMN_FAILED =
  'EDIT_TOPICAL_SEARCH_TOOL_COLUMN_FAILED';

export const DELETE_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN =
  'DELETE_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN';
export const DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS =
  'DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS';
export const DELETE_TOPICAL_SEARCH_TOOL_COLUMN_FAILED =
  'DELETE_TOPICAL_SEARCH_TOOL_COLUMN_FAILED';

export const ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN =
  'ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN';
export const ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS =
  'ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS';
export const ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED =
  'ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED';

export const EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN =
  'EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN';
export const EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS =
  'EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS';
export const EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED =
  'EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED';

export const DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN =
  'DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN';
export const DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS =
  'DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS';
export const DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED =
  'DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED';

export const GET_LAYER_LOOKUP_FIELDS_BEGIN = 'GET_LAYER_LOOKUP_FIELDS_BEGIN';
export const GET_LAYER_LOOKUP_FIELDS_SUCCESS =
  'GET_LAYER_LOOKUP_FIELDS_SUCCESS';
export const GET_LAYER_LOOKUP_FIELDS_FAILED = 'GET_LAYER_LOOKUP_FIELDS_FAILED';

export const GET_LAYER_FIELD_SEARCH_TYPES_BEGIN =
  'GET_LAYER_FIELD_SEARCH_TYPES_BEGIN';
export const GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS =
  'GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS';
export const GET_LAYER_FIELD_SEARCH_TYPES_FAILED =
  'GET_LAYER_FIELD_SEARCH_TYPES_FAILED';

export const GET_LAYER_RESULT_FIELDS_BEGIN = 'GET_LAYER_RESULT_FIELDS_BEGIN';
export const GET_LAYER_RESULT_FIELDS_SUCCESS =
  'GET_LAYER_RESULT_FIELDS_SUCCESS';
export const GET_LAYER_RESULT_FIELDS_FAILED = 'GET_LAYER_RESULT_FIELDS_FAILED';

export const DELETE_TOPICAL_SEARCH_TOOL_BEGIN =
  'DELETE_TOPICAL_SEARCH_TOOL_BEGIN';
export const DELETE_TOPICAL_SEARCH_TOOL_SUCCESS =
  'DELETE_TOPICAL_SEARCH_TOOL_SUCCESS';
export const DELETE_TOPICAL_SEARCH_TOOL_FAILED =
  'DELETE_TOPICAL_SEARCH_TOOL_FAILED';

export const GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_BEGIN =
  'GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_BEGIN';
export const GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_SUCCESS =
  'GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_SUCCESS';
export const GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_FAILED =
  'GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_FAILED';

export const CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_BEGIN =
  'CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_BEGIN';
export const CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS =
  'CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS';
export const CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_FAILED =
  'CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_FAILED';

export const TOPICAL_SEARCH_BEGIN = 'TOPICAL_SEARCH_BEGIN';
export const TOPICAL_SEARCH_SUCCESS = 'TOPICAL_SEARCH_SUCCESS';
export const TOPICAL_SEARCH_FAILED = 'TOPICAL_SEARCH_FAILED';

export const FETCH_TOPICAL_SEARCH_RESULTS = 'FETCH_TOPICAL_SEARCH_RESULTS';
export const STOP_FETCH_TOPICAL_SEARCH_RESULTS =
  'STOP_FETCH_TOPICAL_SEARCH_RESULTS';

export const CLEAR_TOPICAL_SEARCH_RESULTS = 'CLEAR_TOPICAL_SEARCH_RESULTS';
export const SET_SEARCHED_COL_VAL = 'SET_SEARCHED_COL_VAL';

export const TOPICAL_SEARCH_GEOMETRY_BEGIN = 'TOPICAL_SEARCH_GEOMETRY_BEGIN';
export const TOPICAL_SEARCH_GEOMETRY_SUCCESS =
  'TOPICAL_SEARCH_GEOMETRY_SUCCESS';
export const TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS =
  'TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS';
export const TOPICAL_SEARCH_GEOMETRY_FAILED = 'TOPICAL_SEARCH_GEOMETRY_FAILED';
export const CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS =
  'CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS';

export const CHANGE_TOPICAL_SEARCH_LIST_ORDER_SUCCESS =
  'CHANGE_TOPICAL_SEARCH_LIST_ORDER_SUCCESS';
