import lineSliceAtIntersection from 'turf-line-slice-at-intersection';
import lineIntersect from '@turf/line-intersect';
import { multiLineString, lineString } from '@turf/helpers';
import { geomEach } from '@turf/meta';

const sliceLine = (line, segmenter) => {
  const intersectPoints = lineIntersect(line, segmenter);
  if (!intersectPoints.features.length) return null;

  const splittedLine = lineSliceAtIntersection(line, segmenter);

  const multiLineStringCoords = [];
  geomEach(splittedLine, geom => multiLineStringCoords.push(geom.coordinates));

  if (multiLineStringCoords.length < 2) {
    return lineString(multiLineStringCoords[0]);
  }

  return multiLineString(multiLineStringCoords);
};

export default sliceLine;
