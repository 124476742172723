import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const DistrictPropertiesShape = ImmutablePropTypes.mapContains({
  bip: PropTypes.string,
  dx: PropTypes.number.isRequired,
  dy: PropTypes.number.isRequired,
  geoportal: PropTypes.string,
  herb: PropTypes.string.isRequired,
  herb_fix: PropTypes.string.isRequired,
  nazwa: PropTypes.string.isRequired,
  powiat: PropTypes.string.isRequired,
  prefix: PropTypes.string.isRequired,
  wiki: PropTypes.string,
  two_columns: PropTypes.bool,
  www: PropTypes.string,
  x: PropTypes.number.isRequired,
  y: PropTypes.number.isRequired
});

export const DistrictDataShape = ImmutablePropTypes.mapContains({
  geometry: PropTypes.object.isRequired,
  properties: DistrictPropertiesShape.isRequired
});

export const DistrictShape = ImmutablePropTypes.mapContains({
  data: DistrictDataShape,
  isFetched: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired
});

export const AppDistrictShape = PropTypes.shape({
  id: PropTypes.number,
  is_enabled: PropTypes.bool,
  index: PropTypes.number,
  district: PropTypes.shape({
    id: PropTypes.number.isRequired,
    nazwa: PropTypes.string.isRequired,
    powiat: PropTypes.string.isRequired,
    prefix: PropTypes.string.isRequired,
    herb: PropTypes.string,
    thumbnail_image: PropTypes.string
  })
});

export const AppDistrictsShape = ImmutablePropTypes.listOf(AppDistrictShape);

export const DistrictRoleShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string
}).isRequired;

export const DistrictRolesShape = PropTypes.arrayOf(DistrictRoleShape);

export const DistrictSettingsShape = PropTypes.shape({
  id: PropTypes.number.isRequired,
  show_postal_codes: PropTypes.bool.isRequired,
  district: PropTypes.number.isRequired,
  search_adresses_max_zoom: PropTypes.number.isRequired,
  defects_max_zoom: PropTypes.number.isRequired
});
