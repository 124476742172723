import axios from 'axios';

const fetchDataFromAllPages = async (url, callback, config) => {
  if (!callback) {
    throw Error(
      'Callback must be passed to the fetchDataFromAllPages function!'
    );
  }

  const {
    data: { results, next }
  } = await axios.get(url, config);

  callback(results);
  if (next) await fetchDataFromAllPages(next, callback, config);
};

export default fetchDataFromAllPages;
