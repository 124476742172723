/**
 * scrolluje sidebara na górę wskazanego elementu
 *
 * @param {number} item - numer indexu elementu w sidebarze
 */

const scrollUpToSidebarItem = ({ item }) => {
  const firstSidebraItem = document.getElementsByClassName(
    'sidebar-items-flex'
  )[item];
  firstSidebraItem?.scrollIntoView();
};

export default scrollUpToSidebarItem;
