const getAdAswVariables = (settings = {}, hostName, district) => ({
  isAdAsw: settings.asw_enabled,
  aswAuthorizeUrl: `${settings.asw_identity_base_url}/connect/authorize`,
  aswEndSessionUrl: `${settings.asw_identity_base_url}/connect/endsession`,
  aswUserEditionUrl: `${settings.asw_identity_base_url}/account-edit`,
  aswClientId: settings.asw_client_id,
  aswLoginRedirecUri: `${hostName}/rp/asw/${district}/login`,
  aswLogoutRedirecUri: `${hostName}/rp/asw/${district}/logout`,
  aswCodeChallenge: process.env.REACT_APP_ASW_CODE_CHALLENGE,
  aswIdentityHost: settings.asw_identity_base_url,
  freeipaUrl: settings.freeipa_url
});

export default getAdAswVariables;
