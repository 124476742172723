/**
 * Usuwa wskazaną warstwę z przekazanej mapy.
 *
 * @param {L.Map} map
 * @param {string} layerId
 */

const deleteLayer = (map, layerId) => {
  if (!map) {
    return;
  }

  if (map._layers[layerId]) {
    map.removeLayer(map._layers[layerId]);
  }
};

export default deleteLayer;
