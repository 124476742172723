import qs from 'query-string';

const addParamsToUrl = (url, params = []) => {
  const parsedUrl = qs.parseUrl(url);

  params.forEach(([name, value]) => {
    parsedUrl.query[name] = value;
  });

  return qs.stringifyUrl(parsedUrl);
};

export default addParamsToUrl;
