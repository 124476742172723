import queryString from 'query-string';

import { createApi } from '@reduxjs/toolkit/query/react';

import handleApiError from '@/utils/handleApiError';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';
import {
  updateAdminSubcategories,
  fetchAllSubcategoriesBegin,
  fetchAllSubcategoriesFailed,
  fetchAllSubcategoriesSuccess,
  fetchDistrictReportsBegin,
  fetchDistrictReportsFailed,
  fetchDistrictReportsSuccess,
  fetchReportCategoriesBegin,
  fetchReportCategoriesFailed,
  fetchReportCategoriesSuccess,
  fetchReportCategoryConfigFailed,
  fetchReportCategoryConfigRequest,
  fetchReportCategoryConfigSuccess,
  fetchSingleReportConfigFailed,
  fetchSingleReportConfigRequest,
  fetchSingleReportConfigSuccess,
  getTypeReportsFailed,
  getTypeReportsRequest,
  getTypeReportsSuccess,
  fetchAdminCategoriesBegin,
  fetchAdminCategoriesFailed,
  fetchAdminCategoriesSuccess,
  getDefaultStatusTypeReportSuccess,
  getDefaultStatusTypeReportFailed,
  getDefaultStatusTypeReportRequest,
  fetchDefectConfigurationSuccess,
  updateReportConfigs,
  fetchUserSubscriptionsSuccess,
  fetchUserSubscriptionsFailed,
  fetchUserSubscriptionsBegin,
  fetchReportStatusesBegin,
  fetchReportStatusesSuccess,
  fetchReportStatusesFailed,
  fetchReportSubcategoriesSuccess,
  fetchReportSubcategoriesBegin,
  fetchReportSubcategoriesFailed,
  fetchAllReportsSuccess,
  fetchAllReportsBegin,
  fetchAllReportsFailed,
  fetchOptionsReportsBegin,
  fetchOptionsReportsSuccess
} from '@/store/actions/reportsActions';
import { showError } from '@/store/actions/globalActions';

import {
  EReportTags,
  TAllReportsParams,
  TOptionReports,
  TReportCategoryConfigParams,
  TReportSingleSubcategoriesParams,
  TReportStatusesParams,
  TReportSubcategoriesParams,
  TReportsParams,
  TUserSubscriptionParams
} from '@/types/api-types/reports';
import {
  TReportCategories,
  TReportCategoryConfigResponse,
  TReportSubcategories,
  TReportsResponse,
  TSingleReportConfigResponse,
  TTypeReport,
  TAdminCategory,
  TDefectUnusedSubcategoryResponse,
  TDefaultStatusTypeReportResponse,
  TDefectConfigurationResponse,
  TReportConfigsResponse,
  TUserSubscriptionResponse,
  TReportStatusesResponse,
  TReportSingleSubcategoriesResponse,
  TFetchAllAdminReports,
  TOptionsReportsResponse
} from '@/types/responses/reports';

import { DATA_FORMAT_WITH_TIME } from '@/utils/constants';

export const reportApi = createApi({
  reducerPath: 'reportApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EReportTags.REPORTS,
    EReportTags.REPORT_CATEGORIES,
    EReportTags.REPORT_SUBCATEGORIES,
    EReportTags.REPORT_CATEGORY_CONFIG,
    EReportTags.SINGLE_REPORT_CONFIG,
    EReportTags.TYPE_REPORTS,
    EReportTags.ADMIN_CATEGORIES,
    EReportTags.ADMIN_SUBCATEGORIES,
    EReportTags.DEFECT_UNUSED_SUBCATEGORIES,
    EReportTags.DEFAULT_STATUS_TYPE_REPORT,
    EReportTags.DEFECT_CONFIGURATION,
    EReportTags.REPORT_CONFIGS,
    EReportTags.USER_SUBSCRIPTION,
    EReportTags.REPORT_STATUSES,
    EReportTags.REPORT_SINGLE_SUBCATEGORIES,
    EReportTags.ALL_REPORTS,
    EReportTags.OPTION_RESPONSES
  ],
  endpoints: builder => ({
    fetchReports: builder.query<TReportsResponse, TReportsParams>({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/defect/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchDistrictReportsBegin());

        try {
          const {
            data: { features }
          } = await queryFulfilled;

          dispatch(fetchDistrictReportsSuccess(features));
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchDistrictReportsFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.features.map(({ id }) => ({
                type: EReportTags.REPORTS,
                id
              })),
              {
                type: EReportTags.REPORTS,
                id: EReportTags.REPORTS
              }
            ]
          : [
              {
                type: EReportTags.REPORTS,
                id: EReportTags.REPORTS
              }
            ]
    }),
    fetchReportCategories: builder.query<TReportCategories[], TReportsParams>({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/defect_category/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchReportCategoriesBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchReportCategoriesSuccess(data));
        } catch (error) {
          dispatch(fetchReportCategoriesFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(feature => ({
                type: EReportTags.REPORT_CATEGORIES,
                id: feature?.id ?? ''
              })),
              {
                type: EReportTags.REPORT_CATEGORIES,
                id: EReportTags.REPORT_CATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.REPORT_CATEGORIES,
                id: EReportTags.REPORT_CATEGORIES
              }
            ]
    }),
    fetchReportSubcategories: builder.query<
      TReportSubcategories[],
      TReportSubcategoriesParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/defect_subcategory/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchAllSubcategoriesBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(fetchAllSubcategoriesSuccess(data));
          dispatch(updateAdminSubcategories(data));
        } catch (error) {
          dispatch(fetchAllSubcategoriesFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }) => ({
                type: EReportTags.REPORT_SUBCATEGORIES,
                id
              })),
              {
                type: EReportTags.REPORT_SUBCATEGORIES,
                id: EReportTags.REPORT_SUBCATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.REPORT_SUBCATEGORIES,
                id: EReportTags.REPORT_SUBCATEGORIES
              }
            ]
    }),
    fetchReportCategoryConfig: builder.query<
      TReportCategoryConfigResponse[],
      TReportCategoryConfigParams
    >({
      query: ({ prefix, configId }) => ({
        method: 'GET',
        url: `${prefix}/defect_all_category_configs/${configId}/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchReportCategoryConfigRequest());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchReportCategoryConfigSuccess(data));
        } catch (error) {
          dispatch(fetchReportCategoryConfigFailed());
        }
      },
      providesTags: categoryConfigs =>
        categoryConfigs
          ? [
              ...categoryConfigs.map(({ id }) => ({
                type: EReportTags.REPORT_CATEGORY_CONFIG,
                id
              })),
              {
                type: EReportTags.REPORT_CATEGORY_CONFIG,
                id: EReportTags.REPORT_CATEGORY_CONFIG
              }
            ]
          : [
              {
                type: EReportTags.REPORT_CATEGORY_CONFIG,
                id: EReportTags.REPORT_CATEGORY_CONFIG
              }
            ]
    }),
    fetchSingleReportConfig: builder.query<
      TSingleReportConfigResponse,
      TReportCategoryConfigParams
    >({
      query: ({ prefix, configId }) => ({
        method: 'GET',
        url: `${prefix}/district_defect_config/${configId}/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchSingleReportConfigRequest());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchSingleReportConfigSuccess(data));
        } catch (error) {
          dispatch(fetchSingleReportConfigFailed());
        }
      },
      providesTags: singleReportConfig =>
        singleReportConfig
          ? [
              {
                type: EReportTags.SINGLE_REPORT_CONFIG,
                id: singleReportConfig?.id ?? ''
              },
              {
                type: EReportTags.SINGLE_REPORT_CONFIG,
                id: EReportTags.SINGLE_REPORT_CONFIG
              }
            ]
          : [
              {
                type: EReportTags.SINGLE_REPORT_CONFIG,
                id: EReportTags.SINGLE_REPORT_CONFIG
              }
            ]
    }),
    fetchTypeReports: builder.query<TTypeReport[], null>({
      query: () => ({
        method: 'GET',
        url: `type_report/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(getTypeReportsRequest());

        try {
          const { data } = await queryFulfilled;
          dispatch(getTypeReportsSuccess(data));
        } catch (error) {
          dispatch(getTypeReportsFailed());
        }
      },
      providesTags: typeReports =>
        typeReports
          ? [
              ...typeReports.map(({ id }) => ({
                type: EReportTags.TYPE_REPORTS,
                id
              })),
              {
                type: EReportTags.TYPE_REPORTS,
                id: EReportTags.TYPE_REPORTS
              }
            ]
          : [
              {
                type: EReportTags.TYPE_REPORTS,
                id: EReportTags.TYPE_REPORTS
              }
            ]
    }),
    fetchAdminCategories: builder.query<TAdminCategory[], { prefix: string }>({
      query: ({ prefix }) => ({
        method: 'GET',
        url: `${prefix}/defect_category_admin/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchAdminCategoriesBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchAdminCategoriesSuccess(data));
        } catch (error) {
          dispatch(fetchAdminCategoriesFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: defectCategories =>
        defectCategories
          ? [
              ...defectCategories.map(({ id }) => ({
                type: EReportTags.ADMIN_CATEGORIES,
                id
              })),
              {
                type: EReportTags.ADMIN_CATEGORIES,
                id: EReportTags.ADMIN_CATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.ADMIN_CATEGORIES,
                id: EReportTags.ADMIN_CATEGORIES
              }
            ]
    }),
    fetchAdminSubcategories: builder.query<
      TAdminCategory[],
      { prefix: string }
    >({
      query: ({ prefix }) => ({
        method: 'GET',
        url: `${prefix}/defect_subcategory_admin/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateAdminSubcategories(data));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: defectCategories =>
        defectCategories
          ? [
              ...defectCategories.map(({ id }) => ({
                type: EReportTags.ADMIN_SUBCATEGORIES,
                id
              })),
              {
                type: EReportTags.ADMIN_SUBCATEGORIES,
                id: EReportTags.ADMIN_SUBCATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.ADMIN_SUBCATEGORIES,
                id: EReportTags.ADMIN_SUBCATEGORIES
              }
            ]
    }),
    fetchDefectUnusedSubcategories: builder.query<
      TDefectUnusedSubcategoryResponse[],
      { prefix: string }
    >({
      query: ({ prefix }) => ({
        method: 'GET',
        url: `${prefix}/defect_unused_subcategory/`
      }),
      providesTags: defectCategories =>
        defectCategories
          ? [
              ...defectCategories.map(({ id }) => ({
                type: EReportTags.DEFECT_UNUSED_SUBCATEGORIES,
                id
              })),
              {
                type: EReportTags.DEFECT_UNUSED_SUBCATEGORIES,
                id: EReportTags.DEFECT_UNUSED_SUBCATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.DEFECT_UNUSED_SUBCATEGORIES,
                id: EReportTags.DEFECT_UNUSED_SUBCATEGORIES
              }
            ]
    }),
    fetchDefaultStatusTypeReport: builder.query<
      TDefaultStatusTypeReportResponse[],
      null
    >({
      query: () => ({
        method: 'GET',
        url: `default_status_type_report/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(getDefaultStatusTypeReportRequest());
        try {
          const { data } = await queryFulfilled;
          dispatch(getDefaultStatusTypeReportSuccess(data));
        } catch (error) {
          dispatch(getDefaultStatusTypeReportFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: statusTypeReport =>
        statusTypeReport
          ? [
              ...statusTypeReport.map(({ id }) => ({
                type: EReportTags.DEFAULT_STATUS_TYPE_REPORT,
                id
              })),
              {
                type: EReportTags.DEFAULT_STATUS_TYPE_REPORT,
                id: EReportTags.DEFAULT_STATUS_TYPE_REPORT
              }
            ]
          : [
              {
                type: EReportTags.DEFAULT_STATUS_TYPE_REPORT,
                id: EReportTags.DEFAULT_STATUS_TYPE_REPORT
              }
            ]
    }),
    fetchDefectConfiguration: builder.query<
      TDefectConfigurationResponse[],
      { district: string }
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: `${district}/defect_configuration/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDefectConfigurationSuccess(data[0]));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: defectConfiguration =>
        defectConfiguration
          ? [
              ...defectConfiguration.map(({ id }) => ({
                type: EReportTags.DEFECT_CONFIGURATION,
                id
              })),
              {
                type: EReportTags.DEFECT_CONFIGURATION,
                id: EReportTags.DEFECT_CONFIGURATION
              }
            ]
          : [
              {
                type: EReportTags.DEFECT_CONFIGURATION,
                id: EReportTags.DEFECT_CONFIGURATION
              }
            ]
    }),
    fetchReportConfigs: builder.query<
      TReportConfigsResponse[],
      { prefix: string }
    >({
      query: ({ prefix }) => ({
        method: 'GET',
        url: `${prefix}/district_defect_config/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(updateReportConfigs(data));
        } catch (error) {
          dispatch(
            showError(
              'Wystąpił błąd podczas pobierania dostępnych konfiguracji kolorów zgłoszeń.'
            )
          );
        }
      },
      providesTags: reportConfigs =>
        reportConfigs
          ? [
              ...reportConfigs.map(({ id }) => ({
                type: EReportTags.REPORT_CONFIGS,
                id
              })),
              {
                type: EReportTags.REPORT_CONFIGS,
                id: EReportTags.REPORT_CONFIGS
              }
            ]
          : [
              {
                type: EReportTags.REPORT_CONFIGS,
                id: EReportTags.REPORT_CONFIGS
              }
            ]
    }),
    fetchUserSubscriptions: builder.query<
      TUserSubscriptionResponse,
      TUserSubscriptionParams
    >({
      query: ({ district, name_user, page, resultsPerPage, order }) => ({
        method: 'GET',
        url: `${district}/defect_user_subscriptions/`,
        params: {
          name_user: name_user,
          ordering: order,
          page: page,
          page_size: resultsPerPage
        }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchUserSubscriptionsBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchUserSubscriptionsSuccess(data));
        } catch (error) {
          dispatch(fetchUserSubscriptionsFailed());
        }
      },
      providesTags: defectUserSubscriptions =>
        defectUserSubscriptions
          ? [
              ...defectUserSubscriptions.results.map(({ id }) => ({
                type: EReportTags.USER_SUBSCRIPTION,
                id
              })),
              {
                type: EReportTags.USER_SUBSCRIPTION,
                id: EReportTags.USER_SUBSCRIPTION
              }
            ]
          : [
              {
                type: EReportTags.USER_SUBSCRIPTION,
                id: EReportTags.USER_SUBSCRIPTION
              }
            ]
    }),
    fetchReportStatuses: builder.query<
      TReportStatusesResponse,
      Partial<TReportStatusesParams>
    >({
      query: ({ admin = false, page, resultsPerPage }) => ({
        method: 'GET',
        url: admin ? `defect_status_admin/` : `defect_status/`,
        params: {
          page,
          page_size: resultsPerPage
        }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchReportStatusesBegin());

        try {
          const {
            data: { results }
          } = await queryFulfilled;
          dispatch(fetchReportStatusesSuccess(results));
        } catch (error) {
          dispatch(fetchReportStatusesFailed());
        }
      },
      providesTags: reportStatuses =>
        reportStatuses
          ? [
              ...reportStatuses.results.map(({ id }) => ({
                type: EReportTags.REPORT_STATUSES,
                id
              })),
              {
                type: EReportTags.REPORT_STATUSES,
                id: EReportTags.REPORT_STATUSES
              }
            ]
          : [
              {
                type: EReportTags.REPORT_STATUSES,
                id: EReportTags.REPORT_STATUSES
              }
            ]
    }),
    fetchReportSingleSubcategories: builder.query<
      TReportSingleSubcategoriesResponse[],
      TReportSingleSubcategoriesParams
    >({
      query: ({ district, categoryId }) => ({
        method: 'GET',
        url: `${district}/defect_category/${categoryId}/subcategory/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchReportSubcategoriesBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchReportSubcategoriesSuccess(data));
        } catch (error) {
          dispatch(fetchReportSubcategoriesFailed());
        }
      },
      providesTags: singleSubcategories =>
        singleSubcategories
          ? [
              ...singleSubcategories.map(({ id }) => ({
                type: EReportTags.REPORT_SINGLE_SUBCATEGORIES,
                id
              })),
              {
                type: EReportTags.REPORT_SINGLE_SUBCATEGORIES,
                id: EReportTags.REPORT_SINGLE_SUBCATEGORIES
              }
            ]
          : [
              {
                type: EReportTags.REPORT_SINGLE_SUBCATEGORIES,
                id: EReportTags.REPORT_SINGLE_SUBCATEGORIES
              }
            ]
    }),
    fetchAllAdminReports: builder.query<
      TFetchAllAdminReports,
      TAllReportsParams
    >({
      query: ({
        district,
        data: {
          createdStart,
          createdEnd,
          descOrdering,
          ordering,
          search,
          page,
          pageSize,
          category,
          subcategory,
          status,
          confirmed,
          type
        }
      }) => {
        const prepareCreated = (): string | null => {
          const start = createdStart;
          const end = createdEnd;

          if (start && end) {
            return `${start.format(DATA_FORMAT_WITH_TIME)},${end.format(
              DATA_FORMAT_WITH_TIME
            )}`;
          } else if (start && !end) {
            return `${start.format(DATA_FORMAT_WITH_TIME)}`;
          } else if (!start && end) {
            return `,${end.format(DATA_FORMAT_WITH_TIME)}`;
          } else {
            return null;
          }
        };

        const params = {
          ordering: ordering
            ? `${Boolean(descOrdering) ? '-' : ''}${ordering}`
            : null,
          search,
          page,
          page_size: pageSize,
          category,
          subcategory,
          status,
          confirmed,
          type,
          created: prepareCreated()
        };

        Object.keys(params).forEach(key => {
          const typedKey = key as keyof typeof params;

          if (params.confirmed === 0 || params.confirmed === 1) return;

          if (
            typedKey === 'subcategory' &&
            params[typedKey] !== undefined &&
            !(params[typedKey] as number[]).length
          )
            return;

          if (!params[typedKey]) {
            delete params[typedKey];
          }
        });

        return {
          url: `${district}/defect_subscriber/`,
          method: 'GET',
          params,
          paramsSerializer: (params: TAllReportsParams) =>
            queryString.stringify(params)
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchAllReportsBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchAllReportsSuccess(data));
        } catch (error) {
          dispatch(fetchAllReportsFailed());
        }
      },
      providesTags: allReports =>
        allReports
          ? [
              ...allReports.results.features.map(({ id }) => ({
                type: EReportTags.ALL_REPORTS,
                id
              })),
              { type: EReportTags.ALL_REPORTS, id: EReportTags.ALL_REPORTS }
            ]
          : [{ type: EReportTags.ALL_REPORTS, id: EReportTags.ALL_REPORTS }]
    }),
    fetchOptionReports: builder.query<TOptionsReportsResponse, TOptionReports>({
      query: ({ district }) => ({
        method: 'OPTIONS',
        url: `${district}/defect_subscriber/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchOptionsReportsBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchOptionsReportsSuccess(data.actions.POST));
        } catch (error) {
          dispatch(fetchReportStatusesFailed());
        }
      },
      providesTags: result => [
        {
          type: EReportTags.OPTION_RESPONSES,
          id: result ? result.name : ''
        }
      ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchReportsQuery,
  useLazyFetchReportCategoriesQuery,
  useLazyFetchReportSubcategoriesQuery,
  useLazyFetchReportCategoryConfigQuery,
  useLazyFetchSingleReportConfigQuery,
  useLazyFetchTypeReportsQuery,
  useLazyFetchAdminCategoriesQuery,
  useLazyFetchAdminSubcategoriesQuery,
  useLazyFetchDefectUnusedSubcategoriesQuery,
  useLazyFetchDefaultStatusTypeReportQuery,
  useLazyFetchDefectConfigurationQuery,
  useLazyFetchReportConfigsQuery,
  useLazyFetchUserSubscriptionsQuery,
  useLazyFetchReportStatusesQuery,
  useLazyFetchReportSingleSubcategoriesQuery,
  useLazyFetchAllAdminReportsQuery,
  useLazyFetchOptionReportsQuery
} = reportApi;
