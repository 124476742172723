export const GET_SAVED_INVESTMENTS_REQUEST =
  'investor/GET_SAVED_INVESTMENTS_REQUEST';
export const GET_SAVED_INVESTMENTS_SUCCESS =
  'investor/GET_SAVED_INVESTMENTS_SUCCESS';
export const GET_SAVED_INVESTMENTS_FAILED =
  'investor/GET_SAVED_INVESTMENTS_FAILED';

export const SAVE_INVESTMENT_REQUEST = 'investor/SAVE_INVESTMENT_REQUEST';
export const SAVE_INVESTMENT_SUCCESS = 'investor/SAVE_INVESTMENT_SUCCESS';
export const SAVE_INVESTMENT_FAILED = 'investor/SAVE_INVESTMENT_FAILED';

export const DELETE_INVESTMENT_REQUEST = 'investor/DELETE_INVESTMENT_REQUEST';
export const DELETE_INVESTMENT_SUCCESS = 'investor/DELETE_INVESTMENT_SUCCESS';
export const DELETE_INVESTMENT_FAILED = 'investor/DELETE_INVESTMENT_FAILED';

export const GET_SINGLE_SAVED_INVESTMENT_REQUEST =
  'investor/GET_SINGLE_SAVED_INVESTMENT_REQUEST';
export const GET_SINGLE_SAVED_INVESTMENT_SUCCESS =
  'investor/GET_SINGLE_SAVED_INVESTMENT_SUCCESS';
export const GET_SINGLE_SAVED_INVESTMENT_FAILED =
  'investor/GET_SINGLE_SAVED_INVESTMENT_FAILED';
