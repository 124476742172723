export const FETCH_TARIFF_SUCCESS = 'FETCH_TARIFF_SUCCESS';
export const FETCH_TARIFF_FAILED = 'FETCH_TARIFF_FAILED';
export const FETCH_CONTROL_BOX_BEGIN = 'FETCH_CONTROL_BOX_BEGIN';
export const FETCH_CONTROL_BOX_SUCCESS = 'FETCH_CONTROL_BOX_SUCCESS';
export const FETCH_CONTROL_BOX_FAILED = 'FETCH_CONTROL_BOX_FAILED';
export const CREATE_TARIFF_SUCCESS = 'CREATE_TARIFF_SUCCESS';
export const CREATE_TARIFF_FAILED = 'CREATE_TARIFF_FAILED';
export const DELETE_TARIFF_SUCCESS = 'DELETE_TARIFF_SUCCESS';
export const DELETE_TARIFF_FAILED = 'DELETE_TARIFF_FAILED';
export const CREATE_COST_CONFIG_SUCCESS = 'CREATE_COST_CONFIG_SUCCESS';
export const CREATE_COST_CONFIG_FAILED = 'CREATE_COST_CONFIG_FAILED';
export const DELETE_COST_CONFIG_SUCCESS = 'DELETE_COST_CONFIG_SUCCESS';
export const DELETE_COST_CONFIG_FAILED = 'DELETE_COST_CONFIG_FAILED';
export const FETCH_COST_CONFIG_SUCCESS = 'FETCH_COST_CONFIG_SUCCESS';
export const FETCH_COST_CONFIG_FAILED = 'FETCH_COST_CONFIG_FAILED';
export const IS_LOADING_TARIFF = 'IS_LOADING_TARIFF';
export const IS_LOADING_COST_CONFIG = 'IS_LOADING_COST_CONFIG';
