const getFileNameFromPath = path => {
  if (!path) return;

  const splittedPath = path.split('/');
  const splitLength = splittedPath.length;

  return splittedPath[splitLength - 1];
};

export default getFileNameFromPath;
