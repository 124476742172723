const isHTML = text => {
  const htmlRegex = /<[a-z/][\s\S]*>/;
  const isHtmlByRegex = htmlRegex.test(text);

  const parser = new DOMParser();
  const doc = parser.parseFromString(text, 'text/html');

  const isHtmlByNode =
    doc.body.childNodes.length > 0 &&
    !doc.body.firstChild.isEqualNode(doc.createTextNode(text));

  return isHtmlByRegex || isHtmlByNode;
};

export default isHTML;
