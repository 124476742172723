export default function getBase64ImageSize(base64) {
  return new Promise((resolve, reject) => {
    try {
      const img = new Image();

      img.src = base64;
      img.onload = ({ target: { width, height } }) => {
        resolve({ width, height });
      };
    } catch (err) {
      reject(err);
    }
  });
}
