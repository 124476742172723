import area from '@turf/area';
import { parseArea } from '../';

/**
 * Oblicza pole powierzchni przekazanej warstwy.
 *
 * @param {Layer} layer
 * @return {Object}
 */
const calculateArea = (layer, unit = 'm') => {
  let value = null;
  if ('geometry' in layer) value = area(layer);
  else value = area(layer.toGeoJSON());

  return {
    value, // unit: square meters
    valueString: parseArea(value, unit)
  };
};

export default calculateArea;
