import React, { memo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { useSelector } from 'react-redux';

import useGlobalSettings from '../../hooks/useGlobalSettings';

import {
  districtSelectors,
  mapPortalSelectors,
  userSelectors
} from '../../store/selectors';

import { getHomePageUrl, regEx } from '../../utils/lib';

const { getDistricts } = districtSelectors;
const { getDistrictsMapPortals } = mapPortalSelectors;
const { getLogoutProcessing } = userSelectors;

const getUrl = pathname => window.location.origin + pathname;

export const LocationListener = () => {
  const prevPath = React.useRef();

  const { pathname } = useLocation();
  const routerHistory = useHistory();

  const push = path => {
    const isFullUrl = regEx.urlProtocol.test(path);

    if (!isFullUrl) return routerHistory.push(path);

    window.location = path;
  };

  const globalSettings = useGlobalSettings({ toJS: false });
  const districts = useSelector(state => getDistricts(state));
  const mapPortals = useSelector(state => getDistrictsMapPortals(state));
  const isLogoutProcessing = useSelector(state => getLogoutProcessing(state));

  const updatePrevPath = () => {
    prevPath.current = pathname;
  };

  const handleMatomoLocationChange = () => {
    if (!window._paq) return;

    if (prevPath.current) {
      window._paq.push(['setReferrerUrl', getUrl(prevPath.current)]);
    }

    window._paq.push(['setCustomUrl', getUrl(pathname)]);
    window._paq.push(['setDocumentTitle', document.title]);
    window._paq.push(['trackPageView']);
  };

  const handleGoogleAnalyticsLocationChange = () => {
    if (!window.gtag) return;

    window.gtag('set', 'page', pathname);
    window.gtag('send', 'pageview');
  };

  const handleLogout = () => {
    if (!isLogoutProcessing) return;

    const [, district, portalName] = pathname.split('/');
    const homePageUrl = getHomePageUrl(globalSettings);

    // Obsluga wylogowanie z profilu.
    if (district === 'profil') return push(homePageUrl);

    // Obsług przypadku, gdzie pierwsza część adresu nie jest prefixem
    // żadnej jednostki.
    if (!districts.includes(district)) return;

    // Obsługa wylogowania na liście portali.
    if (!portalName) return;

    // Obsługa wylogowania z panelu admina.
    if (portalName === 'admin') return push(homePageUrl);

    // Obsługa wylogowania z portalu
    const mapPortal = mapPortals
      .get(district)
      ?.find(portal => portal.get('short_name') === portalName);
    if (!mapPortal) return push(homePageUrl);

    const portalRequiredRole = mapPortal?.get('required_role');
    if (!portalRequiredRole) return;

    push(homePageUrl);
  };

  React.useEffect(() => {
    handleMatomoLocationChange();
    handleGoogleAnalyticsLocationChange();
    updatePrevPath();
  }, [pathname]);

  React.useEffect(() => {
    handleLogout();
  }, [isLogoutProcessing]);

  return null;
};

export default memo(LocationListener);
