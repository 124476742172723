export const GET_MAP_PORTAL_NOTES_BEGIN = 'GET_MAP_PORTAL_NOTES_BEGIN';
export const GET_MAP_PORTAL_NOTES_SUCCESS = 'GET_MAP_PORTAL_NOTES_SUCCESS';
export const GET_MAP_PORTAL_NOTES_FAILED = 'GET_MAP_PORTAL_NOTES_FAILED';

export const GET_MAP_PORTAL_NOTE_BEGIN = 'GET_MAP_PORTAL_NOTE_BEGIN';
export const GET_MAP_PORTAL_NOTE_SUCCESS = 'GET_MAP_PORTAL_NOTE_SUCCESS';
export const GET_MAP_PORTAL_NOTE_FAILED = 'GET_MAP_PORTAL_NOTE_FAILED';

export const ADD_MAP_PORTAL_NOTE_BEGIN = 'ADD_MAP_PORTAL_NOTE_BEGIN';
export const ADD_MAP_PORTAL_NOTE_SUCCESS = 'ADD_MAP_PORTAL_NOTE_SUCCESS';
export const ADD_MAP_PORTAL_NOTE_FAILED = 'ADD_MAP_PORTAL_NOTE_FAILED';

export const EDIT_MAP_PORTAL_NOTE_BEGIN = 'EDIT_MAP_PORTAL_NOTE_BEGIN';
export const EDIT_MAP_PORTAL_NOTE_SUCCESS = 'EDIT_MAP_PORTAL_NOTE_SUCCESS';
export const EDIT_MAP_PORTAL_NOTE_FAILED = 'EDIT_MAP_PORTAL_NOTE_FAILED';

export const DELETE_MAP_PORTAL_NOTE_BEGIN = 'DELETE_MAP_PORTAL_NOTE_BEGIN';
export const DELETE_MAP_PORTAL_NOTE_SUCCESS = 'DELETE_MAP_PORTAL_NOTE_SUCCESS';
export const DELETE_MAP_PORTAL_NOTE_FAILED = 'DELETE_MAP_PORTAL_NOTE_FAILED';

export const DELETE_ALL_MAP_PORTAL_NOTES_BEGIN =
  'DELETE_ALL_MAP_PORTAL_NOTES_BEGIN';
export const DELETE_ALL_MAP_PORTAL_NOTES_SUCCESS =
  'DELETE_ALL_MAP_PORTAL_NOTES_SUCCESS';
export const DELETE_ALL_MAP_PORTAL_NOTES_FAILED =
  'DELETE_ALL_MAP_PORTAL_NOTES_FAILED';

export const SET_CURRENT_NOTE = 'SET_CURRENT_NOTE';
export const CLEAR_CURRENT_NOTE = 'CLEAR_CURRENT_NOTE';

export const SET_NOTE_EDITOR_COORDINATES = 'SET_NOTE_EDITOR_COORDINATES';
export const CLEAR_NOTE_EDITOR_COORDINATES = 'CLEAR_NOTE_EDITOR_COORDINATES';
