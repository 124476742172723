/**
 * Funkcja sortujaca alfabetycznie
 *
 * @param {*} a
 * @param {*} b
 * @param {*} key
 */
const sortAlphabetic = (a, b, key) => {
  if (!key) {
    const _a = typeof a === 'string' ? a.toLowerCase() : a;
    const _b = typeof b === 'string' ? b.toLowerCase() : b;

    if (typeof _a === 'string' && typeof _b === 'string')
      return _a.localeCompare(_b);

    if (_a < _b) {
      return -1;
    }
    if (_a > _b) {
      return 1;
    }

    return 0;
  } else {
    const _a = { ...a };
    const _b = { ...b };

    const _valA = typeof _a[key] === 'string' ? _a[key].toLowerCase() : _a[key];
    const _valB = typeof _b[key] === 'string' ? _b[key].toLowerCase() : _b[key];

    if (typeof _valA === 'string' && typeof _valB === 'string')
      return _valA.localeCompare(_valB);

    if (_valA < _valB) {
      return -1;
    }
    if (_valA > _valB) {
      return 1;
    }

    return 0;
  }
};

export default sortAlphabetic;
