import axios from 'axios';
import queryString from 'query-string';
import {
  FETCH_TOPICAL_SEARCH_TOOLS_BEGIN,
  FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS,
  FETCH_TOPICAL_SEARCH_TOOLS_FAILED,
  FETCH_TOPICAL_SEARCH_TOOL_BEGIN,
  FETCH_TOPICAL_SEARCH_TOOL_SUCCESS,
  FETCH_TOPICAL_SEARCH_TOOL_FAILED,
  ADD_TOPICAL_SEARCH_TOOL_BEGIN,
  ADD_TOPICAL_SEARCH_TOOL_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_FAILED,
  EDIT_TOPICAL_SEARCH_TOOL_BEGIN,
  EDIT_TOPICAL_SEARCH_TOOL_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_FAILED,
  EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL,
  ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_BEGIN,
  ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS,
  ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_FAILED,
  UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_BEGIN,
  UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS,
  UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_FAILED,
  FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_BEGIN,
  FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS,
  FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_FAILED,
  GET_TOPICAL_SEARCH_TOOL_COLUMNS_BEGIN,
  GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS,
  GET_TOPICAL_SEARCH_TOOL_COLUMNS_FAILED,
  GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_BEGIN,
  GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS,
  GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_FAILED,
  ADD_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN,
  ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_COLUMN_FAILED,
  EDIT_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN,
  EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_COLUMN_FAILED,
  DELETE_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN,
  DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_COLUMN_FAILED,
  ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN,
  ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED,
  EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN,
  EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED,
  DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN,
  DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED,
  GET_LAYER_LOOKUP_FIELDS_BEGIN,
  GET_LAYER_LOOKUP_FIELDS_SUCCESS,
  GET_LAYER_LOOKUP_FIELDS_FAILED,
  GET_LAYER_FIELD_SEARCH_TYPES_BEGIN,
  GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS,
  GET_LAYER_FIELD_SEARCH_TYPES_FAILED,
  GET_LAYER_RESULT_FIELDS_BEGIN,
  GET_LAYER_RESULT_FIELDS_SUCCESS,
  GET_LAYER_RESULT_FIELDS_FAILED,
  DELETE_TOPICAL_SEARCH_TOOL_BEGIN,
  DELETE_TOPICAL_SEARCH_TOOL_SUCCESS,
  DELETE_TOPICAL_SEARCH_TOOL_FAILED,
  GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_BEGIN,
  GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_SUCCESS,
  GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_FAILED,
  CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_BEGIN,
  CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS,
  CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_FAILED,
  TOPICAL_SEARCH_BEGIN,
  TOPICAL_SEARCH_SUCCESS,
  TOPICAL_SEARCH_FAILED,
  FETCH_TOPICAL_SEARCH_RESULTS,
  STOP_FETCH_TOPICAL_SEARCH_RESULTS,
  CLEAR_TOPICAL_SEARCH_RESULTS,
  SET_SEARCHED_COL_VAL,
  TOPICAL_SEARCH_GEOMETRY_BEGIN,
  TOPICAL_SEARCH_GEOMETRY_SUCCESS,
  TOPICAL_SEARCH_GEOMETRY_FAILED,
  TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS,
  CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS,
  CHANGE_TOPICAL_SEARCH_LIST_ORDER_SUCCESS
} from '../constants/searchToolsActionTypes';

import { showError, showSuccess } from './globalActions';

import { getTranslation } from '../translations';
import { parseResponseError } from '../../utils/lib';
import { PAGE_SIZE } from '../../utils/constants';

const reducerTranslations = getTranslation('searchTools');

export const fetchTopicalSearchToolsBegin = () => ({
  type: FETCH_TOPICAL_SEARCH_TOOLS_BEGIN
});

export const fetchTopicalSearchToolsSuccess = topicalSearchTools => ({
  type: FETCH_TOPICAL_SEARCH_TOOLS_SUCCESS,
  topicalSearchTools
});

export const fetchTopicalSearchToolsFailed = () => ({
  type: FETCH_TOPICAL_SEARCH_TOOLS_FAILED
});

export const fetchTopicalSearchTools = (params = {}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchTopicalSearchToolsBegin());

    const { query, pageSize, page } = params;

    const urlQuery = queryString.stringify({
      search: query,
      page_size: pageSize,
      page
    });

    axios
      .get(`topical_search_tool/${urlQuery ? '?' + urlQuery : ''}`)
      .then(response => {
        dispatch(fetchTopicalSearchToolsSuccess(response.data.results));
        resolve({
          data: response.data.results,
          nextPage: response.data.next
            ? queryString.parseUrl(response.data.next).query.page
            : null
        });
      })
      .catch(err => {
        dispatch(fetchTopicalSearchToolsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const fetchTopicalSearchToolBegin = () => ({
  type: FETCH_TOPICAL_SEARCH_TOOL_BEGIN
});

export const fetchTopicalSearchToolSuccess = topicalSearchTool => ({
  type: FETCH_TOPICAL_SEARCH_TOOL_SUCCESS,
  topicalSearchTool
});

export const fetchTopicalSearchToolFailed = () => ({
  type: FETCH_TOPICAL_SEARCH_TOOL_FAILED
});

export const fetchTopicalSearchTool = toolId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchTopicalSearchToolBegin());

    axios
      .get(`topical_search_tool/${toolId}/`)
      .then(response => {
        dispatch(fetchTopicalSearchToolSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchTopicalSearchToolFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const addTopicalSearchToolBegin = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_BEGIN
});

export const addTopicalSearchToolSuccess = tool => ({
  type: ADD_TOPICAL_SEARCH_TOOL_SUCCESS,
  tool
});

export const addTopicalSearchToolFailed = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_FAILED
});

export const addTopicalSearchTool = tool => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(addTopicalSearchToolBegin());

    axios
      .post('topical_search_tool/', tool)
      .then(response => {
        dispatch(addTopicalSearchToolSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addTopicalSearchToolFailed());
        dispatch(
          showError(
            parseResponseError(err, {
              unique: 'Istnieje już wyszukiwarka z tą nazwą!'
            })
          )
        );
        reject(err);
      });
  });

export const editTopicalSearchToolBegin = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_BEGIN
});

export const editTopicalSearchToolSuccess = payload => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_SUCCESS,
  payload
});

export const editTopicalSearchToolFailed = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_FAILED
});

export const editTopicalSearchTool = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editTopicalSearchToolBegin());

    const { toolId, changes, compositionId, district } = params;

    axios
      .patch(`topical_search_tool/${toolId}/`, changes)
      .then(async response => {
        const payload = {
          tool: response.data,
          compositionId,
          district
        };

        await dispatch(editTopicalSearchToolSuccess(payload));

        // Edytujemy lokalny stan narzędzia zapisanego w obiekcie narzędzi
        // przypisanych do kompozycji.
        if (district && compositionId) {
          await dispatch(editCompositionTopicalSearchTool(payload));
        }

        resolve(response.data);
      })
      .catch(err => {
        dispatch(editTopicalSearchToolFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const editCompositionTopicalSearchTool = payload => ({
  type: EDIT_COMPOSITION_TOPICAL_SEARCH_TOOL,
  payload
});

export const assignTopicalSearchToolToCompositionBegin = () => ({
  type: ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_BEGIN
});

export const assignTopicalSearchToolToCompositionSuccess = payload => ({
  type: ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_SUCCESS,
  payload
});

export const assignTopicalSearchToolToCompositionFailed = () => ({
  type: ASSIGN_TOPICAL_SEARCH_TOOL_TO_COMPOSITION_FAILED
});

export const assignTopicalSearchToolToComposition = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(assignTopicalSearchToolToCompositionBegin());

    const { district, compositionId, toolId } = params;

    const body = {
      map_portal_composition: params.compositionId,
      topical_search_tool: params.toolId
    };

    axios
      .post(`map_portal_topical_search_tool/`, body)
      .then(async () => {
        let tool;

        try {
          tool = await dispatch(fetchTopicalSearchTool(toolId));
        } catch (err) {
          reject(err);
        }

        dispatch(
          assignTopicalSearchToolToCompositionSuccess({
            tool,
            district,
            compositionId
          })
        );
        resolve();
      })
      .catch(err => {
        dispatch(assignTopicalSearchToolToCompositionFailed());
        dispatch(
          showError(
            parseResponseError(err, {
              unique:
                'Wybrana wyszukiwarka jest już przypisana do tej kompozycji.'
            })
          )
        );
        reject(err);
      });
  });

export const unassignTopicalSearchToolFromCompositionBegin = () => ({
  type: UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_BEGIN
});

export const unassignTopicalSearchToolFromCompositionSuccess = payload => ({
  type: UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_SUCCESS,
  payload
});

export const unassignTopicalSearchToolFromCompositionFailed = () => ({
  type: UNASSIGN_TOPICAL_SEARCH_TOOL_FROM_COMPOSITION_FAILED
});

export const unassignTopicalSearchToolFromComposition = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(unassignTopicalSearchToolFromCompositionBegin());

    const { connectionId } = params;

    axios
      .delete(`map_portal_topical_search_tool/${connectionId}/`)
      .then(() => {
        dispatch(unassignTopicalSearchToolFromCompositionSuccess(params));
        resolve();
      })
      .catch(err => {
        dispatch(unassignTopicalSearchToolFromCompositionFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const fetchCompositionTopicalSearchToolsBegin = () => ({
  type: FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_BEGIN
});

export const fetchCompositionTopicalSearchToolsSuccess = ({
  tools,
  district,
  compositionId
}) => ({
  type: FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_SUCCESS,
  tools,
  district,
  compositionId
});

export const fetchCompositionTopicalSearchToolsFailed = () => ({
  type: FETCH_COMPOSITION_TOPICAL_SEARCH_TOOLS_FAILED
});

//RTQ
export const getTopicalSearchToolColumnsBegin = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_COLUMNS_BEGIN
});

export const getTopicalSearchToolColumnsSuccess = payload => ({
  type: GET_TOPICAL_SEARCH_TOOL_COLUMNS_SUCCESS,
  payload
});

export const getTopicalSearchToolColumnsFailed = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_COLUMNS_FAILED
});

//RTQ
export const getTopicalSearchToolResultColumnsBegin = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_BEGIN
});

export const getTopicalSearchToolResultColumnsSuccess = payload => ({
  type: GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_SUCCESS,
  payload
});

export const getTopicalSearchToolResultColumnsFailed = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_RESULT_COLUMNS_FAILED
});

export const addTopicalSearchToolColumnBegin = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN
});

export const addTopicalSearchToolColumnSuccess = column => ({
  type: ADD_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  column
});

export const addTopicalSearchToolColumnFailed = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_COLUMN_FAILED
});

export const addTopicalSearchToolColumn = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addTopicalSearchToolColumnBegin());

    axios
      .post(`topical_search_tool_lookup_column/`, body)
      .then(response => {
        dispatch(addTopicalSearchToolColumnSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addTopicalSearchToolColumnFailed());
        dispatch(
          showError(
            parseResponseError(err, {
              unique:
                'Wybrana warstwa wraz ze wskazanym typem wyszukiwania jest już przypisana do tej kompozycji.'
            })
          )
        );
        reject(err);
      });
  });

export const editTopicalSearchToolColumnBegin = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN
});

export const editTopicalSearchToolColumnSuccess = column => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  column
});

export const editTopicalSearchToolColumnFailed = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_COLUMN_FAILED
});

export const editTopicalSearchToolColumn = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editTopicalSearchToolColumnBegin());

    const { changes, columnId } = params;

    axios
      .patch(`topical_search_tool_lookup_column/${columnId}/`, changes)
      .then(response => {
        dispatch(editTopicalSearchToolColumnSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(editTopicalSearchToolColumnFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteTopicalSearchToolColumnBegin = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_COLUMN_BEGIN
});

export const deleteTopicalSearchToolColumnSuccess = payload => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_COLUMN_SUCCESS,
  payload
});

export const deleteTopicalSearchToolColumnFailed = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_COLUMN_FAILED
});

export const deleteTopicalSearchToolColumn = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteTopicalSearchToolColumnBegin());

    const { columnId } = params;

    axios
      .delete(`topical_search_tool_lookup_column/${columnId}/`)
      .then(() => {
        dispatch(deleteTopicalSearchToolColumnSuccess(params));
        resolve();
      })
      .catch(err => {
        dispatch(deleteTopicalSearchToolColumnFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const addTopicalSearchToolResultColumnBegin = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN
});

export const addTopicalSearchToolResultColumnSuccess = column => ({
  type: ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  column
});

export const addTopicalSearchToolResultColumnFailed = () => ({
  type: ADD_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED
});

export const addTopicalSearchToolResultColumn = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addTopicalSearchToolResultColumnBegin());

    axios
      .post(`topical_search_tool_result_column/`, body)
      .then(response => {
        dispatch(addTopicalSearchToolResultColumnSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addTopicalSearchToolResultColumnFailed());
        dispatch(
          showError(
            parseResponseError(err, {
              blank: 'Nie podano nazwy kolumny wynikowej.'
            })
          )
        );
        reject(err);
      });
  });

export const editTopicalSearchToolResultColumnBegin = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN
});

export const editTopicalSearchToolResultColumnSuccess = column => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  column
});

export const editTopicalSearchToolResultColumnFailed = () => ({
  type: EDIT_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED
});

export const editTopicalSearchToolResultColumn = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editTopicalSearchToolResultColumnBegin());

    const { changes, columnId } = params;

    axios
      .patch(`topical_search_tool_result_column/${columnId}/`, changes)
      .then(response => {
        dispatch(editTopicalSearchToolResultColumnSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(editTopicalSearchToolResultColumnFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteTopicalSearchToolResultColumnBegin = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_BEGIN
});

export const deleteTopicalSearchToolResultColumnSuccess = payload => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_SUCCESS,
  payload
});

export const deleteTopicalSearchToolResultColumnFailed = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_RESULT_COLUMN_FAILED
});

export const deleteTopicalSearchToolResultColumn = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteTopicalSearchToolResultColumnBegin());

    const { columnId } = params;

    axios
      .delete(`topical_search_tool_result_column/${columnId}/`)
      .then(() => {
        dispatch(deleteTopicalSearchToolResultColumnSuccess(params));
        resolve();
      })
      .catch(err => {
        dispatch(deleteTopicalSearchToolResultColumnFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const getLayerLookupFieldsBegin = () => ({
  type: GET_LAYER_LOOKUP_FIELDS_BEGIN
});

export const getLayerLookupFieldsSuccess = payload => ({
  type: GET_LAYER_LOOKUP_FIELDS_SUCCESS,
  payload
});

export const getLayerLookupFieldsFailed = () => ({
  type: GET_LAYER_LOOKUP_FIELDS_FAILED
});

export const getLayerLookupFields = contentTypeId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getLayerLookupFieldsBegin());

    axios
      .get(`layer_lookup_fields/${contentTypeId}/`)
      .then(response => {
        dispatch(
          getLayerLookupFieldsSuccess({
            fields: response.data,
            contentTypeId
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(getLayerLookupFieldsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const getLayerFieldSearchTypesBegin = () => ({
  type: GET_LAYER_FIELD_SEARCH_TYPES_BEGIN
});

export const getLayerFieldSearchTypesSuccess = payload => ({
  type: GET_LAYER_FIELD_SEARCH_TYPES_SUCCESS,
  payload
});

export const getLayerFieldSearchTypesFailed = () => ({
  type: GET_LAYER_FIELD_SEARCH_TYPES_FAILED
});

export const getLayerFieldSearchTypes = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getLayerFieldSearchTypesBegin());

    const { contentTypeId, fieldName } = params;

    axios
      .get(`layer_field_search_types/${contentTypeId}/${fieldName}/`)
      .then(response => {
        dispatch(
          getLayerFieldSearchTypesSuccess({
            searchTypes: response.data,
            contentTypeId,
            fieldName
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(getLayerFieldSearchTypesFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const getLayerResultFieldsBegin = () => ({
  type: GET_LAYER_RESULT_FIELDS_BEGIN
});

export const getLayerResultFieldsSuccess = payload => ({
  type: GET_LAYER_RESULT_FIELDS_SUCCESS,
  payload
});

export const getLayerResultFieldsFailed = () => ({
  type: GET_LAYER_RESULT_FIELDS_FAILED
});

export const getLayerResultFields = contentTypeId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getLayerResultFieldsBegin());

    axios
      .get(`layer_result_fields/${contentTypeId}/`)
      .then(response => {
        dispatch(
          getLayerResultFieldsSuccess({
            fields: response.data,
            contentTypeId
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(getLayerResultFieldsFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const deleteTopicalSearchToolBegin = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_BEGIN
});

export const deleteTopicalSearchToolSuccess = (toolId, district) => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_SUCCESS,
  toolId,
  district
});

export const deleteTopicalSearchToolFailed = () => ({
  type: DELETE_TOPICAL_SEARCH_TOOL_FAILED
});

export const deleteTopicalSearchTool = (toolId, district) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    dispatch(deleteTopicalSearchToolBegin());

    axios
      .delete(`topical_search_tool/${toolId}/`)
      .then(() => {
        const lang = getState().global.get('language');

        dispatch(deleteTopicalSearchToolSuccess(toolId, district));
        dispatch(
          showSuccess(
            reducerTranslations('deleteSearchToolSuccessMessage', lang)
          )
        );
        resolve();
      })
      .catch(err => {
        dispatch(deleteTopicalSearchToolFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const getTopicalSearchToolConnectIdBegin = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_BEGIN
});

export const getTopicalSearchToolConnectIdSuccess = payload => ({
  type: GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_SUCCESS,
  payload
});

export const getTopicalSearchToolConnectIdFailed = () => ({
  type: GET_TOPICAL_SEARCH_TOOL_CONNECT_ID_FAILED
});

export const getTopicalSearchToolConnectId = params => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getTopicalSearchToolConnectIdBegin());

    const { district, compositionId, toolId } = params;

    axios
      .get(
        `${district}/map_portal_composition/${compositionId}/topical_search_tool/${toolId}/`
      )
      .then(response => {
        const connectionId = response.data.id;

        dispatch(
          getTopicalSearchToolConnectIdSuccess({
            ...params,
            connectionId
          })
        );

        resolve(connectionId);
      })
      .catch(err => {
        dispatch(getTopicalSearchToolConnectIdFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const checkGeonetworkSearchToolAvailabilityBegin = () => ({
  type: CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_BEGIN
});

export const checkGeonetworkSearchToolAvailabilitySuccess = payload => ({
  type: CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_SUCCESS,
  payload
});

export const checkGeonetworkSearchToolAvailabilityFailed = () => ({
  type: CHECK_GEONETWORK_SEARCH_TOOL_AVAILABILITY_FAILED
});

export const checkGeonetworkSearchToolAvailability = district => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(checkGeonetworkSearchToolAvailabilityBegin());

    const requestOptions = {
      validateStatus: status =>
        (status >= 200 && status < 300) || status === 400
    };

    axios
      .get(`${district}/geonetwork_status/`, requestOptions)
      .then(response => {
        const payload = { district, available: response.status === 200 };

        dispatch(checkGeonetworkSearchToolAvailabilitySuccess(payload));
        resolve(response.status);
      })
      .catch(err => {
        dispatch(checkGeonetworkSearchToolAvailabilityFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const topicalSearchBegin = () => ({
  type: TOPICAL_SEARCH_BEGIN
});

export const topicalSearchSuccess = (searchResults, count) => ({
  type: TOPICAL_SEARCH_SUCCESS,
  searchResults,
  count
});

export const topicalSearchFailed = () => ({
  type: TOPICAL_SEARCH_FAILED
});

export const topicalSearchTool = (params = {}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(topicalSearchBegin());

    const {
      prefix,
      toolId,
      colsAndVals,
      page,
      map_portal_composition
    } = params;

    if (!prefix || !map_portal_composition || !toolId) {
      reject();
      return;
    }

    axios
      .get(
        `${prefix}/map_portal_composition/${map_portal_composition}/topical_search/${toolId}/`,
        {
          params: { ...colsAndVals, page_size: PAGE_SIZE, page },
          paramsSerializer: queryString.stringify
        }
      )
      .then(({ data: { results, count } }) => {
        dispatch(topicalSearchSuccess(results, count));
        resolve({
          data: results,
          count
        });
      })
      .catch(err => {
        dispatch(topicalSearchFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const fetchTopicalSearchResults = () => ({
  type: FETCH_TOPICAL_SEARCH_RESULTS
});

export const stopFetchTopicalSearchResults = () => ({
  type: STOP_FETCH_TOPICAL_SEARCH_RESULTS
});

export const clearTopicalSearchResults = () => ({
  type: CLEAR_TOPICAL_SEARCH_RESULTS
});

export const setSearchColVal = payload => ({
  type: SET_SEARCHED_COL_VAL,
  payload
});

export const topicalSearchToolGeometry = (props = {}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(topicalSearchGeometryBegin());

    const {
      prefix,
      toolId,
      page,
      map_portal_composition,
      colsAndVals,
      paginationOff
    } = props;

    if (!prefix || !map_portal_composition || !toolId) return reject();

    const params = paginationOff
      ? { pagination_false: true }
      : { page_size: PAGE_SIZE, page };

    axios
      .get(
        `${prefix}/map_portal_composition/${map_portal_composition}/topical_search_geometry/${toolId}/`,
        {
          params: { ...params, ...colsAndVals },
          paramsSerializer: queryString.stringify
        }
      )
      .then(({ data: fetchedData }) => {
        if (paginationOff) {
          const data = fetchedData.features;

          dispatch(topicalSearchGeometryAllSuccess(data));
          resolve({
            data
          });
        }
        if (!paginationOff) {
          const data = fetchedData.results?.features;
          dispatch(topicalSearchGeometrySuccess(data));
          resolve({
            data
          });
        }
      })
      .catch(err => {
        dispatch(topicalSearchGeometryFailed());
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const topicalSearchGeometryBegin = () => ({
  type: TOPICAL_SEARCH_GEOMETRY_BEGIN
});

export const topicalSearchGeometrySuccess = searchResults => ({
  type: TOPICAL_SEARCH_GEOMETRY_SUCCESS,
  searchResults
});

export const topicalSearchGeometryAllSuccess = searchResults => ({
  type: TOPICAL_SEARCH_GEOMETRY_ALL_SUCCESS,
  searchResults
});

export const topicalSearchGeometryFailed = () => ({
  type: TOPICAL_SEARCH_GEOMETRY_FAILED
});

export const clearTopicalSearchGeometryAllResults = () => ({
  type: CLEAR_TOPICAL_SEARCH_GEOMETRY_ALL_RESULTS
});

export const changeTopicalSearchListOrderSuccess = (filtersList, toolId) => ({
  type: CHANGE_TOPICAL_SEARCH_LIST_ORDER_SUCCESS,
  filtersList,
  toolId
});

export const changeTopicalSearchListOrder = ({
  toolsIds,
  toolId,
  translation
}) => dispatch => {
  axios
    .post(`topical_search_tool/${toolId}/lookup_columns/order/`, toolsIds)
    .then(({ data }) => {
      dispatch(changeTopicalSearchListOrderSuccess(data, toolId));
      dispatch(showSuccess(translation));
    })
    .catch(err => {
      dispatch(showError(parseResponseError(err)));
    });
};
