import { useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import LanguageContext from '../../contexts/LanguageContext';

import { defaultLang, sessionStorageKeys } from '../../config';

const LanguageRecognizer = () => {
  const location = useLocation();
  const { language, setLanguage } = useContext(LanguageContext);

  const getSessionStorageLanguage = () =>
    sessionStorage.getItem(sessionStorageKeys.language);

  useEffect(() => {
    if (getSessionStorageLanguage()) {
      return recognizePathAndSetLanguage();
    }

    setLanguage(defaultLang);
  }, [location]);

  const setLanguageToPolish = () => {
    const plCode = 'pl';
    if (language && language !== plCode)
      setLanguage(plCode, { saveToStorage: false });
  };

  const setLanguageFromLocalStorage = () => {
    const sessionStorageLanguage = getSessionStorageLanguage();

    if (language && language !== sessionStorageLanguage) {
      setLanguage(sessionStorageLanguage);
    }
  };

  const recognizePathAndSetLanguage = () => {
    const path = location.pathname;
    let splited = null;
    if (path) splited = path.split('/');
    if (splited.includes('admin')) setLanguageToPolish();
    else setLanguageFromLocalStorage();
  };

  return null;
};

export default LanguageRecognizer;
