import { renderMarkerHtmlIcon } from '../utils/mapUtils';
import { mapMarkerSize } from '../mapConfig';

const html = renderMarkerHtmlIcon(mapMarkerSize);

export const BASIC_MARKER_ICON = {
  html,
  iconSize: Array(2).fill(mapMarkerSize),
  className: 'map-marker'
};
