const createKeysFromObject = object => {
  const keysObject = {};

  Object.keys(object).forEach(key => {
    keysObject[key + 'Key'] = key;
  });

  return keysObject;
};

export default createKeysFromObject;
