import { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import { globalSelectors } from '../../store/selectors';

import { defaultAppTitle } from '../../config';

const { getSystemTitle } = globalSelectors;

const ViewTitle = ({ title = '' }) => {
  const systemTitle = useSelector(getSystemTitle) || defaultAppTitle;

  useEffect(() => {
    document.title = `${systemTitle} - ${title}`;

    return () => {
      document.title = systemTitle;
    };
  }, [systemTitle, title]);

  return null;
};

ViewTitle.propTypes = {
  title: PropTypes.string.isRequired
};

export default ViewTitle;
