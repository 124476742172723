import { useMemo } from 'react';
import { camelCase } from 'lodash';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';

import { districtSelectors } from '@/store/selectors';

import useGlobalSettings from '../useGlobalSettings';

import { getApiUrl, renameObjectKeys } from '@/utils/lib';
import getValuesFromObject from '@/utils/getValuesFromObject';

import { FOOTER_GLOBAL_LOCAL_SETTINGS_VALUES } from './consts';

import { TDistrictSettings } from '@/types/district-settings';
import { TDistrictDetails } from '@/types/responses/district-details';
import { TGlobalSettings } from '@/types/global-settings';
import { TPortalParams } from '@/types/portal-params';
import { TUseFooterGlobalLocalSettings } from './types';

const { getDistrictSettings, getDistrictDetails } = districtSelectors;

const useFooterGlobalLocalSettings = (): TUseFooterGlobalLocalSettings => {
  const { prefix } = useParams<TPortalParams>();

  const globalSettings: TGlobalSettings = useGlobalSettings();
  const districSettings: TDistrictSettings = useSelector(getDistrictSettings);

  const districDetails: TDistrictDetails = useSelector(state =>
    getDistrictDetails(state, prefix)
  ).get('data');

  const rpoLogo = districDetails?.properties?.rpo_logo;
  const globalFooterSettingsEnabled =
    districSettings?.global_footer_settings_enabled;

  const footerValues = useMemo(
    () =>
      globalFooterSettingsEnabled || !prefix
        ? globalSettings
        : { rpo_Logo: `${getApiUrl()}${rpoLogo}`, ...districSettings },
    [
      districSettings,
      globalFooterSettingsEnabled,
      globalSettings,
      prefix,
      rpoLogo
    ]
  );

  const memoizedValues = useMemo(() => {
    const pickedValues = getValuesFromObject(
      FOOTER_GLOBAL_LOCAL_SETTINGS_VALUES,
      footerValues
    );

    return renameObjectKeys(pickedValues, camelCase);
  }, [footerValues]) as TUseFooterGlobalLocalSettings;

  return memoizedValues;
};

export default useFooterGlobalLocalSettings;
