import round from 'lodash/round';

import { coordinatesRoundPrecision } from '../../../mapConfig';

/**
 * Zaokrągla przekazaną współrzędną.
 *
 * @param {number} value - współrzędna do zaokrąglenia
 * @param {number} precision - przybliżona współrzędna
 */
const roundCoordinate = (value, precision = coordinatesRoundPrecision) => {
  if ((value !== 0 && !value) || Number.isNaN(parseInt(value, 10))) {
    return;
  }

  return round(value, precision).toFixed(precision);
};

export default roundCoordinate;
