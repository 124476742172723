const selectors = {
  getShapesDistrict: state => state.sketchbook.get('district'),
  getShapesPortal: state => state.sketchbook.get('portal'),
  getUserId: state => state.sketchbook.get('userId'),
  isLoadingBasicShapes: state => state.sketchbook.get('isLoadingBasicShapes'),
  isLoadingUserDefinedShapes: state =>
    state.sketchbook.get('isLoadingUserDefinedShapes'),
  getBasicShapes: state => state.sketchbook.get('basicShapes'),
  getUserDefinedShapes: state => state.sketchbook.get('userDefinedShapes'),
  isUpdatingShape: state => state.sketchbook.get('isUpdatingShape'),
  isAddingNewShape: state => state.sketchbook.get('isAddingNewShape'),
  isDeletingUserShape: state => state.sketchbook.get('isDeletingUserShape')
};

export default selectors;
