import { Map } from 'immutable';

import {
  DELETE_BI_RAPORT_STARTED,
  DELETE_BI_RAPORT_SUCCESS,
  DELETE_BI_RAPORT_FAILED,
  GET_ALL_BI_RAPORTS_SUCCESS,
  GET_ALL_BI_COMPOSITIONS_SUCCESS,
  CREATE_NEW_BI_RAPORT_SUCCESS,
  DELETE_BI_CONNECTION_FAILED,
  GET_PUBLISHED_BI_RAPORTS_SUCCESS
} from '../constants/biRaports';

export const initialState = Map({
  compositionBIRaports: null,
  allRaports: null,
  idDeletingRaport: null,
  publishedBIRaports: null
});

const deleteBIRaportStarted = (state, { id }) =>
  state.set('idDeletingRaport', id);

const deleteBIRaportSuccess = (state, { id }) => {
  const biRaports = state.get('compositionBIRaports');
  const compositionBIRaports = biRaports.filter(raportId => raportId !== id);
  return state.merge(Map({ idDeletingRaport: null, compositionBIRaports }));
};

const deleteBIRaportFailed = state => state.set('idDeletingRaport', null);

const getAllBIRaportsSuccess = (state, { data }) =>
  state.merge(
    Map({
      allRaports: data.results
    })
  );

const getPublishedBIRaportsSuccess = (state, { data }) =>
  state.set('publishedBIRaports', data);

const getAllBICompositionsSuccess = (state, { data }) =>
  state.merge(
    Map({
      compositionBIRaports: data
    })
  );

const deleteBIConnectionFailed = state => state.set('idDeletingRaport', null);

const createNewBIRaportSuccess = (state, { data }) =>
  state.merge(
    Map({
      allRaports: data.results
    })
  );

export default function reducer(state = initialState, action) {
  return (
    {
      [DELETE_BI_RAPORT_STARTED]: deleteBIRaportStarted,
      [DELETE_BI_RAPORT_SUCCESS]: deleteBIRaportSuccess,
      [DELETE_BI_RAPORT_FAILED]: deleteBIRaportFailed,
      [GET_ALL_BI_RAPORTS_SUCCESS]: getAllBIRaportsSuccess,
      [GET_ALL_BI_COMPOSITIONS_SUCCESS]: getAllBICompositionsSuccess,
      [DELETE_BI_CONNECTION_FAILED]: deleteBIConnectionFailed,
      [CREATE_NEW_BI_RAPORT_SUCCESS]: createNewBIRaportSuccess,
      [GET_PUBLISHED_BI_RAPORTS_SUCCESS]: getPublishedBIRaportsSuccess
    }[action.type] || (s => s)
  )(state, action);
}
