import queryString from 'query-string';

/**
 * Wyciąga wartość wybranego query stringa z przekazanego adresu URL.
 *
 * Przykład:
 * getParamValueFromUrl(http://example.com/page/?number=1&tag=lorem, tag)
 * zwróci wartość „lorem”.
 *
 * @param {string} url
 * @param {string} paramName
 */
const getParamValueFromUrl = (url, paramName) => {
  if (!url || !paramName) return;

  const { query } = queryString.parseUrl(url);
  return query[paramName];
};

export default getParamValueFromUrl;
