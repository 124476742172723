/**
 * Emituje zdarzenie 'onstorage'.
 *
 * @param {string} key
 * @param {any} newData
 * @param {any} oldData
 */
const emitStorageEvent = (key, newData, oldData) => {
  const event = document.createEvent('StorageEvent');

  event.initStorageEvent(
    'storage',
    true,
    false,
    key,
    JSON.stringify(newData),
    JSON.stringify(oldData)
  );

  window.dispatchEvent(event);
};

export default emitStorageEvent;
