import { Suspense } from 'react';
import PropTypes from 'prop-types';
import { camelCase } from 'lodash';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { userSelectors } from '@/store/selectors';

import useGlobalSettings from '../../hooks/useGlobalSettings';
import useTranslations from '../../hooks/useTranslations';

import HandleError from '../HandleError';
import LoaderFullScreen from '../LoaderFullScreen';
import GlobalFooter from '../GlobalFooter';

import { renameObjectKeys } from '../../utils/lib';

import { GLOBAL_TRANSLATIONS } from '../../constants/translations';

import './SuspenseWrapper.less';

const { isUserDataLoading } = userSelectors;

const SuspenseWrapper = ({
  children,
  fallback,
  ignoreFallback,
  showErrorBoard,
  withFooter
}) => {
  const { GENERAL_LOADING } = useTranslations(GLOBAL_TRANSLATIONS);
  const isUserDataLoadingNow = useSelector(isUserDataLoading);
  const globalSettings = useGlobalSettings();

  const { homePageFooterPosition } = renameObjectKeys(
    globalSettings,
    camelCase
  );

  const fallbackComponent =
    ignoreFallback || isUserDataLoadingNow
      ? null
      : fallback || <LoaderFullScreen tip={GENERAL_LOADING} />;

  const isFooterEmbedded = homePageFooterPosition === 'embedded';
  const isFooterEnd = homePageFooterPosition === 'at_the_end';

  const containerClassNames = classNames({
    'footer-embedded-container': isFooterEmbedded,
    'footer-end-container': isFooterEnd
  });

  return (
    <HandleError showBoard={showErrorBoard}>
      <Suspense fallback={fallbackComponent}>
        {!withFooter && children}
        {withFooter && (
          <div class={containerClassNames}>
            {children}
            <GlobalFooter />
          </div>
        )}
      </Suspense>
    </HandleError>
  );
};

SuspenseWrapper.propTypes = {
  children: PropTypes.node,
  showErrorBoard: PropTypes.bool,
  fallback: PropTypes.node,
  ignoreFallback: PropTypes.bool,
  withFooter: PropTypes.bool
};

export default SuspenseWrapper;
