import { parseString as xmlToJs } from 'xml2js';

const axios = require('axios').create();
axios.defaults.baseURL = '';

const getWmtsCapabilities = url =>
  new Promise((resolve, reject) => {
    const params = {
      service: 'WMTS',
      request: 'GetCapabilities'
    };

    axios
      .get(url, { params, crossDomain: true })
      .then(response =>
        xmlToJs(response.data, (err, result) =>
          err ? reject(err) : resolve(result)
        )
      )
      .catch(reject);
  });

export default getWmtsCapabilities;
