import { rolesHierarchy } from '../';

/**
 * Sprawdza czy przekazana rola jest równa lub większa niż przekazana
 * requiredRole.
 *
 * @param {string} role
 * @param {string} requiredRole
 * @return {bool}
 */
const isFillTheRole = (role, requiredRole) => {
  const requiredRoleIndex = rolesHierarchy.indexOf(requiredRole);
  const roleIndex = rolesHierarchy.indexOf(role);

  if (requiredRoleIndex === -1 || roleIndex === -1) {
    return false;
  }

  return roleIndex >= requiredRoleIndex;
};

export default isFillTheRole;
