import { createApi } from '@reduxjs/toolkit/query/react';

import {
  fetchBackgroundWmsFailed,
  fetchBackgroundWmsSuccess
} from '@/store/actions/wmsActions';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import handleApiError from '@/utils/handleApiError';

import { EWmsTags, TFetchBackgroundWmsParams } from '@/types/api-types/wms';
import { TBackgroundWmsResponse } from '@/types/responses/wms';

export const wmsApi = createApi({
  reducerPath: 'wmsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EWmsTags.BACKGROUND_WMS],
  endpoints: builder => ({
    fetchBackgroundWms: builder.query<
      TBackgroundWmsResponse[],
      TFetchBackgroundWmsParams
    >({
      query: ({ district, portalId }) => ({
        method: 'GET',
        url: `${district}/map_portal/${portalId}/background_wms/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          dispatch(fetchBackgroundWmsSuccess(data));
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchBackgroundWmsFailed());
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EWmsTags.BACKGROUND_WMS,
                id: id ?? ''
              })),
              { type: EWmsTags.BACKGROUND_WMS, id: EWmsTags.BACKGROUND_WMS }
            ]
          : [{ type: EWmsTags.BACKGROUND_WMS, id: EWmsTags.BACKGROUND_WMS }]
    })
  }),
  keepUnusedDataFor: 1200
});

export const { useLazyFetchBackgroundWmsQuery } = wmsApi;
