import { Map } from 'immutable';

import {
  FETCH_BASIC_SHAPES_STARTED,
  FETCH_BASIC_SHAPES_SUCCESS,
  FETCH_BASIC_SHAPES_FAILED,
  FETCH_USER_DEFINED_SHAPES_STARTED,
  FETCH_USER_DEFINED_SHAPES_SUCCESS,
  FETCH_USER_DEFINED_SHAPES_FAILED,
  UPDATE_SHAPE_STARTED,
  UPDATE_SHAPE_SUCCESS,
  UPDATE_SHAPE_FAILED,
  ADD_NEW_SHAPE_STARTED,
  ADD_NEW_SHAPE_SUCCESS,
  ADD_NEW_SHAPE_FAILED,
  DELETE_USER_SHAPE_STARTED,
  DELETE_USER_SHAPE_SUCCESS,
  DELETE_USER_SHAPE_FAILED
} from '../constants/sketchbookActionTypes';

export const initialState = Map({
  addShapeModeIsOpen: false,
  district: '',
  portal: '',
  isLoadingBasicShapes: false,
  basicShapes: [],
  userId: null,
  isLoadingUserDefinedShapes: false,
  userDefinedShapes: [],
  isUpdatingShape: false,
  isAddingNewShape: false,
  isDeletingUserShape: false
});

// *** Fetch basic shapes ***
const fetchBasicShapesStarted = state =>
  state.merge(
    Map({
      isLoadingBasicShapes: true,
      basicShapes: []
    })
  );

const fetchBasicShapesSuccess = (state, { district, portal, basicShapes }) =>
  state.merge(
    Map({
      district,
      portal,
      basicShapes,
      isLoadingBasicShapes: false
    })
  );

const fetchBasicShapesFailed = state =>
  state.set('isLoadingBasicShapes', false);

// *** Fetch user defined shapes ***
const fetchUserDefinedShapesStarted = state =>
  state.merge(
    Map({
      isLoadingUserDefinedShapes: true,
      userDefinedShapes: []
    })
  );

const fetchUserDefinedShapesSuccess = (
  state,
  { userId, district, portal, userDefinedShapes }
) =>
  state.merge(
    Map({
      district,
      portal,
      userId,
      userDefinedShapes,
      isLoadingUserDefinedShapes: false
    })
  );

const fetchUserDefinedShapesFailed = state =>
  state.set('isLoadingUserDefinedShapes', false);

// *** Update shape ***
const updateShapeStarted = state => state.set('isUpdatingShape', true);

const updateShapeSuccess = (state, { updatedShape }) => {
  const isBasicShape = 'is_enabled' in updatedShape;

  const shapes = state.get(isBasicShape ? 'basicShapes' : 'userDefinedShapes');
  const updatedShapes = shapes.map(basicShape =>
    basicShape.id === updatedShape.id ? updatedShape : basicShape
  );

  return state.merge(
    Map(
      isBasicShape
        ? {
            isUpdatingShape: false,
            basicShapes: updatedShapes
          }
        : {
            isUpdatingShape: false,
            userDefinedShapes: updatedShapes
          }
    )
  );
};

const updateShapeFailed = state => state.set('isUpdatingShape', false);

// *** Add new shape ***
const addNewShapeStarted = state => state.set('isAddingNewShape', true);

const addNewShapeSuccess = (state, { addedShape }) => {
  const shapes = state.get('userDefinedShapes');
  const userDefinedShapes = [...shapes, addedShape];

  return state.merge(Map({ isAddingNewShape: false, userDefinedShapes }));
};

const addNewShapeFailed = state => state.set('isAddingNewShape', false);

// *** Delete user shape ***
const deleteUserShapeStarted = state => state.set('isDeletingUserShape', true);

const deleteUserShapeSuccess = (state, { shapeId }) => {
  const shapes = state.get('userDefinedShapes');
  const userDefinedShapes = shapes.filter(({ id }) => id !== shapeId);

  return state.merge(Map({ isDeletingUserShape: false, userDefinedShapes }));
};

const deleteUserShapeFailed = state => state.set('isDeletingUserShape', false);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_BASIC_SHAPES_STARTED]: fetchBasicShapesStarted,
      [FETCH_BASIC_SHAPES_SUCCESS]: fetchBasicShapesSuccess,
      [FETCH_BASIC_SHAPES_FAILED]: fetchBasicShapesFailed,
      [FETCH_USER_DEFINED_SHAPES_STARTED]: fetchUserDefinedShapesStarted,
      [FETCH_USER_DEFINED_SHAPES_SUCCESS]: fetchUserDefinedShapesSuccess,
      [FETCH_USER_DEFINED_SHAPES_FAILED]: fetchUserDefinedShapesFailed,
      [UPDATE_SHAPE_STARTED]: updateShapeStarted,
      [UPDATE_SHAPE_SUCCESS]: updateShapeSuccess,
      [UPDATE_SHAPE_FAILED]: updateShapeFailed,
      [ADD_NEW_SHAPE_STARTED]: addNewShapeStarted,
      [ADD_NEW_SHAPE_SUCCESS]: addNewShapeSuccess,
      [ADD_NEW_SHAPE_FAILED]: addNewShapeFailed,
      [DELETE_USER_SHAPE_STARTED]: deleteUserShapeStarted,
      [DELETE_USER_SHAPE_SUCCESS]: deleteUserShapeSuccess,
      [DELETE_USER_SHAPE_FAILED]: deleteUserShapeFailed
    }[action.type] || (s => s)
  )(state, action);
}
