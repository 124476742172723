export const GLOBAL_FOOTER_TRANSLATIONS = {
  TITLE_PROFILE_AVAILABILITY: {
    id: 'title_profile_availability',
    defaultMessage: 'Declaration of availability'
  },
  TITLE_PROFILE_TERMS: {
    id: 'title_profile_terms',
    defaultMessage: 'Terms of use'
  },
  TITLE_PROFILE_PRIVACY_POLICY: {
    id: 'title_profile_privacy_policy',
    defaultMessage: 'Privacy policy'
  }
};
