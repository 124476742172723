/**
 * Zamienia na wielką literę pierwszy znak przekazanego ciągu.
 *
 * @param {string} string
 * @return {string}
 */
const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export default capitalizeFirstLetter;
