const reverseNestedCoordinates = coordinates =>
  coordinates.map(coordinate =>
    Array.isArray(coordinate[0])
      ? reverseCoordinates(coordinate)
      : [...coordinate].reverse()
  );

const reverseCoordinates = coordinates => {
  if (typeof coordinates[0] === 'number') {
    return [...coordinates].reverse();
  }

  return reverseNestedCoordinates(coordinates);
};

export default reverseCoordinates;
