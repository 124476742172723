/* eslint-disable no-console */

export const CustomLogger = {
  log: (...params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(params);
    }
  },
  error: (...params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.error(params);
    }
  },
  warn: (...params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.warn(params);
    }
  },
  group: label => {
    if (process.env.NODE_ENV !== 'production') {
      console.group(label);
    }
  },
  groupEnd: label => {
    if (process.env.NODE_ENV !== 'production') {
      console.groupEnd(label);
    }
  },
  groupCollapsed: (...params) => {
    if (process.env.NODE_ENV !== 'production') {
      console.groupCollapsed(params);
    }
  }
};
