import {
  convertCoordinatesToHDMS,
  transformCoordinates,
  roundCoordinate
} from '../';

const inputCrs = 'EPSG:4326';
const latLngSystems = ['EPSG:4326'];

const getCursorCoordinates = ({
  currentCursorCrs,
  coordinates,
  hideAxis,
  showHDMS,
  reverseHdmsCoordinates = true
}) => {
  if (!currentCursorCrs || !coordinates) return {};

  const processCoordinates = () => {
    const [lat, lng] = transformCoordinates(
      inputCrs,
      currentCursorCrs,
      [...coordinates].reverse()
    );
    return [roundCoordinate(lat, precision), roundCoordinate(lng, precision)];
  };

  const convertToHDMS = coordinates => {
    const { lngDMS, latDMS } = convertCoordinatesToHDMS(coordinates);

    if (reverseHdmsCoordinates) {
      return {
        lng: latDMS,
        lat: lngDMS
      };
    }

    return {
      lng: lngDMS,
      lat: latDMS
    };
  };

  const isLatLngSystem = latLngSystems.includes(currentCursorCrs);
  const precision = isLatLngSystem ? 6 : 2;

  const [lat, lng] = processCoordinates();
  if (!hideAxis) {
    if (isLatLngSystem) {
      const latDirection = lng > 0 ? 'N' : 'S';
      const lngDirection = lat > 0 ? 'E' : 'W';

      if (showHDMS) {
        return convertToHDMS({ lat, lng });
      }

      return {
        lat: `${Math.abs(lng).toPrecision(precision)} ${latDirection}`,
        lng: `${Math.abs(lat).toPrecision(precision)} ${lngDirection}`
      };
    }

    return {
      lat: `X: ${lng}`,
      lng: `Y: ${lat}`
    };
  } else {
    if (showHDMS) {
      return convertToHDMS({ lat, lng });
    }

    return { lat, lng };
  }
};

export default getCursorCoordinates;
