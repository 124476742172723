const regEx = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
  onlyNumbers: /^[0-9]*$/,
  phoneNumber: /^[+][0-9]*$/,
  urlProtocol: /^https?:\/\//,
  login: /^([a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9]\.[a-zA-Z]{2,})?(\\?)(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  moreThanOneSpace: / {2,}/,
  digit: /\d+/,
  multiGeometryType: /^Multi(Point|LineString|Polygon)/,
  onlyAlphabetic: /^\p{L}+$/u
};

export default regEx;
