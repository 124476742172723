const selectors = {
  getIsFetchingSelectionLayers: state => state.selection.get('isFetching'),
  getIsUpdatingSelectionLayers: state => state.selection.get('isUpdating'),
  getSelectionLayersByCompositionId: (state, districtName, compositionId) =>
    state.selection.getIn([
      'selectionLayersConfigs',
      districtName,
      compositionId
    ]),
  getIsAddingPredefinedSelection: state =>
    state.selection.get('isAddingPredefinedSelection'),
  getPredefinedSelectionsByCompositionId: (
    state,
    districtName,
    compositionId
  ) =>
    state.selection.getIn([
      'predefinedSelections',
      districtName,
      compositionId
    ]),
  getIsFetchingPredefinedSelections: state =>
    state.selection.get('isFetchingPredefinedSelections'),
  getIsDeletingPredefinedSelection: state =>
    state.selection.get('isDeletingPredefinedSelection'),
  getIsEditingPredefinedSelection: state =>
    state.selection.get('isEditingPredefinedSelection'),
  getIsIdentificationForSinglePoint: state =>
    state.selection.get('isIdentificationForSinglePoint')
};

export default selectors;
