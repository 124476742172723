import * as L from 'leaflet';
import { toBounds } from 'leaflet/src/geometry/Bounds';

/**
 * Nadpisujemy Leafletową implementację warstwy WMS, bo musimy dodać
 * obsługę dla customowych układów CRS.
 *
 * Import znajduje się w pliku ./src/index.js (żeby nadpisać implementację w
 * całej aplikacji).
 */

L.TileLayer.WMS = L.TileLayer.WMS.extend({
  getTileUrl: function(coords) {
    var tileBounds = this._tileCoordsToNwSe(coords),
      crs = this._crs,
      bounds = toBounds(crs.project(tileBounds[0]), crs.project(tileBounds[1])),
      min = bounds.min,
      max = bounds.max,
      bbox = (this._wmsVersion >= 1.3 &&
      (this._crs === L.CRS.EPSG4326 || this._crs.code === 'EPSG:2180')
        ? [min.y, min.x, max.y, max.x]
        : [min.x, min.y, max.x, max.y]
      ).join(','),
      url = L.TileLayer.prototype.getTileUrl.call(this, coords);

    return (
      url +
      L.Util.getParamString(this.wmsParams, url, this.options.uppercase) +
      (this.options.uppercase ? '&BBOX=' : '&bbox=') +
      bbox
    );
  }
});

L.tileLayer.wms = function(url, options) {
  return new L.TileLayer.WMS(url, options);
};
