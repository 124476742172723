import { Map, List, fromJS } from 'immutable';

import {
  CREATE_USER_BEGIN,
  CREATE_USER_SUCCESS,
  CREATE_USER_FAILED,
  CREATE_USER_RESET,
  USER_LOGIN_FINISHED,
  SET_LOGOUT_PROCESSING,
  USER_LOGOUT_FINISHED,
  FETCH_USERS_BEGIN,
  FETCH_USERS_SUCCESS,
  FETCH_USER_PORTAL_DATA_SUCCESS,
  FETCH_USER_PORTAL_DATA_FAILED,
  USER_LOGIN_BEGINS,
  DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS,
  CREATE_BASIC_ROLE_FOR_USER_SUCCESS,
  FETCH_USER_IMAGE_SUCCESS,
  FETCH_USER_DATA_SUCCESS,
  FETCH_USER_BEGIN,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILED,
  FETCH_ALL_USERS_BEGIN,
  FETCH_ALL_USERS_SUCCESS,
  FETCH_ALL_USERS_FAILED,
  PROMOTE_ADMIN_BEGIN,
  PROMOTE_ADMIN_SUCCESS,
  PROMOTE_ADMIN_FAILED,
  FETCH_ADMINS_SUCCESS,
  DEACTIVATE_USER_SUCCESS,
  SET_NOT_A_ROBOT
} from '../constants/userActionTypes';

import { responseStatuses } from '../../config';

const initialState = Map({
  creatingUser: null,
  isUserEmailVerified: null,
  isUserFetching: true,
  isUserPortalDataFetched: true,
  isGroupsFetching: true,
  userData: null,
  groups: List(),
  userAddress: List(),
  activeUsersOptions: Map(),
  areUsersBeingFetched: false,
  areUsersFetched: false,
  usersData: List(),
  fetchingUser: false,
  fetchedUser: Map(),
  fetchedUsers: Map({}),
  fetchingAllUsers: false,
  promoteAdmin: false,
  adminsList: Map(),
  isLogoutProcessing: false,
  notARobot: false,
  isUserDataLoading: false
});

const createUserBegin = state =>
  state.merge(
    Map({
      creatingUser: responseStatuses.STARTED
    })
  );

const createUserSuccess = state =>
  state.merge(
    Map({
      creatingUser: responseStatuses.SUCCESS
    })
  );

const createUserFailed = state =>
  state.merge(
    Map({
      creatingUser: responseStatuses.FAILED
    })
  );
const createUserReset = state =>
  state.merge(
    Map({
      creatingUser: null
    })
  );
const userLoginFinished = (state, action) =>
  state.merge(
    Map({
      isUserFetching: false,
      userData: fromJS(action.data)
    })
  );

const setLogoutProcessing = state => state.set('isLogoutProcessing', true);

const userLogoutFinished = (state, action) =>
  state.merge(
    Map({
      isUserFetching: false,
      userData: null,
      isLogoutProcessing: false
    })
  );

const usersFetchBegin = state =>
  state.merge(
    Map({
      areUsersBeingFetched: true
    })
  );

const usersFetchSuccess = (state, action) =>
  state.merge(
    Map({
      usersData: List(action.usersData),
      areUsersBeingFetched: false,
      areUsersFetched: true,
      isUserDataLoading: false
    })
  );

const fetchUserPortalDataBegin = state =>
  state.merge(
    Map({
      isUserPortalDataFetched: false,
      isUserDataLoading: true
    })
  );

const fetchUserPortalDataEnd = state =>
  state.merge(
    Map({
      isUserPortalDataFetched: true,
      isUserDataLoading: false
    })
  );

const deleteUserRoleFromDistrictSuccess = (state, action) => {
  return state.updateIn(['userData', 'roles'], List(), list =>
    List(
      list.filter(role => {
        return Number(role.district) !== Number(action.districtId);
      })
    )
  );
};

const createBasicRoleForUserSuccess = (state, action) => {
  return state.updateIn(['userData', 'roles'], List(), list => {
    const newList = [...list];
    newList.push({ name: 'basic', district: action.districtId });
    return fromJS(newList);
  });
};

const fetchUserImageSuccess = (state, action) => {
  return state.setIn(['userData', 'image'], action.image);
};

const fetchUserData = (state, action) =>
  state.merge(Map({ userData: fromJS(action.userData) }));

const fetchUserBegin = state => state.set('fetchingUser', true);
const fetchUserSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingUser: false,
      fetchedUser: Map(action.user)
    })
  );
const fetchUserEnd = state => state.set('fetchingUser', false);
const addUserToList = (state, action) => {
  const fetchedUsers = state.get('fetchedUsers').toJS();
  const user = action.user;

  if (!(user.id in Object.keys(fetchedUsers))) {
    return state.update('fetchedUsers', Map(), map =>
      Map({ ...map.toJS(), [user.id]: user })
    );
  }
  return state;
};

const fetchAllUsersBegin = state => state.set('fetchingAllUsers', true);
const fetchAllUsersEnd = state => state.set('fetchingAllUsers', false);

const promoteAdminBegin = state => state.set('promoteAdmin', true);
const promoteAdminEnd = state => state.set('promoteAdmin', false);

const adminsFetchSuccess = (state, action) =>
  state.set('adminsList', action.adminsList);

const deleteUserSuccess = (state, action) => {
  return state.update('usersData', List(), list =>
    List(
      list.filter(user => {
        return Number(user.id) !== Number(action.userId);
      })
    )
  );
};

const setNotARobot = state => state.merge(Map({ notARobot: true }));

export default function reducer(state = initialState, action) {
  return (
    {
      [CREATE_USER_BEGIN]: createUserBegin,
      [CREATE_USER_SUCCESS]: createUserSuccess,
      [CREATE_USER_FAILED]: createUserFailed,
      [CREATE_USER_RESET]: createUserReset,
      [USER_LOGIN_FINISHED]: userLoginFinished,
      [SET_LOGOUT_PROCESSING]: setLogoutProcessing,
      [USER_LOGOUT_FINISHED]: userLogoutFinished,
      [FETCH_USERS_BEGIN]: usersFetchBegin,
      [FETCH_USERS_SUCCESS]: usersFetchSuccess,
      [USER_LOGIN_BEGINS]: fetchUserPortalDataBegin,
      [FETCH_USER_PORTAL_DATA_SUCCESS]: fetchUserPortalDataEnd,
      [FETCH_USER_PORTAL_DATA_FAILED]: fetchUserPortalDataEnd,
      [DELETE_USER_ROLE_FROM_DISTRICT_SUCCESS]: deleteUserRoleFromDistrictSuccess,
      [CREATE_BASIC_ROLE_FOR_USER_SUCCESS]: createBasicRoleForUserSuccess,
      [FETCH_USER_IMAGE_SUCCESS]: fetchUserImageSuccess,
      [FETCH_USER_DATA_SUCCESS]: fetchUserData,
      [FETCH_USER_BEGIN]: fetchUserBegin,
      [FETCH_USER_SUCCESS]: fetchUserSuccess,
      [FETCH_USER_SUCCESS]: addUserToList,
      [FETCH_USER_FAILED]: fetchUserEnd,
      [FETCH_ALL_USERS_BEGIN]: fetchAllUsersBegin,
      [FETCH_ALL_USERS_SUCCESS]: fetchAllUsersEnd,
      [FETCH_ALL_USERS_FAILED]: fetchAllUsersEnd,
      [PROMOTE_ADMIN_BEGIN]: promoteAdminBegin,
      [PROMOTE_ADMIN_SUCCESS]: promoteAdminEnd,
      [PROMOTE_ADMIN_FAILED]: promoteAdminEnd,
      [FETCH_ADMINS_SUCCESS]: adminsFetchSuccess,
      [DEACTIVATE_USER_SUCCESS]: deleteUserSuccess,
      [SET_NOT_A_ROBOT]: setNotARobot
    }[action.type] || (s => s)
  )(state, action);
}
