import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { globalSelectors } from '../../store/selectors';

import loader from '../../images/spinners/loader.gif';
import Timer from '../Timer';

import './Loader.less';

const { getGlobalSettings } = globalSelectors;

const Loader = ({ inverse, style, tip, showTimer = true }) => {
  const globalSettings = useSelector(state => getGlobalSettings(state));

  const [loaderSrc, setLoaderSrc] = useState(
    localStorage?.getItem('system_loader')
  );

  const savedLoaderBE = globalSettings.get('system_loader');

  useEffect(() => {
    const savedLoaderLS = localStorage.getItem('system_loader');

    if (savedLoaderBE !== savedLoaderLS) {
      localStorage.setItem('system_loader', savedLoaderBE);
      setLoaderSrc(savedLoaderBE);
    }

    if (!savedLoaderLS || savedLoaderLS === 'null') {
      localStorage.setItem('system_loader', loader);
      setLoaderSrc(loader);
    }
  }, [savedLoaderBE]);

  const title = (
    <FormattedMessage id="general_loading_time" defaultMessage="Loading time" />
  );

  return (
    <div
      className={['loader', inverse ? 'loader-inverse' : ''].join(' ')}
      style={style || {}}
    >
      {loaderSrc && <img src={loaderSrc} alt="Trwa wczytywanie..." />}
      <span className="tip">{tip}</span>
      {showTimer && <Timer title={title} />}
    </div>
  );
};

Loader.propTypes = {
  tip: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  inverse: PropTypes.bool,
  style: PropTypes.object
};

export default Loader;
