import axios from 'axios';

import { locationApi } from '../api/location';

import { showError, showSuccess } from './globalActions';
import {
  DELETE_LOCATION_SUCCESS,
  DELETE_LOCATION_STARTED,
  DELETE_LOCATION_FAILED,
  GET_ALL_LOCATIONS_SUCCESS,
  ADD_LOCATION_FAILED,
  ADD_LOCATION_SUCCESS,
  ADD_LOCATION_STARTED,
  EDIT_LOCATION_FAILED,
  EDIT_LOCATION_STARTED,
  EDIT_LOCATION_SUCCESS,
  GET_SINGLE_LOCATION_STARTED,
  GET_SINGLE_LOCATION_SUCCESS,
  GET_SINGLE_LOCATION_FAILED
} from '../constants/locations';

export const getAllLocationsSuccess = data => {
  return {
    type: GET_ALL_LOCATIONS_SUCCESS,
    data
  };
};

const deleteLocationStarted = () => ({
  type: DELETE_LOCATION_STARTED
});

const deleteLocationSuccess = ({ id }) => ({
  type: DELETE_LOCATION_SUCCESS,
  id
});

const deleteLocationFailed = () => ({
  type: DELETE_LOCATION_FAILED
});

export const deleteLocation = (id, district) => dispatch => {
  dispatch(deleteLocationStarted());
  axios
    .delete(`${district}/defect_location_limit/${id}`)
    .then(data => {
      dispatch(deleteLocationSuccess(data));
      locationApi.endpoints.fetchAllLocations.initiate({ district });
      dispatch(showSuccess('Usunięto lokalizacje'));
    })
    .catch(() => {
      dispatch(deleteLocationFailed());
    });
};

const addLocationStarted = () => ({
  type: ADD_LOCATION_STARTED
});

const addLocationFailed = () => ({
  type: ADD_LOCATION_FAILED
});

const addLocationSuccess = data => {
  return {
    type: ADD_LOCATION_SUCCESS,
    data
  };
};

export const addLocation = (data, prefix) => dispatch => {
  dispatch(addLocationStarted());
  axios
    .post(`${prefix}/defect_location_limit/`, data)
    .then(data => {
      dispatch(addLocationSuccess());
      locationApi.endpoints.fetchAllLocations.initiate({ district: prefix });
      dispatch(showSuccess('Dodano nową lokalizacje'));
    })
    .catch(() => {
      dispatch(addLocationFailed());
      dispatch(showError('Niepoprawny format pliku'));
    });
};

const editLocationStarted = () => ({
  type: EDIT_LOCATION_STARTED
});

const editLocationFailed = () => ({
  type: EDIT_LOCATION_FAILED
});

const editLocationSuccess = data => {
  return {
    type: EDIT_LOCATION_SUCCESS,
    data
  };
};

export const editLocation = (data, prefix, id) => dispatch => {
  dispatch(editLocationStarted());

  axios
    .put(`${prefix}/defect_location_limit/${id}/`, data)
    .then(data => {
      dispatch(editLocationSuccess());
      locationApi.endpoints.fetchAllLocations.initiate({ district: prefix });
      dispatch(showSuccess('Edycja przebiegła pomyślnie'));
    })
    .catch(() => {
      dispatch(editLocationFailed());
    });
};

const getSingleLocationStarted = () => ({
  type: GET_SINGLE_LOCATION_STARTED
});

const getSingleLocationFailed = () => ({
  type: GET_SINGLE_LOCATION_FAILED
});

const getSingleLocationSuccess = data => {
  return {
    type: GET_SINGLE_LOCATION_SUCCESS,
    data
  };
};

export const getSingleLocation = (
  prefix,
  filteredLocationId
) => async dispatch => {
  dispatch(getSingleLocationStarted());

  try {
    const response = await axios.get(
      `${prefix}/defect_location_limit/${filteredLocationId}/`
    );
    dispatch(getSingleLocationSuccess(response.data));
    return response;
  } catch (error) {
    dispatch(getSingleLocationFailed());
  }
};
