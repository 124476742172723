import axios from 'axios';

const getFileDetails = config =>
  new Promise((resolve, reject) => {
    const { district, fileId } = config;

    axios
      .get(`${district}/get_file_detail/${fileId}/`)
      .then(response => {
        resolve(response.data);
      })
      .catch(err => {
        reject(err);
      });
  });

export default getFileDetails;
