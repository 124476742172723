/**
 * Funkcja wykorzystywana do wyszukiwania w obiekcie kompozycji
 * grupy lub warstwy na podstawie wskazanego pola.
 *
 * @param {object} data - obiekt z entries kompozycji
 * @param {string|number} expectedValue - oczekiwana wartość pola po
 * którym przeszukiwana jest kompozycji
 * @param {string} property - nazwa pola po którym ma być przeszukiwana
 * kompozycja (domyślnie "id")
 */

import removeProjectIdFromLayerId from '../../mapUtils/removeProjectIdFromLayerId';

export default function findEntryInComposition(
  data,
  expectedValue,
  property = 'id'
) {
  if (!expectedValue) return data;

  let entry;

  data.forEach(item => {
    if (entry) return;

    const currentItem =
      property === 'layerid' || property === 'groupid'
        ? removeProjectIdFromLayerId(item[property])
        : item[property];

    if (expectedValue === currentItem) {
      return (entry = item);
    }

    const { entries } = item;
    if (entries) {
      entry = findEntryInComposition(entries, expectedValue, property);
    }
  });

  return entry;
}
