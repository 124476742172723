import { Map } from 'immutable';

import {
  DELETE_INVESTMENT_FAILED,
  DELETE_INVESTMENT_REQUEST,
  DELETE_INVESTMENT_SUCCESS,
  GET_SAVED_INVESTMENTS_FAILED,
  GET_SAVED_INVESTMENTS_REQUEST,
  GET_SAVED_INVESTMENTS_SUCCESS,
  SAVE_INVESTMENT_FAILED,
  SAVE_INVESTMENT_REQUEST,
  SAVE_INVESTMENT_SUCCESS
} from '../constants/investorActionTypes';

export const initialState = Map({
  savedInvestments: Map({}),
  fetchingSavedInvestments: false,
  savingInvestment: false,
  deletingInvestment: false
});

const getSavedInvestmentsRequest = state =>
  state.set('fetchingSavedInvestments', true);

const getSavedInvestmentsSuccess = (state, { payload }) =>
  state
    .set('fetchingSavedInvestments', false)
    .set('savedInvestments', Map(payload));

const getSavedInvestmentsFailed = state =>
  state.set('fetchingSavedInvestments', false).set('savedInvestments', Map({}));

const saveInvestmentRequest = state => state.set('savingInvestment', true);
const saveInvestmentSuccess = state => state.set('savingInvestment', false);
const saveInvestmentFailed = state => state.set('savingInvestment', false);

const deleteInvestmentStart = state => state.set('deletingInvestment', true);
const deleteInvestmentSuccess = (state, { payload }) => {
  const savedInvestments = state.get('savedInvestments').toJS();
  const newInvestments = savedInvestments.results.features.filter(
    ({ id }) => id !== payload
  );
  savedInvestments.results.features = [...newInvestments];

  return state
    .set('deletingInvestment', false)
    .set('savedInvestments', Map(savedInvestments));
};
const deleteInvestmentEnd = state => state.set('deletingInvestment', false);

export default function reducer(state = initialState, action) {
  return (
    {
      [GET_SAVED_INVESTMENTS_REQUEST]: getSavedInvestmentsRequest,
      [GET_SAVED_INVESTMENTS_SUCCESS]: getSavedInvestmentsSuccess,
      [GET_SAVED_INVESTMENTS_FAILED]: getSavedInvestmentsFailed,
      [SAVE_INVESTMENT_REQUEST]: saveInvestmentRequest,
      [SAVE_INVESTMENT_SUCCESS]: saveInvestmentSuccess,
      [SAVE_INVESTMENT_FAILED]: saveInvestmentFailed,
      [DELETE_INVESTMENT_REQUEST]: deleteInvestmentStart,
      [DELETE_INVESTMENT_SUCCESS]: deleteInvestmentSuccess,
      [DELETE_INVESTMENT_FAILED]: deleteInvestmentEnd
    }[action.type] || (s => s)
  )(state, action);
}
