import { Map } from 'immutable';

import { GET_ALL_LOCATIONS_SUCCESS } from '../constants/locations';

export const initialState = Map({
  allLocations: null
});

const getAllLocationsSuccess = (state, { data: { features } }) =>
  state.merge(
    Map({
      allLocations: features
    })
  );

export default function reducer(state = initialState, action) {
  return (
    {
      [GET_ALL_LOCATIONS_SUCCESS]: getAllLocationsSuccess
    }[action.type] || (s => s)
  )(state, action);
}
