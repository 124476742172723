export const FETCH_PROJECT_CACHE_LOGS_BEGIN = 'FETCH_PROJECT_CACHE_LOGS_BEGIN';
export const FETCH_PROJECT_CACHE_LOGS_SUCCESS =
  'FETCH_PROJECT_CACHE_LOGS_SUCCESS';
export const FETCH_PROJECT_CACHE_LOGS_FAILED =
  'FETCH_PROJECT_CACHE_LOGS_FAILED';

export const DELETE_CACHE_UPDATE_LOG_BEGIN = 'DELETE_CACHE_UPDATE_LOG_BEGIN';
export const DELETE_CACHE_UPDATE_LOG_SUCCESS =
  'DELETE_CACHE_UPDATE_LOG_SUCCESS';
export const DELETE_CACHE_UPDATE_LOG_FAILED = 'DELETE_CACHE_UPDATE_LOG_FAILED';

export const FETCH_PROJECTS_CACHE_LOGS_BEGIN =
  'FETCH_PROJECTS_CACHE_LOGS_BEGIN';
export const FETCH_PROJECTS_CACHE_LOGS_SUCCESS =
  'FETCH_PROJECTS_CACHE_LOGS_SUCCESS';
export const FETCH_PROJECTS_CACHE_LOGS_FAILED =
  'FETCH_PROJECTS_CACHE_LOGS_FAILED';

export const CLEAR_PROJECTS_CACHE_LOGS = 'CLEAR_PROJECTS_CACHE_LOGS';
export const CLEAR_SINGLE_PROJECT_CACHE_LOGS =
  'CLEAR_SINGLE_PROJECT_CACHE_LOGS';
