import { rolesHierarchy } from '../';

/**
 * Zwraca rolę dla podanego id.
 *
 * @param {number} id
 * @return {string} - basic_plus
 */
const getRoleById = id => rolesHierarchy[id];

export default getRoleById;
