import getFileDetails from '../../../services/getFileDetails';

const getFilePathFromUrl = async (fileUrl, district) => {
  /**
   * Pobiera ścieżkę z samby dla plików ustawionych w obiekcie kompozycji
   * @param {string} fileUrl np. "/media/files/770c0e4c-0099-40ac-8663-7b8e04d25865", wartosc pobrana z obiektu kompozycji (this.props.composition.thumbnail_image)
   * @param {string} district prefix jednostki
   */
  if (!fileUrl) return;

  try {
    const fileId = fileUrl.substring(fileUrl.lastIndexOf('/') + 1);
    const { path } = await getFileDetails({
      district,
      fileId
    });
    return path;
  } catch (err) {
    throw err;
  }
};

export default getFilePathFromUrl;
