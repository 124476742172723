import { createApi } from '@reduxjs/toolkit/query/react';
import queryString from 'query-string';

import { parcelsApi } from '@/store/api/parcels';

import {
  desktopFetchPopupConfigsBegin,
  desktopFetchPopupConfigsFailed,
  desktopFetchPopupConfigsSuccess,
  fetchPopupLayersConfigsSuccess,
  openInformationModal,
  setCoordinatesToIdentification,
  setIsQueryStringPopup,
  setMultipleCoordinatesToIdentification,
  updatePlotListInformation
} from '@/store/actions/popupActions';
import { showError } from '@/store/actions/globalActions';
import { setSelectedDefaultParcels } from '@/store/actions/mapActions';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import handleApiError from '@/utils/handleApiError';
import matomoTrackPopupEvent from '@/utils/matomo/trackPopupEvent';
import gaTrackPopupEvent from '@/utils/googleAnalytics/trackPopupEvent';
import getCoordinatesFromParcelResponse from '@/utils/getCoordinatesFromParcelResponse';
import sortIds from '@/utils/sortIds';
import isNotFoundRequestError from '@/utils/isNotFoundRequestError';

import { TDjangoModelsDataResponse } from '@/types/responses/django-models-data-response';
import { TContentTypeInfoResponse } from '@/types/responses/content-type-info-response';
import { TDefaultLayerConfig } from '@/types/responses/default-layer-config';
import { TDesktopLayerConfig } from '@/types/responses/desktop-layer-config';
import { TJoinsResponse } from '@/types/responses/joins-response';
import { TCoverPath } from '@/types/responses/cover-path-response';
import { TAttachment } from '@/types/responses/attachments';
import { TSetParcels } from '@/types/responses/set-parcels';
import {
  TFeatureInfoDataCollection,
  TFeatureInfoDataResponse
} from '@/types/responses/feature-info-response';
import {
  EPopupTags,
  TFeatureInfoLayersQuery,
  TFetchAttachmentsParams,
  TFetchContentTypeInfoParams,
  TFetchCoverPath,
  TFetchDjangoModelsDataParams,
  TFetchFeatureInfoDataParams,
  TFetchFeatureInfoJoinsParams,
  TFetchFeatureInfoLayersParams,
  TGetSharedPlotsIdentification,
  TParsedDefaultLayerConfig,
  TSharePlotsIdentification,
  TTransformedDjangoModelsDataResponse,
  TTransformedFeatureInfoDataResponse
} from '@/types/api-types/popup';

export const popupApi = createApi({
  reducerPath: 'popupApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EPopupTags.LAYER_CONFIG,
    EPopupTags.FEATURE_INFO_DATA,
    EPopupTags.FEATURE_INFO_JOINS,
    EPopupTags.COVER_PATH,
    EPopupTags.ATTACHMENTS,
    EPopupTags.DJANGO_MODELS_LAYERS,
    EPopupTags.DJANGO_MODELS_DATA,
    EPopupTags.CONTENT_TYPE_INFO,
    EPopupTags.FEATURE_INFO_LAYERS,
    EPopupTags.ALL_FEATURE_INFO_LAYERS,
    EPopupTags.SHARE_PLOTS_IDENTIFICATION
  ],
  endpoints: builder => ({
    fetchPopupLayersConfigs: builder.query<TDefaultLayerConfig[], undefined>({
      query: () => ({
        method: 'GET',
        url: `layer_config/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;
          dispatch(fetchPopupLayersConfigsSuccess(data));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EPopupTags.LAYER_CONFIG,
                id: id ?? ''
              })),
              { type: EPopupTags.LAYER_CONFIG, id: EPopupTags.LAYER_CONFIG }
            ]
          : [{ type: EPopupTags.LAYER_CONFIG, id: EPopupTags.LAYER_CONFIG }]
    }),
    fetchFeatureInfoLayers: builder.query<
      TDesktopLayerConfig[],
      TFetchFeatureInfoLayersParams
    >({
      query: ({ district, compositionId, params }) => ({
        method: 'GET',
        url: `${district}/map_portal_composition/${compositionId}/featureinfo_layers/`,
        params
      }),
      transformResponse: (configs: TDesktopLayerConfig[]) => {
        const configsWithSortedAttributes = configs.map(config => {
          const { attributes } = config;

          return {
            ...config,
            attributes: attributes.sort((a, b) => a.index - b.index)
          };
        });

        const sortedConfigs = configsWithSortedAttributes.sort(
          (a, b) => a.index - b.index
        );

        return sortedConfigs;
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
          dispatch(setIsQueryStringPopup(false));
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EPopupTags.FEATURE_INFO_LAYERS,
                id
              })),
              {
                type: EPopupTags.FEATURE_INFO_LAYERS,
                id: EPopupTags.FEATURE_INFO_LAYERS
              }
            ]
          : [
              {
                type: EPopupTags.FEATURE_INFO_LAYERS,
                id: EPopupTags.FEATURE_INFO_LAYERS
              }
            ]
    }),
    fetchFeatureInfoData: builder.query<
      TTransformedFeatureInfoDataResponse,
      TFetchFeatureInfoDataParams
    >({
      query: ({ district, projectId, mapPortalCompositionId, data }) => ({
        method: 'POST',
        url: `${district}/get_feature_info/${projectId}/${mapPortalCompositionId}/`,
        data
      }),
      transformResponse: (
        response: TFeatureInfoDataResponse | TFeatureInfoDataCollection
      ): TTransformedFeatureInfoDataResponse => {
        if ('results' in response) {
          const {
            results: { features },
            count
          } = response;

          return {
            count,
            results: features
          };
        }

        const { features } = response;

        return {
          count: features?.length,
          results: features
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: response => {
        const results = response?.results;

        if (!results) {
          return [
            {
              type: EPopupTags.FEATURE_INFO_DATA,
              id: EPopupTags.FEATURE_INFO_DATA
            }
          ];
        }

        return [
          ...results.map(({ id }) => ({
            type: EPopupTags.FEATURE_INFO_DATA,
            id
          })),
          {
            type: EPopupTags.FEATURE_INFO_DATA,
            id: EPopupTags.FEATURE_INFO_DATA
          }
        ];
      }
    }),
    fetchFeatureInfoJoins: builder.query<
      TJoinsResponse,
      TFetchFeatureInfoJoinsParams
    >({
      query: ({ district, mapPortalCompositionId, layerConfigId }) => ({
        method: 'GET',
        url: `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: response =>
        response?.length
          ? [
              ...response.map(({ id }) => ({
                type: EPopupTags.FEATURE_INFO_JOINS,
                id
              })),
              {
                type: EPopupTags.FEATURE_INFO_JOINS,
                id: EPopupTags.FEATURE_INFO_JOINS
              }
            ]
          : [
              {
                type: EPopupTags.FEATURE_INFO_JOINS,
                id: EPopupTags.FEATURE_INFO_JOINS
              }
            ]
    }),
    fetchCoverPath: builder.query<TCoverPath, TFetchCoverPath>({
      query: ({
        isDjangoModel = false,
        district,
        mapPortalId,
        layerId,
        objectId,
        coverId
      }) => {
        const layerPath = isDjangoModel ? 'layer_config' : 'layer';

        return {
          method: 'GET',
          url: `${district}/map_portal_composition/${mapPortalId}/${layerPath}/${layerId}/object/${objectId}/object_attachments/${coverId}/`
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              {
                type: EPopupTags.COVER_PATH,
                id: result.file.url
              },
              { type: EPopupTags.COVER_PATH, id: EPopupTags.COVER_PATH }
            ]
          : [{ type: EPopupTags.COVER_PATH, id: EPopupTags.COVER_PATH }]
    }),
    fetchAttachments: builder.query<TAttachment[], TFetchAttachmentsParams>({
      query: ({
        isDjangoModel = false,
        district,
        mapPortalId,
        layerId,
        objectId
      }) => {
        const layerPath = isDjangoModel ? 'layer_config' : 'layer';

        return {
          method: 'GET',
          url: `${district}/map_portal_composition/${mapPortalId}/${layerPath}/${layerId}/object/${objectId}/object_attachments/`
        };
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ pk: id }) => ({
                type: EPopupTags.ATTACHMENTS,
                id
              })),
              { type: EPopupTags.ATTACHMENTS, id: EPopupTags.ATTACHMENTS }
            ]
          : [{ type: EPopupTags.ATTACHMENTS, id: EPopupTags.ATTACHMENTS }]
    }),
    fetchDjangoModelsLayers: builder.query<
      TParsedDefaultLayerConfig[],
      TFetchFeatureInfoLayersParams
    >({
      query: ({ district, compositionId, params }) => ({
        method: 'GET',
        url: `${district}/pointed_layer_config/${compositionId}/`,
        params
      }),
      transformResponse: (
        configs: TDefaultLayerConfig[]
      ): TParsedDefaultLayerConfig[] => {
        const configsWithSortedAttributes = configs.map(config => {
          const { attrs, ...restConfig } = config;

          return {
            ...restConfig,
            attributes: attrs
              .sort((a, b) => a.index - b.index)
              .map(attribute => ({
                ...attribute,
                name: attribute.displayable_name
              }))
          };
        });

        const sortedConfigs = configsWithSortedAttributes.sort(
          (a, b) => b.index - a.index
        );

        return sortedConfigs;
      },
      onQueryStarted: async (
        { district, compositionId, params },
        { dispatch, queryFulfilled }
      ) => {
        const { lat, lng } = params;
        const popupEventUrl = `/api/v1/${district}/pointed_layer_config/${compositionId}/?lat=${lat}&lng=${lng}`;

        matomoTrackPopupEvent(popupEventUrl);
        gaTrackPopupEvent(popupEventUrl);

        try {
          await queryFulfilled;
          dispatch(setIsQueryStringPopup(false));
        } catch (error) {
          // It is essential to hide the error display when no configuration
          // is added to the map composition (error with status 404).
          if (isNotFoundRequestError(error)) {
            return;
          }

          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EPopupTags.DJANGO_MODELS_LAYERS,
                id
              })),
              {
                type: EPopupTags.DJANGO_MODELS_LAYERS,
                id: EPopupTags.DJANGO_MODELS_LAYERS
              }
            ]
          : [
              {
                type: EPopupTags.DJANGO_MODELS_LAYERS,
                id: EPopupTags.DJANGO_MODELS_LAYERS
              }
            ]
    }),
    fetchDjangoModelsDataResponse: builder.query<
      TTransformedDjangoModelsDataResponse,
      TFetchDjangoModelsDataParams
    >({
      query: ({ district, objectId, params }) => ({
        method: 'GET',
        url:
          `${district}/${
            'parcels' in params ? 'parcels' : 'pointed'
          }_layer_object/${objectId}?` +
          // It's necessary to pass parcels array in correct format
          queryString.stringify(params, {
            arrayFormat: 'comma'
          })
      }),
      transformResponse: ({
        results,
        count
      }: TDjangoModelsDataResponse): TTransformedDjangoModelsDataResponse => ({
        count,
        results: results.features
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.results.map(({ id }) => ({
                type: EPopupTags.DJANGO_MODELS_DATA,
                id
              })),
              {
                type: EPopupTags.DJANGO_MODELS_DATA,
                id: EPopupTags.DJANGO_MODELS_DATA
              }
            ]
          : [
              {
                type: EPopupTags.DJANGO_MODELS_DATA,
                id: EPopupTags.DJANGO_MODELS_DATA
              }
            ]
    }),
    fetchContentTypeInfo: builder.query<
      TContentTypeInfoResponse,
      TFetchContentTypeInfoParams
    >({
      query: ({ data }) => ({
        method: 'POST',
        url: 'content_type_info/',
        data
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }) => ({
                type: EPopupTags.CONTENT_TYPE_INFO,
                id
              })),
              {
                type: EPopupTags.CONTENT_TYPE_INFO,
                id: EPopupTags.CONTENT_TYPE_INFO
              }
            ]
          : [
              {
                type: EPopupTags.CONTENT_TYPE_INFO,
                id: EPopupTags.CONTENT_TYPE_INFO
              }
            ]
    }),
    fetchAllFeatureInfoLayers: builder.query<
      TDesktopLayerConfig[],
      TFeatureInfoLayersQuery
    >({
      query: ({ district, compositionId }) => ({
        method: 'GET',
        url: `${district}/map_portal_composition/${compositionId}/featureinfo_layers/`
      }),
      onQueryStarted: async (
        { district, compositionId },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(desktopFetchPopupConfigsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(
            desktopFetchPopupConfigsSuccess(district, compositionId, data)
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(desktopFetchPopupConfigsFailed());
          dispatch(
            showError(
              "Wystąpił błąd podczas pobierania listy konfiguracji popup'a"
            )
          );
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EPopupTags.ALL_FEATURE_INFO_LAYERS,
                id: id ?? ''
              })),
              {
                type: EPopupTags.ALL_FEATURE_INFO_LAYERS,
                id: EPopupTags.ALL_FEATURE_INFO_LAYERS
              }
            ]
          : [
              {
                type: EPopupTags.ALL_FEATURE_INFO_LAYERS,
                id: EPopupTags.ALL_FEATURE_INFO_LAYERS
              }
            ]
    }),
    sharePlotsIdentification: builder.query<
      TSetParcels,
      TSharePlotsIdentification
    >({
      query: ({ parcels }) => ({
        method: 'POST',
        url: `parcel_set/`,
        data: { parcels }
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              {
                type: EPopupTags.SHARE_PLOTS_IDENTIFICATION,
                id: result.id
              },
              {
                type: EPopupTags.SHARE_PLOTS_IDENTIFICATION,
                id: EPopupTags.SHARE_PLOTS_IDENTIFICATION
              }
            ]
          : [
              {
                type: EPopupTags.SHARE_PLOTS_IDENTIFICATION,
                id: EPopupTags.SHARE_PLOTS_IDENTIFICATION
              }
            ]
    }),
    getSharedPlotsIdentification: builder.query<
      TSetParcels,
      TGetSharedPlotsIdentification
    >({
      query: ({ id }) => ({
        method: 'GET',
        url: `parcel_set/${id}/`
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        try {
          const {
            data: { parcels }
          } = await queryFulfilled;

          const sortedPlotsList = sortIds(parcels);

          const parcelsGeoJson = await dispatch(
            parcelsApi.endpoints.fetchParcelsData.initiate({
              prefix: district,
              parcels: sortedPlotsList
            })
          ).unwrap();

          dispatch(setSelectedDefaultParcels(parcelsGeoJson));
          dispatch(updatePlotListInformation(sortedPlotsList));

          const coordinatesToIdentification = parcelsGeoJson.map(item =>
            getCoordinatesFromParcelResponse(item)
          );

          dispatch(
            setMultipleCoordinatesToIdentification(coordinatesToIdentification)
          );

          const firstCoordinatesToIdentification =
            coordinatesToIdentification[0];

          if (firstCoordinatesToIdentification) {
            dispatch(
              setCoordinatesToIdentification(firstCoordinatesToIdentification)
            );
          }

          dispatch(openInformationModal());
        } catch (error) {
          handleApiError(error, dispatch);
        }
      }
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchPopupLayersConfigsQuery,
  useLazyFetchFeatureInfoLayersQuery,
  useLazyFetchFeatureInfoDataQuery,
  useLazyFetchFeatureInfoJoinsQuery,
  useLazyFetchCoverPathQuery,
  useLazyFetchAttachmentsQuery,
  useLazyFetchDjangoModelsLayersQuery,
  useLazyFetchDjangoModelsDataResponseQuery,
  useLazyFetchContentTypeInfoQuery,
  useLazySharePlotsIdentificationQuery
} = popupApi;
