import centerOfMass from '@turf/center-of-mass';

import { TParcelDataItem } from '@/types/responses/parcels-data';
import { TPositionCoordinates } from '@/types/map-geometries';

const getCoordinatesFromParcelResponse = (
  item: TParcelDataItem
): TPositionCoordinates => {
  const { geometry } = item;
  const centeredPoint = centerOfMass(geometry);
  const [lng, lat] = centeredPoint.geometry.coordinates;

  return {
    lat,
    lng
  };
};

export default getCoordinatesFromParcelResponse;
