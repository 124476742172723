/**
 * Zwaraca url do mapproxy
 *
 * Przydatne gdy nie mamy pewności czy
 * w envie będzie ustawiona zmienna REACT_APP_MAPPROXY
 *
 */

const getMapproxyUrl = () => {
  const envVariable = process.env.REACT_APP_MAPPROXY;
  if (!envVariable) return window.location.origin;
  return envVariable;
};

export default getMapproxyUrl;
