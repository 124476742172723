import L from 'leaflet';
import 'proj4leaflet';

const getLeafletCrsFromGrid = ({
  crs_def: { crs, proj4text },
  scales,
  origin,
  bbox_xmax,
  bbox_xmin,
  bbox_ymax,
  bbox_ymin
}) =>
  new L.Proj.CRS(crs, proj4text, {
    resolutions: scales.map(({ resolution }) => +resolution).reverse(),
    origin: origin.split(',').map(origin => +origin),
    bounds: L.bounds([bbox_ymin, bbox_xmin], [bbox_ymax, bbox_xmax])
  });

export default getLeafletCrsFromGrid;
