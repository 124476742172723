import L from 'leaflet';

/**
 *
 * @param {LatLng} pointFrom
 * @param {LatLng} pointTo
 *
 * Funkcja obliczajaca odleglosc w linii prostej pomiedzy dwoma punktami
 */
const getDistance = (pointFrom, pointTo) => {
  const markerFrom = L.circleMarker([pointFrom.lat, pointFrom.lng]);
  const markerTo = L.circleMarker([pointTo.lat, pointTo.lng]);

  const from = markerFrom.getLatLng();
  const to = markerTo.getLatLng();
  const m = from.distanceTo(to).toFixed(2);

  return {
    valueString: m > 1000 ? `${m / 1000} km` : `${m} m`,
    value: m
  };
};

export default getDistance;
