import { useEffect } from 'react';

const useMouseMove = ({ handler }) => {
  useEffect(() => {
    window.addEventListener('mousemove', handler);

    return () => {
      window.removeEventListener('mousemove', handler);
    };
  }, [handler]);
};

export default useMouseMove;
