export enum EDistrictModelsTags {
  DISTRICT_MODELS = 'districtModels',
  TRANSLATION_MODELS = 'translationModels',
  ATTACHMENTS = 'attachments'
}

export type TDistrictModelsParams = {
  district: string;
};

export type TDistrictTranslationModelsParams = {
  mapPortalId: number;
};

export type TSpatialApiAttachmentsParams = {
  district: string;
  model: string;
  objectId: number;
};
