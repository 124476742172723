const strictObjectProxy = require('./utils/strictObjectProxy');

const config = {
  coordinatesRoundPrecision: 5,
  selectedPointLayerId: 'selectedPointLayer',
  selectedParcelLayerId: 'selectedParcelLayer',
  selectedNeighboringParcelsLayerId: 'selectedNeighboringParcelsLayer',
  selectedDefaultParcelsLayerId: 'selectedDefaultParcelsLayer',
  identificationMarker: 'identificationMarker',
  minPopupSearchRadius: 0,
  mapMarkerSize: 50,
  districtBorderDefaultColor: 'rgba(51, 136, 255, 1)',
  mapSearchedParcelNeighboringColor: 'rgba(255, 255, 255, 1)',
  mapSearchedParcelDefaultColor: 'rgba(255, 120, 0, 1)',
  wmsRootGroupKey: 'WMS_ROOT_GROUP',
  csvRootGroupKey: 'CSV_ROOT_GROUP',
  baseLayerZIndex: 0,
  checkedLayersWarnLimit: 10,
  popupCardLayersWarnLimit: 10,
  shapesDeepLevels: [
    ['Point', 'MultiPoint', 'LineString'],
    ['MultiLineString', 'Polygon'],
    ['MultiPolygon', 'MultiRectangle', 'MultiCircle']
  ],
  enumClickTypes: {
    identification: 'Identification',
    pointCoordinates: 'PointCoordinates',
    reportCoordinates: 'ReportCoordinates',
    addObjectToolDraw: 'AddObjectToolDraw',
    investorToolDraw: 'InvestorToolDraw',
    noteCoordinates: 'NoteCoordinates',
    streetView: 'StreetView',
    sketchbook: 'Sketchbook',
    routePlanning: 'RoutePlanning',
    wmsIdentification: 'WmsIdentification',
    singlePlot: 'singlePlot',
    plotsIdentificationTool: 'plotsIdentificationTool',
    closestObjectsSelection: 'closestObjectsSelection',
    none: ''
  },
  mapLegendDefaultCrs: 'EPSG:2180',
  invertedCoordsCrs: ['EPSG:2180', 'EPSG:3857']
};

module.exports = strictObjectProxy(config, 'mapConfig');
