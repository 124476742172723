const getParamsObjectFromURL = url => {
  const params = new URLSearchParams(url.split('?')[1]);
  const paramsObject = {};

  for (const [key, value] of params) {
    paramsObject[key] = value;
  }

  return paramsObject;
};

export default getParamsObjectFromURL;
