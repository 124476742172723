import { createApi } from '@reduxjs/toolkit/query/react';

import {
  TParcelDataItem,
  TParcelDataResponse
} from '@/types/responses/parcels-data';

import {
  fetchParcelsDataFinished,
  fetchParcelsDataStarted
} from '@/store/actions/popupActions';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import handleApiError from '@/utils/handleApiError';

import { TParcelsResponse } from '@/types/responses/parcel';
import { TParcelAttributes } from '@/types/parcel-attributes';
import {
  EParcelsTags,
  TFetchClickedParcelAttributesParams,
  TFetchParcelsDataParams,
  TFetchPlotsListParams
} from '@/types/api-types/parcels';
import {
  TClickedParcelAttributes,
  TClickedParcelAttributesResult
} from '@/types/responses/clicked-parcel-attributes';

export const parcelsApi = createApi({
  reducerPath: 'parcelsApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EParcelsTags.CLICKED_PARCEL,
    EParcelsTags.PARCELS,
    EParcelsTags.NEIGHBORING_PARCELS,
    EParcelsTags.PLOTS_LIST
  ],
  endpoints: builder => ({
    fetchClickedParcelAttributes: builder.query<
      TClickedParcelAttributesResult[],
      TFetchClickedParcelAttributesParams
    >({
      query: ({ prefix, params }) => ({
        method: 'GET',
        url: `${prefix}/parcel/`,
        params
      }),
      transformResponse: ({ results }: TClickedParcelAttributes) => results,
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EParcelsTags.CLICKED_PARCEL,
                id: id ?? ''
              })),
              {
                type: EParcelsTags.CLICKED_PARCEL,
                id: EParcelsTags.CLICKED_PARCEL
              }
            ]
          : [
              {
                type: EParcelsTags.CLICKED_PARCEL,
                id: EParcelsTags.CLICKED_PARCEL
              }
            ]
    }),
    fetchParcelsData: builder.query<TParcelDataItem[], TFetchParcelsDataParams>(
      {
        query: ({ prefix, parcels }) => ({
          method: 'GET',
          url: `${prefix}/parcel/?parcels=${parcels.join(',')}`
        }),
        transformResponse: ({ results: { features } }: TParcelDataResponse) =>
          features,
        onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
          dispatch(fetchParcelsDataStarted());

          try {
            await queryFulfilled;
          } catch (error) {
            handleApiError(error, dispatch);
          } finally {
            dispatch(fetchParcelsDataFinished());
          }
        },
        providesTags: result =>
          result
            ? [
                ...result.map(({ id }) => ({
                  type: EParcelsTags.PARCELS,
                  id: id ?? ''
                })),
                { type: EParcelsTags.PARCELS, id: EParcelsTags.PARCELS }
              ]
            : [{ type: EParcelsTags.PARCELS, id: EParcelsTags.PARCELS }]
      }
    ),
    fetchNeighboringParcelsData: builder.query<
      TParcelAttributes[],
      TFetchParcelsDataParams
    >({
      query: ({ prefix, parcels }) => ({
        method: 'GET',
        url: `${prefix}/neighboring_parcels/?parcels=${parcels.join(',')}`
      }),
      transformResponse: ({ results }: TParcelsResponse) => results,
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EParcelsTags.NEIGHBORING_PARCELS,
                id: id ?? ''
              })),
              {
                type: EParcelsTags.NEIGHBORING_PARCELS,
                id: EParcelsTags.NEIGHBORING_PARCELS
              }
            ]
          : [
              {
                type: EParcelsTags.NEIGHBORING_PARCELS,
                id: EParcelsTags.NEIGHBORING_PARCELS
              }
            ]
    }),
    fetchPlotsList: builder.query<TParcelsResponse, TFetchPlotsListParams>({
      query: ({ url, params, omitBaseUrl }) => ({
        method: 'GET',
        url,
        params,
        omitBaseUrl
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: response =>
        response?.results
          ? [
              ...response.results.map(({ id }) => ({
                type: EParcelsTags.PLOTS_LIST,
                id: id ?? ''
              })),
              {
                type: EParcelsTags.PLOTS_LIST,
                id: EParcelsTags.PLOTS_LIST
              }
            ]
          : [
              {
                type: EParcelsTags.PLOTS_LIST,
                id: EParcelsTags.PLOTS_LIST
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchClickedParcelAttributesQuery,
  useLazyFetchParcelsDataQuery,
  useLazyFetchNeighboringParcelsDataQuery,
  useLazyFetchPlotsListQuery
} = parcelsApi;
