export const DISTRICT_CARD_TESTID = 'district-card';
export const DISTRICT_LIST_SEARCH_INPUT_TESTID = 'district-list-search-input';
export const TOGGLE_HIGH_CONTRAST_BUTTON_TESTID = 'toggle-high-contrast-button';
export const BRAND_WRAPPER_TESTID = 'brand-wrapper';
export const COOKIES_ALERT_CONTAINER_TESTID = 'cookies-alert-container';
export const COOKIES_ALERT_FOOTER_TESTID = 'cookies-alert-footer';
export const DISTRICT_FOOTER_WITHOUT_TESTID = 'district-card-footer-without';
export const DISTRICT_HOME_SECTION_TITLE_TESTID = 'district-home-section-title';
export const DISTRICT_HOME_HEADER_TESTID = 'district-home-header';
export const FOOTER_ROW_TESTID = 'footer-row';
export const PRIVACY_CONTAINER_TESTID = 'privacy-container';
