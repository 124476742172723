/**
 * Zwraca wyświetlaną nazwę danej roli.
 *
 * @param {string} role - key sprawdzanej roli.
 * @param {function} formatMessage - formatMessage z "react-intl". Jeśli
 * pominięto, zostanie wyświetlona polska nazwa.
 */
const getRoleDisplayableName = (role, formatMessage) => {
  const roles = {
    basic: formatMessage
      ? formatMessage({ id: 'roles_basic', defaultMessage: 'Basic' })
      : 'Podstawowa',
    basic_plus: formatMessage
      ? formatMessage({ id: 'roles_basic_plus', defaultMessage: 'Basic plus' })
      : 'Podstawowa plus',
    advanced: formatMessage
      ? formatMessage({ id: 'roles_advanced', defaultMessage: 'Advanced' })
      : 'Zaawansowana',
    administrator: formatMessage
      ? formatMessage({ id: 'roles_admin', defaultMessage: 'Administrator' })
      : 'Administrator'
  };

  return roles[role];
};

export default getRoleDisplayableName;
