const selectors = {
  getBackgroundWms: state => state.wms.get('backgroundWms'),
  getPortalWmsFromLibrary: (state, district, mapPortalId) => {
    const wmsLibrary = state.wms.getIn(['wmsLibrary', district]);
    if (!wmsLibrary) return;
    return wmsLibrary.filter(({ map_portal }) => map_portal.id === mapPortalId);
  },
  getPortalPublicWMS: (state, mapPortalId) => {
    const publicWMS = state.wms.get('mapPortalPublicWMS');
    if (!publicWMS) return;
    return publicWMS.get(mapPortalId);
  }
};

export default selectors;
