import { useSelector } from 'react-redux';

import { mapPortalSelectors } from '@/store/selectors';

import { TMapPortal } from '@/types/map-portal';

const {
  getMapPortalByShortName,
  getDistrictMapPortalByID
} = mapPortalSelectors;

type TUseMapPortalMainLoaderParams = {
  id?: string;
  shortName?: string;
  prefix?: string;
};

const useMapPortalMainLoader = ({
  id,
  shortName,
  prefix
}: TUseMapPortalMainLoaderParams = {}): TMapPortal | undefined =>
  useSelector(state => {
    if (shortName) {
      return getMapPortalByShortName(state, prefix, shortName);
    }

    return getDistrictMapPortalByID(state, prefix, Number(id));
  });

export default useMapPortalMainLoader;
