import { isObject } from '../';

/**
 * Parsuje do czytelnego zapisu komunikaty zwracane przez API w błędnych
 * odpowiedziach.
 *
 * @param {object | unknown} errObj - obiekt błędu z odpowiedzi
 * @return {string}
 */
const parseResponseError = (errObj, codeTranslations = {}) => {
  if (!errObj || (!errObj.response && !errObj.error)) return 'Wystąpił błąd.';

  // It is necessary for proper handling of errors from Axios.
  const { data } = 'data' in errObj ? errObj : errObj.response || errObj.error;

  let errMsg = '';

  if (!data) {
    return 'Wystąpił błąd.';
  }

  if (typeof data === 'string') {
    errMsg = data;
  }

  if (Array.isArray(data)) {
    data.forEach(item => {
      if (Array.isArray(item)) {
        item.forEach(message => (errMsg += `${message}\n`));
        return;
      }

      if (isObject(item)) {
        if (codeTranslations[item.code]) {
          return (errMsg += `${codeTranslations[item.code]}\n`);
        }

        return (errMsg += item.message);
      }

      errMsg += item;
    });
  }

  if (isObject(data)) {
    // eslint-disable-next-line no-unused-vars
    for (const key in data) {
      const currentItem = data[key];
      let message = '';

      if (typeof currentItem === 'string' && key !== 'code') {
        errMsg += `${currentItem}\n`;
      }

      if (Array.isArray(currentItem)) {
        currentItem.forEach(item => {
          if (!(item.message || item.code) && isObject(item)) {
            for (const key in item) {
              const currentItem = item[key];

              if (Array.isArray(currentItem)) {
                // eslint-disable-next-line no-loop-func
                currentItem.forEach(item => {
                  if (codeTranslations[item.code]) {
                    return (message += `${codeTranslations[item.code]}\n`);
                  }

                  message += `${item.message}\n`;
                });
              }
            }

            return message;
          }
          // Jeśli wywołanie funkcji parseResponseError posiada tłumaczenia
          // (parametr codeTranslations) dla poszczególnych kodów -
          // korzystamy z nich.
          if (codeTranslations[item.code]) {
            return (message += `${codeTranslations[item.code]}\n`);
          }

          // W przeciwnym razie wstawiamy domyślną informację z backendu.
          message += `${item.message}\n`;
        });
      }

      if (isObject(currentItem)) {
        message += parseResponseError(
          { response: { data: currentItem } },
          codeTranslations
        );
      }

      errMsg += message;
    }
  }

  return errMsg;
};

export default parseResponseError;
