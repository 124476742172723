import { Modal } from 'antd';
import { FormattedMessage } from 'react-intl';

import useLogoutCountdownTimer from './useLogoutCountdownTimer';

import './LogoutCountdownTimer.less';

const zIndex = 1500;

const LogoutCountdownTimer = () => {
  const {
    minutes,
    seconds,
    isLogoutCountdownTimesOpen
  } = useLogoutCountdownTimer();

  return (
    <Modal
      style={{ top: '20vh' }}
      visible={isLogoutCountdownTimesOpen}
      width={350}
      className="logout-countdown-timer-modal"
      zIndex={zIndex}
    >
      <div>
        <FormattedMessage
          id="session_expire_message"
          defaultMessage="Your session will expire in"
        />
        :
      </div>
      <div>
        {minutes}:{seconds}
      </div>
    </Modal>
  );
};

export default LogoutCountdownTimer;
