import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import {
  getUserFormsBegin,
  getUserFormsFailed,
  getUserFormsSuccess
} from '@/store/actions/editDataActions';

import { EEditDataTags, TEditDataParams } from '@/types/api-types/edit-data';
import { TEditDataResponse } from '@/types/responses/edit-data';

export const editDataApi = createApi({
  reducerPath: 'editDataApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EEditDataTags.USER_FORMS],
  endpoints: builder => ({
    fetchUserForms: builder.query<TEditDataResponse[], TEditDataParams>({
      query: ({ district, mapPortalCompositionId }) => ({
        method: 'GET',
        url: `${district}/forms/`,
        params: { map_portal_composition_id: mapPortalCompositionId }
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        dispatch(getUserFormsBegin());
        try {
          const { data } = await queryFulfilled;
          dispatch(getUserFormsSuccess(district, data));
        } catch (error) {
          dispatch(getUserFormsFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EEditDataTags.USER_FORMS,
                id: id ?? ''
              })),
              {
                type: EEditDataTags.USER_FORMS,
                id: EEditDataTags.USER_FORMS
              }
            ]
          : [
              {
                type: EEditDataTags.USER_FORMS,
                id: EEditDataTags.USER_FORMS
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const { useLazyFetchUserFormsQuery } = editDataApi;
