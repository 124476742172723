import { TUpulAttestationSettingsResponse } from '../responses/upul';

export const enum EUpulTags {
  SETTINGS = 'settings',
  TEMPLATES = 'templates'
}

export const enum EUpulEndpoints {
  GET_UPUL_SETTINGS = 'getUpulSettings',
  PATCH_UPUL_SETTINGS = 'patchUpulSettings',
  GET_UPUL_TEMPLATES = 'getUpulTemplates',
  POST_UPUL_TEMPLATES = 'postUpulTemplates',
  PATCH_UPUL_TEMPLATES = 'patchUpulTemplates',
  DELETE_UPUL_TEMPLATES = 'deleteUpulTemplates'
}

export type TUpulAttestationSettingsParams = {
  district: string;
  id?: number;
  data?: TUpulAttestationSettingsResponse | FormData;
};

export type TUpulAttestationTemplatesParams = TUpulAttestationSettingsParams;
