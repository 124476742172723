import { Map, List } from 'immutable';

import {
  GET_MAP_PORTAL_NOTES_SUCCESS,
  GET_MAP_PORTAL_NOTE_SUCCESS,
  ADD_MAP_PORTAL_NOTE_SUCCESS,
  EDIT_MAP_PORTAL_NOTE_SUCCESS,
  DELETE_MAP_PORTAL_NOTE_SUCCESS,
  DELETE_ALL_MAP_PORTAL_NOTES_SUCCESS,
  SET_CURRENT_NOTE,
  CLEAR_CURRENT_NOTE,
  SET_NOTE_EDITOR_COORDINATES,
  CLEAR_NOTE_EDITOR_COORDINATES
} from '../constants/notesActionTypes';

export const initialState = Map({
  mapPortalNotes: Map({}),
  currentNote: null,
  noteEditorCoordinates: null
});

const getMapPortalNotes = (state, { mapPortalId, notes }) =>
  state.setIn(['mapPortalNotes', mapPortalId], List(notes));

// Zanim zedytujesz, pamiętaj, że ta funkcja obsługuje dwie akcje -
// pobranie pojedynczej notatki i dodanie nowej notatki.
const addMapPortalNote = (state, { mapPortalId, note }) =>
  state.updateIn(['mapPortalNotes', mapPortalId], List(), list =>
    List([...list, note])
  );

const editMapPortalNote = (state, { mapPortalId, noteId, note }) => {
  const mapPortalNotes = state.getIn(['mapPortalNotes', mapPortalId]);
  const noteIndex = mapPortalNotes.findIndex(({ id }) => id === noteId);
  const newNotes = mapPortalNotes.set(noteIndex, note);
  return state.setIn(['mapPortalNotes', mapPortalId], newNotes);
};

const deleteMapPortalNote = (state, { mapPortalId, noteId }) => {
  const mapPortalNotes = state.getIn(['mapPortalNotes', mapPortalId]);
  const newNotes = mapPortalNotes.filter(({ id }) => id !== noteId);
  return state.setIn(['mapPortalNotes', mapPortalId], newNotes);
};

const deleteAllMapPortalNotes = (state, { mapPortalId }) =>
  state.setIn(['mapPortalNotes', mapPortalId], List());

const setCurrentNote = (state, { noteId }) => state.set('currentNote', noteId);

const clearCurrentNote = state => state.set('currentNote', null);

const setNoteEditorCoordinates = (state, { coordinates }) =>
  state.set(
    'noteEditorCoordinates',
    Map({
      lat: coordinates?.coords?.latitude ?? coordinates.lat,
      lng: coordinates?.coords?.longitude ?? coordinates.lng
    })
  );

const clearNoteEditorCoordinates = state =>
  state.set('noteEditorCoordinates', null);

export default function reducer(state = initialState, action) {
  return (
    {
      [GET_MAP_PORTAL_NOTES_SUCCESS]: getMapPortalNotes,
      [GET_MAP_PORTAL_NOTE_SUCCESS]: addMapPortalNote,
      [ADD_MAP_PORTAL_NOTE_SUCCESS]: addMapPortalNote,
      [EDIT_MAP_PORTAL_NOTE_SUCCESS]: editMapPortalNote,
      [DELETE_MAP_PORTAL_NOTE_SUCCESS]: deleteMapPortalNote,
      [DELETE_ALL_MAP_PORTAL_NOTES_SUCCESS]: deleteAllMapPortalNotes,
      [SET_CURRENT_NOTE]: setCurrentNote,
      [CLEAR_CURRENT_NOTE]: clearCurrentNote,
      [SET_NOTE_EDITOR_COORDINATES]: setNoteEditorCoordinates,
      [CLEAR_NOTE_EDITOR_COORDINATES]: clearNoteEditorCoordinates
    }[action.type] || (s => s)
  )(state, action);
}
