import round from 'lodash/round';

/**
 * Parsuje jednostkę powierzchni w zależności od wielkości
 * przekazanej wartości. Dla liczb mniejszych od 10000cm2
 * zwraca wartość w m2, a dla większych w ha.
 *
 * @param {number} length - pole powierzchni w cm2
 * @return {string}
 */
const parseArea = (length, unit) => {
  const value = round(length, 2);

  if (!unit) {
    return value < 1000000 ? `${value} m2` : `${round(value / 1000000, 2)} km2`;
  } else if (unit === 'm') {
    return `${value} m2`;
  } else if (unit === 'km') {
    return `${round(value / 1000000, 2)} km2`;
  } else if (unit === 'ha') {
    return `${round(value / 10000, 2)} ha`;
  }
};

export default parseArea;
