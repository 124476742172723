import { createLogger } from 'redux-logger';
import Immutable from 'immutable';

const stateTransformer = state => {
  const newState = {};

  for (var i of Object.keys(state)) {
    if (Immutable.Iterable.isIterable(state[i])) {
      newState[i] = state[i].toJS();
    } else {
      newState[i] = state[i];
    }
  }

  return newState;
};

const logger = createLogger({
  collapsed: true,
  duration: true,
  predicate: (getState, action) => action.type !== 'AUTH_REMOVE_TOKEN',
  stateTransformer
});

export default logger;
