/**
 * Usuwa ewentualny znak / ze końca przekazanej ścieżki,
 * czyli dla "/zawidow/portal/" zwróci "/zawidow/portal".
 *
 * @param {string} path
 * @return {string}
 */
const removeLastSlashFromPath = path => {
  if (!path.endsWith('/')) {
    return path;
  }

  const newPath = path.split('/');
  newPath.pop();

  return newPath.join('/');
};

export default removeLastSlashFromPath;
