import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';

export const UserDataShape = ImmutablePropTypes.mapContains({
  id: PropTypes.number.isRequired,
  first_name: PropTypes.string.isRequired,
  date_joined: PropTypes.string.isRequired,
  last_name: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone_number: PropTypes.string.isRequired,
  addresses: ImmutablePropTypes.array,
  permissions: ImmutablePropTypes.array,
  roles: ImmutablePropTypes.array,
  is_staff: PropTypes.bool.isRequired,
  is_superuser: PropTypes.bool,
  data_filled: PropTypes.bool
});
