const selectors = {
  getMapPortalNotes: (state, mapPortalId) =>
    state.notes.getIn(['mapPortalNotes', mapPortalId]),
  getMapPortalNote: (state, mapPortalId, noteId) => {
    const portalNotes = state.notes.getIn(['mapPortalNotes', mapPortalId]);
    if (!portalNotes) return;
    return portalNotes.find(({ id }) => id === noteId);
  },
  getCurrentNote: state => state.notes.get('currentNote'),
  getNoteEditorCoordinates: state => state.notes.get('noteEditorCoordinates')
};

export default selectors;
