import { GeoJSON } from 'leaflet';
import cloneDeep from 'lodash/cloneDeep';

import getGeometryDeepLevel from '../getGeometryDeepLevel';

const getInvertedGeoJsonCoordinates = geometry => {
  const geometryType = geometry.type;
  const levelsDeep = getGeometryDeepLevel(geometryType);

  const coordinates = cloneDeep(geometry.coordinates).filter(elem =>
    Array.isArray(elem) ? !elem.includes(undefined) : elem !== undefined
  );

  if (levelsDeep === 0) {
    if (Array.isArray(coordinates[0])) {
      return coordinates.map(([lat, lng]) => [lng, lat]);
    }

    return coordinates.reverse();
  }

  return GeoJSON.coordsToLatLngs(coordinates, levelsDeep);
};

export default getInvertedGeoJsonCoordinates;
