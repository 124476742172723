import { Map, List } from 'immutable';
import {
  FETCH_GROUPS_BEGIN,
  FETCH_GROUPS_SUCCESS,
  FETCH_GROUPS_FAILED,
  FETCH_ALL_GROUPS_BEGIN,
  FETCH_ALL_GROUPS_SUCCESS,
  FETCH_ALL_GROUPS_FAILED,
  CLEAR_DISTRICT_ALL_GROUPS,
  DELETE_GROUP_BEGIN,
  DELETE_GROUP_SUCCESS,
  DELETE_GROUP_FAILED,
  ADD_USER_TO_GROUP_BEGIN,
  ADD_USER_TO_GROUP_FAILED,
  ADD_USER_TO_GROUP_SUCCESS,
  ADD_GROUP_BEGIN,
  ADD_GROUP_SUCCESS,
  ADD_GROUP_FAILED,
  FETCH_GROUP_BEGIN,
  FETCH_GROUP_SUCCESS,
  FETCH_GROUP_FAILED,
  FETCH_USERS_GROUP_BEGIN,
  FETCH_USERS_GROUP_SUCCESS,
  FETCH_USERS_GROUP_FAILED,
  DELETE_USER_FROM_GROUP_BEGIN,
  DELETE_USER_FROM_GROUP_SUCCESS,
  DELETE_USER_FROM_GROUP_FAILED,
  FETCH_NO_USERS_GROUP_BEGIN,
  FETCH_NO_USERS_GROUP_FAILED,
  FETCH_NO_USERS_GROUP_SUCCESS,
  UPDATE_GROUP_BEGIN,
  UPDATE_GROUP_FAILED,
  UPDATE_GROUP_SUCCESS,
  ADD_GROUP_TO_MAP_PORTAL_BEGIN,
  ADD_GROUP_TO_MAP_PORTAL_SUCCESS,
  ADD_GROUP_TO_MAP_PORTAL_FAILED,
  DELETE_GROUP_FROM_MAP_PORTAL_BEGIN,
  DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS,
  DELETE_GROUP_FROM_MAP_PORTAL_FAILED,
  DELETE_GROUP_FROM_COMPOSITION_SUCCESS,
  DELETE_GROUP_FROM_COMPOSITION_FAILED,
  ADD_GROUP_TO_COMPOSITION_BEGIN,
  ADD_GROUP_TO_COMPOSITION_FAILED,
  DELETE_GROUP_FROM_COMPOSITION_BEGIN,
  ADD_GROUP_TO_COMPOSITION_SUCCESS
} from '../constants/groupsActionTypes';

const initialState = Map({
  groups: Map({}),
  groupsFetched: false,
  deletingGroup: false,
  addingUserToGroup: false,
  addingGroup: false,
  fetchingGroup: false,
  fetchingUsersGroup: false,
  fetchingNoUsersGroup: false,
  deletingUserFromGroup: false,
  updatingGroup: false,
  addingGroupToMapPortal: false,
  deletingGroupFromMapPortal: false,
  deletingGroupFromComposition: false,
  addingGroupToComposition: false,
  isFetchingAllGroups: false,
  allGroups: Map()
});

export const fetchGroupsBegin = state => state.set('fetchingGroups', true);

export const fetchGroupsSuccess = (state, action) => {
  const oldGroups = state.get('groups').toJS();
  const oldResults = oldGroups.results;
  let newGroups = Map(action.groups);

  // Przy kolejnych wywołaniach dodajemy nowe grupy, jeśli nie są
  // jeszcze zapisane w storze.
  if (oldResults) {
    const newResults = [...oldResults];
    const actionResults = action.groups.results;

    actionResults.forEach(({ id: newGroupId }, index) => {
      if (!oldResults.find(({ id: oldGroupId }) => newGroupId === oldGroupId)) {
        newResults.push(actionResults[index]);
      }
    });

    newGroups = Map({
      ...action.groups,
      results: newResults
    });
  }

  return state.merge(
    Map({
      fetchingGroups: false,
      groupsFetched: true,
      groups: Map(newGroups)
    })
  );
};

export const fetchGroupsFailed = state => state.set('fetchingGroups', false);

export const deleteGroupBegin = state => state.set('deletingGroup', true);

export const deleteGroupSuccess = (state, action) => {
  const groups = state.get('groups').toJS();
  const newGroups = groups.results.filter(group => group.id !== action.groupId);
  groups.results = newGroups;

  return state.merge(
    Map({
      deletingGroup: false,
      groups: Map(groups)
    })
  );
};

export const deleteGroupFailed = state => state.set('deletingGroup', false);

export const addUserToGroupStart = state =>
  state.set('addingUserToGroup', true);

export const addUserToGroupEnd = state => state.set('addingUserToGroup', false);

export const addGroupStart = state => state.set('addingGroup', true);

export const addGroupEnd = state => {
  return state.merge(
    Map({
      addingGroup: false,
      groupsFetched: false
    })
  );
};

export const fetchGroupStart = state => state.set('fetchingGroup', true);

export const fetchGroupEnd = state => state.set('fetchingGroup', false);

export const fetchUsersGroupStart = state =>
  state.set('fetchingUsersGroup', true);

export const fetchUsersGroupEnd = state =>
  state.set('fetchingUsersGroup', false);

export const fetchNoUsersGroupStart = state =>
  state.set('fetchingNoUsersGroup', true);

export const fetchNoUsersGroupEnd = state =>
  state.set('fetchingNoUsersGroup', false);

export const deleteUserFromGroupStart = state =>
  state.set('deletingUserFromGroup', true);

export const deleteUserFromGroupEnd = state =>
  state.set('deletingUserFromGroup', false);

export const updateGroupStart = state =>
  state.set('deletingUserFromGroup', false);

export const updateGroupEnd = state => state.set('updatingGroup', false);

export const addGroupToMapPortalStart = state =>
  state.set('addingGroupToMapPortal', true);

export const addGroupToMapPortalEnd = state =>
  state.set('addingGroupToMapPortal', false);

export const deleteGroupFromMapPortalStart = state =>
  state.set('deletingGroupFromMapPortal', true);

export const deleteGroupFromMapPortalEnd = state =>
  state.set('deletingGroupFromMapPortal', false);

export const deleteGroupFromCompositionStart = state =>
  state.set('deletingGroupFromComposition', true);

export const deleteGroupFromCompositionEnd = state =>
  state.set('deletingGroupFromComposition', false);

export const addGroupToCompositionStart = state =>
  state.set('addingGroupToComposition', true);

export const addGroupToCompositionEnd = state =>
  state.set('addingGroupToComposition', false);

const fetchAllGroupsStarted = state => state.set('isFetchingAllGroups', true);

export const fetchAllGroupsSuccess = (state, { district, groups }) => {
  const updatedGroups = state.get('allGroups').setIn([district], List(groups));

  return state
    .set('allGroups', updatedGroups)
    .set('isFetchingAllGroups', false);
};

const fetchAllGroupsFailed = state => state.set('isFetchingAllGroups', false);

export const clearDistrictAllGroups = (state, { district }) => {
  const updatedGroups = state.get('allGroups').setIn([district], []);
  return state.set('allGroups', updatedGroups);
};

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_GROUPS_BEGIN]: fetchGroupsBegin,
      [FETCH_GROUPS_SUCCESS]: fetchGroupsSuccess,
      [FETCH_GROUPS_FAILED]: fetchGroupsFailed,
      [DELETE_GROUP_BEGIN]: deleteGroupBegin,
      [DELETE_GROUP_SUCCESS]: deleteGroupSuccess,
      [DELETE_GROUP_FAILED]: deleteGroupFailed,
      [ADD_USER_TO_GROUP_BEGIN]: addUserToGroupStart,
      [ADD_USER_TO_GROUP_FAILED]: addUserToGroupEnd,
      [ADD_USER_TO_GROUP_SUCCESS]: addUserToGroupEnd,
      [ADD_GROUP_BEGIN]: addGroupStart,
      [ADD_GROUP_SUCCESS]: addGroupEnd,
      [ADD_GROUP_FAILED]: addGroupEnd,
      [FETCH_GROUP_BEGIN]: fetchGroupStart,
      [FETCH_GROUP_SUCCESS]: fetchGroupEnd,
      [FETCH_GROUP_FAILED]: fetchGroupEnd,
      [FETCH_USERS_GROUP_BEGIN]: fetchUsersGroupStart,
      [FETCH_USERS_GROUP_SUCCESS]: fetchUsersGroupEnd,
      [FETCH_USERS_GROUP_FAILED]: fetchUsersGroupEnd,
      [DELETE_USER_FROM_GROUP_BEGIN]: deleteUserFromGroupStart,
      [DELETE_USER_FROM_GROUP_SUCCESS]: deleteUserFromGroupEnd,
      [DELETE_USER_FROM_GROUP_FAILED]: deleteUserFromGroupEnd,
      [FETCH_NO_USERS_GROUP_BEGIN]: fetchNoUsersGroupStart,
      [FETCH_NO_USERS_GROUP_FAILED]: fetchNoUsersGroupEnd,
      [FETCH_NO_USERS_GROUP_SUCCESS]: fetchNoUsersGroupEnd,
      [UPDATE_GROUP_BEGIN]: updateGroupStart,
      [UPDATE_GROUP_SUCCESS]: updateGroupEnd,
      [UPDATE_GROUP_FAILED]: updateGroupEnd,
      [ADD_GROUP_TO_MAP_PORTAL_BEGIN]: addGroupToMapPortalStart,
      [ADD_GROUP_TO_MAP_PORTAL_SUCCESS]: addGroupToMapPortalEnd,
      [ADD_GROUP_TO_MAP_PORTAL_FAILED]: addGroupToMapPortalEnd,
      [DELETE_GROUP_FROM_MAP_PORTAL_BEGIN]: deleteGroupFromMapPortalStart,
      [DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS]: deleteGroupFromMapPortalEnd,
      [DELETE_GROUP_FROM_MAP_PORTAL_FAILED]: deleteGroupFromMapPortalEnd,
      [DELETE_GROUP_FROM_COMPOSITION_BEGIN]: deleteGroupFromCompositionStart,
      [DELETE_GROUP_FROM_COMPOSITION_SUCCESS]: deleteGroupFromCompositionEnd,
      [DELETE_GROUP_FROM_COMPOSITION_FAILED]: deleteGroupFromCompositionEnd,
      [ADD_GROUP_TO_COMPOSITION_BEGIN]: addGroupToCompositionStart,
      [ADD_GROUP_TO_COMPOSITION_SUCCESS]: addGroupToCompositionEnd,
      [ADD_GROUP_TO_COMPOSITION_FAILED]: addGroupToCompositionEnd,
      [FETCH_ALL_GROUPS_BEGIN]: fetchAllGroupsStarted,
      [FETCH_ALL_GROUPS_SUCCESS]: fetchAllGroupsSuccess,
      [FETCH_ALL_GROUPS_FAILED]: fetchAllGroupsFailed,
      [CLEAR_DISTRICT_ALL_GROUPS]: clearDistrictAllGroups
    }[action.type] || (s => s)
  )(state, action);
}
