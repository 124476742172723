import { point } from '@turf/helpers';
import flip from '@turf/flip';
import midpoint from '@turf/midpoint';
import { GeoJSON } from 'leaflet';

const getPoint = point =>
  Array.isArray(point) ? point : [point.lat, point.lng];

const getLineMiddlePoint = (p1, p2) => {
  const lineStartPoint = point(getPoint(p1));
  const lineEndPoint = point(getPoint(p2));
  const middlePoint = flip(midpoint(lineStartPoint, lineEndPoint));
  return GeoJSON.coordsToLatLng(middlePoint.geometry.coordinates);
};

export default getLineMiddlePoint;
