import { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { showError } from '../../store/actions/globalActions';

import ErrorBoard from './ErrorBoard';

export class HandleError extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hasError: false
    };
  }

  componentDidCatch(err) {
    this.setState({ hasError: true });
    this.props.showError(err.message);
  }

  render() {
    if (this.state.hasError) {
      return this.props.showBoard ? <ErrorBoard /> : null;
    }

    return this.props.children;
  }
}

const mapDispatchToProps = {
  showError
};

HandleError.propTypes = {
  showError: PropTypes.func.isRequired,
  showBoard: PropTypes.bool
};

export default connect(null, mapDispatchToProps)(HandleError);
