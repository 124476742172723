/**
 * Przeszukuje przekazany obiekt iterujący przez obiekty zawarte we wskazanym
 * kluczu. Funkcja kończy działanie po znalezieniu wskazanej wartości lub
 * osiągnięciu określonego limitu liczby iteracji (domyślnie 10).
 *
 * @param {Object} element - obiekt do przeszukania
 * @param {string} config.attribute - nazwa atrybutu
 * @param {any} config.value - poszukiwana wartość jaką musi spełanić atrybut
 * @param {string} [config.parentKey=parentElement] - nazwa klucza pod którym znajduje się rodzic
 * @param {number} [config.iterationLimit=10] - maksymalna liczba iteracji
 */

const findParentNode = (
  element,
  { parentKey = 'parentElement', attribute, value, iterationLimit = 10 }
) => {
  let currentNode = element;
  let currentIteration = 1;

  while (
    currentNode[attribute] !== value ||
    currentIteration >= iterationLimit
  ) {
    currentNode = currentNode?.[parentKey];

    const nextIteration = currentIteration + 1;
    const isNotFounded = currentNode?.[attribute] !== value;
    if (!currentNode || (nextIteration >= iterationLimit && isNotFounded)) {
      return null;
    }

    currentIteration = nextIteration;
  }

  return currentNode;
};

export default findParentNode;
