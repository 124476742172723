import { Map, List } from 'immutable';

import {
  FETCH_BACKGROUND_WMS_SUCCESS,
  GET_WMS_SUCCESS,
  ADD_WMS_SUCCESS,
  EDIT_WMS_SUCCESS,
  DELETE_WMS_SUCCESS,
  GET_LIBRARY_WMS_SUCCESS,
  ADD_WMS_TO_LIBRARY_SUCCESS,
  EDIT_LIBRARY_WMS_SUCCESS,
  DELETE_WMS_FROM_LIBRARY_SUCCESS,
  GET_MAPPORTAL_PUBLIC_WMS_SUCCESS
} from '../constants/wmsActionTypes';

const initialState = Map({
  backgroundWms: List([]),
  wms: List([]),
  wmsCount: Map(),
  wmsLibrary: Map(),
  mapPortalPublicWMS: Map()
});

const fetchBackgroundWmsSuccess = (state, action) =>
  state.merge({
    backgroundWms: List(action.default)
  });

const getWmsSuccess = (state, { wms }) =>
  state.set('wms', List(wms.results)).set('wmsCount', Number(wms.count));

const addWmsSuccess = (state, { wms }) =>
  state.update('wms', List(), list => List([wms, ...list]));

const editWmsSuccess = (state, { wms }) =>
  state.update('wms', List(), list => {
    const newList = [...list];
    const editedWmsIndex = newList.findIndex(({ id }) => id === wms.id);

    newList[editedWmsIndex] = wms;
    return List(newList);
  });

const deleteWmsSuccess = (state, { wmsId }) =>
  state.update('wms', List(), list =>
    List(list.filter(({ id }) => wmsId !== id))
  );

const getLibraryWmsSuccess = (state, { district, wms }) =>
  state.setIn(['wmsLibrary', district], List(wms));

const addWmsToLibrarySuccess = (state, { district, wms }) =>
  state.updateIn(['wmsLibrary', district], List(), list =>
    List([...list, wms])
  );

const editLibraryWmsSuccess = (state, { district, wms }) =>
  state.updateIn(['wmsLibrary', district], List(), list => {
    const newList = [...list];
    const editedWmsIndex = newList.findIndex(({ id }) => id === wms.id);

    newList[editedWmsIndex] = wms;
    return List(newList);
  });

const deleteWmsFromLibrarySuccess = (state, { district, wmsId }) =>
  state.updateIn(['wmsLibrary', district], List(), list =>
    List(list.filter(({ id }) => wmsId !== id))
  );

const getMapPortalPublicWMSSuccess = (state, { mapPortal, wms }) =>
  state.setIn(['mapPortalPublicWMS', mapPortal], List(wms));

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_BACKGROUND_WMS_SUCCESS]: fetchBackgroundWmsSuccess,
      [GET_WMS_SUCCESS]: getWmsSuccess,
      [ADD_WMS_SUCCESS]: addWmsSuccess,
      [EDIT_WMS_SUCCESS]: editWmsSuccess,
      [DELETE_WMS_SUCCESS]: deleteWmsSuccess,
      [GET_LIBRARY_WMS_SUCCESS]: getLibraryWmsSuccess,
      [ADD_WMS_TO_LIBRARY_SUCCESS]: addWmsToLibrarySuccess,
      [EDIT_LIBRARY_WMS_SUCCESS]: editLibraryWmsSuccess,
      [DELETE_WMS_FROM_LIBRARY_SUCCESS]: deleteWmsFromLibrarySuccess,
      [GET_MAPPORTAL_PUBLIC_WMS_SUCCESS]: getMapPortalPublicWMSSuccess
    }[action.type] || (s => s)
  )(state, action);
}
