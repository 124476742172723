import queryString from 'query-string';
import { useSelector } from 'react-redux';
import { mapPortalSelectors, userSelectors } from '../../store/selectors';
import { COMPONENTS_TO_RENDER } from '../../utils/constants';
import mainRoutePaths from '../../router/portal/paths';
import isItIframe from '../../utils/isItIframe';

const useComponentsToRender = () => {
  const { pathname, search } = window.location;
  const { popup } = queryString.parse(search);
  const [, district, portalName] = pathname.split('/');

  const mapPortal = useSelector(state =>
    mapPortalSelectors.getMapPortalByShortName(state, district, portalName)
  );
  const isCaptchaEnabled = mapPortal?.get('enable_captcha');
  const isUserLogged = useSelector(state => userSelectors.isUserLogged(state));
  const isNotARobot = useSelector(state => userSelectors.isNotARobot(state));

  // // pozostałe głowne ścieżki
  const isMainPath = Object.values(mainRoutePaths).find(elem => {
    const indexOfSecondSlash = elem.substr(1).indexOf('/');
    const mainPath =
      indexOfSecondSlash > 0
        ? elem.substr(1, indexOfSecondSlash)
        : elem.substr(1);
    return mainPath === district || mainPath === portalName;
  });
  if (isMainPath) return [];

  if (isItIframe()) return COMPONENTS_TO_RENDER.iframeMapPortal;

  //portal mapowy z captcha
  if (
    district &&
    portalName &&
    isCaptchaEnabled &&
    !isUserLogged &&
    !isNotARobot
  )
    return COMPONENTS_TO_RENDER.mapPortalCaptcha;

  //link do popupa
  if (popup) return COMPONENTS_TO_RENDER.portalWithPopup;

  //portal mapowy
  if (district && portalName) return COMPONENTS_TO_RENDER.mapPortal;

  return [];
};

export default useComponentsToRender;
