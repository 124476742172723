import { createContext } from 'react';

const LanguageContext = createContext({
  language: '',
  setLanguage: () => {}
});

LanguageContext.displayName = 'LangaugeContext';

export default LanguageContext;
