import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';

import rootReducer from './reducers';
import logger from './logger-configuration';

import { getLanguages, getPingPong } from './actions/globalActions';
import { fetchDistricts, fetchAppDistrict } from './actions/districtActions';

import { apiMiddlewares } from './api';

const setupStore = ({
  initialState = {},
  middlewares,
  isProductionEnvironment = true
}) =>
  configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: getDefaultMiddleware =>
      getDefaultMiddleware({
        serializableCheck: false,
        immutableCheck: false
      }).concat(middlewares),
    devTools: !isProductionEnvironment
  });

export default function storeConfiguration(initialState) {
  const isProductionEnvironment = process.env.NODE_ENV === 'production';
  const shouldAddReduxLogger = process.env.REACT_APP_REDUX_LOGGER !== 'false';
  const shouldAddLogger = !isProductionEnvironment && shouldAddReduxLogger;

  const middlewares = [thunk, ...apiMiddlewares];

  if (shouldAddLogger) {
    middlewares.push(logger);
  }

  const store = setupStore({
    initialState,
    middlewares,
    isProductionEnvironment
  });

  store
    .dispatch(getPingPong())
    .then(() => {
      store.dispatch(getLanguages());
      store.dispatch(fetchDistricts());
      store.dispatch(fetchAppDistrict());
    })
    .catch();
  return store;
}
