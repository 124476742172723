import axios from 'axios';
import queryString from 'query-string';

import { wmsServices } from '../../config';
import {
  FETCH_DISTRICT_MAP_PORTALS_BEGIN,
  FETCH_DISTRICT_MAP_PORTALS_SUCCESS,
  SAVE_DISTRICT_MAP_PORTALS_NAMES,
  FETCH_COMPOSITION_BEGIN,
  FETCH_DISTRICT_COMPOSITIONS_BEGIN,
  FETCH_DISTRICT_COMPOSITIONS_SUCCESS,
  SHOW_LEGEND,
  HIDE_LEGEND,
  FETCH_LEGEND_BEGIN,
  FETCH_LEGEND_SUCCESS,
  FETCH_LEGEND_FAILED,
  CLEAR_FETCHING_LEGEND,
  CREATE_MAP_PORTAL_BEGIN,
  CREATE_MAP_PORTAL_SUCCESS,
  CREATE_MAP_PORTAL_FAILED,
  EDIT_MAP_PORTAL_BEGIN,
  CHANGE_MAP_PORTAL_SUCCESS,
  FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS,
  EDIT_MAP_PORTAL_FAILED,
  DELETE_MAP_PORTAL_BEGIN,
  DELETE_MAP_PORTAL_SUCCESS,
  DELETE_MAP_PORTAL_FAILED,
  UPDATE_CHECKED_MAPPORTALS_LAYERS,
  FETCH_QGIS_PROJECTS_BEGIN,
  FETCH_QGIS_PROJECTS_SUCCESS,
  UPDATE_QGIS_PROJECT_DATA_BEGIN,
  UPDATE_QGIS_PROJECT_DATA_SUCCESS,
  UPDATE_QGIS_PROJECT_DATA_FAILED,
  DELETE_QGIS_PROJECT_BEGIN,
  DELETE_QGIS_PROJECT_SUCCESS,
  DELETE_QGIS_PROJECT_FAILED,
  UPDATE_QGIS_PROJECT_BEGIN,
  UPDATE_QGIS_PROJECT_SUCCESS,
  UPDATE_QGIS_PROJECT_FAILED,
  FETCH_NETWORK_DRIVE_FOLDER_BEGIN,
  FETCH_NETWORK_DRIVE_FOLDER_SUCCESS,
  FETCH_NETWORK_DRIVE_FOLDER_FAILED,
  FETCH_NETWORK_DRIVE_FILE_BEGIN,
  FETCH_NETWORK_DRIVE_FILE_SUCCESS,
  FETCH_NETWORK_DRIVE_FILE_FAILED,
  FETCHING_NETWORK_DRIVE_BEGIN,
  FETCHING_NETWORK_DRIVE_SUCCESS,
  FETCHING_NETWORK_DRIVE_FAILED,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED,
  SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS,
  CLEAR_SELECTING_ND_PROJECT_STATUS,
  ADD_LAYERS_TO_LAYERS_MAP,
  ADD_LAYERS_TO_LAYERS_ID_MAP,
  SET_CURRENT_COMPOSITION,
  DELETE_WMS_LAYER,
  DELETE_WMS_FROM_COMPOSITION,
  DELETE_CSV_FROM_COMPOSITION,
  CLEAR_MAP_PORTAL_REDUCER_STATE,
  EDIT_MAP_PORTAL_COMPOSITION_BEGIN,
  EDIT_MAP_PORTAL_COMPOSITION_SUCCESS,
  EDIT_MAP_PORTAL_COMPOSITION_FAILED,
  EDIT_MAP_PORTAL_TOOLS,
  ADD_COMPOSITION_TO_MAP_PORTAL_BEGIN,
  ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS,
  ADD_COMPOSITION_TO_MAP_PORTAL_FAILED,
  REMOVE_COMPOSITION_FROM_MAP_PORTAL_BEGIN,
  REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS,
  REMOVE_COMPOSITION_FROM_MAP_PORTAL_FAILED,
  FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN,
  FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS,
  FETCH_MAP_PORTAL_COMPOSITIONS_FAILED,
  GET_JOB_STATUS_FROM_ADDED_PROJECT,
  FETCH_COMPOSITION_FAILED,
  SET_PHOTOS_COMPOSITION,
  FETCH_RASTER_TIMELINE_BEGIN,
  FETCH_RASTER_TIMELINE_SUCCESS,
  FETCH_RASTER_TIMELINE_FAILED,
  ADD_RASTER_TIMELINE_BEGIN,
  ADD_RASTER_TIMELINE_SUCCESS,
  ADD_RASTER_TIMELINE_FAILED,
  DELETE_RASTER_TIMELINE_BEGIN,
  DELETE_RASTER_TIMELINE_SUCCESS,
  DELETE_RASTER_TIMELINE_FAILED,
  UPDATE_RASTER_TIMELINE_BEGIN,
  UPDATE_RASTER_TIMELINE_SUCCESS,
  UPDATE_RASTER_TIMELINE_FAILED,
  GET_PUBLIC_WMS_BEGIN,
  GET_PUBLIC_WMS_SUCCESS,
  GET_PUBLIC_WMS_FAILED,
  ADD_COMPOSITION_WMS_ENTRY,
  UPDATE_COMPOSITION_ENTRIES_ORDER,
  INSERT_TO_ADDED_WMS_LIST,
  REMOVE_LAYER_FROM_ADDED_WMS_LIST,
  CLEAR_ADDED_WMS_LIST,
  CLEAR_ADDED_CSV_LIST,
  SET_ACTIVE_TOOL,
  RESET_ACTIVE_TOOL,
  FETCH_DISTRICT_MAP_PORTAL_BEGIN,
  FETCH_DISTRICT_MAP_PORTAL_FAILED,
  FETCH_PORTAL_TAGS_BEGIN,
  FETCH_PORTAL_TAGS_FAILED,
  EDIT_PORTAL_TAGS_BEGIN,
  EDIT_PORTAL_TAGS_SUCCESS,
  EDIT_PORTAL_TAGS_FAILED,
  ADD_VECTOR_TIMELINE_BEGIN,
  ADD_VECTOR_TIMELINE_SUCCESS,
  ADD_VECTOR_TIMELINE_FAILED,
  FETCH_VECTOR_TIMELINES_BEGIN,
  FETCH_VECTOR_TIMELINES_SUCCESS,
  FETCH_VECTOR_TIMELINES_FAILED,
  DELETE_VECTOR_TIMELINE_BEGIN,
  DELETE_VECTOR_TIMELINE_SUCCESS,
  DELETE_VECTOR_TIMELINE_FAILED,
  UPDATE_VECTOR_TIMELINE_BEGIN,
  UPDATE_VECTOR_TIMELINE_SUCCESS,
  UPDATE_VECTOR_TIMELINE_FAILED,
  GET_PROJECT_LAYERS_BEGIN,
  GET_PROJECT_LAYERS_SUCCESS,
  GET_PROJECT_LAYERS_FAILED,
  GET_SPOT_MEASURE_CONFIG_BEGIN,
  GET_SPOT_MEASURE_CONFIG_SUCCESS,
  GET_SPOT_MEASURE_CONFIG_FAILED,
  EDIT_SPOT_MEASURE_LAYER_BEGIN,
  EDIT_SPOT_MEASURE_LAYER_SUCCESS,
  EDIT_SPOT_MEASURE_LAYER_FAILED,
  ADD_SPOT_MEASURE_LAYER_BEGIN,
  ADD_SPOT_MEASURE_LAYER_SUCCESS,
  ADD_SPOT_MEASURE_LAYER_FAILED,
  DELETE_SPOT_MEASURE_LAYER_BEGIN,
  DELETE_SPOT_MEASURE_LAYER_SUCCESS,
  DELETE_SPOT_MEASURE_LAYER_FAILED,
  SET_ENABLE_GEOLOCATION,
  SETTING_GEOLOCATION,
  GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN,
  GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED,
  UPDATE_COMPOSITION_SIDEBAR_SETTINGS_BEGIN,
  UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  UPDATE_COMPOSITION_SIDEBAR_SETTINGS_FAILED,
  SET_PORTAL_COMPOSITION_TAGS_BEGIN,
  SET_PORTAL_COMPOSITION_TAGS_SUCCESS,
  SET_PORTAL_COMPOSITION_TAGS_FAILED,
  GET_PROJECT_UPDATE_LOGS_BEGIN,
  GET_PROJECT_UPDATE_LOGS_SUCCESS,
  GET_PROJECT_UPDATE_LOGS_FAILED,
  DELETE_PROJECT_UPDATE_LOG_BEGIN,
  DELETE_PROJECT_UPDATE_LOG_SUCCESS,
  DELETE_PROJECT_UPDATE_LOG_FAILED,
  UPDATE_PROJECT_MAP_PROXY_SETTINGS_BEGIN,
  UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS,
  UPDATE_PROJECT_MAP_PROXY_SETTINGS_FAILED,
  ADD_COMPOSITION_CSV_ENTRY,
  INSERT_TO_ADDED_CSV_LIST,
  FETCH_LAYER_GEOJSON_BEGIN,
  FETCH_LAYER_GEOJSON_SUCCESS,
  FETCH_LAYER_GEOJSON_FAILED,
  ADD_TO_TOUCHED_GROUPS,
  REMOVE_FROM_TOUCHED_GROUPS,
  CLEAR_TOUCHED_GROUPS,
  UPDATE_LAYER_VISIBILITY_BEGIN,
  UPDATE_LAYER_VISIBILITY_SUCCESS,
  UPDATE_LAYER_VISIBILITY_FAILED,
  FETCH_VOIVODESHIPS_BEGIN,
  FETCH_VOIVODESHIPS_SUCCESS,
  FETCH_VOIVODESHIPS_FAILED,
  FETCH_COUNTIES_BEGIN,
  FETCH_COUNTIES_SUCCESS,
  FETCH_COUNTIES_FAILED,
  FETCH_COMMUNES_BEGIN,
  FETCH_COMMUNES_SUCCESS,
  FETCH_COMMUNES_FAILED,
  FETCH_REGIONS_BEGIN,
  FETCH_REGIONS_SUCCESS,
  FETCH_REGIONS_FAILED,
  DOWNLOAD_LAYER_ATTRIBUTES_BEGIN,
  DOWNLOAD_LAYER_ATTRIBUTES_SUCCESS,
  DOWNLOAD_LAYER_ATTRIBUTES_FAILED,
  SET_INVESTOR_DRAWING,
  SHOW_SERVICES_MODAL,
  HIDE_SERVICES_MODAL,
  SET_SEARCH_INPUT_DATA,
  SET_SEARCH_INPUT_OPTIONS,
  SET_SEARCH_INPUT_GUGIK_ADDRESS,
  SET_SEARCH_INPUT_GUGIK_DATA,
  SET_SEARCH_INPUT_GUGIK_DISPLAY,
  RESET_SEARCH_INPUT,
  SET_ACTIVE_INDEX_TOOLBAR,
  DOWNLOAD_RASTER_LAYER_BEGIN,
  DOWNLOAD_RASTER_LAYER_SUCCESS,
  DOWNLOAD_RASTER_LAYER_FAILED,
  UPDATE_ORDER_MAP_PORTAL_BEGIN,
  UPDATE_ORDER_MAP_PORTAL_SUCCESS,
  UPDATE_ORDER_MAP_PORTAL_FAILED,
  FETCH_SELECTION_METHODS_BEGIN,
  FETCH_SELECTION_METHODS_SUCCESS,
  FETCH_SELECTION_METHODS_FAILED,
  FETCH_SELECTION_TYPES_BEGIN,
  FETCH_SELECTION_TYPES_SUCCESS,
  FETCH_SELECTION_TYPES_FAILED,
  FETCH_SELECTION_OPERATOR_FAILED,
  FETCH_SELECTION_OPERATOR_SUCCESS,
  FETCH_SELECTION_OPERATOR_BEGIN,
  SHOW_PRIVACY_MODAL,
  HIDE_PRIVACY_MODAL,
  FETCH_AR_CONFIGS_BEGIN,
  FETCH_AR_CONFIGS_SUCCESS,
  FETCH_AR_CONFIGS_FAILED,
  ADD_AR_CONFIG_BEGIN,
  ADD_AR_CONFIG_SUCCESS,
  ADD_AR_CONFIG_FAILED,
  UPDATE_AR_CONFIG_BEGIN,
  UPDATE_AR_CONFIG_SUCCESS,
  UPDATE_AR_CONFIG_FAILED,
  FETCH_AR_CONFIG_ATTRS_LIST_BEGIN,
  FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS,
  FETCH_AR_CONFIG_ATTRS_LIST_FAILED,
  UPDATE_AR_CONFIG_ATTRS_LIST_BEGIN,
  UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS,
  UPDATE_AR_CONFIG_ATTRS_LIST_FAILED,
  DELETE_AR_CONFIG_BEGIN,
  DELETE_AR_CONFIG_SUCCESS,
  DELETE_AR_CONFIG_FAILED,
  DELETE_AR_CONFIG_ATTRS_LIST,
  FETCH_AR_COMPOSITION_CONFIG_BEGIN,
  FETCH_AR_COMPOSITION_CONFIG_SUCCESS,
  FETCH_AR_COMPOSITION_CONFIG_FAILED,
  UPDATE_AR_COMPOSITION_CONFIG_BEGIN,
  UPDATE_AR_COMPOSITION_CONFIG_SUCCESS,
  UPDATE_AR_COMPOSITION_CONFIG_FAILED,
  FETCH_MAP_PORTAL_TOOLS_FAILED,
  FETCH_MAP_PORTAL_TOOLS_SUCCESS,
  FETCH_MAP_PORTAL_TOOLS_STARTED,
  SET_DEFAULT_MODE,
  UPDATE_MAP_PORTAL_TOOL_STARTED,
  UPDATE_MAP_PORTAL_TOOL_SUCCESS,
  UPDATE_MAP_PORTAL_TOOL_FAILED,
  FETCH_DISTRICT_LOCALITIES_STARTED,
  FETCH_DISTRICT_LOCALITIES_SUCCESS,
  FETCH_DISTRICT_LOCALITIES_FAILED,
  FETCH_LOCALITY_STREETS_STARTED,
  FETCH_LOCALITY_STREETS_SUCCESS,
  FETCH_LOCALITY_STREETS_FAILED,
  GET_WMS_LEGEND_BEGIN,
  GET_WMS_LEGEND_SUCCESS,
  GET_WMS_LEGEND_FAILED,
  CLEAR_FETCHING_WMS_LEGEND,
  CLEAR_CURRENT_COMPOSITION_ID,
  SHOW_SERVICE_INFO_MODAL,
  HIDE_SERVICE_INFO_MODAL,
  FETCH_HOME_SITE_IFRAME_BEGIN,
  FETCH_HOME_SITE_IFRAME_SUCCESS,
  FETCH_HOME_SITE_IFRAME_FAILED
} from '../constants/mapPortalActionTypes';

import { mapPortalApi } from '../api/mapPortal';
import { showError, showSuccess } from './globalActions';
import { getAllDistrictTags } from './districtActions';

import { mapPortalSelectors } from '../selectors';

import { parseResponseError, getBase64ImageSize } from '../../utils/lib';

import translations from '../translations';
import { responseStatuses } from '../../config';
import handleError from '../../utils/handleError';
import { PAGE_SIZE } from '../../utils/constants';
import useProxy from '../../hooks/useProxy';

//RTQ
export const fetchCompositionBegin = (
  prefix,
  portal_id,
  id = 'default'
) => dispatch => {
  dispatch({
    type: FETCH_COMPOSITION_BEGIN,
    prefix: prefix,
    portal_id: portal_id,
    id: id
  });
};

export const fetchCompositionFailed = () => dispatch => {
  dispatch({
    type: FETCH_COMPOSITION_FAILED
  });
};

export const clearCurrentCompositionId = () => ({
  type: CLEAR_CURRENT_COMPOSITION_ID
});

export const refetchComposition = (prefix, portalName) => (
  dispatch,
  getState
) => {
  const state = getState();
  const compositionId = state.mapPortals.get('currentCompositionId');
  const portalId = mapPortalSelectors.getMapPortalId(state, prefix, portalName);

  const promise = dispatch(
    mapPortalApi.endpoints.fetchComposition.initiate({
      prefix,
      portal_id: portalId,
      id: compositionId
    })
  );

  const { refetch } = promise;
  return refetch();
};

export const setCurrentComposition = currentCompositionId => ({
  type: SET_CURRENT_COMPOSITION,
  currentCompositionId
});

export const editMapPortalCompositionBegin = () => ({
  type: EDIT_MAP_PORTAL_COMPOSITION_BEGIN
});

export const editMapPortalCompositionSuccess = (
  mapPortalId,
  compositionId,
  compositionData,
  district
) => ({
  type: EDIT_MAP_PORTAL_COMPOSITION_SUCCESS,
  mapPortalId,
  compositionId,
  compositionData,
  district
});

export const editMapPortalCompositionFailed = () => ({
  type: EDIT_MAP_PORTAL_COMPOSITION_FAILED
});

export const editMapPortalComposition = (
  mapPortalId,
  compositionId,
  compositionData,
  district,
  callback,
  showNotification = true
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editMapPortalCompositionBegin());

    axios
      .patch(
        `${district}/map_portal/${mapPortalId}/map_portal_compositions/${compositionId}/`,
        compositionData
      )
      .then(res => {
        if (res.status === 200) {
          dispatch(
            editMapPortalCompositionSuccess(
              mapPortalId,
              compositionId,
              res.data,
              district
            )
          );

          // It's necessary to update cached data
          dispatch(
            mapPortalApi.util.updateQueryData(
              'fetchSingleMapPortalCompositions',
              { district, mapPortalId },
              draft => {
                const compositionIndex = draft.findIndex(
                  ({ id }) => id === compositionId
                );

                if (compositionIndex !== -1) {
                  draft[compositionIndex] = res.data;
                }
              }
            )
          );

          if (showNotification) {
            dispatch(showSuccess('Zmiany zostały zapisane.'));
          }
          callback && callback(true);
          resolve(res.data);
        } else {
          throw new Error(`Response status: ${res.status}`);
        }
      })
      .catch(err => {
        dispatch(editMapPortalCompositionFailed());
        if (showNotification) {
          dispatch(
            showError(
              'Wystąpił błąd podczas zapisywania ustawień kompozycji panelu bocznego.'
            )
          );
        }
        callback && callback(false);
        reject(err);
      });
  });

const fetchDistrictCompositionsBegin = prefix => dispatch => {
  dispatch({
    type: FETCH_DISTRICT_COMPOSITIONS_BEGIN,
    prefix: prefix
  });
};

export const fetchDistrictCompositions = (prefix, forceFetch) => (
  dispatch,
  getState
) => {
  const state = getState();
  const fetched_compostions = state.mapPortals
    .get('district_compositions')
    .toJS();

  if (Object.keys(fetched_compostions).indexOf(prefix) < 0 || forceFetch) {
    dispatch(fetchDistrictCompositionsBegin(prefix));

    return axios
      .get(`${prefix}/composition/`)
      .then(response => {
        dispatch({
          type: FETCH_DISTRICT_COMPOSITIONS_SUCCESS,
          data: response.data,
          prefix
        });
      })
      .catch(err => {
        const lang = getState().global.get('language');

        dispatch(
          showError(
            translations.mapPortal.fetchDistrictCompositionsErrorMessage[lang]
          )
        );
      });
  }

  return null;
};

//RTQ
export const fetchMapPortalCompositionsBegin = () => ({
  type: FETCH_MAP_PORTAL_COMPOSITIONS_BEGIN
});

export const fetchMapPortalCompositionsSuccess = ({
  compositions,
  district,
  mapPortalId
}) => ({
  type: FETCH_MAP_PORTAL_COMPOSITIONS_SUCCESS,
  compositions,
  district,
  mapPortalId
});

export const fetchMapPortalCompositionsFailed = () => ({
  type: FETCH_MAP_PORTAL_COMPOSITIONS_FAILED
});

const saveDistrictMapPortalsNames = mapPortalsNames => ({
  type: SAVE_DISTRICT_MAP_PORTALS_NAMES,
  mapPortalsNames
});

export const addCompositionToMapPortalBegin = () => ({
  type: ADD_COMPOSITION_TO_MAP_PORTAL_BEGIN
});

export const addCompositionToMapPortalSuccess = (
  composition,
  district,
  mapPortalId
) => ({
  type: ADD_COMPOSITION_TO_MAP_PORTAL_SUCCESS,
  composition,
  district,
  mapPortalId
});

export const addCompositionToMapPortalFailed = () => ({
  type: ADD_COMPOSITION_TO_MAP_PORTAL_FAILED
});

export const addCompositionToMapPortal = (
  composition,
  district,
  mapPortalId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addCompositionToMapPortalBegin());

    axios
      .post(
        `${district}/map_portal/${mapPortalId}/map_portal_compositions/`,
        composition
      )
      .then(response => {
        dispatch(
          addCompositionToMapPortalSuccess(response.data, district, mapPortalId)
        );
        resolve(response.data);
        dispatch(showSuccess('Przypisano kompozycję do portalu mapowego.'));
      })
      .catch(err => {
        dispatch(
          showError(
            'Wystąpił błąd podczas przypisywania kompozycji do portalu mapowego.'
          )
        );
        dispatch(addCompositionToMapPortalFailed());
        reject(err);
      });
  });

export const removeCompositionFromMapPortalBegin = () => ({
  type: REMOVE_COMPOSITION_FROM_MAP_PORTAL_BEGIN
});

export const removeCompositionFromMapPortalSuccess = (
  compositionId,
  district,
  mapPortalId
) => ({
  type: REMOVE_COMPOSITION_FROM_MAP_PORTAL_SUCCESS,
  compositionId,
  district,
  mapPortalId
});

export const removeCompositionFromMapPortalFailed = () => ({
  type: REMOVE_COMPOSITION_FROM_MAP_PORTAL_FAILED
});

export const removeCompositionFromMapPortal = (
  compositionId,
  district,
  mapPortalId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(removeCompositionFromMapPortalBegin());

    axios
      .delete(
        `${district}/map_portal/${mapPortalId}/map_portal_compositions/${compositionId}/`
      )
      .then(() => {
        resolve();
        dispatch(
          removeCompositionFromMapPortalSuccess(
            compositionId,
            district,
            mapPortalId
          )
        );

        dispatch(showSuccess('Usunięto kompozycję z portalu mapowego.'));
      })
      .catch(err => {
        dispatch(
          showError(
            'Wystąpił błąd podczas usuwania kompozycji z portalu mapowego.'
          )
        );
        dispatch(removeCompositionFromMapPortalFailed());
        reject(err);
      });
  });
/**
 * Fetchs only one district map portal by given id
 *
 * @param {*} dispatch
 * @returns promise
 */

//RTQ
export const fetchDistrictMapPortalBegin = () => ({
  type: FETCH_DISTRICT_MAP_PORTAL_BEGIN
});

export const fetchDistrictMapPortalSuccess = (
  editedMapPortalId,
  editedData,
  district
) => ({
  type: CHANGE_MAP_PORTAL_SUCCESS,
  editedMapPortalId,
  editedData,
  district
});

export const fetchMissingDistrictMapPortalSuccess = (
  editedMapPortalId,
  editedData,
  district
) => ({
  type: FETCH_DISTRICT_MISSING_MAP_PORTAL_SUCCESS,
  editedMapPortalId,
  editedData,
  district
});

export const fetchDistrictMapPortalFailed = () => ({
  type: FETCH_DISTRICT_MAP_PORTAL_FAILED
});

/**
 * Fetchs missing district map portal by given id
 *
 * @param {*} dispatch
 * @returns promise
 */
export const fetchMissingDistrictMapPortal = (prefix, mapPortalId) => (
  dispatch,
  getState
) => {
  dispatch(fetchDistrictMapPortalBegin());
  return new Promise((resolve, reject) =>
    axios
      .get(`${prefix}/map_portal/${mapPortalId}/`)
      .then(response => {
        if (response.data) {
          dispatch(
            fetchMissingDistrictMapPortalSuccess(
              mapPortalId,
              response.data,
              prefix
            )
          );
          resolve(response.data);
        }
      })
      .catch(err => {
        handleError(err);
        dispatch(fetchDistrictMapPortalFailed());
        reject();
      })
  );
};

/**
 * Fetchs all district map portals
 *
 * @param {*} dispatch
 * @returns promise
 */

export const fetchDistrictMapPortalsBegin = prefix => ({
  type: FETCH_DISTRICT_MAP_PORTALS_BEGIN,
  prefix: prefix
});

export const fetchDistrictMapPortals = ({
  prefix,
  filterEmpty = false,
  page = 1,
  page_size = PAGE_SIZE,
  search,
  withoutPagination = false,
  cancelToken
}) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(fetchDistrictMapPortalsBegin(prefix));

    const buildMapPortalUrl = prefix => {
      return prefix ? `${prefix}/map_portal/` : `map_portal/`;
    };

    const url = buildMapPortalUrl(prefix, filterEmpty);
    axios
      .get(url, {
        params: !withoutPagination
          ? {
              filter_empty: filterEmpty,
              page,
              page_size: page_size,
              search
            }
          : {},
        cancelToken: cancelToken
      })
      .then(response => {
        const mapPortalsNames = response.data.results.map(
          mapPortal => mapPortal.name
        );

        dispatch({
          type: FETCH_DISTRICT_MAP_PORTALS_SUCCESS,
          prefix: prefix,
          mapPortals: response.data.results,
          filterEmpty,
          mapPortalsCount: response.data.count
        });
        if (mapPortalsNames.length) {
          dispatch(saveDistrictMapPortalsNames(mapPortalsNames));
        }
        resolve(response.data);
      })
      .catch(err => {
        if (axios.isCancel(err)) return reject(err);
        const lang = getState().global.get('language');

        dispatch(
          showError(
            translations.mapPortal.fetchDistrictMapPortalsErrorMessage[lang]
          )
        );
        reject(err);
      });
  });
/**
 * Akcja „odświeża” portale mapowe, które zostały pobrane do store'a
 * poprzez ponowne pobranie ich z API.
 */
export const refetchDistrictMapPortals = () => (dispatch, getState) => {
  const state = getState();
  const fetchedMapPortals = [
    ...new Set(state.mapPortals.get('fetchedDistrictMapPortals').toJS())
  ];
  const filterEmpty = state.mapPortals.get('filterEmpty');
  const fetchMapPortals = fetchedMapPortals.map(district =>
    dispatch(
      fetchDistrictMapPortals({
        prefix: district,
        filterEmpty
      })
    )
  );
  return Promise.all([...fetchMapPortals]);
};

export const showLegend = () => ({
  type: SHOW_LEGEND
});

export const hideLegend = () => ({
  type: HIDE_LEGEND
});

export const fetchLegendBegin = (id, endpoint) => ({
  type: FETCH_LEGEND_BEGIN,
  id,
  endpoint
});

export const fetchLegendSuccess = payload => ({
  type: FETCH_LEGEND_SUCCESS,
  payload
});

export const fetchLegendFailed = (id, endpoint, params) => ({
  type: FETCH_LEGEND_FAILED,
  id,
  endpoint,
  params
});

export const fetchLegend = params => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const state = getState();

    const {
      prefix,
      projectId,
      enabledLayers,
      styles,
      queryParams = {},
      layerId = 'null',
      fetchJSON = false
    } = params;
    const resolvePromise = (url, legendObject) =>
      resolve(fetchJSON ? legendObject : url);

    const basicQueryParams = {
      layers: enabledLayers,
      styles,
      BOXSPACE: 7,
      SYMBOLSPACE: 2.5,
      SRS: queryParams.srs,
      BBOX: queryParams.bbox,
      WIDTH: queryParams.width,
      HEIGHT: queryParams.height
    };

    const jsonFormatQueryParams = {
      ...basicQueryParams,
      FORMAT: 'application/json'
    };

    const endpoint = queryString.stringifyUrl({
      url: `${prefix}/get_legend/${projectId}/`,
      query: fetchJSON ? jsonFormatQueryParams : basicQueryParams
    });

    dispatch(fetchLegendBegin(layerId, endpoint));

    if (!enabledLayers) {
      const url = null;
      const legendObject = null;

      dispatch(
        fetchLegendSuccess({
          id: layerId,
          endpoint,
          legendObject,
          url,
          params
        })
      );

      return resolvePromise(url, legendObject);
    }

    const fetchedLegends = state.mapPortals.get('fetchedLegends').toJS();
    if (fetchedLegends[endpoint]) {
      dispatch(
        fetchLegendSuccess({
          id: layerId,
          endpoint,
          legendObject: fetchedLegends[endpoint].legendObject,
          url: fetchedLegends[endpoint].url,
          params
        })
      );

      const { url, legendObject } = fetchedLegends[endpoint];

      return resolvePromise(url, legendObject);
    }

    const config = {
      responseType: fetchJSON ? 'json' : 'blob'
    };

    const handleLegendJSON = data => {
      dispatch(
        fetchLegendSuccess({
          id: layerId,
          endpoint,
          legendObject: data,
          url: null,
          params
        })
      );

      resolve(data);
    };

    const handleLegendImage = image => {
      const reader = new FileReader();

      reader.readAsDataURL(image);
      reader.onload = async e => {
        try {
          const validImageMinSize = 50;
          const imageBase64 = e.target.result;
          const { width } = await getBase64ImageSize(imageBase64);
          const img = width > validImageMinSize ? imageBase64 : null;

          dispatch(
            fetchLegendSuccess({
              id: layerId,
              endpoint,
              legendObject: null,
              url: img,
              params
            })
          );
          resolve(img);
        } catch (err) {
          reject(err);
        }
      };
    };

    axios
      .get(endpoint, config)
      .then(async res => {
        const { data } = res;

        if (fetchJSON) {
          handleLegendJSON(data);
          return;
        }

        handleLegendImage(data);
      })
      .catch(e => {
        const lang = getState().global.get('language');

        dispatch(fetchLegendFailed(layerId, endpoint, params));
        dispatch(
          showError(translations.mapPortal.fetchLegendErrorMessage[lang])
        );
        reject(e);
      });
  });

export const clearFetchingLegend = () => ({
  type: CLEAR_FETCHING_LEGEND
});

export const createMapPortalBegin = () => ({
  type: CREATE_MAP_PORTAL_BEGIN
});

export const createMapPortalSuccess = (newMapPortalData, district) => ({
  type: CREATE_MAP_PORTAL_SUCCESS,
  newMapPortalData,
  district
});

export const createMapPortalFailed = () => ({
  type: CREATE_MAP_PORTAL_FAILED
});

export const createMapPortal = (mapPortalData, district) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(createMapPortalBegin());
    const tools = mapPortalData.tools;
    delete mapPortalData.tools;
    const config = {
      headers: {
        'Content-Type':
          mapPortalData.rpo_logo || mapPortalData.logo
            ? 'multipart/form-data'
            : 'application/json'
      }
    };
    axios
      .post(`${district}/map_portal/`, mapPortalData, config)
      .then(response => {
        dispatch(showSuccess('Dodano nowy portal mapowy.'));
        dispatch(updateMapPortalTools(tools, response.data.id));
        dispatch(createMapPortalSuccess(response.data, district));
        resolve(response.data);
      })
      .catch(err => {
        const errorMessage = parseResponseError(err, {
          unique_together: 'Portal mapowy o podanej nazwie już istnieje.'
        });

        dispatch(createMapPortalFailed());
        dispatch(
          showError(
            `Wystąpił błąd podczas dodawania portalu mapowego. ${errorMessage}`
          )
        );
        reject(err);
      });
  });

export const editMapPortalBegin = () => ({
  type: EDIT_MAP_PORTAL_BEGIN
});

export const editMapPortalSuccess = (
  editedMapPortalId,
  editedData,
  district
) => ({
  type: CHANGE_MAP_PORTAL_SUCCESS,
  editedMapPortalId,
  editedData,
  district
});

export const editMapPortalFailed = () => ({
  type: EDIT_MAP_PORTAL_FAILED
});

export const editMapPortal = (
  mapPortalId,
  mapPortalData,
  district,
  callback,
  config = {},
  customMessage
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editMapPortalBegin());
    const tools = mapPortalData.tools;
    delete mapPortalData.tools;
    const params = {
      headers: {
        'Content-Type':
          mapPortalData.rpo_logo || mapPortalData.logo
            ? 'multipart/form-data'
            : 'application/json'
      }
    };
    axios
      .patch(`${district}/map_portal/${mapPortalId}/`, mapPortalData, params)
      .then(async response => {
        dispatch(editMapPortalSuccess(mapPortalId, response.data, district));
        if (tools) {
          await dispatch(updateMapPortalTools(tools, mapPortalId));
        }

        if (!config.hideMessage) {
          dispatch(showSuccess(customMessage ?? 'Zmiany zostały zapisane.'));
        }

        callback && callback(true);
        resolve(response.data);
      })
      .catch(err => {
        const errorMessage = parseResponseError(err);
        dispatch(editMapPortalFailed());

        if (!config.hideMessage) {
          dispatch(
            showError(
              `Wystąpił błąd podczas edytowania portalu mapowego. ${errorMessage}`
            )
          );
        }

        callback && callback(false);
        reject(err);
      });
  });

export const deleteMapPortalBegin = () => ({
  type: DELETE_MAP_PORTAL_BEGIN
});

export const deleteMapPortalSuccess = (mapPortalId, district) => ({
  type: DELETE_MAP_PORTAL_SUCCESS,
  mapPortalId,
  district
});

export const deleteMapPortalFailed = () => ({
  type: DELETE_MAP_PORTAL_FAILED
});

export const deleteMapPortal = (mapPortalId, district) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteMapPortalBegin(mapPortalId, district));

    axios
      .delete(`${district}/map_portal/${mapPortalId}/`)
      .then(() => {
        dispatch(deleteMapPortalSuccess(mapPortalId, district));
        dispatch(showSuccess('Portal mapowy został usunięty.'));
        resolve();
      })
      .catch(e => {
        dispatch(deleteMapPortalFailed(mapPortalId, district));
        dispatch(showError('Wystąpił błąd podczas usuwania portalu mapowego.'));
        reject(e);
      });
  });
/**
 * Update ordering map portals
 *
 * @param {*} prefix - District prefix
 * @param {*} indexData - new index data [{id:Nubmer, index:number}, ...]
 * @returns
 */

export const updateOrderingMapPortalBegin = () => ({
  type: UPDATE_ORDER_MAP_PORTAL_BEGIN
});

export const updateOrderingMapPortalSuccess = (district, indexData) => ({
  type: UPDATE_ORDER_MAP_PORTAL_SUCCESS,
  district,
  indexData
});

export const updateOrderingMapPortalFailed = () => ({
  type: UPDATE_ORDER_MAP_PORTAL_FAILED
});

export const updateOrderingMapPortal = (district, indexData) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateOrderingMapPortalBegin(district));

    axios
      .put(`${district}/map_portal/ordering/`, indexData)
      .then(response => {
        dispatch(updateOrderingMapPortalSuccess(district, response.data));
        dispatch(showSuccess('Kolejność portali zaktualizowano pomyślnie!'));
        resolve();
      })
      .catch(err => {
        dispatch(updateOrderingMapPortalFailed(district));
        dispatch(showError(parseResponseError(err)));
        reject(err);
      });
  });

export const updateMapPortalToolsSuccess = () => ({
  type: EDIT_MAP_PORTAL_TOOLS
});

export const updateMapPortalTools = (tools, mapPortalId) => dispatch =>
  new Promise((resolve, reject) => {
    const url = `map_toolbar_tools/update_map_portal_tools/?map_portal_id=${mapPortalId}`;
    axios
      .post(url, tools)
      .then(response => {
        dispatch(updateMapPortalToolsSuccess());
        resolve();
      })
      .catch(e => {
        dispatch(showError(parseResponseError(e)));
        reject(e);
      });
  });

export const updateCheckedLayers = (legend, prefix, portal) => ({
  type: UPDATE_CHECKED_MAPPORTALS_LAYERS,
  legend: legend,
  prefix: prefix,
  portal: portal
});

/**
 * Fetches map portal layers if district map portals are not fetched
 * then fetch them and check if portal exist
 *
 * @param {*} prefix - District prefix
 * @param {*} short_name - map portal short name
 * @returns
 */

export const deleteWmsLayerFromComposition = (wmsId, layerId) => dispatch => {
  dispatch(removeLayerFromAddedWmsList(layerId));

  dispatch({
    type: DELETE_WMS_LAYER,
    wmsId,
    layerId
  });
};

export const deleteWmsFromComposition = wmsId => ({
  type: DELETE_WMS_FROM_COMPOSITION,
  wmsId
});

export const deleteCsvFromComposition = csvId => ({
  type: DELETE_CSV_FROM_COMPOSITION,
  csvId
});

export const addLayersToLayersMap = layersList => ({
  type: ADD_LAYERS_TO_LAYERS_MAP,
  layersList
});

export const addLayersToLayersIdMap = layersList => ({
  type: ADD_LAYERS_TO_LAYERS_ID_MAP,
  layersList
});

export const fetchQgisProjectsBegin = prefix => ({
  type: FETCH_QGIS_PROJECTS_BEGIN,
  prefix
});

/** fetchQgisProjectsBegin
 * Fetch Qgis project list
 * @param {str} prefix - District unique prefix name
 * @param {bool} forceFetching - Force Qgis project list fetching (after projects fetched)
 */
export const fetchQgisProjects = (prefix, forceFetching) => (
  dispatch,
  getState
) =>
  new Promise((resolve, reject) => {
    const state = getState();
    const qgis_projects = state.mapPortals.get('qgis_projects').get(prefix);

    if (!qgis_projects || forceFetching) {
      dispatch(fetchQgisProjectsBegin(prefix));

      const url = `${prefix}/qgis_project/`;
      return axios
        .get(url)
        .then(response => {
          dispatch({
            type: FETCH_QGIS_PROJECTS_SUCCESS,
            projects: response.data,
            prefix
          });
          resolve(response);
        })
        .catch(err => {
          const lang = getState().global.get('language');

          dispatch(
            showError(
              translations.mapPortal.fetchQgisProjectsErrorMessage[lang]
            )
          );
          reject(err);
        });
    }

    return null;
  });

export const updateQgisProjectDataBegin = () => ({
  type: UPDATE_QGIS_PROJECT_DATA_BEGIN
});

export const updateQgisProjectDataSuccess = (district, projectId, project) => ({
  type: UPDATE_QGIS_PROJECT_DATA_SUCCESS,
  district,
  projectId,
  project
});

export const updateQgisProjectDataFailed = () => ({
  type: UPDATE_QGIS_PROJECT_DATA_FAILED
});

export const updateQgisProjectData = (district, projectId, data) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateQgisProjectDataBegin());

    axios
      .patch(`${district}/qgis_project/${projectId}/`, data)
      .then(({ data }) => {
        dispatch(updateQgisProjectDataSuccess(district, projectId, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(updateQgisProjectDataFailed());
        reject(err);
      });
  });

export const deleteQgisProjectBegin = (prefix, id) => ({
  type: DELETE_QGIS_PROJECT_BEGIN,
  prefix,
  id
});

export const deleteQgisProjectSuccess = (prefix, id) => ({
  type: DELETE_QGIS_PROJECT_SUCCESS,
  prefix,
  id
});

export const deleteQgisProjectFailed = id => ({
  type: DELETE_QGIS_PROJECT_FAILED,
  id
});

/** fetchQgisProjectsBegin
 * Fetch Qgis project list
 * @param {str} prefix - District unique prefix name
 * @param {str} id - District unique id
 */
export const deleteQgisProject = (prefix, id) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteQgisProjectBegin(prefix, id));

    axios
      .delete(`${prefix}/qgis_project/${id}/`)
      .then(({ data }) => {
        // Refetch district projects
        dispatch(fetchQgisProjects(prefix));
        resolve(data);
        return dispatch(deleteQgisProjectSuccess(prefix, id));
      })
      .catch(err => {
        reject(err);
        dispatch(deleteQgisProjectFailed(id));
      });
  });

export const updateQgisProjectBegin = id => ({
  type: UPDATE_QGIS_PROJECT_BEGIN,
  id
});

export const updateQgisProjectSuccess = id => ({
  type: UPDATE_QGIS_PROJECT_SUCCESS,
  id
});

export const updateQgisProjectFailed = id => ({
  type: UPDATE_QGIS_PROJECT_FAILED,
  id
});

export const updateQgisProject = id => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(updateQgisProjectBegin(id));
    axios
      .post(`qgis_project/${id}/update_task/`)
      .then(response => {
        resolve(response);
        dispatch(updateQgisProjectSuccess(id));
      })
      .catch(err => {
        dispatch(updateQgisProjectFailed(id));
        reject(err);
      });
  });
};

export const fetchNetworkDriveFolderBegin = () => ({
  type: FETCH_NETWORK_DRIVE_FOLDER_BEGIN
});

export const fetchNetworkDriveFolderSuccess = folders => ({
  type: FETCH_NETWORK_DRIVE_FOLDER_SUCCESS,
  folders
});

export const fetchNetworkDriveFolderFailed = () => ({
  type: FETCH_NETWORK_DRIVE_FOLDER_FAILED
});

export const fetchNetworkDrive = district => (dispatch, getState) => {
  dispatch(fetchingNetworkDriveBeign());
  dispatch(fetchNetworkDriveFolderBegin());

  axios
    .get(`${district}/files/`)
    .then(res => {
      const { folders } = res.data;

      dispatch(fetchNetworkDriveFolderSuccess(folders));
      folders.forEach((folder, i) => {
        const lastItem = folders.length === i + 1;
        dispatch(fetchNetworkDriveFile(district, folder, lastItem));
      });
    })
    .catch(err => {
      const lang = getState().global.get('language');

      dispatch(fetchNetworkDriveFolderFailed());
      dispatch(fetchingNetworkDriveFailed());
      dispatch(
        showError(
          translations.mapPortal.fetchNetworkDriveStructureErrorMessage[lang]
        )
      );
    });
};

export const fetchNetworkDriveFileBegin = () => ({
  type: FETCH_NETWORK_DRIVE_FILE_BEGIN
});

export const fetchNetworkDriveFileSuccess = (folder, files) => ({
  type: FETCH_NETWORK_DRIVE_FILE_SUCCESS,
  folder,
  files
});

export const fetchNetworkDriveFileFailed = () => ({
  type: FETCH_NETWORK_DRIVE_FILE_FAILED
});

export const fetchNetworkDriveFile = (district, folder, lastFile) => (
  dispatch,
  getState
) => {
  dispatch(fetchNetworkDriveFileBegin());

  axios
    .get(`${district}/files/`, {
      params: {
        path: folder
      }
    })
    .then(res => {
      dispatch(fetchNetworkDriveFileSuccess(folder, res.data.files));

      if (lastFile) {
        dispatch(fetchingNetworkDriveSuccess());
      }
    })
    .catch(err => {
      const lang = getState().global.get('language');

      dispatch(fetchNetworkDriveFileFailed());
      dispatch(fetchingNetworkDriveFailed());
      dispatch(
        showError(
          `${translations.mapPortal.fetchNetworkDriveCatalogErrorMessage[lang]} ${folder}!`
        )
      );
    });
};

export const fetchingNetworkDriveBeign = () => ({
  type: FETCHING_NETWORK_DRIVE_BEGIN
});

export const fetchingNetworkDriveSuccess = () => ({
  type: FETCHING_NETWORK_DRIVE_SUCCESS
});

export const fetchingNetworkDriveFailed = () => ({
  type: FETCHING_NETWORK_DRIVE_FAILED
});

export const selectProjectFromNetworkDrive = data => async (
  dispatch,
  getState
) => {
  dispatch(selectProjectFromNetworkDriveBeign());
  const { isAdding, config } = data;
  let { request } = data;
  if (!isAdding) {
    const { path, district } = data;
    const state = getState();
    const districtState = state.district.get('districts_data').get(district);
    let districtId;

    if (districtState.size) {
      districtId = districtState.get('data').get('id');
    } else {
      districtId = await axios.get(`${district}/details/`).data.id;
    }

    request = {
      file: path,
      district: districtId,
      qgis_server_version: 3
    };
  }

  axios
    .post('qgis_project/', request, config)
    .then(r => {
      localStorage.setItem('checkStatusUploadingQgisProject', 1);
      localStorage.setItem('uploadingProjectUUID', r.data.job_id);
      localStorage.setItem('uploadingProjectError', '');

      dispatch(
        selectProjectFromNetworkDriveStatus(
          r.data.status,
          r.data.job_id,
          r.data.info && r.data.info.current ? r.data.info.current : 0
        )
      );
    })
    .catch(err => {
      let errResponse = err?.response?.data || '';
      errResponse =
        err?.response?.status === 500 ? 'Internal Server Error' : errResponse;
      localStorage.setItem('checkStatusUploadingQgisProject', 0);
      localStorage.setItem('uploadingProjectUUID', '');
      localStorage.setItem('uploadingProjectError', errResponse);

      dispatch(selectProjectFromNetworkDriveFailed(errResponse));
    });
};

export const selectProjectFromNetworkDriveBeign = () => ({
  type: SELECT_PROJECT_FROM_NETWORK_DRIVE_BEGIN
});

export const selectProjectFromNetworkDriveSuccess = () => ({
  type: SELECT_PROJECT_FROM_NETWORK_DRIVE_SUCCESS
});

export const selectProjectFromNetworkDriveFailed = error => ({
  type: SELECT_PROJECT_FROM_NETWORK_DRIVE_FAILED,
  error
});

export const clearSelectingNdProjectStatus = () => ({
  type: CLEAR_SELECTING_ND_PROJECT_STATUS
});

export const clearMapPortalReducerState = () => ({
  type: CLEAR_MAP_PORTAL_REDUCER_STATE
});

export const selectProjectFromNetworkDriveStatus = (
  status = 'STARTED',
  uuidJob,
  progressAddingProject
) => ({
  type: SELECT_PROJECT_FROM_NETWORK_DRIVE_STATUS,
  status,
  uuidJob,
  progressAddingProject
});

export const getJobStatusFromAddedProject = (
  status = 'STARTED',
  progressAddingProject
) => ({
  type: GET_JOB_STATUS_FROM_ADDED_PROJECT,
  status,
  progressAddingProject
});

export const getJobStatus = uuidJob => (dispatch, getState) => {
  axios
    .get(`get_job_status/${uuidJob}/`)
    .then(r => {
      const progress =
        r.data.info !== null && r.data.info !== undefined
          ? r.data.info.current
          : 100;
      const selectingProjectFromNetworkDrive = getState().mapPortals.get(
        'selectingProjectFromNetworkDrive'
      );

      if (selectingProjectFromNetworkDrive !== responseStatuses.SUCCESS) {
        dispatch(getJobStatusFromAddedProject(r.data.status, progress));
      }
      if (r.data.status === 'FAILURE') {
        let error_message = 'Wystąpił błąd podczas dodawania projektu.';
        if (r.data.info) {
          error_message = Object.keys(r.data.info)
            .map(elem => {
              return r.data.info[elem].map(v => {
                return v.message;
              });
            })
            .join('. ');
        }

        localStorage.setItem('checkStatusUploadingQgisProject', 0);
        localStorage.setItem('uploadingProjectUUID', '');
        localStorage.setItem('uploadingProjectError', error_message);

        dispatch(selectProjectFromNetworkDriveFailed(error_message));
      }
    })
    .catch(err => {
      const errResponse = (err && err.response && err.response.data) || '';

      localStorage.setItem('checkStatusUploadingQgisProject', 0);
      localStorage.setItem('uploadingProjectUUID', '');
      localStorage.setItem('uploadingProjectError', errResponse);

      dispatch(selectProjectFromNetworkDriveFailed(errResponse));
    });
};

export const setEmptyPhotoComposition = (
  district,
  mapPortalId,
  compositionId,
  photos
) => (dispatch, getState) => {
  dispatch({
    type: SET_PHOTOS_COMPOSITION,
    compositionId,
    photos,
    mapPortalId,
    district
  });
};

//RTQ
export const fetchRasterTimelineBegin = () => ({
  type: FETCH_RASTER_TIMELINE_BEGIN
});

export const fetchRasterTimelineSuccess = rasterTimelines => ({
  type: FETCH_RASTER_TIMELINE_SUCCESS,
  default: rasterTimelines
});

export const fetchRasterTimelineFailed = () => ({
  type: FETCH_RASTER_TIMELINE_FAILED
});

export const addRasterTimelineBegin = () => ({
  type: ADD_RASTER_TIMELINE_BEGIN
});

export const addRasterTimelineSuccess = rasterTimelines => ({
  type: ADD_RASTER_TIMELINE_SUCCESS,
  default: rasterTimelines
});

export const addRasterTimelineFailed = () => ({
  type: ADD_RASTER_TIMELINE_FAILED
});

export const addRasterTimeline = params => dispatch => {
  const { district, mapPortalId, data } = params;
  return new Promise((resolve, reject) => {
    dispatch(addRasterTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/raster_timeline/`;
    axios
      .post(url, data)
      .then(response => {
        dispatch(addRasterTimelineSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addRasterTimelineFailed());
        reject(err);
      });
  });
};

export const deleteRasterTimelineBegin = () => ({
  type: DELETE_RASTER_TIMELINE_BEGIN
});

export const deleteRasterTimelineSuccess = timelineId => ({
  type: DELETE_RASTER_TIMELINE_SUCCESS,
  timelineId
});

export const deleteRasterTimelineFailed = () => ({
  type: DELETE_RASTER_TIMELINE_FAILED
});

export const deleteRasterTimeline = params => dispatch => {
  const { district, mapPortalId, timelineId } = params;
  return new Promise((resolve, reject) => {
    dispatch(deleteRasterTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/raster_timeline/${timelineId}/`;
    axios
      .delete(url)
      .then(response => {
        dispatch(deleteRasterTimelineSuccess(timelineId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteRasterTimelineFailed());
        reject(err);
      });
  });
};

export const updateRasterTimelineBegin = () => ({
  type: UPDATE_RASTER_TIMELINE_BEGIN
});

export const updateRasterTimelineSuccess = payload => ({
  type: UPDATE_RASTER_TIMELINE_SUCCESS,
  payload
});

export const updateRasterTimelineFailed = () => ({
  type: UPDATE_RASTER_TIMELINE_FAILED
});

export const updateRasterTimeline = params => dispatch => {
  const { district, mapPortalId, timelineId, data } = params;
  return new Promise((resolve, reject) => {
    dispatch(updateRasterTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/raster_timeline/${timelineId}/`;
    axios
      .patch(url, data)
      .then(response => {
        dispatch(updateRasterTimelineSuccess({ timelineId, data }));
        resolve();
      })
      .catch(err => {
        dispatch(updateRasterTimelineFailed());
        reject(err);
      });
  });
};

export const getPublicWMSBegin = () => ({
  type: GET_PUBLIC_WMS_BEGIN
});

export const getPublicWMSSuccess = wms => ({
  type: GET_PUBLIC_WMS_SUCCESS,
  wms
});

export const getPublicWMSFailed = () => ({
  type: GET_PUBLIC_WMS_FAILED
});

export const getPublicWMS = (district, mapPortal) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getPublicWMSBegin());
    axios
      .get(`${district}/map_portal/${mapPortal}/wms/`)
      .then(({ data }) => {
        dispatch(getPublicWMSSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getPublicWMSFailed());
        reject(err);
      });
  });

export const addCompositionWmsEntry = entry => dispatch => {
  dispatch(insertToAddedWmsList(entry));

  return dispatch({
    type: ADD_COMPOSITION_WMS_ENTRY,
    entry
  });
};

export const addCompositionCsvEntry = entry => dispatch => {
  dispatch(insertToAddedCsvList(entry));

  return dispatch({
    type: ADD_COMPOSITION_CSV_ENTRY,
    entry
  });
};

export const insertToAddedWmsList = (entry, compositionId) => ({
  type: INSERT_TO_ADDED_WMS_LIST,
  compositionId,
  entry
});

export const insertToAddedCsvList = (entry, compositionId) => ({
  type: INSERT_TO_ADDED_CSV_LIST,
  compositionId,
  entry
});

export const removeLayerFromAddedWmsList = (layerId, compositionId) => ({
  type: REMOVE_LAYER_FROM_ADDED_WMS_LIST,
  compositionId,
  layerId
});

export const clearAddedWmsList = compositionId => ({
  type: CLEAR_ADDED_WMS_LIST,
  compositionId
});
export const clearAddedCsvList = compositionId => ({
  type: CLEAR_ADDED_CSV_LIST,
  compositionId
});

export const updateCompositionEntriesOrder = (
  compositionId,
  newOrder,
  parentId
) => ({
  type: UPDATE_COMPOSITION_ENTRIES_ORDER,
  compositionId,
  newOrder,
  parentId
});

export const setActiveTool = activeTool => ({
  type: SET_ACTIVE_TOOL,
  activeTool
});

export const resetActiveTool = () => ({
  type: RESET_ACTIVE_TOOL
});

//RTQ
export const fetchPortalTagsBegin = () => ({
  type: FETCH_PORTAL_TAGS_BEGIN
});

export const fetchPortalTagsFailed = () => ({
  type: FETCH_PORTAL_TAGS_FAILED
});

export const editPortalTagsBegin = () => ({
  type: EDIT_PORTAL_TAGS_BEGIN
});

export const editPortalTagsSucces = () => ({
  type: EDIT_PORTAL_TAGS_SUCCESS
});

export const editPortalTagsFailed = () => ({
  type: EDIT_PORTAL_TAGS_FAILED
});

export const editPortalTags = (
  district,
  mapPortalId,
  newTagOrder
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editPortalTagsBegin());
    axios
      .post(`${district}/ordered_tags/map_portal/${mapPortalId}/`, newTagOrder)
      .then(() => {
        dispatch(editPortalTagsSucces());
        resolve();
      })
      .catch(err => {
        dispatch(editPortalTagsFailed());
        reject(err);
      });
  });

//RTQ
export const fetchVectorTimelinesBegin = () => ({
  type: FETCH_VECTOR_TIMELINES_BEGIN
});

export const fetchVectorTimelinesSuccess = rasterTimelines => ({
  type: FETCH_VECTOR_TIMELINES_SUCCESS,
  default: rasterTimelines
});

export const fetchVectorTimelinesFailed = () => ({
  type: FETCH_VECTOR_TIMELINES_FAILED
});

export const addVectorTimelineBegin = () => ({
  type: ADD_VECTOR_TIMELINE_BEGIN
});

export const addVectorTimelineSuccess = timeline => ({
  type: ADD_VECTOR_TIMELINE_SUCCESS,
  default: timeline
});

export const addVectorTimelineFailed = () => ({
  type: ADD_VECTOR_TIMELINE_FAILED
});

export const addVectorTimeline = ({
  district,
  mapPortalId,
  data
}) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(addVectorTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/layer_timeline/`;
    axios
      .post(url, data)
      .then(response => {
        dispatch(addVectorTimelineSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addVectorTimelineFailed());
        reject(err);
      });
  });
};

export const deleteVectorTimelineBegin = () => ({
  type: DELETE_VECTOR_TIMELINE_BEGIN
});

export const deleteVectorTimelineSuccess = timelineId => ({
  type: DELETE_VECTOR_TIMELINE_SUCCESS,
  timelineId
});

export const deleteVectorTimelineFailed = () => ({
  type: DELETE_VECTOR_TIMELINE_FAILED
});

export const deleteVectorTimeline = ({
  district,
  mapPortalId,
  timelineId
}) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(deleteVectorTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/layer_timeline/${timelineId}/`;
    axios
      .delete(url)
      .then(response => {
        dispatch(deleteVectorTimelineSuccess(timelineId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteVectorTimelineFailed());
        reject(err);
      });
  });
};

export const updateVectorTimelineBegin = () => ({
  type: UPDATE_VECTOR_TIMELINE_BEGIN
});

export const updateVectorTimelineSuccess = () => ({
  type: UPDATE_VECTOR_TIMELINE_SUCCESS
});

export const updateVectorTimelineFailed = () => ({
  type: UPDATE_VECTOR_TIMELINE_FAILED
});

export const updateVectorTimeline = ({
  district,
  mapPortalId,
  timelineId,
  data
}) => dispatch => {
  return new Promise((resolve, reject) => {
    dispatch(updateVectorTimelineBegin());
    const url = `${district}/map_portal/${mapPortalId}/layer_timeline/${timelineId}/`;
    axios
      .patch(url, data)
      .then(response => {
        dispatch(updateVectorTimelineSuccess());
        resolve(response.data);
      })
      .catch(err => {
        dispatch(updateVectorTimelineFailed());
        reject(err);
      });
  });
};

export const fetchProjectLayersBegin = () => ({
  type: GET_PROJECT_LAYERS_BEGIN
});

export const fetchProjectLayersSuccess = ({ projectId, projectLayers }) => ({
  type: GET_PROJECT_LAYERS_SUCCESS,
  projectId,
  projectLayers
});

export const fetchProjectLayersFailed = () => ({
  type: GET_PROJECT_LAYERS_FAILED
});

export const fetchProjectLayers = (projectId, params) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchProjectLayersBegin());

    axios
      .get(`project_layers/${projectId}/`, {
        params: params && {
          composition_name: params.compositionName,
          name: params.name
        }
      })
      .then(response => {
        const projectLayers = response.data;

        if (!params) {
          dispatch(fetchProjectLayersSuccess({ projectId, projectLayers }));
        }

        resolve(projectLayers);
      })
      .catch(err => {
        dispatch(fetchProjectLayersFailed());
        reject(err);
      });
  });

//RTQ
export const fetchSpotMeasureConfigBegin = () => ({
  type: GET_SPOT_MEASURE_CONFIG_BEGIN
});

export const fetchSpotMeasureConfigSuccess = ({
  prefix,
  mapPortalId,
  measureConfig
}) => ({
  type: GET_SPOT_MEASURE_CONFIG_SUCCESS,
  prefix,
  mapPortalId,
  measureConfig
});

export const fetchSpotMeasureConfigFailed = () => ({
  type: GET_SPOT_MEASURE_CONFIG_FAILED
});

export const addSpotMeasureLayerBegin = () => ({
  type: ADD_SPOT_MEASURE_LAYER_BEGIN
});

export const addSpotMeasureLayerSuccess = ({
  prefix,
  mapPortalId,
  measureConfig
}) => ({
  type: ADD_SPOT_MEASURE_LAYER_SUCCESS,
  prefix,
  mapPortalId,
  measureConfig
});

export const addSpotMeasureLayerFailed = () => ({
  type: ADD_SPOT_MEASURE_LAYER_FAILED
});

export const addSpotMeasureLayer = (prefix, mapPortalId, layer) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addSpotMeasureLayerBegin());

    axios
      .post(`${prefix}/map_portal/${mapPortalId}/measurment_layers/`, layer)
      .then(response => {
        const measureConfig = response.data;

        dispatch(
          addSpotMeasureLayerSuccess({
            prefix,
            mapPortalId,
            measureConfig
          })
        );

        resolve(measureConfig);
      })
      .catch(err => {
        dispatch(addSpotMeasureLayerFailed());
        reject(err);
      });
  });

export const editSpotMeasureLayerBegin = () => ({
  type: EDIT_SPOT_MEASURE_LAYER_BEGIN
});

export const editSpotMeasureLayerSuccess = (
  prefix,
  mapPortalId,
  layerId,
  config
) => ({
  type: EDIT_SPOT_MEASURE_LAYER_SUCCESS,
  prefix,
  mapPortalId,
  layerId,
  config
});

export const editSpotMeasureLayerFailed = () => ({
  type: EDIT_SPOT_MEASURE_LAYER_FAILED
});

export const editSpotMeasureLayer = (
  prefix,
  mapPortalId,
  layerId,
  changes
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editSpotMeasureLayerBegin());

    const path = `${prefix}/map_portal/${mapPortalId}/measurment_layers/${layerId}/`;

    axios
      .patch(path, changes)
      .then(({ data }) => {
        dispatch(
          editSpotMeasureLayerSuccess(prefix, mapPortalId, layerId, data)
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(editSpotMeasureLayerFailed());
        reject(err);
      });
  });

export const deleteSpotMeasureLayerBegin = () => ({
  type: DELETE_SPOT_MEASURE_LAYER_BEGIN
});

export const deleteSpotMeasureLayerSuccess = ({
  prefix,
  mapPortalId,
  layerId,
  assignedLayerId
}) => ({
  type: DELETE_SPOT_MEASURE_LAYER_SUCCESS,
  prefix,
  mapPortalId,
  layerId,
  assignedLayerId
});

export const deleteSpotMeasureLayerFailed = () => ({
  type: DELETE_SPOT_MEASURE_LAYER_FAILED
});

export const deleteSpotMeasureLayer = (
  prefix,
  mapPortalId,
  layerId,
  assignedLayerId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteSpotMeasureLayerBegin());

    axios
      .delete(
        `${prefix}/map_portal/${mapPortalId}/measurment_layers/${layerId}/`
      )
      .then(() => {
        dispatch(
          deleteSpotMeasureLayerSuccess({
            prefix,
            mapPortalId,
            layerId,
            assignedLayerId
          })
        );
        resolve();
      })
      .catch(err => {
        dispatch(deleteSpotMeasureLayerFailed());
        reject(err);
      });
  });

export const setEnableGeolocation = (enable, coords) => ({
  type: SET_ENABLE_GEOLOCATION,
  enable,
  coords
});

export const setSetingGeolocation = setting => ({
  type: SETTING_GEOLOCATION,
  payload: setting
});

export const getCompositionSidebarSettingsBegin = () => ({
  type: GET_COMPOSITION_SIDEBAR_SETTINGS_BEGIN
});

export const getCompositionSidebarSettingsSuccess = (
  district,
  mapPortalCompositionId,
  settings
) => ({
  type: GET_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  district,
  mapPortalCompositionId,
  settings
});

export const getCompositionSidebarSettingsFailed = () => ({
  type: GET_COMPOSITION_SIDEBAR_SETTINGS_FAILED
});

export const getCompositionSidebarSettings = (
  district,
  mapPortalCompositionId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getCompositionSidebarSettingsBegin());

    axios
      .get(`${district}/map_portal_composition/${mapPortalCompositionId}/tabs/`)
      .then(({ data }) => {
        dispatch(
          getCompositionSidebarSettingsSuccess(
            district,
            mapPortalCompositionId,
            data
          )
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(getCompositionSidebarSettingsFailed());
        reject(err);
      });
  });

export const updateCompositionSidebarSettingsBegin = () => ({
  type: UPDATE_COMPOSITION_SIDEBAR_SETTINGS_BEGIN
});

export const updateCompositionSidebarSettingsSuccess = (
  district,
  mapPortalCompositionId,
  settings
) => ({
  type: UPDATE_COMPOSITION_SIDEBAR_SETTINGS_SUCCESS,
  district,
  mapPortalCompositionId,
  settings
});

export const updateCompositionSidebarSettingsFailed = () => ({
  type: UPDATE_COMPOSITION_SIDEBAR_SETTINGS_FAILED
});

export const updateCompositionSidebarSettings = (
  district,
  mapPortalCompositionId,
  settings
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateCompositionSidebarSettingsBegin());

    axios
      .patch(
        `${district}/map_portal_composition/${mapPortalCompositionId}/tabs/`,
        settings
      )
      .then(({ data }) => {
        dispatch(
          updateCompositionSidebarSettingsSuccess(
            district,
            mapPortalCompositionId,
            data
          )
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(updateCompositionSidebarSettingsFailed());
        reject(err);
      });
  });

export const setPortalCompositionTagsBegin = () => ({
  type: SET_PORTAL_COMPOSITION_TAGS_BEGIN
});

export const setPortalCompositionTagsSuccess = (
  prefix,
  mapPortalId,
  compositionId,
  tags
) => ({
  type: SET_PORTAL_COMPOSITION_TAGS_SUCCESS,
  prefix,
  mapPortalId,
  compositionId,
  tags
});

export const setPortalCompositionTagsFailed = () => ({
  type: SET_PORTAL_COMPOSITION_TAGS_FAILED
});

export const setPortalCompositionTags = (
  prefix,
  mapPortalId,
  compositionId,
  tags
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setPortalCompositionTagsBegin());

    axios
      .post(`tags/map_portal_composition/${compositionId}/`, tags)
      .then(({ data }) => {
        dispatch(getAllDistrictTags(prefix));

        dispatch(
          mapPortalApi.endpoints.fetchMapPortalTags(
            { district: prefix, mapPortalId: Number(mapPortalId) },
            true
          )
        );

        dispatch(
          setPortalCompositionTagsSuccess(
            prefix,
            mapPortalId,
            compositionId,
            tags
          )
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(setPortalCompositionTagsFailed());
        reject(err);
      });
  });

export const getProjectUpdateLogsBegin = () => ({
  type: GET_PROJECT_UPDATE_LOGS_BEGIN
});

export const getProjectUpdateLogsSuccess = (district, projectId, logs) => ({
  type: GET_PROJECT_UPDATE_LOGS_SUCCESS,
  district,
  projectId,
  logs
});

export const getProjectUpdateLogsFailed = () => ({
  type: GET_PROJECT_UPDATE_LOGS_FAILED
});

export const getProjectUpdateLogs = ({ district, projectId }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getProjectUpdateLogsBegin());

    axios
      .get(`${district}/qgis_project/${projectId}/log/`)
      .then(({ data }) => {
        dispatch(getProjectUpdateLogsSuccess(district, projectId, data));
        resolve(data);
      })
      .catch(err => {
        dispatch(getProjectUpdateLogsFailed());
        reject(err);
      });
  });

export const deleteProjectUpdateLogBegin = () => ({
  type: DELETE_PROJECT_UPDATE_LOG_BEGIN
});

export const deleteProjectUpdateLogSuccess = (district, projectId, logId) => ({
  type: DELETE_PROJECT_UPDATE_LOG_SUCCESS,
  district,
  projectId,
  logId
});

export const deleteProjectUpdateLogFailed = () => ({
  type: DELETE_PROJECT_UPDATE_LOG_FAILED
});

export const deleteProjectUpdateLog = (
  district,
  projectId,
  logId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deleteProjectUpdateLogBegin());

    axios
      .delete(`${district}/qgis_project/${projectId}/log/${logId}/`)
      .then(() => {
        dispatch(deleteProjectUpdateLogSuccess(district, projectId, logId));
        resolve();
      })
      .catch(err => {
        dispatch(deleteProjectUpdateLogFailed());
        reject(err);
      });
  });

export const updateProjectMapProxySettingsBegin = () => ({
  type: UPDATE_PROJECT_MAP_PROXY_SETTINGS_BEGIN
});

export const updateProjectMapProxySettingsSuccess = (compositionId, data) => ({
  type: UPDATE_PROJECT_MAP_PROXY_SETTINGS_SUCCESS,
  compositionId,
  data
});

export const updateProjectMapProxySettingsFailed = () => ({
  type: UPDATE_PROJECT_MAP_PROXY_SETTINGS_FAILED
});

export const updateProjectMapProxySettings = ({
  district,
  projectId,
  compositionId,
  legendLayers = [],
  legendGroups = []
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateProjectMapProxySettingsBegin());

    const data = {
      legend_layers: legendLayers,
      legend_groups: legendGroups
    };

    axios
      .post(`${district}/qgis_project/${projectId}/mapproxy_settings/`, data)
      .then(() => {
        dispatch(updateProjectMapProxySettingsSuccess(compositionId, data));
        resolve();
      })
      .catch(err => {
        dispatch(updateProjectMapProxySettingsFailed());
        reject(err);
      });
  });

export const fetchLayerGeoJsonBegin = () => ({
  type: FETCH_LAYER_GEOJSON_BEGIN
});

export const fetchLayerGeoJsonSuccess = geojson => ({
  type: FETCH_LAYER_GEOJSON_SUCCESS,
  payload: geojson
});

export const fetchLayerGeoJsonFailed = () => ({
  type: FETCH_LAYER_GEOJSON_FAILED
});

export const fetchLayerGeoJSON = (
  prefix,
  id,
  coordinates,
  defaultCrsCode = 'EPSG:2180'
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchLayerGeoJsonBegin());
    axios
      .post(`${prefix}/legend_layer_geojson/${id}/`, {
        filter: `contains(transform($geometry, 'EPSG:${defaultCrsCode}','EPSG:4326'),geom_from_gml('<gml:LineString srsName="EPSG:4326"><gml:coordinates>${coordinates} ${
          coordinates.split(' ')[0]
        }</gml:coordinates></gml:LineString>'))`
      })
      .then(r => {
        dispatch(fetchLayerGeoJsonSuccess(r.data));
        resolve(r.data);
      })
      .catch(err => {
        dispatch(fetchLayerGeoJsonFailed());
        reject(err);
      });
  });

export const addToTouchedGroups = groupId => ({
  type: ADD_TO_TOUCHED_GROUPS,
  groupId
});

export const removeFromTouchedGroups = groupId => ({
  type: REMOVE_FROM_TOUCHED_GROUPS,
  groupId
});

export const clearTouchedGroups = () => ({
  type: CLEAR_TOUCHED_GROUPS
});

export const updateLayerVisibilityBegin = () => ({
  type: UPDATE_LAYER_VISIBILITY_BEGIN
});

export const updateLayerVisibilitySuccess = (data, uniqueId) => ({
  type: UPDATE_LAYER_VISIBILITY_SUCCESS,
  data,
  uniqueId
});

export const updateLayerVisibilityFailed = () => ({
  type: UPDATE_LAYER_VISIBILITY_FAILED
});

export const updateLayerVisibility = ({
  district,
  layerId,
  data,
  uniqueId
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updateLayerVisibilityBegin());

    axios
      .patch(`${district}/layer_visibility/${layerId}/`, data)
      .then(response => {
        dispatch(updateLayerVisibilitySuccess(response.data, uniqueId));
        resolve();
      })
      .catch(err => {
        dispatch(updateLayerVisibilityFailed());
        reject(err);
      });
  });

export const fetchVoivodeshipsBegin = () => ({
  type: FETCH_VOIVODESHIPS_BEGIN
});

export const fetchVoivodeshipsSuccess = voivodeships => ({
  type: FETCH_VOIVODESHIPS_SUCCESS,
  voivodeships
});

export const fetchVoivodeshipsFailed = () => ({
  type: FETCH_VOIVODESHIPS_FAILED
});

export const fetchVoivodeships = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchVoivodeshipsBegin());

    axios
      .get(`administrative_units/voivodeships/`)
      .then(({ data }) => {
        const voivodeships = data.results;
        dispatch(fetchVoivodeshipsSuccess(voivodeships));
        resolve(voivodeships);
      })
      .catch(err => {
        dispatch(fetchVoivodeshipsFailed());
        reject(err);
      });
  });

export const fetchCountiesBegin = () => ({
  type: FETCH_COUNTIES_BEGIN
});

export const fetchCountiesSuccess = counties => ({
  type: FETCH_COUNTIES_SUCCESS,
  counties
});

export const fetchCountiesFailed = () => ({
  type: FETCH_COUNTIES_FAILED
});

export const fetchCounties = voivodeshipId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchCountiesBegin());
    axios
      .get(`administrative_units/counties_by_voivodeship/${voivodeshipId}/`)
      .then(({ data }) => {
        dispatch(fetchCountiesSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchCountiesFailed());
        reject(err);
      });
  });
export const fetchCommunesBegin = () => ({
  type: FETCH_COMMUNES_BEGIN
});

export const fetchCommunesSuccess = communes => ({
  type: FETCH_COMMUNES_SUCCESS,
  communes
});

export const fetchCommunesFailed = () => ({
  type: FETCH_COMMUNES_FAILED
});

export const fetchCommunes = countyId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchCommunesBegin());
    axios
      .get(`administrative_units/registration_units_by_county/${countyId}/`)
      .then(({ data }) => {
        dispatch(fetchCommunesSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchCommunesFailed());
        reject(err);
      });
  });

export const fetchRegionsBegin = () => ({
  type: FETCH_REGIONS_BEGIN
});

export const fetchRegionsSuccess = regions => ({
  type: FETCH_REGIONS_SUCCESS,
  regions
});

export const fetchRegionsFailed = () => ({
  type: FETCH_REGIONS_FAILED
});

export const fetchRegions = communeId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchRegionsBegin());
    axios
      .get(`administrative_units/regions_by_commune/${communeId}/`)
      .then(({ data }) => {
        dispatch(fetchRegionsSuccess(data));
        resolve(data);
      })
      .catch(err => {
        dispatch(fetchRegionsFailed());
        reject(err);
      });
  });

export const downloadLayerAttrubutesBegin = () => ({
  type: DOWNLOAD_LAYER_ATTRIBUTES_BEGIN
});

export const downloadLayerAttrubutesSuccess = () => ({
  type: DOWNLOAD_LAYER_ATTRIBUTES_SUCCESS
});

export const downloadLayerAttrubutesFailed = () => ({
  type: DOWNLOAD_LAYER_ATTRIBUTES_FAILED
});

export const downloadLayerAttrubutes = ({
  prefix,
  mapPortalId,
  compositionId,
  layerId,
  format
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(downloadLayerAttrubutesBegin());
    axios
      .get(
        `${prefix}/map_portal/${mapPortalId}/compositions/${compositionId}/data_sharing/${layerId}/?format=${format}`,
        { responseType: 'blob' }
      )
      .then(response => {
        dispatch(downloadLayerAttrubutesSuccess(response));
        resolve(response);
      })
      .catch(err => {
        dispatch(downloadLayerAttrubutesFailed());
        reject(err);
      });
  });

export const setInvestorDrawing = drawing => ({
  type: SET_INVESTOR_DRAWING,
  payload: drawing
});

export const showServicesUrlModal = ({ path, capabilities }) => ({
  type: SHOW_SERVICES_MODAL,
  path,
  capabilities
});

export const hideServicesUrlModal = () => ({
  type: HIDE_SERVICES_MODAL
});

export const showPrivacyPolicyModal = () => ({
  type: SHOW_PRIVACY_MODAL
});

export const hidePrivacyPolicyModal = () => ({
  type: HIDE_PRIVACY_MODAL
});

export const setSearchInputData = data => ({
  type: SET_SEARCH_INPUT_DATA,
  payload: data
});

export const setSearchInputOptions = type => ({
  type: SET_SEARCH_INPUT_OPTIONS,
  payload: type
});

export const setSearchInputGugikAddress = address => ({
  type: SET_SEARCH_INPUT_GUGIK_ADDRESS,
  payload: address
});

export const setSearchInputGugikData = data => ({
  type: SET_SEARCH_INPUT_GUGIK_DATA,
  payload: data
});

export const setSearchInputGugikDisplay = data => ({
  type: SET_SEARCH_INPUT_GUGIK_DISPLAY,
  payload: data
});

export const resetSearchInput = () => ({
  type: RESET_SEARCH_INPUT
});

export const setActiveIndexToolbar = toolKey => ({
  type: SET_ACTIVE_INDEX_TOOLBAR,
  payload: toolKey
});

export const downloadRasterLayerBegin = () => ({
  type: DOWNLOAD_RASTER_LAYER_BEGIN
});

export const downloadRasterLayerSuccess = () => ({
  type: DOWNLOAD_RASTER_LAYER_SUCCESS
});

export const downloadRasterLayerFailed = () => ({
  type: DOWNLOAD_RASTER_LAYER_FAILED
});

export const downloadRasterLayer = ({
  prefix,
  mapPortalId,
  compositionId,
  layerId
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(downloadRasterLayerBegin());
    axios
      .get(
        `${prefix}/map_portal/${mapPortalId}/compositions/${compositionId}/raster_sharing/${layerId}/`,
        { responseType: 'blob' }
      )
      .then(resp => {
        dispatch(downloadRasterLayerSuccess());
        resolve(resp);
      })
      .catch(err => {
        dispatch(downloadRasterLayerFailed());
        reject(err);
      });
  });

export const fetchSelectionMethodsBegin = () => ({
  type: FETCH_SELECTION_METHODS_BEGIN
});

export const fetchSelectionMethodsSuccess = payload => ({
  type: FETCH_SELECTION_METHODS_SUCCESS,
  payload
});

export const fetchSelectionMethodsFailed = () => ({
  type: FETCH_SELECTION_METHODS_FAILED
});

export const fetchSelectionMethods = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSelectionMethodsBegin());
    axios
      .get(`selection_search/topical_search_methods/`)
      .then(resp => {
        dispatch(fetchSelectionMethodsSuccess(resp.data));
        resolve(resp);
      })
      .catch(err => {
        dispatch(fetchSelectionMethodsFailed());
        reject(err);
      });
  });

export const fetchSelectionTypesBegin = () => ({
  type: FETCH_SELECTION_TYPES_BEGIN
});

export const fetchSelectionTypesSuccess = payload => ({
  type: FETCH_SELECTION_TYPES_SUCCESS,
  payload
});

export const fetchSelectionTypesFailed = () => ({
  type: FETCH_SELECTION_TYPES_FAILED
});

export const fetchSelectionTypes = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSelectionTypesBegin());
    axios
      .get(`selection_search/types/`)
      .then(resp => {
        dispatch(fetchSelectionTypesSuccess(resp.data));
        resolve(resp);
      })
      .catch(err => {
        dispatch(fetchSelectionTypesFailed());
        reject(err);
      });
  });

export const fetchSelectionOperatorsBegin = () => ({
  type: FETCH_SELECTION_OPERATOR_BEGIN
});

export const fetchSelectionOperatorsSuccess = payload => ({
  type: FETCH_SELECTION_OPERATOR_SUCCESS,
  payload
});

export const fetchSelectionOperatorsFailed = () => ({
  type: FETCH_SELECTION_OPERATOR_FAILED
});

export const fetchSelectionOperators = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSelectionOperatorsBegin());
    axios
      .get(`selection_search/operators/`)
      .then(resp => {
        dispatch(fetchSelectionOperatorsSuccess(resp.data));
        resolve(resp);
      })
      .catch(err => {
        dispatch(fetchSelectionOperatorsFailed());
        reject(err);
      });
  });

export const fetchArConfigsBegin = () => ({
  type: FETCH_AR_CONFIGS_BEGIN
});

export const fetchArConfigsSuccess = payload => ({
  type: FETCH_AR_CONFIGS_SUCCESS,
  payload
});

export const fetchArConfigsFailed = () => ({
  type: FETCH_AR_CONFIGS_FAILED
});

export const fetchArConfigs = ({ mapPortalConpositionId, district }) => async (
  dispatch,
  getState
) => {
  try {
    const cachedArConfigs = mapPortalSelectors.getMapPortalCompositionArConfigs(
      getState(),
      district,
      mapPortalConpositionId
    );

    if (cachedArConfigs) return;

    dispatch(fetchArConfigsBegin());

    const { data } = await axios.get(
      `/${district}/ar/${mapPortalConpositionId}/config/`
    );
    dispatch(
      fetchArConfigsSuccess({ district, mapPortalConpositionId, configs: data })
    );
  } catch (error) {
    dispatch(fetchArConfigsFailed());
    throw error;
  }
};

export const addArConfigBegin = () => ({
  type: ADD_AR_CONFIG_BEGIN
});

export const addArConfigSuccess = payload => ({
  type: ADD_AR_CONFIG_SUCCESS,
  payload
});

export const addArConfigFailed = () => ({
  type: ADD_AR_CONFIG_FAILED
});

export const addArConfig = ({
  mapPortalConpositionId,
  district,
  config
}) => async dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchSelectionTypesBegin());
    axios
      .post(`/${district}/ar/${mapPortalConpositionId}/config/`, config)
      .then(({ data }) => {
        dispatch(
          addArConfigSuccess({ district, mapPortalConpositionId, config: data })
        );
        resolve(data);
      })
      .catch(err => {
        dispatch(addArConfigFailed());
        reject(err);
      });
  });

export const updateArConfigBegin = () => ({
  type: UPDATE_AR_CONFIG_BEGIN
});

export const updateArConfigSuccess = payload => ({
  type: UPDATE_AR_CONFIG_SUCCESS,
  payload
});

export const updateArConfigFailed = () => ({
  type: UPDATE_AR_CONFIG_FAILED
});

export const updateArConfig = ({
  mapPortalCompositionId,
  district,
  configId,
  config
}) => async dispatch => {
  try {
    dispatch(updateArConfigBegin());

    const { data } = await axios.patch(
      `/${district}/ar/${mapPortalCompositionId}/config/${configId}/`,
      config
    );

    dispatch(
      updateArConfigSuccess({
        district,
        mapPortalCompositionId,
        configId,
        config: data
      })
    );
  } catch (error) {
    dispatch(updateArConfigFailed());
    throw error;
  }
};

export const deleteArConfigBegin = () => ({
  type: DELETE_AR_CONFIG_BEGIN
});

export const deleteArConfigSuccess = payload => ({
  type: DELETE_AR_CONFIG_SUCCESS,
  payload
});

export const deleteArConfigFailed = () => ({
  type: DELETE_AR_CONFIG_FAILED
});

export const deleteArConfig = ({
  mapPortalCompositionId,
  district,
  configId
}) => async dispatch => {
  try {
    dispatch(deleteArConfigBegin());

    await axios.delete(
      `/${district}/ar/${mapPortalCompositionId}/config/${configId}/`
    );

    dispatch(
      deleteArConfigSuccess({
        district,
        mapPortalCompositionId,
        configId
      })
    );

    dispatch(
      deleteArConfigAttrsList(district, mapPortalCompositionId, configId)
    );
  } catch (error) {
    dispatch(deleteArConfigFailed());
    throw error;
  }
};

export const fetchArConfigAttrsListBegin = () => ({
  type: FETCH_AR_CONFIG_ATTRS_LIST_BEGIN
});

export const fetchArConfigAttrsListSuccess = payload => ({
  type: FETCH_AR_CONFIG_ATTRS_LIST_SUCCESS,
  payload
});

export const fetchArConfigAttrsListFailed = () => ({
  type: FETCH_AR_CONFIG_ATTRS_LIST_FAILED
});

export const fetchArConfigAttrsList = ({
  district,
  mapPortalCompositionId,
  configId
}) => async (dispatch, getState) => {
  try {
    const cachedList = mapPortalSelectors.getArConfigAttrsList(
      getState(),
      district,
      mapPortalCompositionId,
      configId
    );

    if (cachedList) return;

    dispatch(fetchArConfigAttrsListBegin());

    const { data } = await axios.get(
      `/${district}/ar/${mapPortalCompositionId}/config/${configId}/ar_identification_attribute/`
    );

    dispatch(
      fetchArConfigAttrsListSuccess({
        district,
        mapPortalCompositionId,
        configId,
        attributesList: data
      })
    );
  } catch (error) {
    dispatch(fetchArConfigAttrsListFailed());
    throw error;
  }
};

export const updateArConfigAttrsListBegin = () => ({
  type: UPDATE_AR_CONFIG_ATTRS_LIST_BEGIN
});

export const updateArConfigAttrsListSuccess = payload => ({
  type: UPDATE_AR_CONFIG_ATTRS_LIST_SUCCESS,
  payload
});

export const updateArConfigAttrsListFailed = () => ({
  type: UPDATE_AR_CONFIG_ATTRS_LIST_FAILED
});

export const updateArConfigAttrsList = ({
  district,
  mapPortalCompositionId,
  configId,
  attributesList
}) => async dispatch => {
  try {
    dispatch(updateArConfigAttrsListBegin());

    const { data } = await axios.patch(
      `/${district}/ar/${mapPortalCompositionId}/config/${configId}/ar_identification_attribute/`,
      attributesList
    );

    dispatch(
      updateArConfigAttrsListSuccess({
        district,
        mapPortalCompositionId,
        configId,
        attributesList: data
      })
    );
  } catch (error) {
    dispatch(updateArConfigAttrsListFailed());
    throw error;
  }
};

export const deleteArConfigAttrsList = (
  district,
  mapPortalCompositionId,
  configId
) => ({
  type: DELETE_AR_CONFIG_ATTRS_LIST,
  payload: { district, mapPortalCompositionId, configId }
});

export const fetchArCompositionConfigBegin = () => ({
  type: FETCH_AR_COMPOSITION_CONFIG_BEGIN
});

export const fetchArCompositionConfigSuccess = ({
  district,
  mapPortalCompositionId,
  config
}) => ({
  type: FETCH_AR_COMPOSITION_CONFIG_SUCCESS,
  payload: {
    district,
    mapPortalCompositionId,
    config
  }
});

export const fetchArCompositionConfigFailed = () => ({
  type: FETCH_AR_COMPOSITION_CONFIG_FAILED
});

export const fetchArCompositionConfig = ({
  district,
  mapPortalCompositionId
}) => async (dispatch, getState) => {
  try {
    const cachedConfig = mapPortalSelectors.getArCompositionConfig(
      getState(),
      district,
      mapPortalCompositionId
    );

    if (cachedConfig) return cachedConfig.toJS();

    dispatch(fetchArCompositionConfigBegin());

    const { data } = await axios.get(
      `/${district}/ar/${mapPortalCompositionId}/`
    );

    dispatch(
      fetchArCompositionConfigSuccess({
        district,
        mapPortalCompositionId,
        config: data
      })
    );

    return data;
  } catch (error) {
    dispatch(fetchArCompositionConfigFailed());
    throw error;
  }
};

export const updateArCompositionConfigBegin = () => ({
  type: UPDATE_AR_COMPOSITION_CONFIG_BEGIN
});

export const updateArCompositionConfigSuccess = ({
  district,
  mapPortalCompositionId,
  config
}) => ({
  type: UPDATE_AR_COMPOSITION_CONFIG_SUCCESS,
  payload: {
    district,
    mapPortalCompositionId,
    config
  }
});

export const updateArCompositionConfigFailed = () => ({
  type: UPDATE_AR_COMPOSITION_CONFIG_FAILED
});

export const updateArCompositionConfig = ({
  district,
  mapPortalCompositionId,
  config
}) => async dispatch => {
  try {
    dispatch(updateArCompositionConfigBegin());

    const { data } = await axios.patch(
      `/${district}/ar/${mapPortalCompositionId}/`,
      config
    );

    dispatch(
      updateArCompositionConfigSuccess({
        district,
        mapPortalCompositionId,
        config: data
      })
    );

    return data;
  } catch (error) {
    dispatch(updateArCompositionConfigFailed());
    throw error;
  }
};

//RTQ
export const fetchMapPortalToolsStarted = () => ({
  type: FETCH_MAP_PORTAL_TOOLS_STARTED
});

export const fetchMapPortalToolsSuccess = ({ data }) => ({
  type: FETCH_MAP_PORTAL_TOOLS_SUCCESS,
  data
});

export const fetchMapPortalToolsFailed = () => ({
  type: FETCH_MAP_PORTAL_TOOLS_FAILED
});

export const setDefaultMode = data => ({
  type: SET_DEFAULT_MODE,
  payload: data
});

const updateMapPortalToolStarted = () => ({
  type: UPDATE_MAP_PORTAL_TOOL_STARTED
});

const updateMapPortalToolSuccess = () => ({
  type: UPDATE_MAP_PORTAL_TOOL_SUCCESS
});

const updateMapPortalToolFailed = () => ({
  type: UPDATE_MAP_PORTAL_TOOL_FAILED
});

export const updateMapPortalTool = ({ toolbarId, data }) => async (
  dispatch,
  getState
) => {
  const lang = getState().global.get('language');

  try {
    dispatch(updateMapPortalToolStarted());

    await axios.patch(`map_toolbar_display/${toolbarId}/`, data);

    dispatch(updateMapPortalToolSuccess());
    dispatch(
      showSuccess(
        translations.mapPortal.fetchMapPortalToolConfigurationSuccessMessage[
          lang
        ]
      )
    );
  } catch (error) {
    dispatch(updateMapPortalToolFailed());
    dispatch(
      showError(
        translations.mapPortal.fetchMapPortalToolConfigurationErrorMessage[lang]
      )
    );
    throw error;
  }
};

const fetchDistrictLocalitiesStarted = () => ({
  type: FETCH_DISTRICT_LOCALITIES_STARTED
});

const fetchDistrictLocalitiesSuccess = ({ district, namesOfLocalities }) => ({
  type: FETCH_DISTRICT_LOCALITIES_SUCCESS,
  payload: {
    district,
    namesOfLocalities
  }
});

const fetchDistrictLocalitiesFailed = () => ({
  type: FETCH_DISTRICT_LOCALITIES_FAILED
});

export const fetchDistrictLocalities = ({ district }) => async (
  dispatch,
  getState
) => {
  const districtLocalities = getState()
    .mapPortals.get('districtLocalities')
    .toJS();

  // If the locations for the current district were previously fetched,
  // there is no need to do it a second time.
  if (district in districtLocalities) {
    return;
  }

  dispatch(fetchDistrictLocalitiesStarted());

  axios
    .get(`${district}/get_prng/`)
    .then(({ data }) => {
      const namesOfLocalities = data.map(({ nazwa, identyfikatorsimc }) => ({
        label: nazwa,
        value: identyfikatorsimc
      }));

      dispatch(
        fetchDistrictLocalitiesSuccess({
          district,
          namesOfLocalities
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error);
      dispatch(showError(errorMessage));
      dispatch(fetchDistrictLocalitiesFailed());
    });
};

const fetchLocalityStreetsStarted = () => ({
  type: FETCH_LOCALITY_STREETS_STARTED
});

const fetchLocalityStreetsSuccess = ({ simc, localityStreets }) => ({
  type: FETCH_LOCALITY_STREETS_SUCCESS,
  payload: {
    simc,
    localityStreets
  }
});

const fetchLocalityStreetsFailed = () => ({
  type: FETCH_LOCALITY_STREETS_FAILED
});

const gugikAxiosInstance = axios.create({
  baseURL: 'https://services.gugik.gov.pl/uug'
});

export const fetchLocalityStreets = ({ simc }) => async (
  dispatch,
  getState
) => {
  const localityStreets = getState()
    .mapPortals.get('localityStreets')
    .toJS();

  // If the streets for the current locality were previously fetched,
  // there is no need to do it a second time.
  if (simc in localityStreets) {
    return;
  }

  dispatch(fetchLocalityStreetsStarted());

  const params = {
    request: 'GetStreet',
    simc,
    srid: '4326'
  };

  gugikAxiosInstance
    .get('/', { params })
    .then(({ data: { results } }) => {
      // We receive results as an object
      let localityStreets = [];

      if (results) {
        localityStreets = Object.values(results).map(({ id, street }) => ({
          label: street,
          value: String(id)
        }));
      }

      dispatch(
        fetchLocalityStreetsSuccess({
          simc,
          localityStreets
        })
      );
    })
    .catch(error => {
      const errorMessage = parseResponseError(error);
      dispatch(showError(errorMessage));
      dispatch(fetchLocalityStreetsFailed());
    });
};

const getWmsLegendBegin = (url, layer) => ({
  type: GET_WMS_LEGEND_BEGIN,
  url,
  layer
});

const getWmsLegendSuccess = payload => ({
  type: GET_WMS_LEGEND_SUCCESS,
  payload
});

const getWmsLegendFailed = payload => ({
  type: GET_WMS_LEGEND_FAILED,
  payload
});

export const getWmsLegend = ({ url, layer, type }) => async (
  dispatch,
  getState
) => {
  dispatch(getWmsLegendBegin(url, layer));
  const state = getState();
  const fetchedWmsLegends = state.mapPortals.get('fetchedWmsLegends').toJS();

  const params = {
    SERVICE: type.toUpperCase(),
    REQUEST: wmsServices.getLegendGraphic,
    VERSION: type === 'wms' ? '1.3.0' : '1.0.0',
    FORMAT: 'image/png',
    LAYER: layer,
    SLD_VERSION: '1.1.0'
  };

  return new Promise((resolve, reject) => {
    if (fetchedWmsLegends[url]?.[layer]) {
      dispatch(
        getWmsLegendSuccess({
          legend: fetchedWmsLegends[url][layer].legendObject,
          url: fetchedWmsLegends[url][layer].url,
          layer
        })
      );

      const { legendObject } = fetchedWmsLegends[url][layer];
      return resolve(legendObject);
    }

    const proxyFn = useProxy();
    return proxyFn(url, params, { responseType: 'blob' })
      .then(response => {
        const dataUrl = window.URL.createObjectURL(new Blob([response.data]));
        dispatch(getWmsLegendSuccess({ legend: dataUrl, layer, url }));
        resolve(dataUrl);
      })
      .catch(err => {
        dispatch(getWmsLegendFailed({ layer, url }));
        reject(err);
      });
  });
};

export const clearFetchingWmsLegend = () => ({
  type: CLEAR_FETCHING_WMS_LEGEND
});

export const showServiceInfoModal = serviceLayer => ({
  type: SHOW_SERVICE_INFO_MODAL,
  serviceLayer
});

export const hideServiceInfoModal = () => ({
  type: HIDE_SERVICE_INFO_MODAL
});

export const fetchHomeSiteIframeBegin = () => ({
  type: FETCH_HOME_SITE_IFRAME_BEGIN
});

export const fetchHomeSiteIframeSuccess = payload => ({
  type: FETCH_HOME_SITE_IFRAME_SUCCESS,
  payload
});

export const fetchHomeSiteIframeFailed = () => ({
  type: FETCH_HOME_SITE_IFRAME_FAILED
});
