export const DELETE_BI_RAPORT_STARTED = 'DELETE_BI_RAPORT_STARTED';
export const DELETE_BI_RAPORT_SUCCESS = 'DELETE_BI_RAPORT_SUCCESS';
export const DELETE_BI_RAPORT_FAILED = 'DELETE_BI_RAPORT_FAILED';

export const GET_ALL_BI_RAPORTS_STARTED = 'GET_ALL_BI_RAPORTS_STARTED';
export const GET_ALL_BI_RAPORTS_SUCCESS = 'GET_ALL_BI_RAPORTS_SUCCESS';
export const GET_ALL_BI_RAPORTS_FAILED = 'GET_ALL_BI_RAPORTS_FAILED';

export const GET_PUBLISHED_BI_RAPORTS_STARTED =
  'GET_PUBLISHED_BI_RAPORTS_STARTED';
export const GET_PUBLISHED_BI_RAPORTS_SUCCESS =
  'GET_PUBLISHED_BI_RAPORTS_SUCCESS';
export const GET_PUBLISHED_BI_RAPORTS_FAILED =
  'GET_PUBLISHED_BI_RAPORTS_FAILED';

export const GET_ALL_BI_COMPOSITIONS_STARTED =
  'GET_ALL_BI_COMPOSITIONS_STARTED';
export const GET_ALL_BI_COMPOSITIONS_SUCCESS =
  'GET_ALL_BI_COMPOSITIONS_SUCCESS';
export const GET_ALL_BI_COMPOSITIONS_FAILED = 'GET_ALL_BI_COMPOSITIONS_FAILED';

export const DELETE_BI_CONNECTION_STARTED = 'DELETE_BI_CONNECTION_STARTED';
export const DELETE_BI_CONNECTION_SUCCESS = 'DELETE_BI_CONNECTION_SUCCESS';
export const DELETE_BI_CONNECTION_FAILED = 'DELETE_BI_CONNECTION_FAILED';

export const CREATE_NEW_BI_RAPORT_STARTED = 'CREATE_NEW_BI_RAPORT_STARTED';
export const CREATE_NEW_BI_RAPORT_SUCCESS = 'CREATE_NEW_BI_RAPORT_SUCCESS';
export const CREATE_NEW_BI_RAPORT_FAILED = 'CREATE_NEW_BI_RAPORT_FAILURE';

export const ADD_RAPORT_TO_COMPOSITION_STARTED =
  'ADD_RAPORT_TO_COMPOSITION_STARTED';
export const ADD_RAPORT_TO_COMPOSITION_SUCCESS =
  'ADD_RAPORT_TO_COMPOSITION_SUCCESS';
export const ADD_RAPORT_TO_COMPOSITION_FAILED =
  'ADD_RAPORT_TO_COMPOSITION_FAILED';

export const EDIT_BI_RAPORT_STARTED = 'EDIT_BI_RAPORT_STARTED';
export const EDIT_BI_RAPORT_SUCCESS = 'EDIT_BI_RAPORT_SUCCESS';
export const EDIT_BI_RAPORT_FAILED = 'EDIT_BI_RAPORT_FAILURE';
