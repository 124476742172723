const selectors = {
  getTerytApplications: (state, teryt) =>
    state.eConstructions.getIn(['applications', teryt]),
  getDetails: state => state.eConstructions.get('applicationDetails'),
  getIsOpenEConstructionDetails: state =>
    state.eConstructions.get('isOpenEConstructionDetails'),
  getIsEConstructionEdition: state => state.eConstructions.get('isEdition'),
  getDetailsFetched: state => state.eConstructions.get('areDetailsFetched')
};

export default selectors;
