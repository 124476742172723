export const FETCH_BACKGROUND_WMS_BEGIN = 'FETCH_BACKGROUND_WMS_BEGIN';
export const FETCH_BACKGROUND_WMS_SUCCESS = 'FETCH_BACKGROUND_WMS_SUCCESS';
export const FETCH_BACKGROUND_WMS_FAILED = 'FETCH_BACKGROUND_WMS_FAILED';

export const GET_WMS_BEGIN = 'GET_WMS_BEGIN';
export const GET_WMS_SUCCESS = 'GET_WMS_SUCCESS';
export const GET_WMS_FAILED = 'GET_WMS_FAILED';

export const ADD_WMS_BEGIN = 'ADD_WMS_BEGIN';
export const ADD_WMS_SUCCESS = 'ADD_WMS_SUCCESS';
export const ADD_WMS_FAILED = 'ADD_WMS_FAILED';

export const EDIT_WMS_BEGIN = 'EDIT_WMS_BEGIN';
export const EDIT_WMS_SUCCESS = 'EDIT_WMS_SUCCESS';
export const EDIT_WMS_FAILED = 'EDIT_WMS_FAILED';

export const DELETE_WMS_BEGIN = 'DELETE_WMS_BEGIN';
export const DELETE_WMS_SUCCESS = 'DELETE_WMS_SUCCESS';
export const DELETE_WMS_FAILED = 'DELETE_WMS_FAILED';

export const GET_LIBRARY_WMS_BEGIN = 'GET_LIBRARY_WMS_BEGIN';
export const GET_LIBRARY_WMS_SUCCESS = 'GET_LIBRARY_WMS_SUCCESS';
export const GET_LIBRARY_WMS_FAILED = 'GET_LIBRARY_WMS_FAILED';

export const ADD_WMS_TO_LIBRARY_BEGIN = 'ADD_WMS_TO_LIBRARY_BEGIN';
export const ADD_WMS_TO_LIBRARY_SUCCESS = 'ADD_WMS_TO_LIBRARY_SUCCESS';
export const ADD_WMS_TO_LIBRARY_FAILED = 'ADD_WMS_TO_LIBRARY_FAILED';

export const EDIT_LIBRARY_WMS_BEGIN = 'EDIT_LIBRARY_WMS_BEGIN';
export const EDIT_LIBRARY_WMS_SUCCESS = 'EDIT_LIBRARY_WMS_SUCCESS';
export const EDIT_LIBRARY_WMS_FAILED = 'EDIT_LIBRARY_WMS_FAILED';

export const DELETE_WMS_FROM_LIBRARY_BEGIN = 'DELETE_WMS_FROM_LIBRARY_BEGIN';
export const DELETE_WMS_FROM_LIBRARY_SUCCESS =
  'DELETE_WMS_FROM_LIBRARY_SUCCESS';
export const DELETE_WMS_FROM_LIBRARY_FAILED = 'DELETE_WMS_FROM_LIBRARY_FAILED';

export const GET_MAPPORTAL_PUBLIC_WMS_BEGIN = 'GET_MAPPORTAL_PUBLIC_WMS_BEGIN';
export const GET_MAPPORTAL_PUBLIC_WMS_SUCCESS =
  'GET_MAPPORTAL_PUBLIC_WMS_SUCCESS';
export const GET_MAPPORTAL_PUBLIC_WMS_FAILED =
  'GET_MAPPORTAL_PUBLIC_WMS_FAILED';
