import { TBuildUrlOParams } from '@/utils/buildUrlParams/types';

export const enum ERegistersTags {
  compositionRegisters = 'compositionRegisters',
  registers = 'registers'
}

export type TCompositionRegistersParams = {
  district: string;
  compositionId: number;
};

export type TRegistersParams = {
  district: string;
  id?: number;
  data?: { upul_button_enabled: boolean };
  queryParams?: TBuildUrlOParams;
};
