import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import { showError } from '@/store/actions/globalActions';
import {
  editMapPortalSuccess,
  fetchCompositionBegin,
  fetchCompositionFailed,
  fetchDistrictMapPortalBegin,
  fetchDistrictMapPortalFailed,
  fetchDistrictMapPortalSuccess,
  fetchDistrictMapPortalsBegin,
  fetchHomeSiteIframeBegin,
  fetchHomeSiteIframeFailed,
  fetchHomeSiteIframeSuccess,
  fetchMapPortalCompositionsBegin,
  fetchMapPortalCompositionsFailed,
  fetchMapPortalCompositionsSuccess,
  fetchMapPortalToolsFailed,
  fetchMapPortalToolsStarted,
  fetchMapPortalToolsSuccess,
  fetchPortalTagsBegin,
  fetchPortalTagsFailed,
  fetchRasterTimelineBegin,
  fetchRasterTimelineFailed,
  fetchRasterTimelineSuccess,
  fetchSpotMeasureConfigBegin,
  fetchSpotMeasureConfigFailed,
  fetchSpotMeasureConfigSuccess,
  fetchVectorTimelinesBegin,
  fetchVectorTimelinesFailed,
  fetchVectorTimelinesSuccess
} from '@/store/actions/mapPortalActions';

import { globalSelectors } from '@/store/selectors';

import handleApiError from '@/utils/handleApiError';

import {
  EMapPortalTags,
  TFetchCompositionError,
  TFetchCompositionParams,
  TFetchDistrictMapPortalsParams,
  TFetchHomeSiteIframeParams,
  TFetchMapPortalDataParams,
  TFetchMapPortalToolsParams,
  TFetchRasterTimelineParams,
  TFetchSingleDistrictMapPortalParams
} from '@/types/api-types/map-portal';
import { TMapPortal } from '@/types/map-portal';
import { TCurrentComposition } from '@/types/current-composition';
import { TDjangoPaginationResponse } from '@/types/django-pagination-response';

import {
  FETCH_COMPOSITION_SUCCESS,
  FETCH_DISTRICT_MAP_PORTALS_SUCCESS
} from '@/store/constants/mapPortalActionTypes';
import {
  TFetchMapPortalVectorTimelinesResponse,
  TFetchRasterTimelineResponse,
  TMapPortalCompositionResponse,
  TMapPortalToolsResponse,
  TSpotMeasureConfigResponse
} from '@/types/responses/map-portal';

import translations from '@/store/translations';

const buildQueryParams = ({
  prefix,
  filterEmpty,
  page,
  pageSize,
  search,
  withoutPagination
}: TFetchDistrictMapPortalsParams): { url: string; method: 'GET' } => {
  const searchParams = new URLSearchParams();
  if (filterEmpty !== undefined)
    searchParams.set('filter_empty', filterEmpty.toString());
  if (page) searchParams.set('page', page.toString());
  if (pageSize) searchParams.set('page_size', pageSize.toString());
  if (search) searchParams.set('search', search);
  if (withoutPagination)
    searchParams.set('without_pagination', withoutPagination.toString());

  return {
    url: `${prefix}/map_portal/?${searchParams}`,
    method: 'GET'
  };
};

export const mapPortalApi = createApi({
  reducerPath: 'mapPortalApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    EMapPortalTags.DISTRICT_MAP_PORTALS,
    EMapPortalTags.MAP_PORTAL_TOOLS,
    EMapPortalTags.MAP_PORTAL_COMPOSITION,
    EMapPortalTags.RASTER_TIMELINE,
    EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL,
    EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS,
    EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES,
    EMapPortalTags.MAP_PORTAL_TAGS,
    EMapPortalTags.SPOT_MEASURE_CONFIG,
    EMapPortalTags.IFRAME_PORTAL_DATA
  ],
  endpoints: builder => ({
    fetchDistrictMapPortals: builder.query<
      TDjangoPaginationResponse<TMapPortal[]>,
      TFetchDistrictMapPortalsParams
    >({
      query: buildQueryParams,
      onQueryStarted: async (
        { prefix, filterEmpty },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchDistrictMapPortalsBegin(prefix));

        try {
          const {
            data: { results, count }
          } = await queryFulfilled;

          dispatch({
            type: FETCH_DISTRICT_MAP_PORTALS_SUCCESS,
            prefix: prefix,
            mapPortals: results,
            filterEmpty: filterEmpty,
            mapPortalsCount: count
          });
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data?.results
          ? [
              ...data.results.map(({ id }: { id: number }) => ({
                type: EMapPortalTags.DISTRICT_MAP_PORTALS,
                id: id ?? ''
              })),
              {
                type: EMapPortalTags.DISTRICT_MAP_PORTALS,
                id: EMapPortalTags.DISTRICT_MAP_PORTALS
              }
            ]
          : [
              {
                type: EMapPortalTags.DISTRICT_MAP_PORTALS,
                id: EMapPortalTags.DISTRICT_MAP_PORTALS
              }
            ]
    }),
    fetchMapPortalTools: builder.query<
      TMapPortalToolsResponse[],
      TFetchMapPortalToolsParams
    >({
      query: ({ map_portal_id }) => ({
        method: 'GET',
        url: `map_toolbar_tools/?map_portal_id=${map_portal_id}`
      }),
      onQueryStarted: async (arg, { dispatch, getState, queryFulfilled }) => {
        dispatch(fetchMapPortalToolsStarted());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchMapPortalToolsSuccess({ data }));
        } catch (error) {
          const language: 'pl' | 'en' = globalSelectors.getLanguage(getState());
          dispatch(fetchMapPortalToolsFailed());
          dispatch(
            showError(translations.mapPortal.fetchMapPortalTools[language])
          );

          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EMapPortalTags.MAP_PORTAL_TOOLS,
                id: id ?? ''
              })),
              {
                type: EMapPortalTags.MAP_PORTAL_TOOLS,
                id: EMapPortalTags.MAP_PORTAL_TOOLS
              }
            ]
          : [
              {
                type: EMapPortalTags.MAP_PORTAL_TOOLS,
                id: EMapPortalTags.MAP_PORTAL_TOOLS
              }
            ]
    }),
    fetchComposition: builder.query<
      TMapPortalCompositionResponse,
      TFetchCompositionParams
    >({
      query: ({ prefix, portal_id, id = 'default' }) => ({
        method: 'GET',
        url: `${prefix}/map_portal/${portal_id}/compositions/${id}/`
      }),
      onQueryStarted: async (
        { prefix, portal_id, id },
        { dispatch, getState, queryFulfilled }
      ) => {
        dispatch(fetchCompositionBegin(prefix, portal_id, id));

        try {
          const response = await queryFulfilled;

          dispatch({
            type: FETCH_COMPOSITION_SUCCESS,
            data: (response && response.data) || [],
            prefix,
            portal_id,
            id
          });
        } catch (error) {
          const language: 'pl' | 'en' = globalSelectors.getLanguage(getState());
          dispatch(fetchCompositionFailed());
          const status = (error as TFetchCompositionError).error.status;

          if (status === 403) {
            dispatch(
              showError(
                translations.mapPortal
                  .fetchDistrictCompositionsPermissionErrorMessage[language]
              )
            );
          }

          if (id !== 'default' && status !== 403)
            dispatch(
              showError(
                translations.mapPortal.fetchDistrictCompositionsErrorMessage[
                  language
                ]
              )
            );
        }
      },
      providesTags: result => [
        {
          type: EMapPortalTags.MAP_PORTAL_COMPOSITION,
          id: result ? result.id : ''
        }
      ]
    }),
    fetchRasterTimeline: builder.query<
      TFetchRasterTimelineResponse[],
      TFetchRasterTimelineParams
    >({
      query: ({ district, mapPortalId }) => ({
        method: 'GET',
        url: `${district}/map_portal/${mapPortalId}/raster_timeline/`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchRasterTimelineBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchRasterTimelineSuccess(data));
        } catch (error) {
          dispatch(fetchRasterTimelineFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EMapPortalTags.RASTER_TIMELINE,
                id: id ?? ''
              })),
              {
                type: EMapPortalTags.RASTER_TIMELINE,
                id: EMapPortalTags.RASTER_TIMELINE
              }
            ]
          : [
              {
                type: EMapPortalTags.RASTER_TIMELINE,
                id: EMapPortalTags.RASTER_TIMELINE
              }
            ]
    }),
    fetchSingleDistrictMapPortal: builder.query<
      TMapPortal,
      TFetchSingleDistrictMapPortalParams
    >({
      query: ({ prefix, mapPortalId }) => ({
        method: 'GET',
        url: `${prefix}/map_portal/${mapPortalId}/`
      }),
      onQueryStarted: async (
        { prefix, mapPortalId },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchDistrictMapPortalBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchDistrictMapPortalSuccess(mapPortalId, data, prefix));
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchDistrictMapPortalFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              {
                type: EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL,
                id: data.id.toString()
              },
              {
                type: EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL,
                id: EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL
              }
            ]
          : [
              {
                type: EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL,
                id: EMapPortalTags.SINGLE_DISTRICT_MAP_PORTAL
              }
            ]
    }),
    fetchSingleMapPortalCompositions: builder.query<
      TCurrentComposition[],
      TFetchMapPortalDataParams
    >({
      query: ({ district, mapPortalId }) => ({
        method: 'GET',
        url: `${district}/map_portal/${mapPortalId}/map_portal_compositions`
      }),
      onQueryStarted: async (
        { district, mapPortalId },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchMapPortalCompositionsBegin());
        try {
          const { data } = await queryFulfilled;

          dispatch(
            fetchMapPortalCompositionsSuccess({
              district,
              mapPortalId,
              compositions: data
            })
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchMapPortalCompositionsFailed());
          dispatch(
            showError(
              'Wystąpił błąd podczas pobierania kompozycji portalu mapowego.'
            )
          );
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS,
                id: id ?? ''
              })),
              {
                type: EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS,
                id: EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS
              }
            ]
          : [
              {
                type: EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS,
                id: EMapPortalTags.SINGLE_MAP_PORTAL_COMPOSITIONS
              }
            ]
    }),
    fetchMapPortalVectorTimelines: builder.query<
      TFetchMapPortalVectorTimelinesResponse[],
      TFetchMapPortalDataParams
    >({
      query: ({ district, mapPortalId }) => ({
        method: 'GET',
        url: `${district}/map_portal/${mapPortalId}/layer_timeline`
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        dispatch(fetchVectorTimelinesBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchVectorTimelinesSuccess(data));
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchVectorTimelinesFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(({ id }: { id: number }) => ({
                type: EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES,
                id: id ?? ''
              })),
              {
                type: EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES,
                id: EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES
              }
            ]
          : [
              {
                type: EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES,
                id: EMapPortalTags.MAP_PORTAL_VECTOR_TIMELINES
              }
            ]
    }),
    fetchMapPortalTags: builder.query<string[], TFetchMapPortalDataParams>({
      query: ({ district, mapPortalId }) => ({
        method: 'GET',
        url: `${district}/ordered_tags/map_portal/${mapPortalId}/`
      }),
      onQueryStarted: async (
        { mapPortalId, district },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchPortalTagsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(
            editMapPortalSuccess(
              mapPortalId,
              { map_portal_tags: data },
              district
            )
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchPortalTagsFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(singleTag => ({
                type: EMapPortalTags.MAP_PORTAL_TAGS,
                id: singleTag ?? ''
              })),
              {
                type: EMapPortalTags.MAP_PORTAL_TAGS,
                id: EMapPortalTags.MAP_PORTAL_TAGS
              }
            ]
          : [
              {
                type: EMapPortalTags.MAP_PORTAL_TAGS,
                id: EMapPortalTags.MAP_PORTAL_TAGS
              }
            ]
    }),
    fetchSpotMeasureConfig: builder.query<
      TSpotMeasureConfigResponse[],
      TFetchMapPortalDataParams
    >({
      query: ({ district, mapPortalId }) => ({
        method: 'GET',
        url: `${district}/map_portal/${mapPortalId}/measurment_layers/`
      }),
      onQueryStarted: async (
        { district, mapPortalId },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchSpotMeasureConfigBegin());
        try {
          const { data } = await queryFulfilled;

          dispatch(
            fetchSpotMeasureConfigSuccess({
              prefix: district,
              mapPortalId,
              measureConfig: data
            })
          );
        } catch (error) {
          dispatch(fetchSpotMeasureConfigFailed());
          handleApiError(error, dispatch);
        }
      },
      providesTags: data =>
        data
          ? [
              ...data.map(singleLayerUnits => ({
                type: EMapPortalTags.SPOT_MEASURE_CONFIG,
                id: singleLayerUnits.id
              })),
              {
                type: EMapPortalTags.SPOT_MEASURE_CONFIG,
                id: EMapPortalTags.SPOT_MEASURE_CONFIG
              }
            ]
          : [
              {
                type: EMapPortalTags.SPOT_MEASURE_CONFIG,
                id: EMapPortalTags.SPOT_MEASURE_CONFIG
              }
            ]
    }),
    fetchHomeSiteIframe: builder.query<TMapPortal, TFetchHomeSiteIframeParams>({
      query: ({ prefix, mapPortalId }) => ({
        method: 'GET',
        url: `${prefix}/map_portal/${mapPortalId}/`
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        dispatch(fetchHomeSiteIframeBegin());

        try {
          const { data } = await queryFulfilled;
          dispatch(fetchHomeSiteIframeSuccess(data));
        } catch (error) {
          dispatch(fetchHomeSiteIframeFailed());
        }
      },
      providesTags: data =>
        data
          ? [
              {
                type: EMapPortalTags.IFRAME_PORTAL_DATA,
                id: data.id.toString()
              },
              {
                type: EMapPortalTags.IFRAME_PORTAL_DATA,
                id: EMapPortalTags.IFRAME_PORTAL_DATA
              }
            ]
          : [
              {
                type: EMapPortalTags.IFRAME_PORTAL_DATA,
                id: EMapPortalTags.IFRAME_PORTAL_DATA
              }
            ]
    })
  }),

  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchDistrictMapPortalsQuery,
  useLazyFetchMapPortalToolsQuery,
  useLazyFetchCompositionQuery,
  useLazyFetchRasterTimelineQuery,
  useLazyFetchSingleDistrictMapPortalQuery,
  useLazyFetchSingleMapPortalCompositionsQuery,
  useFetchSingleMapPortalCompositionsQuery,
  useLazyFetchMapPortalVectorTimelinesQuery,
  useLazyFetchMapPortalTagsQuery,
  useLazyFetchSpotMeasureConfigQuery,
  useLazyFetchHomeSiteIframeQuery
} = mapPortalApi;
