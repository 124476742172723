import { toStringHDMS } from 'ol/coordinate';

const getDMSValue = (dmsAsString, start, end) => {
  return dmsAsString
    .split(' ')
    .slice(start, end)
    .join(' ');
};

const convertCoordinatesToHDMS = ({ lat, lng }) => {
  const dmsAsString = toStringHDMS([Number(lat), Number(lng)]);
  const lngDMS = getDMSValue(dmsAsString, 0, 4);
  const latDMS = getDMSValue(dmsAsString, 4, 8);

  return { lngDMS, latDMS };
};

export default convertCoordinatesToHDMS;
