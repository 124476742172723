import { useIntl, FormattedMessage } from 'react-intl';

import Button from '../Button';
import ViewTitle from '../ViewTitle';
import SystemInformationView from '../../views/SystemInformationView';

const ErrorBoard = () => {
  const { formatMessage } = useIntl();

  return (
    <>
      <ViewTitle
        title={formatMessage({
          id: 'error_board_title',
          defaultMessage: 'error'
        })}
      />
      <SystemInformationView
        title={formatMessage({
          id: 'error_board_header',
          defaultMessage: 'Error'
        })}
        icon="exclamation-circle"
      >
        <p>
          <FormattedMessage
            id="error_board_message"
            defaultMessage="An unexpected error has occurred. Please refresh the page or try again in a moment. If the error still persists, please contact the administration."
            tagName="p"
          />
        </p>
        <Button
          variant="secondary"
          onClick={() => window.location.reload()}
          // Temporary solution to solve WCAG issue
          style={{ backgroundColor: '#0E9BDD' }}
        >
          {formatMessage({
            id: 'error_board_refresh_page',
            defaultMessage: 'Refresh the page'
          })}
        </Button>
      </SystemInformationView>
    </>
  );
};

export default ErrorBoard;
