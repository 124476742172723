import L from 'leaflet';

const getGeoJsonBBox = layerGeoJSON => {
  const bbox = [];
  Object.values(L.geoJson(layerGeoJSON).getBounds()).forEach(({ lat, lng }) =>
    bbox.push(lng, lat)
  );
  return bbox;
};

export default getGeoJsonBBox;
