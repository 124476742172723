// UPUL API
export const API_DISTRICT_UPUL_ATTESTATION_SETTINGS =
  ':district:/upul/attestation/const_parts_settings/';

export const API_DISTRICT_UPUL_ATTESTATION_TEMPLATES =
  ':district:/upul/attestation/templates/';

export const API_DISTRICT_UPUL_ATTESTATION_TEMPLATES_SINGLE =
  ':district:/upul/attestation/templates/:id:/';

export const API_DISTRICT_UPUL_ANALYZE =
  ':district:/upul/attestation/analyze_plot/';

export const API_DISTRICT_UPUL_GENERATE =
  ':district:/upul/attestation/generate/';

// REGISTERS API

export const API_DISTRICT_COMPOSITION_REGISTERS = `:district:/registers/:compositionId:/config/`;
export const API_DISTRICT_REGISTERS = ':district:/registers/config/';

export const API_DISTRICT_REGISTERS_SINGLE =
  ':district:/registers/config/:id:/';

// FINN API
export const API_FINN_SERVICE = 'finn/:service:/';

// APPEARANCE
export const API_APPEARANCE = ':district:/appearance/';
export const API_APPEARANCE_SINGLE = ':district:/appearance/:id:/';
export const API_APPEARANCE_LIST = ':district:/appearance_list/:appearance:/';

export const API_APP_DISTRICT_ALPHABETIZE_SINGLE = 'app_district/alphabetize/';

export const API_DISTRICT_ROLES = ':district:/user_roles/';
