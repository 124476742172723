import { Map, List } from 'immutable';

import {
  FETCH_LAYER_FORMS_BEGIN,
  FETCH_LAYER_FORMS_SUCCESS,
  FETCH_LAYER_FORMS_FAILED,
  FETCH_ADMIN_LAYER_OBJECTS_SUCCESS,
  OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS,
  GET_USER_FORMS_SUCCESS,
  GET_USER_FORM_SUCCESS,
  EDIT_LAYER_FORM_SUCCESS,
  FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS,
  GET_OBJECT_ATTACHMENTS_SUCCESS
} from '../constants/editDataTypes';

const initialState = Map({
  fetchedLayersForms: Map({}),
  fetchingLayersForms: false,
  fetchedLayerObjects: Map({}),
  options: Map({}),
  userFormsList: Map({}),
  userForms: Map({}),
  fetchedSubscriptionsLayerObjects: Map({})
});

const fetchLayerFormBegin = state => state.set('fetchingLayersForms', true);

const fetchLayerFormFailed = state => state.set('fetchingLayersForms', false);

const fetchLayerFormSuccess = (state, action) => {
  return state.merge(
    Map({
      fetchedLayersForms: state
        .get('fetchedLayersForms')
        .setIn([action.payload.district], action.payload.forms),
      fetchingLayersForms: false
    })
  );
};

const fetchLayerObjectsSuccess = (state, action) =>
  state.set('fetchedLayerObjects', action.layerObjects);

const optionsLayerObjectsSuccess = (state, action) =>
  state.set('options', action.options);

const optionsSubscriptionsLayerObjectsSuccess = (state, action) =>
  state.set('options', action.options);

const getUserFormsSuccess = (state, { district, userForms }) =>
  state.setIn(['userFormsList', district], List(userForms));

const getUserFormSuccess = (state, { district, userForm }) =>
  state.setIn(['userForms', district, userForm.id], Map(userForm));

const editLayerFormSuccess = (state, { district, payload }) => {
  const path = ['fetchedLayersForms', district];
  const layers = state.getIn(path);
  const newList = [...layers.results];
  const layersIndex = newList.findIndex(({ id }) => id === payload.id);
  newList[layersIndex] = payload;
  return state.setIn(path, { ...layers, results: newList });
};

const fetchSubscriptionsLayerObjectsSuccess = (state, action) =>
  state.set(
    'fetchedSubscriptionsLayerObjects',
    action.payload.layerSubscriptionsObjects
  );

const getObjectAttachmentsSuccess = (state, action) =>
  state.set('singleObjectAttachments', action.payload);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_LAYER_FORMS_SUCCESS]: fetchLayerFormSuccess,
      [FETCH_LAYER_FORMS_BEGIN]: fetchLayerFormBegin,
      [FETCH_LAYER_FORMS_FAILED]: fetchLayerFormFailed,
      [FETCH_ADMIN_LAYER_OBJECTS_SUCCESS]: fetchLayerObjectsSuccess,
      [OPTIONS_ADMIN_LAYER_OBJECTS_SUCCESS]: optionsLayerObjectsSuccess,
      [GET_USER_FORMS_SUCCESS]: getUserFormsSuccess,
      [GET_USER_FORM_SUCCESS]: getUserFormSuccess,
      [EDIT_LAYER_FORM_SUCCESS]: editLayerFormSuccess,
      [FETCH_SUBSCRIBER_LAYER_OBJECTS_SUCCESS]: fetchSubscriptionsLayerObjectsSuccess,
      [OPTIONS_SUBSCRIBER_LAYER_OBJECTS_SUCCESS]: optionsSubscriptionsLayerObjectsSuccess,
      [GET_OBJECT_ATTACHMENTS_SUCCESS]: getObjectAttachmentsSuccess
    }[action.type] || (s => s)
  )(state, action);
}
