/**
 * Funkcja dogłębnie przeszukuje przekazany obiekt lub tablicę
 * w celu podmiany wskazanej wartości.
 *
 * @param {object} config.obj - tablica lub obiekt do przeszukania.
 * @param {function} config.checkCondition - funkcja sprawdzająca czy aktualnie
 * iterowany item to poszukiwany obiekt. Jako parametr przekazuje iterowaną wartość.
 * @param {replace} config.replace - funkcja, która wykonuje dowolną operację na każdym
 * obiekcie, który spełnia kryteria przekazane w funkcji checkCondition. Jako parametr
 * przyjmuje element, na którym wykonywana jest operacja.
 * @param {mutation} config.mutation - określa, czy iterowany obiekt ma zostać zmutowany,
 * czy zwrócić kopię jako wynik wywołania funkcji. W pierwszym wypadku funkcja zwraca
 * wartość undefined.
 */

// @TODO - Dodać parametr (flaga), który wywoła zatrzymanie dalszego iterowania
// w przypadku znalezienia pierwszej poszukiwanej wartości.
export default function deepReplace({
  obj,
  checkCondition,
  replace,
  mutation
}) {
  function through(obj) {
    // eslint-disable-next-line no-unused-vars
    for (const key in obj) {
      const item = obj[key];

      if (checkCondition(item)) {
        obj[key] = replace(item);
      } else {
        if (typeof item === 'object') through(item);
      }
    }
  }

  if (mutation) return through(obj);

  const newObj = JSON.parse(JSON.stringify(obj)); // Fast way to deep copy
  through(newObj);
  return newObj;
}
