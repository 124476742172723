const selectors = {
  getDistrictUserForms: (state, district) =>
    state.editData.getIn(['userFormsList', district]),
  getDistrictUserForm: (state, district, userFormId) =>
    state.editData.getIn(['userForms', district, userFormId]),
  getExistingObjectAttachments: state =>
    state.editData.get('singleObjectAttachments')
};

export default selectors;
