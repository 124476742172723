export const FETCH_BASIC_SHAPES_STARTED = 'FETCH_BASIC_SHAPES_STARTED';
export const FETCH_BASIC_SHAPES_SUCCESS = 'FETCH_BASIC_SHAPES_SUCCESS';
export const FETCH_BASIC_SHAPES_FAILED = 'FETCH_BASIC_SHAPES_FAILED';

export const FETCH_USER_DEFINED_SHAPES_STARTED =
  'FETCH_USER_DEFINED_SHAPES_STARTED';
export const FETCH_USER_DEFINED_SHAPES_SUCCESS =
  'FETCH_USER_DEFINED_SHAPES_SUCCESS';
export const FETCH_USER_DEFINED_SHAPES_FAILED =
  'FETCH_USER_DEFINED_SHAPES_FAILED';

export const UPDATE_SHAPE_STARTED = 'UPDATE_SHAPE_STARTED';
export const UPDATE_SHAPE_SUCCESS = 'UPDATE_SHAPE_SUCCESS';
export const UPDATE_SHAPE_FAILED = 'UPDATE_SHAPE_FAILED';

export const ADD_NEW_SHAPE_STARTED = 'ADD_NEW_SHAPE_STARTED';
export const ADD_NEW_SHAPE_SUCCESS = 'ADD_NEW_SHAPE_SUCCESS';
export const ADD_NEW_SHAPE_FAILED = 'ADD_NEW_SHAPE_FAILED';

export const DELETE_USER_SHAPE_STARTED = 'DELETE_USER_SHAPE_STARTED';
export const DELETE_USER_SHAPE_SUCCESS = 'DELETE_USER_SHAPE_SUCCESS';
export const DELETE_USER_SHAPE_FAILED = 'DELETE_USER_SHAPE_FAILED';
