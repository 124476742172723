export const FETCH_SEARCHABLE_MODELS_BEGIN = 'FETCH_SEARCHABLE_MODELS_BEGIN';
export const FETCH_SEARCHABLE_MODELS_SUCCESS =
  'FETCH_SEARCHABLE_MODELS_SUCCESS';
export const FETCH_SEARCHABLE_MODELS_FAILED = 'FETCH_SEARCHABLE_MODELS_FAILED';

export const FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN =
  'FETCH_DISTRICT_SEARCHABLE_MODELS_BEGIN';
export const FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS =
  'FETCH_DISTRICT_SEARCHABLE_MODELS_SUCCESS';
export const FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED =
  'FETCH_DISTRICT_SEARCHABLE_MODELS_FAILED';

export const FETCH_NAMES_MODELS_BEGIN = 'FETCH_NAMES_MODELS_BEGIN';
export const FETCH_NAMES_MODELS_SUCCESS = 'FETCH_NAMES_MODELS_SUCCESS';
export const FETCH_NAMES_MODELS_FAILED = 'FETCH_NAMES_MODELS_FAILED';

export const FETCH_DATA_MODEL_BEGIN = 'FETCH_DATA_MODEL_BEGIN';
export const FETCH_DATA_MODEL_SUCCESS = 'FETCH_DATA_MODEL_SUCCESS';
export const FETCH_DATA_MODEL_FAILED = 'FETCH_DATA_MODEL_FAILED';

export const FETCH_OPTIONS_MODEL_BEGIN = 'FETCH_OPTIONS_MODEL_BEGIN';
export const FETCH_OPTIONS_MODEL_SUCCESS = 'FETCH_OPTIONS_MODEL_SUCCESS';
export const FETCH_OPTIONS_MODEL_FAILED = 'FETCH_OPTIONS_MODEL_FAILED';

export const UPDATE_DATA_MODEL_BEGIN = 'UPDATE_DATA_MODEL_BEGIN';
export const UPDATE_DATA_MODEL_SUCCESS = 'UPDATE_DATA_MODEL_SUCCESS';
export const UPDATE_DATA_MODEL_FAILED = 'UPDATE_DATA_MODEL_FAILED';

export const GET_MODELS_TRANSLATIONS_BEGIN = 'GET_MODELS_TRANSLATIONS_BEGIN';
export const GET_MODELS_TRANSLATIONS_SUCCESS =
  'GET_MODELS_TRANSLATIONS_SUCCESS';
export const GET_MODELS_TRANSLATIONS_FAILED = 'GET_MODELS_TRANSLATIONS_FAILED';
export const GET_ALL_MODELS_TRANSLATIONS_SUCCESS =
  'GET_ALL_MODELS_TRANSLATIONS_SUCCESS';

export const ADD_MODEL_TRANSLATION_BEGIN = 'ADD_MODEL_TRANSLATION_BEGIN';
export const ADD_MODEL_TRANSLATION_SUCCESS = 'ADD_MODEL_TRANSLATION_SUCCESS';
export const ADD_MODEL_TRANSLATION_FAILED = 'ADD_MODEL_TRANSLATION_FAILED';

export const EDIT_MODEL_TRANSLATION_BEGIN = 'EDIT_MODEL_TRANSLATION_BEGIN';
export const EDIT_MODEL_TRANSLATION_SUCCESS = 'EDIT_MODEL_TRANSLATION_SUCCESS';
export const EDIT_MODEL_TRANSLATION_FAILED = 'EDIT_MODEL_TRANSLATION_FAILED';

export const DELETE_MODEL_TRANSLATION_BEGIN = 'DELETE_MODEL_TRANSLATION_BEGIN';
export const DELETE_MODEL_TRANSLATION_SUCCESS =
  'DELETE_MODEL_TRANSLATION_SUCCESS';
export const DELETE_MODEL_TRANSLATION_FAILED =
  'DELETE_MODEL_TRANSLATION_FAILED';

export const FETCH_CONTENT_TYPE_LAYERS_BEGIN =
  'FETCH_CONTENT_TYPE_LAYERS_BEGIN';
export const FETCH_CONTENT_TYPE_LAYERS_SUCCESS =
  'FETCH_CONTENT_TYPE_LAYERS_SUCCESS';
export const FETCH_CONTENT_TYPE_LAYERS_FAILED =
  'FETCH_CONTENT_TYPE_LAYERS_FAILED';

export const FETCH_DISTRICT_MODELS_DATA_SUCCESS =
  'FETCH_DISTRICT_MODELS_DATA_SUCCESS';
