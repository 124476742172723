import { Map } from 'immutable';

import {
  SET_SKETCH_ID,
  SET_MEASUREMENT_ID,
  SET_OBJECT_GEOMETRY_DATA_BEGIN,
  SET_OBJECT_GEOMETRY_DATA_SUCCESS,
  SET_OBJECT_GEOMETRY_DATA_FAILED,
  SET_PM_AND_PID
} from '../constants/queryParamsActionTypes';

export const initialState = Map({
  sketchId: null,
  sketchData: null,
  measurementId: null,
  pm: null,
  pid: null,
  objectGeometry: null,
  isLoading: false,
  error: null
});

const setSketchId = (state, { sketchId }) => state.set('sketchId', sketchId);

const setMeasurementId = (state, { measurementId }) =>
  state.set('measurementId', measurementId);

const setPmAndPid = (state, { pm, pid }) => state.merge({ pm, pid });

const setObjectGeometryDataBegin = state => state.set('isLoading', true);

const setObjectGeometryDataSuccess = (state, { pm, pid, data }) =>
  state.merge({ isLoading: false, pm, pid, objectGeometry: data });

const setObjectGeometryDataFailed = (state, { error }) =>
  state.merge({ isLoading: false, error });

export default function reducer(state = initialState, action) {
  return (
    {
      [SET_SKETCH_ID]: setSketchId,
      [SET_MEASUREMENT_ID]: setMeasurementId,
      [SET_PM_AND_PID]: setPmAndPid,
      [SET_OBJECT_GEOMETRY_DATA_BEGIN]: setObjectGeometryDataBegin,
      [SET_OBJECT_GEOMETRY_DATA_SUCCESS]: setObjectGeometryDataSuccess,
      [SET_OBJECT_GEOMETRY_DATA_FAILED]: setObjectGeometryDataFailed
    }[action.type] || (s => s)
  )(state, action);
}
