export const translations = {
  loadingMainPortal: {
    id: 'loading_main_portal',
    defaultMessage: 'Loading map portal...'
  },
  loadingInProgress: {
    id: 'loading_in_progress',
    defaultMessage: 'Loading in progress...'
  }
};
