import { createApi } from '@reduxjs/toolkit/query/react';
import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import handleApiError from '@/utils/handleApiError';

import {
  fetchCompositionTopicalSearchToolsBegin,
  fetchCompositionTopicalSearchToolsFailed,
  fetchCompositionTopicalSearchToolsSuccess,
  getTopicalSearchToolColumnsBegin,
  getTopicalSearchToolColumnsFailed,
  getTopicalSearchToolColumnsSuccess,
  getTopicalSearchToolResultColumnsBegin,
  getTopicalSearchToolResultColumnsFailed,
  getTopicalSearchToolResultColumnsSuccess
} from '@/store/actions/searchToolsActions';

import {
  TTopicalSearchToolsLookupColumnsResponse,
  TTopicalSearchToolsResponse,
  TTopicalSearchToolsResultResponse
} from '@/types/responses/search-tool';
import {
  ESearchToolTags,
  TFetchTopicalSearchOptionsParams,
  TFetchTopicalSearchToolResultColumnsParams,
  TFetchTopicalSearchToolsParams
} from '@/types/api-types/searchTool';

import {
  TDjangoPaginationResponse,
  TSearchOptionsResponse
} from '@/types/django-pagination-response';

export const searchToolApi = createApi({
  reducerPath: 'searchToolApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [
    ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS,
    ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS,
    ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS,
    ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS
  ],
  endpoints: builder => ({
    fetchCompositionTopicalSearchTools: builder.query<
      TTopicalSearchToolsResponse[],
      TFetchTopicalSearchToolsParams
    >({
      query: ({ compositionId, district }) => ({
        method: 'GET',
        url: `${district}/map_portal_composition/${compositionId}/topical_search_tools/`
      }),
      onQueryStarted: async (
        { compositionId, district },
        { dispatch, queryFulfilled }
      ) => {
        dispatch(fetchCompositionTopicalSearchToolsBegin());
        try {
          const { data } = await queryFulfilled;

          dispatch(
            fetchCompositionTopicalSearchToolsSuccess({
              tools: data,
              district,
              compositionId
            })
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(fetchCompositionTopicalSearchToolsFailed());
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS,
                id
              })),
              {
                type: ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS,
                id: ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS
              }
            ]
          : [
              {
                type: ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS,
                id: ESearchToolTags.COMPOSITION_TOPICAL_SEARCH_TOOLS
              }
            ]
    }),
    fetchTopicalSearchToolsResultColumns: builder.query<
      TTopicalSearchToolsResultResponse[],
      TFetchTopicalSearchToolResultColumnsParams
    >({
      query: ({ toolId }) => ({
        method: 'GET',
        url: `topical_search_tool/${toolId}/result_columns/`
      }),
      onQueryStarted: async ({ toolId }, { dispatch, queryFulfilled }) => {
        dispatch(getTopicalSearchToolResultColumnsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(
            getTopicalSearchToolResultColumnsSuccess({
              columns: data,
              toolId
            })
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(getTopicalSearchToolResultColumnsFailed());
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS,
                id
              })),
              {
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS,
                id: ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS
              }
            ]
          : [
              {
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS,
                id: ESearchToolTags.TOPICAL_SEARCH_TOOL_RESULT_COLUMNS
              }
            ]
    }),
    fetchTopicalSearchOptions: builder.query<
      TDjangoPaginationResponse<TSearchOptionsResponse>,
      TFetchTopicalSearchOptionsParams
    >({
      query: ({ url, params }) => {
        const isFullUrl =
          url.startsWith('http://') || url.startsWith('https://');

        const modifiedUrl = url.startsWith('/') ? url.slice(1) : url;
        const exactUrl = isFullUrl ? url : modifiedUrl;

        return {
          url: exactUrl,
          method: 'GET',
          params: isFullUrl ? {} : params,
          omitBaseUrl: isFullUrl
        };
      },
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      },
      providesTags: response => {
        const results = response?.results;

        if (!results?.features?.length) {
          return [
            {
              type: ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS,
              id: ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS
            }
          ];
        }

        return [
          ...results.features.map(id => ({
            type: ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS,
            id
          })),
          {
            type: ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS,
            id: ESearchToolTags.TOPICAL_SEARCH_TOOL_OPTIONS
          }
        ];
      }
    }),
    fetchTopicalSearchToolsLookupColumns: builder.query<
      TTopicalSearchToolsLookupColumnsResponse[],
      TFetchTopicalSearchToolResultColumnsParams
    >({
      query: ({ toolId }) => ({
        method: 'GET',
        url: `topical_search_tool/${toolId}/lookup_columns/`
      }),
      onQueryStarted: async ({ toolId }, { dispatch, queryFulfilled }) => {
        dispatch(getTopicalSearchToolColumnsBegin());

        try {
          const { data } = await queryFulfilled;

          dispatch(
            getTopicalSearchToolColumnsSuccess({
              columns: data,
              toolId
            })
          );
        } catch (error) {
          handleApiError(error, dispatch);
          dispatch(getTopicalSearchToolColumnsFailed());
        }
      },
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS,
                id
              })),
              {
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS,
                id: ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS
              }
            ]
          : [
              {
                type: ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS,
                id: ESearchToolTags.TOPICAL_SEARCH_TOOL_LOOKUP_COLUMNS
              }
            ]
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useLazyFetchCompositionTopicalSearchToolsQuery,
  useLazyFetchTopicalSearchToolsResultColumnsQuery,
  useLazyFetchTopicalSearchOptionsQuery,
  useLazyFetchTopicalSearchToolsLookupColumnsQuery
} = searchToolApi;
