import { Map } from 'immutable';

import {
  FETCH_PROJECT_CACHE_LOGS_SUCCESS,
  DELETE_CACHE_UPDATE_LOG_SUCCESS,
  FETCH_PROJECTS_CACHE_LOGS_SUCCESS,
  CLEAR_PROJECTS_CACHE_LOGS,
  CLEAR_SINGLE_PROJECT_CACHE_LOGS
} from '../constants/projectsActionTypes';

const initialState = Map({
  cacheLogs: Map({}),
  allCacheLogs: Map({})
});

const fetchProjectCacheLogs = (
  state,
  { projectId, page, resultsPerPage, results, count }
) =>
  state.setIn(['cacheLogs', projectId, page, resultsPerPage], {
    count,
    data: results
  });

const deleteProjectCacheLog = (state, { page, logId }) => {
  const cacheLogs = state.getIn(['cacheLogs', page]);
  const newCacheLogs = cacheLogs.filter(({ id }) => id !== logId);
  return state.setIn(['cacheLogs', page], newCacheLogs);
};

const fetchProjectsCacheLogs = (state, { district, logs }) =>
  state.setIn(['allCacheLogs', String(district)], logs);

const clearProjectsCacheLogs = state => state.set('cacheLogs', Map({}));

const clearSingleProjectCacheLogs = (state, { projectId }) =>
  state.setIn(['cacheLogs', String(projectId)], Map({}));

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_PROJECT_CACHE_LOGS_SUCCESS]: fetchProjectCacheLogs,
      [DELETE_CACHE_UPDATE_LOG_SUCCESS]: deleteProjectCacheLog,
      [FETCH_PROJECTS_CACHE_LOGS_SUCCESS]: fetchProjectsCacheLogs,
      [CLEAR_PROJECTS_CACHE_LOGS]: clearProjectsCacheLogs,
      [CLEAR_SINGLE_PROJECT_CACHE_LOGS]: clearSingleProjectCacheLogs
    }[action.type] || (s => s)
  )(state, action);
}
