import axios from 'axios';
import { Map, List } from 'immutable';

import { mapPortalApi } from '../api/mapPortal';
import { showError, showSuccess } from './globalActions';

import { popupSelectors } from '../selectors';

import { parseResponseError, downloadFile } from '../../utils/lib';
import { CustomLogger } from '../../utils/CustomLogger';
import handleError from '../../utils/handleError';

import {
  ADD_POPUP_CONFIG_BEGIN,
  ADD_POPUP_CONFIG_SUCCESS,
  ADD_POPUP_CONFIG_FAILED,
  DELETE_POPUP_CONFIG_BEGIN,
  DELETE_POPUP_CONFIG_SUCCESS,
  DELETE_POPUP_CONFIG_FAILED,
  FETCH_POPUP_CONFIGS_BEGIN,
  FETCH_POPUP_CONFIGS_SUCCESS,
  FETCH_POPUP_CONFIGS_FAILED,
  FETCH_POPUP_CONFIG_BEGIN,
  FETCH_POPUP_CONFIG_SUCCESS,
  FETCH_POPUP_CONFIG_FAILED,
  FETCH_POPUP_LAYERS_CONFIG_BEGIN,
  FETCH_POPUP_LAYERS_CONFIG_SUCCESS,
  FETCH_POPUP_LAYERS_CONFIG_FAILED,
  SET_COORDINATES_TO_IDENTIFICATION,
  CLEAR_COORDINATES_TO_IDENTIFICATION,
  ADD_POPUP_LAYER_CONFIG_BEGIN,
  ADD_POPUP_LAYER_CONFIG_SUCCESS,
  ADD_POPUP_LAYER_CONFIG_FAILED,
  EDIT_POPUP_LAYER_CONFIG_BEGIN,
  EDIT_POPUP_LAYER_CONFIG_SUCCESS,
  EDIT_POPUP_LAYER_CONFIG_FAILED,
  DELETE_POPUP_LAYER_CONFIG_BEGIN,
  DELETE_POPUP_LAYER_CONFIG_SUCCESS,
  DELETE_POPUP_LAYER_CONFIG_FAILED,
  SET_POPUP_LAYER_CONFIG_ORDER_BEGIN,
  SET_POPUP_LAYER_CONFIG_ORDER_SUCCESS,
  SET_POPUP_LAYER_CONFIG_ORDER_FAILED,
  FETCH_LAYER_ATTRIBUTES_BEGIN,
  FETCH_LAYER_ATTRIBUTES_SUCCESS,
  FETCH_LAYER_ATTRIBUTES_FAILED,
  PATCH_LAYER_ATTRIBUTE_BEGIN,
  PATCH_LAYER_ATTRIBUTE_SUCCESS,
  PATCH_LAYER_ATTRIBUTE_FAILED,
  OPEN_INFORMATION_MODAL,
  CLOSE_INFORMATION_MODAL,
  MINIMIZE_INFORMATION_MODAL,
  MAXIMIZE_INFORMATION_MODAL,
  RESET_MINIMIZED_MODAL_INFORMATIONS,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED,
  FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED,
  UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  DESKTOP_ADD_POPUP_CONFIG_BEGIN,
  DESKTOP_ADD_POPUP_CONFIG_SUCCESS,
  DESKTOP_ADD_POPUP_CONFIG_FAILED,
  DESKTOP_EDIT_POPUP_CONFIG_BEGIN,
  DESKTOP_EDIT_POPUP_CONFIG_SUCCESS,
  DESKTOP_EDIT_POPUP_CONFIG_FAILED,
  DESKTOP_DELETE_POPUP_CONFIG_BEGIN,
  DESKTOP_DELETE_POPUP_CONFIG_SUCCESS,
  DESKTOP_DELETE_POPUP_CONFIG_FAILED,
  DESKTOP_FETCH_POPUP_CONFIGS_BEGIN,
  DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS,
  DESKTOP_FETCH_POPUP_CONFIGS_FAILED,
  DESKTOP_ADD_POPUP_LAYER_CONFIG_BEGIN,
  DESKTOP_ADD_POPUP_LAYER_CONFIG_SUCCESS,
  DESKTOP_ADD_POPUP_LAYER_CONFIG_FAILED,
  FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_BEGIN,
  FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS,
  FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_FAILED,
  ADD_POPUP_LAYER_ATTRIBUTE_BEGIN,
  ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  ADD_POPUP_LAYER_ATTRIBUTE_FAILED,
  EDIT_POPUP_LAYER_ATTRIBUTE_BEGIN,
  EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  EDIT_POPUP_LAYER_ATTRIBUTE_FAILED,
  DELETE_POPUP_LAYER_ATTRIBUTE_BEGIN,
  DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  DELETE_POPUP_LAYER_ATTRIBUTE_FAILED,
  UPDATE_POPUP_STRUCTURE,
  CLEAR_POPUP_STRUCTURE,
  SET_POPUP_CARD_LOADING_LAYER_STATUS,
  RESET_POPUP_CARD_LOADING_LAYERS_STATUS,
  SET_POPUP_CARD_TREE,
  ADD_POPUP_LAYER_JOIN_BEGIN,
  ADD_POPUP_LAYER_JOIN_FAILED,
  ADD_POPUP_LAYER_JOIN_SUCCESS,
  FETCH_POPUP_LAYER_JOINS_BEGIN,
  FETCH_POPUP_LAYER_JOINS_FAILED,
  FETCH_POPUP_LAYER_JOINS_SUCCESS,
  DELETE_POPUP_LAYER_JOIN_BEGIN,
  DELETE_POPUP_LAYER_JOIN_FAILED,
  DELETE_POPUP_LAYER_JOIN_SUCCESS,
  UPDATE_POPUP_LAYER_JOIN_BEGIN,
  UPDATE_POPUP_LAYER_JOIN_FAILED,
  UPDATE_POPUP_LAYER_JOIN_SUCCESS,
  FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN,
  FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED,
  FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  ADD_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN,
  ADD_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED,
  ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN,
  DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED,
  DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN,
  UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED,
  UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  UPDATE_PLOT_LIST_INFORMATION,
  SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION,
  CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION,
  DOWNLOAD_POPUP_DATA_BEGIN,
  DOWNLOAD_POPUP_DATA_SUCCESS,
  DOWNLOAD_POPUP_DATA_FAILED,
  UPDATE_ROAD_LIST_INFORMATION,
  GET_QRCODE_FOR_OBJECT_FINISHED,
  GET_QRCODE_FOR_OBJECT_STARTED,
  FETCH_PARCELS_DATA_STARTED,
  FETCH_PARCELS_DATA_SUCCESS,
  FETCH_PARCELS_DATA_FINISHED,
  IS_QUERY_PARAMS_POPUP,
  DOWNLOAD_ATTACHMENTS_DATA_TO_PRINT,
  SET_POPUP_URL,
  SET_IS_PREVIEW_MAPS_ENABLED,
  SET_IS_GENERATING_DATA_TO_PRINT
} from '../constants/popupActionTypes';

/////////////////////
// addPopupConfig //
///////////////////
export const addPopupConfigBegin = () => ({
  type: ADD_POPUP_CONFIG_BEGIN
});

export const addPopupConfigSuccess = config => ({
  type: ADD_POPUP_CONFIG_SUCCESS,
  config
});

export const addPopupConfigFailed = () => ({
  type: ADD_POPUP_CONFIG_FAILED
});

export const addPopupConfig = body => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addPopupConfigBegin());

    axios
      .post('config/', body)
      .then(response => {
        dispatch(addPopupConfigSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addPopupConfigFailed());
        dispatch(
          showError(
            parseResponseError(err, {
              unique: 'Istnieje już konfiguracja z tą nazwą!'
            })
          )
        );
        handleError(err);
        reject(err);
      });
  });

////////////////////////
// deletePopupConfig //
//////////////////////
export const deletePopupConfigBegin = () => ({
  type: DELETE_POPUP_CONFIG_BEGIN
});

export const deletePopupConfigSuccess = configId => ({
  type: DELETE_POPUP_CONFIG_SUCCESS,
  configId
});

export const deletePopupConfigFailed = () => ({
  type: DELETE_POPUP_CONFIG_FAILED
});

export const deletePopupConfig = (
  configId,
  district,
  mapPortalId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deletePopupConfigBegin());

    axios
      .delete(`config/${configId}/`)
      .then(async response => {
        await dispatch(deletePopupConfigSuccess(configId));
        await dispatch(
          mapPortalApi.endpoints.fetchSingleMapPortalCompositions.initiate(
            {
              district,
              mapPortalId
            },
            true
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(deletePopupConfigFailed());
        dispatch(
          showError("Wystąpił błąd podczas usuwania konfiguracji popup'a")
        );
        reject(err);
      });
  });

////////////////////////
// fetchPopupConfigs //
//////////////////////
export const fetchPopupConfigsBegin = () => ({
  type: FETCH_POPUP_CONFIGS_BEGIN
});

export const fetchPopupConfigsSuccess = configs => ({
  type: FETCH_POPUP_CONFIGS_SUCCESS,
  configs
});

export const fetchPopupConfigsFailed = () => ({
  type: FETCH_POPUP_CONFIGS_FAILED
});

export const fetchPopupConfigs = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupConfigsBegin());

    axios
      .get('config/')
      .then(response => {
        dispatch(fetchPopupConfigsSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchPopupConfigsFailed());
        dispatch(
          showError(
            "Wystąpił błąd podczas pobierania listy konfiguracji popup'a"
          )
        );
        reject(err);
      });
  });

///////////////////////
// fetchPopupConfig //
/////////////////////
export const fetchPopupConfigBegin = () => ({
  type: FETCH_POPUP_CONFIG_BEGIN
});

export const fetchPopupConfigSuccess = config => ({
  type: FETCH_POPUP_CONFIG_SUCCESS,
  config
});

export const fetchPopupConfigFailed = () => ({
  type: FETCH_POPUP_CONFIG_FAILED
});

export const fetchPopupConfig = configId => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupConfigBegin());

    // Jeśli konfiguracja popup'a jest zapisana w store - wczytaj ją.
    const state = getState();
    const popupConfig = state.popup.get('configs').get(String(configId));

    if (popupConfig) {
      dispatch(fetchPopupConfigSuccess(popupConfig));
      resolve(popupConfig);
    }

    // A jeśli nie to pobierz z backendu.
    axios
      .get(`config/${configId}/`)
      .then(response => {
        dispatch(fetchPopupConfigSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err);

        dispatch(fetchPopupConfigFailed());
        dispatch(
          showError(
            errInfo
              ? errInfo
              : "Wystąpił błąd podczas pobierania konfiguracji popup'a!"
          )
        );
        reject(err);
      });
  });

//////////////////////////////
// fetchPopupLayersConfigs //
////////////////////////////
export const fetchPopupLayersConfigsBegin = () => ({
  type: FETCH_POPUP_LAYERS_CONFIG_BEGIN
});

export const fetchPopupLayersConfigsSuccess = layersConfigs => ({
  type: FETCH_POPUP_LAYERS_CONFIG_SUCCESS,
  layersConfigs
});

export const fetchPopupLayersConfigsFailed = () => ({
  type: FETCH_POPUP_LAYERS_CONFIG_FAILED
});

export const fetchPopupLayersConfigs = () => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupLayersConfigsBegin());

    axios
      .get('layer_config/')
      .then(response => {
        dispatch(fetchPopupLayersConfigsSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err);

        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas pobierania listy konfiguracji warstw.'
          )
        );
        dispatch(fetchPopupLayersConfigsFailed());
        reject(err);
      });
  });

//////////////////////////
// addPopupLayerConfig //
////////////////////////
export const addPopupLayerConfigBegin = () => ({
  type: ADD_POPUP_LAYER_CONFIG_BEGIN
});

export const addPopupLayerConfigSuccess = layerConfig => ({
  type: ADD_POPUP_LAYER_CONFIG_SUCCESS,
  layerConfig
});

export const addPopupLayerConfigFailed = () => ({
  type: ADD_POPUP_LAYER_CONFIG_FAILED
});

export const addPopupLayerConfig = ({ layerConfig, onSuccess }) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addPopupLayerConfigBegin());

    axios
      .post('layer_config/', layerConfig)
      .then(response => {
        onSuccess?.();
        dispatch(addPopupLayerConfigSuccess(response.data));
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err, {
          unique: 'Wybrana warstwa jest już przypisana do tej konfiguracji.'
        });

        dispatch(addPopupLayerConfigFailed());
        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas dodawania konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

///////////////////////////
// editPopupLayerConfig //
/////////////////////////
export const editPopupLayerConfigBegin = () => ({
  type: EDIT_POPUP_LAYER_CONFIG_BEGIN
});

export const editPopupLayerConfigSuccess = layerConfig => ({
  type: EDIT_POPUP_LAYER_CONFIG_SUCCESS,
  layerConfig
});

export const editPopupLayerConfigFailed = () => ({
  type: EDIT_POPUP_LAYER_CONFIG_FAILED
});

export const editPopupLayerConfig = layerConfig => dispatch =>
  new Promise(async (resolve, reject) => {
    /**
     * 1. Najpierw wysyłamy wszystkie atrybuty konfiguracji warstwy (Promise.all).
     * 2. Gdy wszystkie atrybuty zostaną zaktualizowane updateujemy sam obiekt konfiguracji.
     */
    dispatch(editPopupLayerConfigBegin());

    let editAddedAttrs = [];
    let editRemovedAttrs = [];
    const { layerConfigData, addedAttrs, removedAttrs } = layerConfig;

    if (addedAttrs?.attrs.length) {
      editAddedAttrs = addedAttrs.attrs.map(
        async attr => await dispatch(patchLayerAttribute(attr))
      );
    }

    try {
      await Promise.all(editAddedAttrs).then(async () => {
        if (removedAttrs?.attrs.length) {
          editRemovedAttrs = removedAttrs.attrs.map(
            async attr => await dispatch(patchLayerAttribute(attr))
          );
        }
        await Promise.all(editRemovedAttrs);
      });

      axios
        .patch(`layer_config/${layerConfigData.id}/`, layerConfigData)
        .then(async response => {
          dispatch(editPopupLayerConfigSuccess(response.data));
          await dispatch(fetchPopupConfigs());
          dispatch(
            showSuccess('Konfiguracja warstwy została zapisana poprawnie.')
          );
          resolve(response.data);
        })
        .catch(err => {
          const errInfo = parseResponseError(err);

          dispatch(
            showError(
              errInfo
                ? errInfo
                : 'Wystąpił błąd podczas zapisywania konfiguracji.'
            )
          );
          dispatch(editPopupLayerConfigFailed());
          reject(err);
        });
    } catch (err) {
      const errInfo = parseResponseError(err);

      dispatch(
        showError(
          errInfo ? errInfo : 'Wystąpił błąd podczas zapisywania konfiguracji.'
        )
      );
      dispatch(editPopupLayerConfigFailed());
      reject(err);
    }
  });

////////////////////////////
// patch layer attribute //
//////////////////////////

export const patchLayerAttributeBegin = () => ({
  type: PATCH_LAYER_ATTRIBUTE_BEGIN
});

export const patchLayerAttributeSuccess = () => ({
  type: PATCH_LAYER_ATTRIBUTE_SUCCESS
});

export const patchLayerAttributeFailed = () => ({
  type: PATCH_LAYER_ATTRIBUTE_FAILED
});

export const patchLayerAttribute = attr => dispatch =>
  new Promise(async (resolve, reject) => {
    dispatch(patchLayerAttributeBegin());
    axios
      .patch(`layer_attr/${attr.id}/`, attr)
      .then(async response => {
        dispatch(patchLayerAttributeSuccess());
        resolve(response);
      })
      .catch(err => {
        dispatch(patchLayerAttributeFailed());
        reject(err);
      });
  });

/////////////////////////////
// deletePopupLayerConfig //
///////////////////////////
export const deletePopupLayerConfigBegin = () => ({
  type: DELETE_POPUP_LAYER_CONFIG_BEGIN
});

export const deletePopupLayerConfigSuccess = (layerConfigId, configId) => ({
  type: DELETE_POPUP_LAYER_CONFIG_SUCCESS,
  layerConfigId,
  configId
});

export const deletePopupLayerConfigFailed = () => ({
  type: DELETE_POPUP_LAYER_CONFIG_FAILED
});

export const deletePopupLayerConfig = (layerConfigId, configId) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deletePopupLayerConfigBegin());

    axios
      .delete(`layer_config/${layerConfigId}/`)
      .then(response => {
        dispatch(deletePopupLayerConfigSuccess(layerConfigId, configId));
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err);

        dispatch(deletePopupLayerConfigFailed());
        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas usuwania konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

///////////////////////////////
// setPopupLayerConfigOrder //
/////////////////////////////
export const setPopupLayerConfigOrderBegin = () => ({
  type: SET_POPUP_LAYER_CONFIG_ORDER_BEGIN
});

export const setPopupLayerConfigOrderSuccess = (layerConfigId, configId) => ({
  type: SET_POPUP_LAYER_CONFIG_ORDER_SUCCESS,
  layerConfigId,
  configId
});

export const setPopupLayerConfigOrderFailed = () => ({
  type: SET_POPUP_LAYER_CONFIG_ORDER_FAILED
});

export const setPopupLayerConfigOrder = (
  layerConfigId,
  newIndex,
  configId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(setPopupLayerConfigOrderBegin());

    axios
      .patch(`layer_config/${layerConfigId}/`, { index: newIndex })
      .then(async response => {
        await dispatch(fetchPopupConfig(configId));
        dispatch(setPopupLayerConfigOrderSuccess());
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err);

        dispatch(setPopupLayerConfigOrderSuccess());
        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas ustawianiue kolejności warstw.'
          )
        );
        reject(err);
      });
  });

////////////////////////////////
// coordinatesIdentification //
//////////////////////////////
export const setCoordinatesToIdentification = ({ lat, lng }) => ({
  type: SET_COORDINATES_TO_IDENTIFICATION,
  lat,
  lng
});

export const clearCoordinatesToIdentification = () => ({
  type: CLEAR_COORDINATES_TO_IDENTIFICATION
});

export const fetchLayerAttributesBegin = () => ({
  type: FETCH_LAYER_ATTRIBUTES_BEGIN
});

export const fetchLayerAttributesSuccess = (layerAttributes, modelId) => ({
  type: FETCH_LAYER_ATTRIBUTES_SUCCESS,
  layerAttributes,
  modelId
});

export const fetchLayerAttributesFailed = () => ({
  type: FETCH_LAYER_ATTRIBUTES_FAILED
});

export const fetchLayerAttributes = modelId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchLayerAttributesBegin());
    axios
      .get(`/layer_attrs_list/${modelId}/`)
      .then(response => {
        resolve(dispatch(fetchLayerAttributesSuccess(response.data, modelId)));
      })
      .catch(error => {
        reject(dispatch(fetchLayerAttributesFailed()));
      });
  });

export const openInformationModal = () => ({
  type: OPEN_INFORMATION_MODAL
});

export const closeInformationModal = (activeToolIndex = false) => ({
  type: CLOSE_INFORMATION_MODAL,
  activeToolIndex
});

export const minimizeInformationModal = ({
  panelName,
  coordinatesToIdentification = Map({}),
  plotsListToIdentification = List([])
}) => ({
  type: MINIMIZE_INFORMATION_MODAL,
  panelName,
  coordinatesToIdentification,
  plotsListToIdentification
});

export const maximizeInformationModal = () => ({
  type: MAXIMIZE_INFORMATION_MODAL
});

export const resetMinimizedModalInformations = () => ({
  type: RESET_MINIMIZED_MODAL_INFORMATIONS
});

export const getDefaultAttachmentsAttributesBegin = () => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN
});

export const getDefaultAttachmentsAttributesSuccess = attributes => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  default: attributes
});

export const getDefaultAttachmentsAttributesFailed = () => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED
});

const fetchDefaultAttributes = (district, id) => {
  return axios.get(
    `${district}/map_portal/${id}/default_attachments_attributes/`
  );
};

/**
 * Pobiera domyślne wartości dla atrybutów załączników
 *
 * @param {string} district
 * @param {int} id - id map portalu
 */
export const getDefaultAttachmentsAttributes = (district, id) => dispatch => {
  dispatch(getDefaultAttachmentsAttributesBegin());

  fetchDefaultAttributes(district, id)
    .then(r => {
      if (r.data) {
        dispatch(getDefaultAttachmentsAttributesSuccess(r.data));
      }
    })
    .catch(err => {
      CustomLogger.log(err);
      dispatch(getDefaultAttachmentsAttributesFailed());
    });
};

export const updateDefaultAttachmentsAttributesBegin = () => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_BEGIN
});

export const updateDefaultAttachmentsAttributesSuccess = attributes => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_SUCCESS,
  attributes
});

export const updateDefaultAttachmentsAttributesFailed = () => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_FAILED
});

export const updateDefaultAttachmentsAttributes = (district, id, data) => (
  dispatch,
  getState
) => {
  dispatch(updateDefaultAttachmentsAttributesBegin());

  axios
    .put(`${district}/map_portal/${id}/default_attachments_attributes/`, data)
    .then(r => {
      dispatch(
        showSuccess('Domyślne atrybuty załączników zostały zaktualizowane.')
      );
      dispatch(updateDefaultAttachmentsAttributesSuccess(data));
    })
    .catch(err => {
      dispatch(showError('Wystąpił błąd podczas aktualizacji.'));
      dispatch(updateDefaultAttachmentsAttributesFailed());
    });
};

export const getDefaultAttachmentsAttributesCompositionBegin = () => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN
});

export const getDefaultAttachmentsAttributesCompositionSuccess = (
  attributes,
  exists
) => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  default: attributes,
  exists
});

export const getDefaultAttachmentsAttributesCompositionFailed = () => ({
  type: FETCH_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED
});

/**
 * Pobiera domyślne wartości dla atrybutów załączników dla danej kompozycji
 *
 * @param {string} district
 * @param {int} id - id map portalu
 * @param {int} composition - id kompozycji
 */
export const getDefaultAttachmentsAttributesComposition = (
  district,
  id,
  composition
) => dispatch => {
  dispatch(getDefaultAttachmentsAttributesCompositionBegin());

  axios
    .get(
      `${district}/map_portal/${id}/map_portal_compositions/${composition}/attachments_attributes/`
    )
    .then(r => {
      if (r.data) {
        dispatch(
          getDefaultAttachmentsAttributesCompositionSuccess(r.data, true)
        );
      }
    })
    .catch(err => {
      if (err.response.status === 404) {
        fetchDefaultAttributes(district, id)
          .then(r => {
            if (r.data) {
              dispatch(
                getDefaultAttachmentsAttributesCompositionSuccess(r.data, false)
              );
            }
          })
          .catch(err => {
            dispatch(getDefaultAttachmentsAttributesCompositionFailed());
          });
      } else {
        dispatch(getDefaultAttachmentsAttributesCompositionFailed());
      }
    });
};

export const updateDefaultAttachmentsAttributesCompositionBegin = () => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_BEGIN
});

export const updateDefaultAttachmentsAttributesCompositionSuccess = attributes => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_SUCCESS,
  attributes
});

export const updateDefaultAttachmentsAttributesCompositionFailed = () => ({
  type: UPDATE_DEFAULT_ATTACHMENTS_ATTRIBUTES_COMPOSITION_FAILED
});

export const updateDefaultAttachmentsAttributesComposition = (
  district,
  id,
  compositionid,
  data
) => (dispatch, getState) => {
  dispatch(updateDefaultAttachmentsAttributesCompositionBegin());

  axios({
    url: `${district}/map_portal/${id}/map_portal_compositions/${compositionid}/attachments_attributes/`,
    method: getState().popup.get('existsDefaultAttributesComposition')
      ? 'PUT'
      : 'POST',
    data
  })
    .then(r => {
      dispatch(
        showSuccess('Domyślne atrybuty załączników zostały zaktualizowane.')
      );
      dispatch(updateDefaultAttachmentsAttributesCompositionSuccess(r.data));
    })
    .catch(err => {
      dispatch(showError('Wystąpił błąd podczas aktualizacji.'));
      dispatch(updateDefaultAttachmentsAttributesComposition());
    });
};

////////////////////////////
// desktopAddPopupConfig //
//////////////////////////
export const desktopAddPopupConfigBegin = () => ({
  type: DESKTOP_ADD_POPUP_CONFIG_BEGIN
});

export const desktopAddPopupConfigSuccess = ({
  district,
  mapPortalCompositionId,
  config,
  isExternalLayer,
  sourceType
}) => ({
  type: DESKTOP_ADD_POPUP_CONFIG_SUCCESS,
  district,
  mapPortalCompositionId,
  config,
  isExternalLayer,
  sourceType
});

export const desktopAddPopupConfigFailed = () => ({
  type: DESKTOP_ADD_POPUP_CONFIG_FAILED
});

export const desktopAddPopupConfig = ({
  district,
  mapPortalCompositionId,
  data,
  isExternalLayer,
  sourceType
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(desktopAddPopupConfigBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/`;

    axios
      .post(url, data)
      .then(response => {
        dispatch(
          desktopAddPopupConfigSuccess({
            district,
            mapPortalCompositionId,
            config: response.data,
            isExternalLayer,
            sourceType
          })
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(desktopAddPopupConfigFailed());
        dispatch(
          showError("Wystąpił błąd podczas dodawania konfiguracji popup'a")
        );
        reject(err);
      });
  });

/////////////////////////////
// desktopEditPopupConfig //
///////////////////////////
export const desktopEditPopupConfigBegin = () => ({
  type: DESKTOP_EDIT_POPUP_CONFIG_BEGIN
});

export const desktopEditPopupConfigSuccess = (
  district,
  mapPortalCompositionId,
  config
) => ({
  type: DESKTOP_EDIT_POPUP_CONFIG_SUCCESS,
  district,
  mapPortalCompositionId,
  config
});

export const desktopEditPopupConfigFailed = () => ({
  type: DESKTOP_EDIT_POPUP_CONFIG_FAILED
});

export const desktopEditPopupConfig = (
  district,
  mapPortalCompositionId,
  configId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(desktopEditPopupConfigBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${configId}/`;

    axios
      .patch(url, data)
      .then(response => {
        dispatch(
          desktopEditPopupConfigSuccess(
            district,
            mapPortalCompositionId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(desktopEditPopupConfigFailed());
        dispatch(
          showError('Wystąpił błąd podczas edytowania konfiguracji warstwy.')
        );
        reject(err);
      });
  });

///////////////////////////////
// desktopDeletePopupConfig //
/////////////////////////////
export const desktopDeletePopupConfigBegin = () => ({
  type: DESKTOP_DELETE_POPUP_CONFIG_BEGIN
});

export const desktopDeletePopupConfigSuccess = (
  district,
  mapPortalCompositionId,
  configId
) => ({
  type: DESKTOP_DELETE_POPUP_CONFIG_SUCCESS,
  district,
  mapPortalCompositionId,
  configId
});

export const desktopDeletePopupConfigFailed = () => ({
  type: DESKTOP_DELETE_POPUP_CONFIG_FAILED
});

export const desktopDeletePopupConfig = (
  district,
  mapPortalCompositionId,
  configId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(desktopDeletePopupConfigBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${configId}/`;

    axios
      .delete(url)
      .then(async response => {
        await dispatch(
          desktopDeletePopupConfigSuccess(
            district,
            mapPortalCompositionId,
            configId
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(desktopDeletePopupConfigFailed());
        dispatch(
          showError("Wystąpił błąd podczas usuwania konfiguracji popup'a")
        );
        reject(err);
      });
  });

//RTQ
export const desktopFetchPopupConfigsBegin = () => ({
  type: DESKTOP_FETCH_POPUP_CONFIGS_BEGIN
});

export const desktopFetchPopupConfigsSuccess = (
  district,
  mapPortalCompositionId,
  configs
) => ({
  type: DESKTOP_FETCH_POPUP_CONFIGS_SUCCESS,
  district,
  mapPortalCompositionId,
  configs
});

export const desktopFetchPopupConfigsFailed = () => ({
  type: DESKTOP_FETCH_POPUP_CONFIGS_FAILED
});

/////////////////////////////////
// desktopAddPopupLayerConfig //
///////////////////////////////
export const desktopAddPopupLayerConfigBegin = () => ({
  type: DESKTOP_ADD_POPUP_LAYER_CONFIG_BEGIN
});

export const desktopAddPopupLayerConfigSuccess = layerConfig => ({
  type: DESKTOP_ADD_POPUP_LAYER_CONFIG_SUCCESS,
  layerConfig
});

export const desktopAddPopupLayerConfigFailed = () => ({
  type: DESKTOP_ADD_POPUP_LAYER_CONFIG_FAILED
});

export const desktopAddPopupLayerConfig = ({
  district,
  mapPortalCompositionId,
  layerConfig,
  onSuccess
}) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(desktopAddPopupLayerConfigBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/`;

    axios
      .post(url, layerConfig)
      .then(response => {
        onSuccess?.();
        dispatch(
          desktopAddPopupLayerConfigSuccess(
            district,
            mapPortalCompositionId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        const errInfo = parseResponseError(err, {
          unique: 'Wybrana warstwa jest już przypisana do tej konfiguracji.'
        });

        dispatch(desktopAddPopupLayerConfigFailed());
        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas dodawania konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

///////////////////////////////////////
// fetchPopupLayerDefaultAttributes //
/////////////////////////////////////
export const fetchPopupLayerDefaultAttributesBegin = () => ({
  type: FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_BEGIN
});

export const fetchPopupLayerDefaultAttributesSuccess = (
  layerId,
  attributes
) => ({
  type: FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_SUCCESS,
  layerId,
  attributes
});

export const fetchPopupLayerDefaultAttributesFailed = () => ({
  type: FETCH_POPUP_LAYER_DEFAULT_ATTRIBUTES_FAILED
});

export const fetchPopupLayerDefaultAttributes = layerId => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupLayerDefaultAttributesBegin());

    axios
      .get(`get_simplified_legend_layer_fields/${layerId}/`)
      .then(({ data: { layer_fields } }) => {
        dispatch(
          fetchPopupLayerDefaultAttributesSuccess(layerId, layer_fields)
        );
        resolve(layer_fields);
      })
      .catch(err => {
        const errInfo = parseResponseError(err);

        dispatch(
          showError(
            errInfo
              ? errInfo
              : 'Wystąpił błąd podczas pobierania listy konfiguracji warstw.'
          )
        );
        dispatch(fetchPopupLayerDefaultAttributesFailed());
        reject(err);
      });
  });

/////////////////////////////
// addPopupLayerAttribute //
///////////////////////////
export const addPopupLayerAttributeBegin = () => ({
  type: ADD_POPUP_LAYER_ATTRIBUTE_BEGIN
});

export const addPopupLayerAttributeSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  attribute
) => ({
  type: ADD_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  attribute
});

export const addPopupLayerAttributeFailed = () => ({
  type: ADD_POPUP_LAYER_ATTRIBUTE_FAILED
});

export const addPopupLayerAttribute = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addPopupLayerAttributeBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/attributes/`;

    axios
      .post(url, data)
      .then(response => {
        dispatch(
          addPopupLayerAttributeSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(addPopupLayerAttributeFailed());
        dispatch(
          showError(
            'Wystąpił błąd podczas dodawania atrybutu konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

//////////////////////////////
// editPopupLayerAttribute //
////////////////////////////
export const editPopupLayerAttributeBegin = () => ({
  type: EDIT_POPUP_LAYER_ATTRIBUTE_BEGIN
});

export const editPopupLayerAttributeSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  attribute
) => ({
  type: EDIT_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  attribute
});

export const editPopupLayerAttributeFailed = () => ({
  type: EDIT_POPUP_LAYER_ATTRIBUTE_FAILED
});

export const editPopupLayerAttribute = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  attribute
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(editPopupLayerAttributeBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/attributes/${attribute.id}/`;

    axios
      .patch(url, attribute)
      .then(response => {
        dispatch(
          editPopupLayerAttributeSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(editPopupLayerAttributeFailed());
        dispatch(
          showError(
            'Wystąpił błąd podczas edycji atrybutu konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

////////////////////////////////
// deletePopupLayerAttribute //
//////////////////////////////
export const deletePopupLayerAttributeBegin = () => ({
  type: DELETE_POPUP_LAYER_ATTRIBUTE_BEGIN
});

export const deletePopupLayerAttributeSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  attributeId
) => ({
  type: DELETE_POPUP_LAYER_ATTRIBUTE_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  attributeId
});

export const deletePopupLayerAttributeFailed = () => ({
  type: DELETE_POPUP_LAYER_ATTRIBUTE_FAILED
});

export const deletePopupLayerAttribute = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  attributeId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deletePopupLayerAttributeBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/attributes/${attributeId}/`;

    axios
      .delete(url)
      .then(response => {
        dispatch(
          deletePopupLayerAttributeSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            attributeId
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        handleError(err);
        dispatch(deletePopupLayerAttributeFailed());
        dispatch(
          showError(
            'Wystąpił błąd podczas usuwania atrybutu konfiguracji warstwy.'
          )
        );
        reject(err);
      });
  });

/////////////////////
// popupStructure //
///////////////////
export const updateLayersStructure = layers => ({
  type: UPDATE_POPUP_STRUCTURE,
  layers
});

export const clearPopupStructure = () => ({
  type: CLEAR_POPUP_STRUCTURE
});

export const setPopupCardTree = tree => ({
  type: SET_POPUP_CARD_TREE,
  tree
});

export const setPopupCardLayerLoadingStatus = (id, status) => ({
  type: SET_POPUP_CARD_LOADING_LAYER_STATUS,
  id,
  status
});

export const resetPopupCardLayersLoadingStatus = () => ({
  type: RESET_POPUP_CARD_LOADING_LAYERS_STATUS
});

////////////////////////
// addPopupLayerJoin //
//////////////////////
export const addPopupLayerJoinBegin = () => ({
  type: ADD_POPUP_LAYER_JOIN_BEGIN
});

export const addPopupLayerJoinSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  join
) => ({
  type: ADD_POPUP_LAYER_JOIN_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  join
});

export const addPopupLayerJoinFailed = () => ({
  type: ADD_POPUP_LAYER_JOIN_FAILED
});

export const addPopupLayerJoin = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addPopupLayerJoinBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/`;

    axios
      .post(url, data)
      .then(response => {
        dispatch(
          addPopupLayerJoinSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addPopupLayerJoinFailed());
        reject(err);
      });
  });

///////////////////////////
// fetchPopupLayerJoins //
/////////////////////////
export const fetchPopupLayerJoinsBegin = () => ({
  type: FETCH_POPUP_LAYER_JOINS_BEGIN
});

export const fetchPopupLayerJoinsSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joins
) => ({
  type: FETCH_POPUP_LAYER_JOINS_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joins
});

export const fetchPopupLayerJoinsFailed = () => ({
  type: FETCH_POPUP_LAYER_JOINS_FAILED
});

export const fetchPopupLayerJoins = (
  district,
  mapPortalCompositionId,
  layerConfigId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupLayerJoinsBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/`;

    axios
      .get(url)
      .then(response => {
        dispatch(
          fetchPopupLayerJoinsSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchPopupLayerJoinsFailed());
        reject(err);
      });
  });

///////////////////////////
// deletePopupLayerJoin //
/////////////////////////
export const deletePopupLayerJoinBegin = () => ({
  type: DELETE_POPUP_LAYER_JOIN_BEGIN
});

export const deletePopupLayerJoinSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId
) => ({
  type: DELETE_POPUP_LAYER_JOIN_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId
});

export const deletePopupLayerJoinFailed = () => ({
  type: DELETE_POPUP_LAYER_JOIN_FAILED
});

export const deletePopupLayerJoin = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deletePopupLayerJoinBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/`;

    axios
      .delete(url)
      .then(() => {
        dispatch(
          deletePopupLayerJoinSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId
          )
        );
        resolve();
      })
      .catch(err => {
        dispatch(deletePopupLayerJoinFailed());
        reject(err);
      });
  });

///////////////////////////
// updatePopupLayerJoin //
/////////////////////////
export const updatePopupLayerJoinBegin = () => ({
  type: UPDATE_POPUP_LAYER_JOIN_BEGIN
});

export const updatePopupLayerJoinSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  join
) => ({
  type: UPDATE_POPUP_LAYER_JOIN_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  join
});

export const updatePopupLayerJoinFailed = () => ({
  type: UPDATE_POPUP_LAYER_JOIN_FAILED
});

export const updatePopupLayerJoin = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updatePopupLayerJoinBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/`;

    axios
      .patch(url, data)
      .then(response => {
        dispatch(
          updatePopupLayerJoinSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(updatePopupLayerJoinFailed());
        reject(err);
      });
  });

////////////////////////////////////
// fetchPopupLayerJoinAttributes //
//////////////////////////////////
export const fetchPopupLayerJoinAttributesBegin = () => ({
  type: FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN
});

export const fetchPopupLayerJoinAttributesSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  joinAttributes
) => ({
  type: FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  joinAttributes
});

export const fetchPopupLayerJoinAttributesFailed = () => ({
  type: FETCH_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED
});

export const fetchPopupLayerJoinAttributes = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(fetchPopupLayerJoinAttributesBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/attributes/`;

    axios
      .get(url)
      .then(response => {
        dispatch(
          fetchPopupLayerJoinAttributesSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(fetchPopupLayerJoinAttributesFailed());
        reject(err);
      });
  });

//////////////////////////////////
// addPopupLayerJoinAttributes //
////////////////////////////////
export const addPopupLayerJoinAttributesBegin = () => ({
  type: ADD_POPUP_LAYER_JOIN_ATTRIBUTES_BEGIN
});

export const addPopupLayerJoinAttributesSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attribute
) => ({
  type: ADD_POPUP_LAYER_JOIN_ATTRIBUTES_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attribute
});

export const addPopupLayerJoinAttributesFailed = () => ({
  type: ADD_POPUP_LAYER_JOIN_ATTRIBUTES_FAILED
});

export const addPopupLayerJoinAttributes = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(addPopupLayerJoinAttributesBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/attributes/`;

    axios
      .post(url, data)
      .then(response => {
        dispatch(
          addPopupLayerJoinAttributesSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(addPopupLayerJoinAttributesFailed());
        reject(err);
      });
  });

////////////////////////////////////
// deletePopupLayerJoinAttribute //
//////////////////////////////////
export const deletePopupLayerJoinAttributeBegin = () => ({
  type: DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN
});

export const deletePopupLayerJoinAttributeSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId
) => ({
  type: DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId
});

export const deletePopupLayerJoinAttributeFailed = () => ({
  type: DELETE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED
});

export const deletePopupLayerJoinAttribute = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(deletePopupLayerJoinAttributeBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/attributes/${attributeId}/`;

    axios
      .delete(url)
      .then(() => {
        dispatch(
          deletePopupLayerJoinAttributeSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId,
            attributeId
          )
        );
        resolve();
      })
      .catch(err => {
        dispatch(deletePopupLayerJoinAttributeFailed());
        reject(err);
      });
  });

///////////////////////////
// updatePopupLayerJoin //
/////////////////////////
export const updatePopupLayerJoinAttributeBegin = () => ({
  type: UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_BEGIN
});

export const updatePopupLayerJoinAttributeSuccess = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId,
  attribute
) => ({
  type: UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_SUCCESS,
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId,
  attribute
});

export const updatePopupLayerJoinAttributeFailed = () => ({
  type: UPDATE_POPUP_LAYER_JOIN_ATTRIBUTE_FAILED
});

export const updatePopupLayerJoinAttribute = (
  district,
  mapPortalCompositionId,
  layerConfigId,
  joinId,
  attributeId,
  data
) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(updatePopupLayerJoinAttributeBegin());

    const url = `${district}/map_portal_composition/${mapPortalCompositionId}/featureinfo_layers/${layerConfigId}/joins/${joinId}/attributes/${attributeId}/`;

    axios
      .patch(url, data)
      .then(response => {
        dispatch(
          updatePopupLayerJoinAttributeSuccess(
            district,
            mapPortalCompositionId,
            layerConfigId,
            joinId,
            attributeId,
            response.data
          )
        );
        resolve(response.data);
      })
      .catch(err => {
        dispatch(updatePopupLayerJoinAttributeFailed());
        reject(err);
      });
  });

export const updatePlotListInformation = plotListInformation => ({
  type: UPDATE_PLOT_LIST_INFORMATION,
  plotListInformation
});

export const updateRoadListInformation = roadListInformation => ({
  type: UPDATE_ROAD_LIST_INFORMATION,
  roadListInformation
});

export const setMultipleCoordinatesToIdentification = multipleCoordinatesToIdentification => ({
  type: SET_MULTIPLE_COORDINATES_TO_IDENTIFICATION,
  multipleCoordinatesToIdentification
});

export const clearMultipleCoordinatesToIdentification = () => ({
  type: CLEAR_MULTIPLE_COORDINATES_TO_IDENTIFICATION
});

/// Download popup data ///
export const downloadPopupDataBegin = () => ({
  type: DOWNLOAD_POPUP_DATA_BEGIN
});

export const downloadPopupDataSuccess = () => ({
  type: DOWNLOAD_POPUP_DATA_SUCCESS
});

export const downloadPopupDataFailed = () => ({
  type: DOWNLOAD_POPUP_DATA_FAILED
});

export const downloadPopupData = (district, requestBody) => dispatch => {
  dispatch(downloadPopupDataBegin());

  axios
    .post(`${district}/data_sharing/`, requestBody, { responseType: 'blob' })
    .then(response => {
      let extension = requestBody.format;
      const isZipFormat = response.headers['content-type'].includes('zip');

      if (isZipFormat) {
        extension = 'zip';
      }

      const fileName = `Popup-data.${extension}`;
      const file = window.URL.createObjectURL(new Blob([response.data]));

      downloadFile(fileName, file);
      dispatch(downloadPopupDataSuccess());
    })
    .catch(error => {
      dispatch(
        showError(
          parseResponseError(error, {
            unique: 'Istnieje już konfiguracja z tą nazwą!'
          })
        )
      );
      dispatch(downloadPopupDataFailed());
    });
};

export const getQRCodeForObjectStarted = () => ({
  type: GET_QRCODE_FOR_OBJECT_STARTED
});

export const getQRCodeForObjectFinished = () => ({
  type: GET_QRCODE_FOR_OBJECT_FINISHED
});

export const getQRCodeForObject = (prefix, params) => dispatch =>
  new Promise((resolve, reject) => {
    dispatch(getQRCodeForObjectStarted());

    return axios
      .post(`/${prefix}/qrcodes/`, params, { responseType: 'blob' })
      .then(response => {
        resolve(response);
      })
      .catch(err => {
        dispatch(showError(parseResponseError(err)));
        reject(err);
      })
      .finally(() => {
        dispatch(getQRCodeForObjectFinished());
      });
  });

// Fetching parcels data actions

export const fetchParcelsDataStarted = () => ({
  type: FETCH_PARCELS_DATA_STARTED
});

export const fetchParcelsDataSuccess = parcelsData => ({
  type: FETCH_PARCELS_DATA_SUCCESS,
  parcelsData
});

export const fetchParcelsDataFinished = () => ({
  type: FETCH_PARCELS_DATA_FINISHED
});

const isParcelsDataUpToDate = (plotList, currentParcelsData) => {
  if (
    !currentParcelsData.length ||
    plotList.length !== currentParcelsData.length
  ) {
    return false;
  }

  const currentParcelsIds = currentParcelsData.map(({ id }) => id);
  const concatedPlotList = [...plotList, ...currentParcelsIds];
  const reducedPlotList = [...new Set(concatedPlotList)];

  return plotList.length === reducedPlotList.length;
};

export const fetchParcelsData = (prefix, plotList) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    const currentParcelsData = popupSelectors.getParcelsData(getState()).toJS();

    if (isParcelsDataUpToDate(plotList, currentParcelsData)) {
      return resolve(currentParcelsData);
    }

    dispatch(fetchParcelsDataStarted());

    const plotListString = plotList.join(',');
    const endpoint = `${prefix}/parcel/?parcels=${plotListString}`;

    return axios
      .get(endpoint)
      .then(({ data }) => {
        const features = data.results.features;

        dispatch(fetchParcelsDataSuccess(features));
        resolve(features);
      })
      .catch(error => {
        dispatch(fetchParcelsDataFinished());
        dispatch(showError(parseResponseError(error)));
        reject(error);
      });
  });

export const setIsQueryStringPopup = isQueryStringPopup => ({
  type: IS_QUERY_PARAMS_POPUP,
  isQueryStringPopup
});

export const setDownladAttachmentsDataToPrint = downloadAttachments => ({
  type: DOWNLOAD_ATTACHMENTS_DATA_TO_PRINT,
  downloadAttachments
});

export const setPopupUrl = url => ({
  type: SET_POPUP_URL,
  url
});

export const setIsPreviewMapsEnabled = value => ({
  type: SET_IS_PREVIEW_MAPS_ENABLED,
  value
});

export const setIsGeneratingDataToPrint = value => ({
  type: SET_IS_GENERATING_DATA_TO_PRINT,
  value
});
