const convertHDMSToDD = ({ degrees, minutes, seconds, direction }) => {
  let decimalDegrees =
    Number(degrees) + Number(minutes) / 60 + Number(seconds) / (60 * 60);

  if (direction === 'S' || direction === 'W') {
    decimalDegrees = decimalDegrees * -1;
  }

  return decimalDegrees;
};

const parseHdmsToDecimalDegrees = hdmsCoordinate => {
  const parts = hdmsCoordinate.split(/[^\d\w]+/);
  const coordinate = convertHDMSToDD({
    degrees: parts[0],
    minutes: parts[1],
    seconds: parts[2],
    direction: parts[3]
  });

  return coordinate;
};

export default parseHdmsToDecimalDegrees;
