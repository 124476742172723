import { Map } from 'immutable';

import {
  FETCH_SELECTION_LAYERS_STARTED,
  FETCH_SELECTION_LAYERS_SUCCESS,
  FETCH_SELECTION_LAYERS_FAILED,
  UPDATE_SELECTION_LAYERS_STARTED,
  UPDATE_SELECTION_LAYERS_SUCCESS,
  UPDATE_SELECTION_LAYERS_FAILED,
  ADD_PREDEFINED_SELECTION_STARTED,
  ADD_PREDEFINED_SELECTION_SUCCESS,
  ADD_PREDEFINED_SELECTION_FAILED,
  FETCH_PREDEFINED_SELECTIONS_STARTED,
  FETCH_PREDEFINED_SELECTIONS_SUCCESS,
  FETCH_PREDEFINED_SELECTIONS_FAILED,
  DELETE_PREDEFINED_SELECTION_STARTED,
  DELETE_PREDEFINED_SELECTION_SUCCESS,
  DELETE_PREDEFINED_SELECTION_FAILED,
  EDIT_PREDEFINED_SELECTION_STARTED,
  EDIT_PREDEFINED_SELECTION_SUCCESS,
  EDIT_PREDEFINED_SELECTION_FAILED,
  IS_IDENTIFICATION_FOR_SINGLE_POINT
} from '../constants/selectionActionTypes';

export const initialState = Map({
  selectionLayersConfigs: Map(),
  isFetching: false,
  isUpdating: false,
  isAddingPredefinedSelection: false,
  predefinedSelections: Map(),
  isFetchingPredefinedSelections: false,
  isDeletingPredefinedSelection: false,
  isEditingPredefinedSelection: false,
  isIdentificationForSinglePoint: true
});

// *** Utils ***
const getCompositionPredefinedSelections = (
  state,
  district,
  mapPortalCompositionId
) =>
  state.get('predefinedSelections').getIn([district, mapPortalCompositionId]);

// *** Fetch selection layers ***
const fetchSelectionLayersConfigStarted = state =>
  state.set('isFetching', true);

const fetchSelectionLayersConfigSuccess = (
  state,
  { district, mapPortalCompositionId, data }
) => {
  const updatedConfigs = state
    .get('selectionLayersConfigs')
    .setIn([district, mapPortalCompositionId], data);

  return state
    .set('selectionLayersConfigs', updatedConfigs)
    .set('isFetching', false);
};

const fetchSelectionLayersConfigFailed = state =>
  state.set('isFetching', false);

// *** Fetch selection layers ***
const updateSelectionLayersConfigStarted = state =>
  state.set('isUpdating', true);

const updateSelectionLayersConfigSuccess = (
  state,
  { district, mapPortalCompositionId, data }
) => {
  const updatedConfigs = state
    .get('selectionLayersConfigs')
    .setIn([district, mapPortalCompositionId], data);

  return state
    .set('selectionLayersConfigs', updatedConfigs)
    .set('isUpdating', false);
};

const updateSelectionLayersConfigFailed = state =>
  state.set('isUpdating', false);

// *** Add predefined selection ***
const addPredefinedSelectionStarted = state =>
  state.set('isAddingPredefinedSelection', true);

const addPredefinedSelectionSuccess = (
  state,
  { district, mapPortalCompositionId, data }
) => {
  const compositionPredefinedSelections = getCompositionPredefinedSelections(
    state,
    district,
    mapPortalCompositionId
  );

  const updatedCompositionPredefinedSelections = [
    data,
    ...compositionPredefinedSelections
  ];

  const updatedPredefinedSelections = state
    .get('predefinedSelections')
    .setIn(
      [district, mapPortalCompositionId],
      updatedCompositionPredefinedSelections
    );

  return state
    .set('predefinedSelections', updatedPredefinedSelections)
    .set('isAddingPredefinedSelection', false);
};

const addPredefinedSelectionFailed = state =>
  state.set('isAddingPredefinedSelection', false);

// *** Fetch predefined selections ***
const fetchPredefinedSelectionsStarted = state =>
  state.set('isFetchingPredefinedSelections', true);

const fetchPredefinedSelectionsSuccess = (
  state,
  { district, mapPortalCompositionId, data }
) => {
  const updatedPredefinedSelections = state
    .get('predefinedSelections')
    .setIn([district, mapPortalCompositionId], data);

  return state
    .set('predefinedSelections', updatedPredefinedSelections)
    .set('isFetchingPredefinedSelections', false);
};

const fetchPredefinedSelectionsFailed = state =>
  state.set('isFetchingPredefinedSelections', false);

// *** Delete predefined selection ***
const deletePredefinedSelectionStarted = state =>
  state.set('isDeletingPredefinedSelection', true);

const deletePredefinedSelectionSuccess = (
  state,
  { district, mapPortalCompositionId, predefinedSelectionId }
) => {
  const compositionPredefinedSelections = getCompositionPredefinedSelections(
    state,
    district,
    mapPortalCompositionId
  );

  const updatedCompositionPredefinedSelections = compositionPredefinedSelections.filter(
    ({ id }) => id !== predefinedSelectionId
  );

  const updatedPredefinedSelections = state
    .get('predefinedSelections')
    .setIn(
      [district, mapPortalCompositionId],
      updatedCompositionPredefinedSelections
    );

  return state
    .set('predefinedSelections', updatedPredefinedSelections)
    .set('isDeletingPredefinedSelection', false);
};

const deletePredefinedSelectionFailed = state =>
  state.set('isDeletingPredefinedSelection', false);

// *** Edit predefined selection ***
const editPredefinedSelectionStarted = state =>
  state.set('isEditingPredefinedSelection', true);

const editPredefinedSelectionSuccess = (
  state,
  { district, mapPortalCompositionId, data }
) => {
  const compositionPredefinedSelections = getCompositionPredefinedSelections(
    state,
    district,
    mapPortalCompositionId
  );

  const updatedCompositionPredefinedSelections = compositionPredefinedSelections.map(
    predefinedSelection =>
      predefinedSelection.id === data.id ? data : predefinedSelection
  );

  const updatedPredefinedSelections = state
    .get('predefinedSelections')
    .setIn(
      [district, mapPortalCompositionId],
      updatedCompositionPredefinedSelections
    );

  return state
    .set('predefinedSelections', updatedPredefinedSelections)
    .set('isEditingPredefinedSelection', false);
};

const editPredefinedSelectionFailed = state =>
  state.set('isEditingPredefinedSelection', false);

const setIsIdentificationForSinglePoint = (
  state,
  { isIdentificationForSinglePoint }
) =>
  state.set('isIdentificationForSinglePoint', isIdentificationForSinglePoint);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_SELECTION_LAYERS_STARTED]: fetchSelectionLayersConfigStarted,
      [FETCH_SELECTION_LAYERS_SUCCESS]: fetchSelectionLayersConfigSuccess,
      [FETCH_SELECTION_LAYERS_FAILED]: fetchSelectionLayersConfigFailed,
      [UPDATE_SELECTION_LAYERS_STARTED]: updateSelectionLayersConfigStarted,
      [UPDATE_SELECTION_LAYERS_SUCCESS]: updateSelectionLayersConfigSuccess,
      [UPDATE_SELECTION_LAYERS_FAILED]: updateSelectionLayersConfigFailed,
      [ADD_PREDEFINED_SELECTION_STARTED]: addPredefinedSelectionStarted,
      [ADD_PREDEFINED_SELECTION_SUCCESS]: addPredefinedSelectionSuccess,
      [ADD_PREDEFINED_SELECTION_FAILED]: addPredefinedSelectionFailed,
      [FETCH_PREDEFINED_SELECTIONS_STARTED]: fetchPredefinedSelectionsStarted,
      [FETCH_PREDEFINED_SELECTIONS_SUCCESS]: fetchPredefinedSelectionsSuccess,
      [FETCH_PREDEFINED_SELECTIONS_FAILED]: fetchPredefinedSelectionsFailed,
      [DELETE_PREDEFINED_SELECTION_STARTED]: deletePredefinedSelectionStarted,
      [DELETE_PREDEFINED_SELECTION_SUCCESS]: deletePredefinedSelectionSuccess,
      [DELETE_PREDEFINED_SELECTION_FAILED]: deletePredefinedSelectionFailed,
      [EDIT_PREDEFINED_SELECTION_STARTED]: editPredefinedSelectionStarted,
      [EDIT_PREDEFINED_SELECTION_SUCCESS]: editPredefinedSelectionSuccess,
      [EDIT_PREDEFINED_SELECTION_FAILED]: editPredefinedSelectionFailed,
      [IS_IDENTIFICATION_FOR_SINGLE_POINT]: setIsIdentificationForSinglePoint
    }[action.type] || (s => s)
  )(state, action);
}
