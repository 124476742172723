import PropTypes from 'prop-types';

import { Icon } from 'antd';

import './SystemInformationView.less';

const SystemInformationView = props => (
  <div className="system-information-view">
    <div className="system-information-view-content">
      {props.icon && <Icon type={props.icon} />}
      <div className="system-information-view-content-message">
        <span className="header">{props.title}</span>
        {props.children}
      </div>
    </div>
  </div>
);

SystemInformationView.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  icon: PropTypes.string
};

export default SystemInformationView;
