import L from 'leaflet';

/**
 * Sprawdza czy strona wyświetlana jest w przeglądarce mobilnej.
 *
 * @return {boolean}
 */
const isMobile = () => L.Browser.mobile;

export default isMobile;
