import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'core-js/es/map';
import 'core-js/es/set';
import { createRef, Component } from 'react';
import PropTypes from 'prop-types';
import ImmutablePropTypes from 'react-immutable-proptypes';
import ReactDOM from 'react-dom';
// Import necessary to add React 18
/* import { createRoot } from 'react-dom/client'; */
import axios from 'axios';
import withStore from './HOCs/withStore';
import { connect } from 'react-redux';
import dotenv from 'dotenv';
import moment from 'moment';
import * as L from 'leaflet';
import './components/MapControl/Leaflet.WMSTileLayer';
import configureRequests, {
  configureInterceptors,
  setAuthorization
} from './utils/request';
import configureStore from './store/store';
import {
  getTranslation,
  showError,
  changeLanguage,
  checkUseAdvancedFeatures
} from './store/actions/globalActions';
import {
  setEnableGeolocation,
  setSetingGeolocation
} from './store/actions/mapPortalActions';
import { ConfigProvider } from 'antd';
import MainView from './views/MainView';

import { IntlProvider } from 'react-intl';
import LanguageContext from './contexts/LanguageContext';
import LeafletMapContext from './contexts/LeafletMapContext';
import SessionProvider from './components/SessionProvider';
import WindowSizeObserver from './components/WindowSizeObserver';

import antdPl from 'antd/lib/locale-provider/pl_PL';
import antdLocaleFiles from './utils/antdLocaleFiles';

import 'moment/locale/pl';
import 'moment/locale/en-gb';

import displayReactVersion from './utils/displayReactVersion';
import { watchPosition } from './utils/mapPortalUtils/watchPosition';
import { mapMarkerSize } from './mapConfig';

import {
  globalSelectors,
  mapPortalSelectors,
  mapSelectors
} from './store/selectors';

import { sessionStorageKeys, defaultLang } from './config';

import { BASIC_MARKER_ICON } from './constants/basic-marker-icon';

import './proj4Defs';

import './less/main.less';

dotenv.config();

displayReactVersion();
configureRequests();

const store = configureStore();
window.store = store;

configureInterceptors(store);

const defaultLanguage = defaultLang;

const geolocationLayer = new L.LayerGroup();

class App extends Component {
  leafletMap = createRef();
  getLeafletElement = () =>
    this.leafletMap.current && this.leafletMap.current.leafletElement;
  geolocation = null;

  state = {
    language: defaultLanguage,
    antdLocale: antdPl,
    flyToGeolocation: false,
    componentsToRender: [],
    isAppReady: false
  };

  componentDidMount() {
    this.setMomentLibrary();
    this.importAntDesignLocale(defaultLanguage);
    this.props.changeLanguage(defaultLanguage);
    this.setHtmlLangAttribute(defaultLanguage);
    this.props.isApiAvailable && this.fetchTranslation();
    this.props.checkUseAdvancedFeatures();
    this.updateMapMarkerAppearance();
    window.addEventListener('storage', this.changeLocalStorage, false);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.isApiAvailable !== this.props.isApiAvailable &&
      this.props.isApiAvailable
    ) {
      this.fetchTranslation();
    }
  }

  setMomentLibrary = () => {
    moment.locale(defaultLanguage, {
      calendar: {
        sameDay: '[Dziś o] LTS',
        nextDay: '[Jutro o] LTS'
      }
    });
  };

  setHtmlLangAttribute = language => {
    document.documentElement.lang = language;
  };

  changeLocalStorage = e => {
    if (e.key === 'jwt') {
      setAuthorization();
    }
  };

  fetchTranslation = async () => {
    try {
      await this.props.getTranslation();
    } catch (err) {
      if (err.response && err.response.status === 404) {
        sessionStorage.removeItem(sessionStorageKeys.language);

        this.props.getTranslation(defaultLang);
        this.setLanguage(defaultLang);
      } else {
        this.props.showError('An error occurred while fetching translations!');
      }
    }
  };

  setLanguage = (language, { saveToStorage = true } = {}) => {
    this.setState({ language }, () => {
      this.setHtmlLangAttribute(language);
      moment.locale(language);
      axios.defaults.headers.common['Accept-Language'] = language;
      this.props.getTranslation(language);
      this.importAntDesignLocale(language);
      if (saveToStorage)
        sessionStorage.setItem(sessionStorageKeys.language, language);
      this.props.changeLanguage(language);
    });
  };

  getLanguageCode = language => {
    // Brak tłumaczeń dla języka udmurckiego.
    // Wybieramy najbliższy mu, czyli rosyjski.
    if (language === 'udm') return 'ru';

    return language.split('-')[0];
  };

  importAntDesignLocale = language => {
    const languageCode = this.getLanguageCode(language);
    const fileName = antdLocaleFiles[languageCode] || 'en_GB';

    (async () => {
      const antdLocale = await import(
        `antd/lib/locale-provider/${fileName}.js`
      );
      this.setState({ antdLocale: antdLocale.default });
    })();
  };

  // Replace default Leaflet marker icon
  updateMapMarkerAppearance = () => {
    L.Marker.prototype.options.icon = L.divIcon(BASIC_MARKER_ICON);
  };

  stopSettingGeolocation = () => {
    this.props.setEnableGeolocation(false, null);
    this.props.setSetingGeolocation(false);
  };

  successGetGeolocation = (e, map, coords, zoom) => {
    const latitude = coords ? coords.latitude : e.coords.latitude;
    const longitude = coords ? coords.longitude : e.coords.longitude;

    if (
      this.props.geolocationCoords?.latitude === latitude &&
      this.props.geolocationCoords?.longitude === longitude
    )
      return;

    if (!this.state.flyToGeolocation) {
      map.flyTo([latitude, longitude]);
      this.setState({
        flyToGeolocation: true
      });
    }

    const icon = L.divIcon({
      html: `<div class="report-marker" />`,
      iconSize: Array(2).fill(mapMarkerSize),
      className:
        'leaflet-marker-icon marker-cluster marker-cluster-small leaflet-zoom-animated leaflet-interactive'
    });
    const marker = new L.Marker([latitude, longitude], { icon });

    geolocationLayer.clearLayers();
    geolocationLayer.addLayer(marker);
    geolocationLayer.addTo(map);
    this.props.setEnableGeolocation(true, e.coords);
    this.props.setSetingGeolocation(false);
  };

  toggleGeolocation = (
    map,
    coords = null,
    zoom = this.props.maxMapZoomLevel
  ) => {
    if (!map) return;

    const { setSetingGeolocation, enabledGeolocation, showError } = this.props;
    const { language } = this.state;
    const noLocalizationText =
      language === 'pl'
        ? 'Nie udało się pobrać lokalizacji tego urządzenia. Zezwól na lokalizacje w urządzeniu.'
        : 'The location of this device could not be retrieved. Allow locations on the device.';

    const noSupportGeoText =
      language === 'pl'
        ? 'Nie udało się pobrać lokalizacji tego urządzenia. Przeglądarka nie wspiera geolokalizacji.'
        : 'The location of this device could not be retrieved. The browser does not support geolocation.';

    const removeGeolocationLayer = err => {
      const errMsg = err?.code === 1 ? noLocalizationText : err?.message;

      geolocationLayer.clearLayers();
      map.removeLayer(geolocationLayer);
      this.stopSettingGeolocation();
      this.setState({
        flyToGeolocation: false
      });
      return showError(errMsg);
    };

    setSetingGeolocation(true);

    if (enabledGeolocation) {
      removeGeolocationLayer();
      this.stopWatchPosition();
    } else {
      if (!navigator?.geolocation) {
        this.stopSettingGeolocation();
        return showError(noSupportGeoText);
      }

      this.geolocation = watchPosition(
        e => this.successGetGeolocation(e, map, coords, zoom),
        removeGeolocationLayer
      );
    }
  };

  stopWatchPosition = () => {
    navigator?.geolocation?.clearWatch(this.geolocation);
  };

  render() {
    const { language } = this.state;

    return (
      <LanguageContext.Provider
        value={{
          language: this.state.language,
          setLanguage: this.setLanguage
        }}
      >
        <IntlProvider
          locale={this.state.language}
          key={this.state.language}
          messages={
            this.props.translations &&
            this.props.translations.get(language) &&
            this.props.translations.getIn([language, 'attrs'])
          }
        >
          <SessionProvider />
          <WindowSizeObserver />
          <ConfigProvider locale={this.state.antdLocale}>
            <LeafletMapContext.Provider
              value={{
                leafletMap: this.leafletMap,
                getLeafletElement: this.getLeafletElement,
                toggleGeolocation: this.toggleGeolocation,
                stopWatchPosition: this.stopWatchPosition
              }}
            >
              <MainView />
            </LeafletMapContext.Provider>
          </ConfigProvider>
        </IntlProvider>
      </LanguageContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  translations: globalSelectors.getTranslations(state),
  enabledGeolocation: mapPortalSelectors.getEnableGeolocation(state),
  isApiAvailable: globalSelectors.getApiAvailability(state),
  geolocationCoords: state.mapPortals.get('geolocationCoords'),
  maxMapZoomLevel: mapSelectors.getMaxMapZoomLevel(state)
});

const mapDispatchToProps = {
  getTranslation,
  changeLanguage,
  showError,
  setEnableGeolocation,
  setSetingGeolocation,
  checkUseAdvancedFeatures
};

App.propTypes = {
  translations: ImmutablePropTypes.map.isRequired,
  maxMapZoomLevel: PropTypes.number.isRequired,
  getTranslation: PropTypes.func.isRequired,
  changeLanguage: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  enabledGeolocation: PropTypes.bool.isRequired,
  setEnableGeolocation: PropTypes.func.isRequired,
  setSetingGeolocation: PropTypes.func.isRequired
};

App = withStore(connect(mapStateToProps, mapDispatchToProps)(App), store);

ReactDOM.render(App, document.getElementById('app'));

// Lines below are necessary to add React 18
/* const container = document.getElementById('app');
const root = createRoot(container);
root.render(App); */
