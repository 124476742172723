import { combineReducers } from 'redux';

import global from './global';
import user from './user';
import district from './district';
import map from './map';
import mapPortals from './mapPortals';
import models from './models';
import notes from './notes';
import popup from './popup';
import reports from './reports';
import searchTools from './searchTools';
import groups from './groups';
import editData from './editData';
import wms from './wms';
import investor from './investor';
import projects from './projects';
import latarnik from './latarnik';
import routePlanning from './routePlanning';
import queryParams from './queryParams';
import sketchbook from './sketchbook';
import registers from './registers';
import biRaports from './biRaports';
import selection from './selection';
import locations from './location';
import eConstructions from './eConstructions';
import wmsPopup from './wmsPopup';
import eServices from './eServices';

import { apiReducers } from '../api';

export default combineReducers({
  global,
  user,
  district,
  map,
  mapPortals,
  models,
  notes,
  popup,
  projects,
  reports,
  searchTools,
  groups,
  editData,
  wms,
  investor,
  latarnik,
  routePlanning,
  queryParams,
  sketchbook,
  registers,
  biRaports,
  selection,
  locations,
  eConstructions,
  wmsPopup,
  eServices,
  ...apiReducers
});
