import { Browser } from 'leaflet';

const getBrowserType = () => {
  if (Browser.gecko) return 'gecko'; // Najczęściej oznacza Firefox'a.
  if (Browser.chrome) return 'chrome';
  if (Browser.safari) return 'safari';
  if (Browser.edge) return 'edge';
  if (Browser.ie) return 'ie';
};

export default getBrowserType;
