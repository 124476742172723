// TODO: Przenieść do config.
const rolesNames = [
  'Podstawowa',
  'Podstawowa plus',
  'Zaawansowana',
  'Administrator',
  'Superuser'
];

export default rolesNames;
