import { Map } from 'immutable';
import {
  CLOSE_ECONSTRUCTION_DETAILS,
  FETCH_REQUESTS_BY_TERYT_SUCCESS,
  FETCH_REQUEST_DETAILS_BEGIN,
  FETCH_REQUEST_DETAILS_SUCCESS,
  OPEN_ECONSTRUCTION_DETAILS
} from '../constants/eConstructionsActionTypes';

export const initialState = Map({
  applications: Map({}),
  isApplicationsFetched: Map({}),
  isOpenEConstructionDetails: false,
  isEdition: false,
  areDetailsFetched: false
});

const fetchApplicationsByTerytSuccess = (state, { requests, teryt }) =>
  state
    .setIn(['applications', teryt], Map(requests))
    .setIn(['isApplicationsFetched', teryt], true);

const fetchRequestDetailsSuccess = (state, { details }) =>
  state.set('applicationDetails', Map(details)).set('areDetailsFetched', true);

const fetchRequestDetailsBegin = state => state.set('areDetailsFetched', false);

const openEConstructionDetails = (state, { isEdition }) =>
  state.set('isOpenEConstructionDetails', true).set('isEdition', isEdition);

const closeEConstructionDetails = state =>
  state.set('isOpenEConstructionDetails', false).set('isEdition', false);

export default function reducer(state = initialState, action) {
  return (
    {
      [FETCH_REQUESTS_BY_TERYT_SUCCESS]: fetchApplicationsByTerytSuccess,
      [FETCH_REQUEST_DETAILS_SUCCESS]: fetchRequestDetailsSuccess,
      [FETCH_REQUEST_DETAILS_BEGIN]: fetchRequestDetailsBegin,
      [OPEN_ECONSTRUCTION_DETAILS]: openEConstructionDetails,
      [CLOSE_ECONSTRUCTION_DETAILS]: closeEConstructionDetails
    }[action.type] || (s => s)
  )(state, action);
}
