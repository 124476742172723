import { GeoJSON } from 'leaflet';

import transformCoordinates from '../transformCoordinates';

const transformOutput = (latLng, outputCrs) => {
  const coords = GeoJSON.latLngToCoords(latLng);
  const transformedCoords = transformCoordinates(
    'EPSG:4326',
    outputCrs,
    coords
  ).reverse();
  return GeoJSON.coordsToLatLng(transformedCoords);
};

export default function getBBoxString(bbox, outputCrs) {
  let bboxString = '';
  const bboxKeys = Object.keys(bbox);

  bboxKeys.forEach((v, i) => {
    const latLng = outputCrs ? transformOutput(bbox[v], outputCrs) : bbox[v];

    bboxString += Object.values(latLng).join(',');
    if (i !== bboxKeys.length - 1) bboxString += ',';
  });

  return bboxString;
}
