export const FETCH_GROUPS_BEGIN = 'FETCH_GROUPS_BEGIN';
export const FETCH_GROUPS_SUCCESS = 'FETCH_GROUPS_SUCCESS';
export const FETCH_GROUPS_FAILED = 'FETCH_GROUPS_FAILED';

export const FETCH_ALL_GROUPS_BEGIN = 'FETCH_ALL_GROUPS_BEGIN';
export const FETCH_ALL_GROUPS_SUCCESS = 'FETCH_ALL_GROUPS_SUCCESS';
export const FETCH_ALL_GROUPS_FAILED = 'FETCH_ALL_GROUPS_FAILED';
export const CLEAR_DISTRICT_ALL_GROUPS = 'CLEAR_DISTRICT_ALL_GROUPS';

export const DELETE_GROUP_BEGIN = 'DELETE_GROUP_BEGIN';
export const DELETE_GROUP_SUCCESS = 'DELETE_GROUP_SUCCESS';
export const DELETE_GROUP_FAILED = 'DELETE_GROUP_FAILED';

export const CREATE_GROUP_BEGIN = 'CREATE_GROUP_BEGIN';
export const CREATE_GROUP_SUCCESS = 'CREATE_GROUP_SUCCESS';
export const CREATE_GROUP_FAILED = 'CREATE_GROUP_FAILED';

export const ADD_USER_TO_GROUP_BEGIN = 'ADD_USER_TO_GROUP_BEGIN';
export const ADD_USER_TO_GROUP_SUCCESS = 'ADD_USER_TO_GROUP_SUCCESS';
export const ADD_USER_TO_GROUP_FAILED = 'ADD_USER_TO_GROUP_FAILED';

export const ADD_GROUP_BEGIN = 'ADD_GROUP_BEGIN';
export const ADD_GROUP_SUCCESS = 'ADD_GROUP_SUCCESS';
export const ADD_GROUP_FAILED = 'ADD_GROUP_FAILED';

export const DELETE_USER_FROM_GROUP_BEGIN = 'DELETE_USER_FROM_GROUP_BEGIN';
export const DELETE_USER_FROM_GROUP_SUCCESS = 'DELETE_USER_FROM_GROUP_SUCCESS';
export const DELETE_USER_FROM_GROUP_FAILED = 'DELETE_USER_FROM_GROUP_FAILED';

export const FETCH_GROUP_BEGIN = 'FETCH_GROUP_BEGIN';
export const FETCH_GROUP_SUCCESS = 'FETCH_GROUP_SUCCESS';
export const FETCH_GROUP_FAILED = 'FETCH_GROUP_FAILED';

export const FETCH_USERS_GROUP_BEGIN = 'FETCH_USERS_GROUP_BEGIN';
export const FETCH_USERS_GROUP_FAILED = 'FETCH_USERS_GROUP_FAILED';
export const FETCH_USERS_GROUP_SUCCESS = 'FETCH_USERS_GROUP_SUCCESS';

export const FETCH_NO_USERS_GROUP_BEGIN = 'FETCH_NO_USERS_GROUP_BEGIN';
export const FETCH_NO_USERS_GROUP_FAILED = 'FETCH_NO_USERS_GROUP_FAILED';
export const FETCH_NO_USERS_GROUP_SUCCESS = 'FETCH_NO_USERS_GROUP_SUCCESS';

export const UPDATE_GROUP_BEGIN = 'UPDATE_GROUP_BEGIN';
export const UPDATE_GROUP_SUCCESS = 'UPDATE_GROUP_SUCCESS';
export const UPDATE_GROUP_FAILED = 'UPDATE_GROUP_FAILED';

export const ADD_GROUP_TO_MAP_PORTAL_BEGIN = 'ADD_GROUP_TO_MAP_PORTAL_BEGIN';
export const ADD_GROUP_TO_MAP_PORTAL_SUCCESS =
  'ADD_GROUP_TO_MAP_PORTAL_SUCCESS';
export const ADD_GROUP_TO_MAP_PORTAL_FAILED = 'ADD_GROUP_TO_MAP_PORTAL_FAILED';

export const DELETE_GROUP_FROM_MAP_PORTAL_BEGIN =
  'DELETE_GROUP_FROM_MAP_PORTAL_BEGIN';
export const DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS =
  'DELETE_GROUP_FROM_MAP_PORTAL_SUCCESS';
export const DELETE_GROUP_FROM_MAP_PORTAL_FAILED =
  'DELETE_GROUP_FROM_MAP_PORTAL_FAILED';

export const ADD_GROUP_TO_COMPOSITION_BEGIN = 'ADD_GROUP_TO_COMPOSITION_BEGIN';
export const ADD_GROUP_TO_COMPOSITION_SUCCESS =
  'ADD_GROUP_TO_COMPOSITION_SUCCESS';
export const ADD_GROUP_TO_COMPOSITION_FAILED =
  'ADD_GROUP_TO_COMPOSITION_FAILED';

export const DELETE_GROUP_FROM_COMPOSITION_BEGIN =
  'DELETE_GROUP_FROM_COMPOSITION_BEGIN';
export const DELETE_GROUP_FROM_COMPOSITION_SUCCESS =
  'DELETE_GROUP_FROM_COMPOSITION_SUCCESS';
export const DELETE_GROUP_FROM_COMPOSITION_FAILED =
  'DELETE_GROUP_FROM_COMPOSITION_FAILED';
