import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { AxiosError } from 'axios';

import { showError } from '@/store/actions/globalActions';

import handleError from '../handleError';
import parseResponseError from '../lib/parseResponseError';

import { TCanceledRequestError } from '@/types/canceled-request-error';

const handleApiError = (
  error: unknown,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: ThunkDispatch<any, any, AnyAction>
): void => {
  if (error && (error as TCanceledRequestError)?.error?.name === 'AbortError') {
    return;
  }

  handleError(error);

  if (error && (error as AxiosError)?.isAxiosError) {
    dispatch(showError(parseResponseError(error)));
  } else {
    dispatch(showError(parseResponseError({})));
  }
};

export default handleApiError;
