const getLayerZIndex = (layerId, layersOrder, DEFAULT_LAYER_ZINDEX) => {
  const orderedLayersKeys = layersOrder.map(({ id }) => id);

  if (!orderedLayersKeys.includes(layerId)) {
    return DEFAULT_LAYER_ZINDEX;
  }

  // Zwracamy domyślny z-index warstwy powiększony o: numer indeksu w tablicy
  // kolejności oraz liczbę 1 (dla przypadku, gdy indexOf jest równe 0).
  return DEFAULT_LAYER_ZINDEX + orderedLayersKeys.indexOf(layerId) + 1;
};

export default getLayerZIndex;
