import { useSelector } from 'react-redux';
import { useMemo } from 'react';

import { globalSelectors } from '../../store/selectors';

const { getGlobalSettings } = globalSelectors;

const useGlobalSettings = ({ toJS = true } = {}) => {
  const globalSettings = useSelector(getGlobalSettings);

  const memoizedValues = useMemo(() => {
    if (toJS) {
      return globalSettings?.toJS();
    }

    return globalSettings;
  }, [globalSettings, toJS]);

  return memoizedValues;
};

export default useGlobalSettings;
