import queryString from 'query-string';

export class UrlUpdater {
  changes = {};
  timeout;
  timeoutValue = 500;

  removeTimeout = () => {
    clearTimeout(this.timeout);
  };

  addNewChanges = newChanges => ({
    ...this.changes,
    ...newChanges
  });

  updateChanges = newUrlParams => {
    this.changes = this.addNewChanges(newUrlParams);
  };

  clearChanges = () => {
    this.changes = {};
  };

  setNewTimeout = fn => {
    this.timeout = setTimeout(fn, this.timeoutValue);
  };

  updateUrlParams = (currentUrlParams, pushFn, urlPath) => {
    const urlParams = queryString.parse(currentUrlParams);
    const newUrlParamsKeys = Object.keys(this.changes);

    for (let i = 0; i <= newUrlParamsKeys.length; i++) {
      urlParams[newUrlParamsKeys[i]] = this.changes[newUrlParamsKeys[i]];
    }

    const stringifiedNewUrlParams = queryString.stringify(urlParams);

    pushFn(`${urlPath}?${stringifiedNewUrlParams}`);
    this.clearChanges();
  };

  updateUrl = (
    currentUrlParams,
    pushFn,
    newUrlParams,
    urlPath,
    { immediately = false } = {}
  ) => {
    this.removeTimeout();
    this.updateChanges(newUrlParams);

    if (immediately) {
      return this.updateUrlParams(currentUrlParams, pushFn, urlPath);
    }

    this.setNewTimeout(() => {
      this.updateUrlParams(currentUrlParams, pushFn, urlPath);
    });
  };
}

/**
 * Aktualizuje przekazany query string i wykonuje redirect do wskazanej
 * lokalizacji. Zbiera kilka wywołań zgłoszonych w krótkim odstępie i
 * po 0.5 sekundy wykonuje wszystkie żądane zmiany.
 *
 * @param {string} currentUrlParams - query string z adresu URL
 * @param {function} pushFn - funkcja wykonująca redirect
 * @param {object} newUrlParams - obiekt z nowymi parametrami URL
 * @param {string} urlPath - podstawowa część URL
 * @param {object} options
 * @param {boolean} options.immediately - wymusza natychmiastowy update URL-a
 * @return {void}
 */
const { updateUrl, removeTimeout } = new UrlUpdater();

export { removeTimeout };
export default updateUrl;
