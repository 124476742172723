import mainRoutePaths from '../../../router/portal/paths';

export const forbiddenRedirectPaths = [
  mainRoutePaths.notFound,
  mainRoutePaths.forbidden,
  mainRoutePaths.login,
  mainRoutePaths.registration,
  mainRoutePaths.resetPassword,
  mainRoutePaths.setNewPassword,
  mainRoutePaths.activateChangedEmail.split('/')[1],
  mainRoutePaths.activation.split('/')[1],
  mainRoutePaths.maintenancePage
];

/**
 * Sprawdza czy dla wskazanej ścieżki dopuszczalne jest przekierowanie po zalogowaniu.
 *
 * Jest to potrzebne dlatego, że po kliknięciu przycisku „Zaloguj” zapamiętywane jest
 * miejsce do którego ma nastąpić przekierowanie po pomyślnym logowaniu. Jeśli kliknięcie
 * nastąpiło np. na stronie 404 albo w widoku rejestracji to nie ma sensu przekierowywać
 * usera w te miejsca.
 *
 * @param {string} pathname - ścieżka do sprawdzenia.
 */
const isLoginRedirectAllowed = pathname =>
  !forbiddenRedirectPaths.some(path => pathname.includes(path));

export default isLoginRedirectAllowed;
