import PropTypes from 'prop-types';
import classNames from 'classnames';

import { BRAND_WRAPPER_TESTID } from '@/constants/data-testids';

import './Brand.less';

const PureBrandComponent = ({
  darkTitle,
  inverse,
  className,
  isLinkEnabled,
  settingsLogo,
  settingsTitle,
  settingsSubtitle,
  titleAlign,
  isPortal = false,
  link,
  isMainPortalLoader,
  mainPortalLoaderStyle
}) => {
  const brand = settingsLogo ? (
    <img className="brand-image-element" src={settingsLogo} alt="SIP logo" />
  ) : null;

  const getAlign = align => {
    if (align === 'left' || isPortal) return 'start';
    if (align === 'right') return 'end';

    return align;
  };

  const getHeaderStyle = settingsSubtitle => {
    if (!settingsSubtitle) return { marginBottom: 0 };

    return {};
  };

  const getMainLoaderStyle = (settings, defaultStyle = {}) => {
    if (isMainPortalLoader) return mainPortalLoaderStyle;
    return settings ? getHeaderStyle(settings) : defaultStyle;
  };

  const titleStyle = getMainLoaderStyle(settingsSubtitle);
  const subtitleStyle = getMainLoaderStyle(null);

  return (
    <div className="brand-wrapper" data-testid={BRAND_WRAPPER_TESTID}>
      <div className="brand-image">
        {isLinkEnabled && link ? (
          <a href={link} aria-label={link}>
            {brand}
          </a>
        ) : (
          brand
        )}
      </div>
      <div
        className={classNames('brand', className, {
          'brand-inverse': inverse,
          'brand-dark': darkTitle
        })}
        style={{ justifyContent: isPortal ? 'left' : titleAlign }}
        aria-label="System title"
        aria-level="0"
      >
        <div
          className="brand-title"
          style={{ alignItems: getAlign(titleAlign) }}
        >
          <h1 id="system_title" style={titleStyle}>
            {settingsTitle || ''}
          </h1>
          <span id="system_second_title" style={subtitleStyle}>
            {settingsSubtitle || ''}
          </span>
        </div>
      </div>
    </div>
  );
};

PureBrandComponent.propTypes = {
  className: PropTypes.string,
  inverse: PropTypes.bool,
  isLinkEnabled: PropTypes.bool,
  onClick: PropTypes.func,
  link: PropTypes.string,
  settingsLogo: PropTypes.string,
  settingsTitle: PropTypes.string,
  settingsSubtitle: PropTypes.string,
  isPortal: PropTypes.bool
};

export default PureBrandComponent;
