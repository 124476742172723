const selectors = {
  getDefectConfiguration: state =>
    state.reports?.get('defectConfiguration').toJS(),
  getSingleReport: state => state.reports?.get('singleReport').toJS(),
  getReportTypes: state => state.reports?.get('typeReports').toJS(),
  getCurrentCategoryAttributes: state =>
    state.reports?.get('actualCategoryAttributes').toJS(),
  getNewAttribute: state => state.reports?.get('newCategoryAttribute'),
  getCategoryAttributesAreLoading: state =>
    state.reports?.get('categoryAttributesAreLoading'),
  getCreateAdminReportCategoryIsLoading: state =>
    state.reports?.get('createAdminReportCategoryIsLoading'),
  getReportCategoryConfig: state => state.reports?.get('reportCategoryConfig'),
  getReportStatusConfig: state => state.reports?.get('reportStatusConfig'),
  getCategoryFilter: state => state.reports?.get('categoryFilter'),
  getStatusFilter: state => state.reports?.get('statusFilter')
};

export default selectors;
