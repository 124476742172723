import { createApi } from '@reduxjs/toolkit/query/react';

import { showError } from '@/store/actions/globalActions';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';
import { endpointWithParams, parseResponseError } from '@/utils/lib';

import {
  API_DISTRICT_UPUL_ATTESTATION_SETTINGS,
  API_DISTRICT_UPUL_ATTESTATION_TEMPLATES,
  API_DISTRICT_UPUL_ATTESTATION_TEMPLATES_SINGLE
} from '../consts';

import {
  EUpulEndpoints,
  EUpulTags,
  TUpulAttestationSettingsParams,
  TUpulAttestationTemplatesParams
} from '@/types/api-types/upul';
import {
  TUpulAttestationSettingsResponse,
  TUpulAttestationTemplatesResponse
} from '@/types/responses/upul';

export const upulApi = createApi({
  reducerPath: 'upulApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [EUpulTags.SETTINGS, EUpulTags.TEMPLATES],
  endpoints: builder => ({
    // UPUL SETTINGS
    [EUpulEndpoints.GET_UPUL_SETTINGS]: builder.query<
      TUpulAttestationSettingsResponse,
      TUpulAttestationSettingsParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: endpointWithParams(API_DISTRICT_UPUL_ATTESTATION_SETTINGS, {
          district
        })
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      },
      providesTags: result =>
        result
          ? [
              {
                type: EUpulTags.SETTINGS,
                id: result?.id || ''
              }
            ]
          : [{ type: EUpulTags.SETTINGS, id: EUpulTags.SETTINGS }]
    }),
    [EUpulEndpoints.PATCH_UPUL_SETTINGS]: builder.mutation<
      TUpulAttestationSettingsResponse,
      TUpulAttestationSettingsParams
    >({
      query: ({ district, data }) => ({
        method: 'PATCH',
        url: endpointWithParams(API_DISTRICT_UPUL_ATTESTATION_SETTINGS, {
          district
        }),
        data
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        try {
          const { data: updatedItem } = await queryFulfilled;

          dispatch(
            upulApi.util.updateQueryData(
              EUpulEndpoints.GET_UPUL_SETTINGS,
              { district },
              () => updatedItem
            )
          );
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      }
    }),
    // UPUL TEMPLATES
    [EUpulEndpoints.GET_UPUL_TEMPLATES]: builder.query<
      TUpulAttestationTemplatesResponse[],
      TUpulAttestationTemplatesParams
    >({
      query: ({ district }) => ({
        method: 'GET',
        url: endpointWithParams(API_DISTRICT_UPUL_ATTESTATION_TEMPLATES, {
          district
        })
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      },
      transformResponse: ({ results }) => results,
      providesTags: result =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: EUpulTags.TEMPLATES,
                id
              })),
              {
                type: EUpulTags.TEMPLATES,
                id: EUpulTags.TEMPLATES
              }
            ]
          : [{ type: EUpulTags.TEMPLATES, id: EUpulTags.TEMPLATES }]
    }),
    [EUpulEndpoints.POST_UPUL_TEMPLATES]: builder.mutation<
      TUpulAttestationTemplatesResponse,
      TUpulAttestationTemplatesParams
    >({
      query: ({ district, data }) => ({
        method: 'POST',
        url: endpointWithParams(API_DISTRICT_UPUL_ATTESTATION_TEMPLATES, {
          district
        }),
        data
      }),
      onQueryStarted: async ({ district }, { dispatch, queryFulfilled }) => {
        try {
          const { data: createdObject } = await queryFulfilled;

          dispatch(
            upulApi.util.updateQueryData(
              EUpulEndpoints.GET_UPUL_TEMPLATES,
              { district },
              draft => {
                draft.push(createdObject);

                return draft;
              }
            )
          );
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      }
    }),
    [EUpulEndpoints.PATCH_UPUL_TEMPLATES]: builder.mutation<
      TUpulAttestationTemplatesResponse,
      TUpulAttestationTemplatesParams
    >({
      query: ({ district, id, data }) => ({
        method: 'PATCH',
        url: endpointWithParams(
          API_DISTRICT_UPUL_ATTESTATION_TEMPLATES_SINGLE,
          {
            district,
            id
          }
        ),
        data
      }),
      onQueryStarted: async (
        { district, id },
        { dispatch, queryFulfilled }
      ) => {
        try {
          const { data: updatedObject } = await queryFulfilled;

          dispatch(
            upulApi.util.updateQueryData(
              EUpulEndpoints.GET_UPUL_TEMPLATES,
              { district },
              draft => {
                const index = draft.findIndex(item => item.id === id);
                draft[index] = updatedObject;

                return draft;
              }
            )
          );
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      }
    }),
    [EUpulEndpoints.DELETE_UPUL_TEMPLATES]: builder.mutation<
      TUpulAttestationTemplatesResponse,
      TUpulAttestationTemplatesParams
    >({
      query: ({ district, id }) => ({
        method: 'DELETE',
        url: endpointWithParams(
          API_DISTRICT_UPUL_ATTESTATION_TEMPLATES_SINGLE,
          {
            district,
            id
          }
        )
      }),
      onQueryStarted: async (
        { district, id },
        { dispatch, queryFulfilled }
      ) => {
        try {
          await queryFulfilled;

          dispatch(
            upulApi.util.updateQueryData(
              EUpulEndpoints.GET_UPUL_TEMPLATES,
              { district },
              draft => {
                return draft.filter(item => item.id !== id);
              }
            )
          );
        } catch (error) {
          dispatch(showError(parseResponseError(error)));
        }
      }
    })
  }),
  keepUnusedDataFor: 1200
});

export const {
  useGetUpulSettingsQuery,
  usePatchUpulSettingsMutation,
  useGetUpulTemplatesQuery,
  usePostUpulTemplatesMutation,
  usePatchUpulTemplatesMutation,
  useDeleteUpulTemplatesMutation
} = upulApi;
