export const GLOBAL_TRANSLATIONS = {
  OBJECT_DELETE_ERROR: {
    id: 'object_delete_error',
    defaultMessage: 'An error occurred while deleting an object'
  },
  GENERAL_YES: { id: 'general_yes', defaultMessage: 'Yes' },
  GENERAL_NO: { id: 'general_no', defaultMessage: 'No' },
  GENERAL_OBJECT_DELETE: {
    id: 'general_object_delete',
    defaultMessage: 'Object has been deleted'
  },
  GENERAL_OBJECT_ADD: {
    id: 'general_object_add',
    defaultMessage: 'Object has been added'
  },
  GENERAL_OBJECT_EDIT: {
    id: 'general_object_edit',
    defaultMessage: 'Object has been edited'
  },
  GENERAL_LOADING: {
    id: 'general_loading',
    defaultMessage: 'Loading...'
  },
  GENERAL_EDIT: {
    id: 'general_edit',
    defaultMessage: 'Edit'
  },
  GENERAL_SAVE: {
    id: 'general_save',
    defaultMessage: 'Save'
  },
  GENERAL_DELETE: {
    id: 'general_delete',
    defaultMessage: 'Delete'
  },
  GENERAL_CANCEL: {
    id: 'general_cancel',
    defaultMessage: 'Cancel'
  },
  MAP_UPDATE_MESSAGE: {
    id: 'map_update_message',
    defaultMessage: 'Map update in progress...'
  },
  MAP_UPDATE_ERROR_MESSAGE: {
    id: 'map_update_error_message',
    defaultMessage: 'An error occurred during a map update.'
  },
  GENERAL_FILE_NAME: {
    id: 'general_file_name',
    defaultMessage: 'File name'
  },
  GENERAL_REQUIRED_FIELD: {
    id: 'general_required_field',
    defaultMessage: 'This field is required!'
  },
  GENERAL_FORM_EMPTY_FIELDS: {
    id: 'general_form_empty_fields',
    defaultMessage: 'The form contains empty fields!'
  },
  GENERAL_ERROR: {
    id: 'general_error',
    defaultMessage: 'Error occured'
  },
  LOADING_IN_PROGRESS: {
    id: 'loading_in_progress',
    defaultMessage: 'Loading in progress...'
  },
  TITLE_PROFILE_PRIVACY_POLICY: {
    id: 'title_profile_privacy_policy',
    defaultMessage: 'Privacy policy'
  },
  TITLE_PROFILE: {
    id: 'title_profile',
    defaultMessage: 'profile'
  }
};
