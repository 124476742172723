import { lazy } from 'react';
import { useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';

import { districtSelectors, globalSelectors } from '../../store/selectors';

import LocationListener from '../../components/LocationListener';
import LanguageRecognizer from '../../components/LanguageRecognizer';
import LogoutCountdownTimer from '../../components/LogoutCountdownTimer';
import SuspenseWrapper from '../../components/SuspenseWrapper';
import ComponentsReadyListener from '@/components/ComponentsReadyListener';

import mainRoutePaths from './paths';
import isItIframe from '../../utils/isItIframe';

const HeaderPortal = lazy(() => import('../../components/HeaderPortal'));
const HomeView = lazy(() => import('../../views/Home'));
const LoginView = lazy(() => import('../../views/Login'));
const RegistrationView = lazy(() => import('../../views/Registration'));
const ConfirmEmailView = lazy(() => import('../../views/ConfirmEmail'));
const UserProfileView = lazy(() => import('../../views/UserProfileView'));
const MainAdminPanelView = lazy(() => import('../../views/MainAdminPanel'));
const ResetPasswordView = lazy(() => import('../../views/ResetPassword'));
const ConfirmEmailFromAdminView = lazy(() =>
  import('../../views/ConfirmEmailFromAdmin')
);
const SetNewPasswordView = lazy(() => import('../../views/SetNewPassword'));
const ActivateChangedEmailView = lazy(() =>
  import('../../views/ActivateChangedEmail')
);
const Page404 = lazy(() => import('../../views/Page404'));
const DistrictRouter = lazy(() => import('../../router/district'));
const ForbiddenView = lazy(() => import('../../views/ForbiddenView'));
const PopUpDetailsView = lazy(() => import('../../views/PopUpDetailsView'));
const MaintenancePage = lazy(() => import('../../views/MaintenancePage'));
const Regulations = lazy(() => import('../../views/Regulations'));

const { getDistricts } = districtSelectors;
const { getGlobalSettings, getIsMapPortalLoading } = globalSelectors;

const PortalRouter = () => {
  const districts = useSelector(getDistricts);
  const globalSettings = useSelector(getGlobalSettings);
  const mapPortalLoader = useSelector(getIsMapPortalLoading);

  const districtRegex = districts.join('|');
  const isDistrictListHomePage = globalSettings.get(
    'home_page_is_districts_list'
  );

  return (
    <Router>
      <LocationListener />
      {!isItIframe() && (
        <SuspenseWrapper ignoreFallback>
          <HeaderPortal />
        </SuspenseWrapper>
      )}
      <LanguageRecognizer />
      <Switch>
        <Route
          exact
          path={mainRoutePaths.homePage}
          render={() =>
            isDistrictListHomePage ? (
              <Redirect to={mainRoutePaths.districtList} />
            ) : (
              <SuspenseWrapper withFooter>
                <HomeView />
              </SuspenseWrapper>
            )
          }
        />
        <Route
          exact
          path={mainRoutePaths.districtList}
          render={() =>
            isDistrictListHomePage ? (
              <SuspenseWrapper withFooter>
                <HomeView />
              </SuspenseWrapper>
            ) : (
              <Redirect to={mainRoutePaths.notFound} />
            )
          }
        />
        <Route
          path={mainRoutePaths.login}
          render={() => (
            <SuspenseWrapper withFooter>
              <LoginView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.registration}
          render={() => (
            <SuspenseWrapper withFooter>
              <RegistrationView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.activation}
          render={() => (
            <SuspenseWrapper withFooter>
              <ConfirmEmailView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.adminActivation}
          render={() => (
            <SuspenseWrapper withFooter>
              <ConfirmEmailFromAdminView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.profile}
          render={() => (
            <SuspenseWrapper>
              <UserProfileView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.admin}
          render={() => (
            <SuspenseWrapper>
              <MainAdminPanelView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.availability_statement}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations
                regulations={mainRoutePaths.availability_statement}
              />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.terms}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations regulations={mainRoutePaths.terms} />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.privacy}
          render={() => (
            <SuspenseWrapper withFooter>
              <Regulations regulations={mainRoutePaths.privacy} />
            </SuspenseWrapper>
          )}
        />
        <Route
          exact
          path={mainRoutePaths.resetPassword}
          render={() => (
            <SuspenseWrapper>
              <ResetPasswordView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.setNewPassword}
          render={() => (
            <SuspenseWrapper>
              <SetNewPasswordView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.activateChangedEmail}
          render={() => (
            <SuspenseWrapper>
              <ActivateChangedEmailView withFooter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={`/:prefix(${districtRegex})`}
          render={() => (
            <SuspenseWrapper>
              <DistrictRouter />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.forbidden}
          render={() => (
            <SuspenseWrapper>
              <ForbiddenView />
            </SuspenseWrapper>
          )}
        />
        <Route
          exact
          path={'/popup_details'}
          render={() => (
            <SuspenseWrapper>
              <PopUpDetailsView />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.notFound}
          render={() => (
            <SuspenseWrapper>
              <Page404 />
            </SuspenseWrapper>
          )}
        />
        <Route
          path={mainRoutePaths.maintenancePage}
          render={props => (
            <SuspenseWrapper>
              <MaintenancePage {...props} />
            </SuspenseWrapper>
          )}
        />
        <Route
          component={() => (
            <Redirect
              to={{
                pathname: mainRoutePaths.notFound
              }}
            />
          )}
        />
      </Switch>
      <LogoutCountdownTimer />
      {mapPortalLoader && <ComponentsReadyListener />}
    </Router>
  );
};

export default PortalRouter;
