import { Map, List } from 'immutable';

import getLeafletCrsFromGrid from '../../utils/mapUtils/getLeafletCrsFromGrid';

import {
  ERROR_SHOW,
  ERROR_HIDE,
  SUCCESS_SHOW,
  SUCCESS_HIDE,
  SIDEBAR_SHOW,
  SIDEBAR_HIDE,
  LOGOUT_COUNTDOWN_TIMER_SHOW,
  LOGOUT_COUNTDOWN_TIMER_HIDE,
  CHANGE_VALUE,
  RESET_SIDEBAR_PANELS_VISIBILITY,
  CHANGE_LANGUAGE,
  HAMBURGER_HIDE,
  HAMBURGER_SHOW,
  SET_PREVIOUS_URL,
  FETCH_GLOBAL_SETTINGS_BEGIN,
  FETCH_GLOBAL_SETTINGS_SUCCESS,
  FETCH_GLOBAL_SETTINGS_FAILED,
  UPDATE_GLOBAL_SETTINGS_BEGIN,
  UPDATE_GLOBAL_SETTINGS_SUCCESS,
  UPDATE_GLOBAL_SETTINGS_FAILED,
  DELETE_HEADER_LINK_BEGIN,
  DELETE_HEADER_LINK_SUCCESS,
  DELETE_HEADER_LINK_FAILED,
  UPDATE_HEADER_LINK_BEGIN,
  UPDATE_HEADER_LINK_SUCCESS,
  UPDATE_HEADER_LINK_FAILED,
  CREATE_HEADER_LINK_BEGIN,
  CREATE_HEADER_LINK_SUCCESS,
  CREATE_HEADER_LINK_FAILED,
  FETCH_HEADER_LINK_BEGIN,
  FETCH_HEADER_LINK_SUCCESS,
  FETCH_HEADER_LINK_FAILED,
  GET_LANGUAGES_BEGIN,
  GET_LANGUAGES_SUCCESS,
  GET_LANGUAGES_FAILED,
  GET_TRANSLATION_BEGIN,
  GET_TRANSLATION_SUCCESS,
  GET_TRANSLATION_FAILED,
  CREATE_TRANSLATION_SUCCESS,
  EDIT_TRANSLATION_SUCCESS,
  DELETE_TRANSLATION_SUCCESS,
  FETCH_USER_SETTING_BEGIN,
  FETCH_USER_SETTING_SUCCESS,
  FETCH_USER_SETTING_FAILED,
  FETCH_CURSOR_CRS_LIST_SUCCESS,
  CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS,
  GET_PING_PONG_FAILED,
  GET_PING_PONG_SUCCESS,
  GET_PING_PONG_BEGIN,
  CHECK_DEFAULT_USER_SETTING_REQUEST,
  CHECK_DEFAULT_USER_SETTING_FAILED,
  CHECK_DEFAULT_USER_SETTING_SUCCESS,
  SET_DEFAULT_USER_SETTING_REQUEST,
  SET_DEFAULT_USER_SETTING_FAILED,
  SET_DEFAULT_USER_SETTING_SUCCESS,
  SET_WINDOW_DIMENSIONS,
  GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS,
  SET_RENDERED_COMPONENTS,
  CLEAR_RENDERED_COMPONENTS,
  CHECK_USE_ADVANCED_FEATURES_SUCCESS,
  GET_EBUD_API_KEY_SUCCESS,
  RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY,
  SET_MAP_PORTAL_LOADER,
  SET_IS_CANVAS_TILE_SUCESSFULLY_LOADED
} from '../constants/globalActionTypes';

import {
  defaultLang,
  responseStatuses,
  sessionStorageKeys
} from '../../config';

import sidebarItemsEnums from '../../components/Sidebar/sidebarEnums';

const initialPortalSidebarState = Map({
  [sidebarItemsEnums.compositions]: false,
  [sidebarItemsEnums.layers]: false,
  [sidebarItemsEnums.search_tools]: false,
  [sidebarItemsEnums.informations]: false,
  [sidebarItemsEnums.defects]: false
});

export const initialState = Map({
  language: defaultLang,
  showMessage: false,
  message: '',
  messageType: '',
  mapPortal: initialPortalSidebarState,
  sidebarOpen: true,
  logoutCountdownTimerOpen: false,
  hamburgerMenuOpen: false,
  previousUrl: '',
  fetchingGlobalSettings: false,
  updateingGlobalSetings: false,
  globalSettings: Map({}),
  deletingHeaderLink: false,
  deletedHeaderLink: false,
  updateingHeaderLinks: false,
  updatedHeaderLink: false,
  createingHeaderLink: false,
  createdHeaderLink: false,
  fetchingHeaderLink: false,
  headerLinks: List([]),
  languages: List([]),
  languagesFetching: null,
  translations: Map({
    // Defaultowo dorzucamy tłumaczenia komunikatów, które mogą pojawić się przed
    // pobraniem języków z backendu.
    pl: Map({
      code: 'pl',
      name: 'polski',
      attrs: {
        global_settings_fetching_error:
          'Błąd wczytywania ustawień globalnych - portal może działać nieprawidłowo!'
      }
    })
  }),
  translationFetching: null,
  userSettings: {},
  fetchingUserSettings: false,
  cursorCrsList: null,
  isOfferModelAvailable: null,
  isApiAvailable: null,
  defaultApiFetching: null,
  defaultUserSetting: false,
  checkingDefaultUserSetting: false,
  setingDefaultUserSetting: false,
  windowDimensions: Map({
    width: window.innerWidth,
    height: window.innerHeight
  }),
  windowDimensionsType: null,
  contextualHelpTooltips: null,
  renderedComponents: Map({}),
  advancedFeaturesUse: {},
  crs: null,
  mapPortalLoader: false,
  areCanvasTilesSuccessfullyLoaded: true
});

const errorShow = (state, action) =>
  state.merge(
    Map({
      showMessage: true,
      message: action.errorMessage,
      messageType: 'error'
    })
  );

const errorHide = state =>
  state.merge(
    Map({
      showMessage: false,
      message: ''
    })
  );

const successShow = (state, action) =>
  state.merge(
    Map({
      showMessage: true,
      message: action.successMessage,
      messageType: 'success'
    })
  );

const successHide = state =>
  state.merge(
    Map({
      showMessage: false,
      message: ''
    })
  );

const sidebarShow = state =>
  state.merge(
    Map({
      sidebarOpen: true
    })
  );

const logoutCountdownTimerShow = state =>
  state.merge(
    Map({
      logoutCountdownTimerOpen: true
    })
  );

const logoutCountdownTimerHide = state =>
  state.merge(
    Map({
      logoutCountdownTimerOpen: false
    })
  );

const hamburgerMenuShow = state =>
  state.merge(
    Map({
      hamburgerMenuOpen: true
    })
  );

const hamburgerMenuHide = state =>
  state.merge(
    Map({
      hamburgerMenuOpen: false
    })
  );

const sidebarHide = state =>
  state.merge(
    Map({
      sidebarOpen: false
    })
  );

const resetSidebarPanelsVisibility = state =>
  state.set(
    'mapPortal',
    Map({
      ...state.get('mapPortal').toJS(),
      ...initialPortalSidebarState.toJS()
    })
  );

const resetSidebarTopicalSearchVisibility = state =>
  state.set('mapPortal', initialPortalSidebarState);

const changeValue = (state, action) =>
  state.setIn(['mapPortal', action.name], action.value);

const changeLanguage = (state, action) =>
  state.merge(
    Map({
      language: action.language
    })
  );

const setPreviousUrl = (state, action) =>
  state.merge(
    Map({
      previousUrl: action.url
    })
  );

const fetchGlobaSettingsBegin = state =>
  state.merge(
    Map({
      fetchGlobaSettingsBegin: true
    })
  );

const fetchGlobaSettingsSuccess = (state, { globalSettings }) =>
  state.merge(
    Map({
      globalSettings: Map(globalSettings),
      crs:
        // It's necessary, because during edition typeof `default_grid` is equal "number"
        typeof globalSettings.default_grid === 'object'
          ? getLeafletCrsFromGrid(globalSettings.default_grid)
          : null
    })
  );

const fetchGlobaSettingsEnd = state =>
  state.merge(
    Map({
      fetchGlobaSettingsBegin: false
    })
  );

const updateGlobaSettingsBegin = state =>
  state.merge(
    Map({
      updateingGlobalSetings: true
    })
  );

const updateGlobaSettingsEnd = state =>
  state.merge(
    Map({
      updateingGlobalSetings: false
    })
  );

const deleteHeaderBegin = state =>
  state.merge(
    Map({
      deletedHeaderLink: false,
      deletingHeaderLink: true
    })
  );

const deleteHeaderSuccess = state =>
  state.merge(
    Map({
      deletedHeaderLink: true,
      deletingHeaderLink: false
    })
  );

const deleteHeaderFailed = state =>
  state.merge(
    Map({
      deletedHeaderLink: false,
      deletingHeaderLink: false
    })
  );

const updateHeaderBegin = state =>
  state.merge(
    Map({
      updatedHeaderLink: false,
      updateingHeaderLinks: true
    })
  );

const updateHeaderSuccess = state =>
  state.merge(
    Map({
      updatedHeaderLink: true,
      updateingHeaderLinks: false
    })
  );

const updateHeaderFailed = state =>
  state.merge(
    Map({
      updatedHeaderLink: false,
      updateingHeaderLinks: false
    })
  );

const createHeaderLinkBegin = state =>
  state.merge(
    Map({
      createdHeaderLink: false,
      createingHeaderLink: true
    })
  );

const createHeaderLinkSuccess = state =>
  state.merge(
    Map({
      createdHeaderLink: true,
      createingHeaderLink: false
    })
  );

const createHeaderLinkFailed = state =>
  state.merge(
    Map({
      createdHeaderLink: false,
      createingHeaderLink: false
    })
  );

const fetchHeaderLinkBegin = state =>
  state.merge(
    Map({
      createdHeaderLink: false,
      deletedHeaderLink: false,
      updatedHeaderLink: false,
      fetchingHeaderLink: true
    })
  );

const fetchHeaderLinkSuccess = (state, action) =>
  state.merge(
    Map({
      fetchingHeaderLink: false,
      headerLinks: List(action.headerLinks)
    })
  );

const fetchHeaderLinkEnd = state =>
  state.merge(
    Map({
      fetchingHeaderLink: false
    })
  );
const getLanguagesBegin = state =>
  state.set('languagesFetching', responseStatuses.STARTED);

const getLanguagesSuccess = (state, action) =>
  state
    .set('languages', List(action.languages))
    .set('languagesFetching', responseStatuses.SUCCESS);

const getLanguagesFailed = state =>
  state.set('languagesFetching', responseStatuses.FAILED);

const getTranslationBegin = state =>
  state.set('translationFetching', responseStatuses.STARTED);

const getTranslationSuccess = (state, action) => {
  const lsLanguage = sessionStorage.getItem(sessionStorageKeys.language);
  const defaultLanguage = action.langCode || lsLanguage || defaultLang;
  const translationsObj = {
    ...action.translation,
    attrs: action.translation.attrs.reduce((object, item) => {
      object[item.attr_name] = item.value;
      return object;
    }, {})
  };

  return state
    .setIn(['translations', defaultLanguage], Map(translationsObj))
    .set('translationFetching', responseStatuses.SUCCESS);
};

const getTranslationFailed = state =>
  state.set('translationFetching', responseStatuses.FAILED);

const createTranslationSuccess = (
  state,
  { translation: { id, code, name, is_enabled, ...translations } }
) =>
  state
    .update('languages', List(), list => List([...list, { code, name }]))
    .setIn(['translations', code], Map(translations));

const editTranslationSuccess = (
  state,
  { translation: { id, code, name, is_enabled, ...translations } }
) => {
  const editedLangIndex = state
    .get('languages')
    .findIndex(lang => lang.code === code);

  return state
    .setIn(['languages', editedLangIndex], { code, name })
    .setIn(['translations', code], Map(translations));
};

const deleteTranslationSuccess = (state, { langCode }) =>
  state
    .update('languages', List(), list =>
      List([...list].filter(lang => lang.code !== langCode))
    )
    .deleteIn(['translations', langCode]);

const fetchUserSettingsBegin = state => state.set('fetchingUserSettings', true);

const fetchUserSettingsSuccess = (state, action) =>
  state
    .set('fetchingUserSettings', false)
    .set('userSettings', action.userSettings);

const fetchUserSettingsFailed = state =>
  state.set('fetchingUserSettings', false);

const fetchCursorCrsListSuccess = (state, { cursorCrsList }) =>
  state.set('cursorCrsList', List(cursorCrsList));

const checkIfOffetModelAvailableSuccess = (state, { isAvailable }) =>
  state.set('isOfferModelAvailable', isAvailable);

const getPingPongBegin = state =>
  state.set('defaultApiFetching', responseStatuses.STARTED);

const getPingPongFailed = state =>
  state
    .set('defaultApiFetching', responseStatuses.FAILED)
    .set('isApiAvailable', false);

const getPingPongSuccess = state =>
  state
    .set('defaultApiFetching', responseStatuses.SUCCESS)
    .set('isApiAvailable', true);

const checkDefaultUserSettingRequest = state =>
  state.set('checkingDefaultUserSetting', true);

const checkDefaultUserSettingSuccess = (state, action) =>
  state
    .set('checkingDefaultUserSetting', false)
    .set('defaultUserSetting', action.payload);

const checkDefaultUserSettingFailed = (state, action) =>
  state
    .set('checkingDefaultUserSetting', false)
    .set('defaultUserSetting', false);

const setingDefaultUserSettingStart = state =>
  state.set('setingDefaultUserSetting', true);

const setingDefaultUserSettingEnd = state =>
  state.set('setingDefaultUserSetting', false);

const setWindowDimensions = (state, { dimensions, dimensionsType }) =>
  state
    .set('windowDimensions', Map(dimensions))
    .set('windowDimensionsType', dimensionsType);

const getContextualHelpTooltipsSuccess = (state, { tooltips }) =>
  state.set('contextualHelpTooltips', List(tooltips));

const setRenederedComponent = (state, { component }) =>
  state.setIn(['renderedComponents', component], true);

const clearRenederedComponents = state =>
  state.set('renderedComponents', Map({}));

const checkAdvancedFeaturesUse = (state, action) =>
  state.set('advancedFeaturesUse', action.payload);

const setEbudApiKey = (state, action) =>
  state.set('ebudApiKey', action.payload);

const setMapPortalLoader = (state, { isVisible }) =>
  state.set('mapPortalLoader', isVisible);

const setAreCanvasTilesLoadedSuccessfull = (state, { areSuccessfullyLoaded }) =>
  state.set('areCanvasTilesSuccessfullyLoaded', areSuccessfullyLoaded);

export default function reducer(state = initialState, action) {
  return (
    {
      [ERROR_SHOW]: errorShow,
      [ERROR_HIDE]: errorHide,
      [SUCCESS_SHOW]: successShow,
      [SUCCESS_HIDE]: successHide,
      [SIDEBAR_SHOW]: sidebarShow,
      [SIDEBAR_HIDE]: sidebarHide,
      [LOGOUT_COUNTDOWN_TIMER_SHOW]: logoutCountdownTimerShow,
      [LOGOUT_COUNTDOWN_TIMER_HIDE]: logoutCountdownTimerHide,
      [CHANGE_VALUE]: changeValue,
      [RESET_SIDEBAR_PANELS_VISIBILITY]: resetSidebarPanelsVisibility,
      [RESET_SIDEBAR_TOPICAL_SEARCH_VISIBILITY]: resetSidebarTopicalSearchVisibility,
      [CHANGE_LANGUAGE]: changeLanguage,
      [HAMBURGER_SHOW]: hamburgerMenuShow,
      [HAMBURGER_HIDE]: hamburgerMenuHide,
      [SET_PREVIOUS_URL]: setPreviousUrl,
      [FETCH_GLOBAL_SETTINGS_BEGIN]: fetchGlobaSettingsBegin,
      [FETCH_GLOBAL_SETTINGS_SUCCESS]: fetchGlobaSettingsEnd,
      [FETCH_GLOBAL_SETTINGS_SUCCESS]: fetchGlobaSettingsSuccess,
      [FETCH_GLOBAL_SETTINGS_FAILED]: fetchGlobaSettingsEnd,
      [UPDATE_GLOBAL_SETTINGS_BEGIN]: updateGlobaSettingsBegin,
      [UPDATE_GLOBAL_SETTINGS_SUCCESS]: updateGlobaSettingsEnd,
      [UPDATE_GLOBAL_SETTINGS_SUCCESS]: fetchGlobaSettingsSuccess,
      [UPDATE_GLOBAL_SETTINGS_FAILED]: updateGlobaSettingsEnd,
      [UPDATE_HEADER_LINK_BEGIN]: updateHeaderBegin,
      [UPDATE_HEADER_LINK_SUCCESS]: updateHeaderSuccess,
      [UPDATE_HEADER_LINK_FAILED]: updateHeaderFailed,
      [DELETE_HEADER_LINK_BEGIN]: deleteHeaderBegin,
      [DELETE_HEADER_LINK_SUCCESS]: deleteHeaderSuccess,
      [DELETE_HEADER_LINK_FAILED]: deleteHeaderFailed,
      [CREATE_HEADER_LINK_BEGIN]: createHeaderLinkBegin,
      [CREATE_HEADER_LINK_SUCCESS]: createHeaderLinkSuccess,
      [CREATE_HEADER_LINK_FAILED]: createHeaderLinkFailed,
      [FETCH_HEADER_LINK_BEGIN]: fetchHeaderLinkBegin,
      [FETCH_HEADER_LINK_SUCCESS]: fetchHeaderLinkSuccess,
      [FETCH_HEADER_LINK_FAILED]: fetchHeaderLinkEnd,
      [GET_LANGUAGES_BEGIN]: getLanguagesBegin,
      [GET_LANGUAGES_SUCCESS]: getLanguagesSuccess,
      [GET_LANGUAGES_FAILED]: getLanguagesFailed,
      [GET_TRANSLATION_BEGIN]: getTranslationBegin,
      [GET_TRANSLATION_SUCCESS]: getTranslationSuccess,
      [GET_TRANSLATION_FAILED]: getTranslationFailed,
      [CREATE_TRANSLATION_SUCCESS]: createTranslationSuccess,
      [EDIT_TRANSLATION_SUCCESS]: editTranslationSuccess,
      [DELETE_TRANSLATION_SUCCESS]: deleteTranslationSuccess,
      [FETCH_USER_SETTING_BEGIN]: fetchUserSettingsBegin,
      [FETCH_USER_SETTING_SUCCESS]: fetchUserSettingsSuccess,
      [FETCH_USER_SETTING_FAILED]: fetchUserSettingsFailed,
      [FETCH_CURSOR_CRS_LIST_SUCCESS]: fetchCursorCrsListSuccess,
      [CHECK_IF_OFFER_MODEL_AVAILABLE_SUCCESS]: checkIfOffetModelAvailableSuccess,
      [GET_PING_PONG_FAILED]: getPingPongFailed,
      [GET_PING_PONG_SUCCESS]: getPingPongSuccess,
      [GET_PING_PONG_BEGIN]: getPingPongBegin,
      [CHECK_DEFAULT_USER_SETTING_REQUEST]: checkDefaultUserSettingRequest,
      [CHECK_DEFAULT_USER_SETTING_SUCCESS]: checkDefaultUserSettingSuccess,
      [CHECK_DEFAULT_USER_SETTING_FAILED]: checkDefaultUserSettingFailed,
      [SET_DEFAULT_USER_SETTING_REQUEST]: setingDefaultUserSettingStart,
      [SET_DEFAULT_USER_SETTING_SUCCESS]: setingDefaultUserSettingEnd,
      [SET_DEFAULT_USER_SETTING_FAILED]: setingDefaultUserSettingEnd,
      [SET_WINDOW_DIMENSIONS]: setWindowDimensions,
      [GET_CONTEXTUAL_HELP_TOOLTIPS_SUCCESS]: getContextualHelpTooltipsSuccess,
      [SET_RENDERED_COMPONENTS]: setRenederedComponent,
      [CLEAR_RENDERED_COMPONENTS]: clearRenederedComponents,
      [CHECK_USE_ADVANCED_FEATURES_SUCCESS]: checkAdvancedFeaturesUse,
      [GET_EBUD_API_KEY_SUCCESS]: setEbudApiKey,
      [SET_MAP_PORTAL_LOADER]: setMapPortalLoader,
      [SET_IS_CANVAS_TILE_SUCESSFULLY_LOADED]: setAreCanvasTilesLoadedSuccessfull
    }[action.type] || (s => s)
  )(state, action);
}
