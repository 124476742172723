import * as L from 'leaflet';

import { deleteLayer } from '../';

/**
 * Dodaje do przekazanej mapy warstwę GeoJSON.
 * Do wygenerowania na mapie warstwy typu GeoJSON nie możemy
 * użyć komponentu <GeoJSON /> z paczki 'react-leaflet', ponieważ
 * po zamontowaniu w DOM nie da się go rerenderować. Aby zmieniać
 * dynamicznie zawartość takiej warstwy należy użyć poniższej funkcji.
 *
 * @param {L.Map} map - obiekt mapy Leaflet
 * @param {Object} config - obiekt konfiguracyjny
 * @param {?string} config.id - ID warstwy
 * @param {Object} config.data - obiekt z danymi warstwy
 * @param {?Object} config.style - style renderowanej warstwy
 * @param {?fit} config.fit - przybliż widok mapy do wyrenderowanej warstwy
 *
 * @return {L.geoJSON}
 */

const renderGeoJSON = (map, config = {}) => {
  if (!map) {
    return;
  }

  const { id, data, style, fit, maxZoom, addToMap = true } = config;

  if (map._layers[id]) {
    deleteLayer(map, id);
  }

  const layer = L.geoJSON(data, style);

  if (id) {
    layer._leaflet_id = id;
  }

  if (fit)
    map.fitBounds(layer.getBounds(), {
      maxZoom: maxZoom
    });

  if (addToMap) layer.addTo(map);

  return layer;
};

export default renderGeoJSON;
