import * as turfHelpers from '@turf/helpers';
import lineOffset from '@turf/line-offset';
import lineToPolygon from '@turf/line-to-polygon';
import lineIntersect from '@turf/line-intersect';
import difference from '@turf/difference';
import booleanWithin from '@turf/boolean-within';
import { getCoords, getGeom } from '@turf/invariant';

/**
 * Biblioteka Turf.js udostępnia metodę, za pomocą której można podzielić
 * jeden Polygon za pomocą drugiego. Poniższy algorytm wykorzystuje właśnie
 * tę funkcję. Przekazaną linię podziału zamieniamy w bardzo cienki Polygon,
 * a następnie za jego pomocą dokonujemy podziału właściwej geometrii, co w
 * rezultacie zwraca gotowy MultiPolygon.
 */

/*
  Modified version of function from
  'https://gis.stackexchange.com/questions/344068/
  splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js'

  Author: TomazicM
  License: CC BY-SA 4.0
*/
function polygonCut(polygon, line) {
  const THICK_LINE_UNITS = 'kilometers';
  const THICK_LINE_WIDTH = 0.001;
  let i, j, forCut, forSelect;
  let thickLineString, thickLinePolygon, clipped, polyg, intersect;

  const cutFeaturesCoords = [];
  const offsetLine = [];
  let retVal = null;
  if (
    (polygon.type !== 'Polygon' && polygon.type !== 'MultiPolygon') ||
    line.type !== 'LineString'
  ) {
    return retVal;
  }

  const intersectPoints = lineIntersect(polygon, line);
  if (intersectPoints.features.length === 0) {
    return retVal;
  }

  const lineCoords = getCoords(line);

  if (
    booleanWithin(turfHelpers.point(lineCoords[0]), polygon) ||
    booleanWithin(turfHelpers.point(lineCoords[lineCoords.length - 1]), polygon)
  ) {
    return retVal;
  }

  offsetLine[0] = lineOffset(line, THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS
  });
  offsetLine[1] = lineOffset(line, -THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS
  });

  for (i = 0; i <= 1; i++) {
    forCut = i;
    forSelect = (i + 1) % 2;
    const polyCoords = [];

    for (j = 0; j < line.coordinates.length; j++) {
      polyCoords.push(line.coordinates[j]);
    }
    for (j = offsetLine[forCut].geometry.coordinates.length - 1; j >= 0; j--) {
      polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
    }
    polyCoords.push(line.coordinates[0]);

    thickLineString = turfHelpers.lineString(polyCoords);
    thickLinePolygon = lineToPolygon(thickLineString);
    clipped = difference(polygon, thickLinePolygon);

    const cutPolyGeoms = [];
    for (j = 0; j < clipped.geometry.coordinates.length; j++) {
      polyg = turfHelpers.polygon(clipped.geometry.coordinates[j]);
      intersect = lineIntersect(polyg, offsetLine[forSelect]);
      if (intersect.features.length > 0) {
        cutPolyGeoms.push(polyg.geometry.coordinates);
      }
    }

    cutPolyGeoms.forEach(function(geometry) {
      cutFeaturesCoords.push(getCoords(geometry));
    });
  }

  if (cutFeaturesCoords.length > 0)
    retVal = turfHelpers.multiPolygon(cutFeaturesCoords);

  return retVal;
}

const slicePolygonByLine = (polygon, line) => {
  // Punkty przecięcia polygonu przez linię.

  const intersectPoints = lineIntersect(polygon, line);

  // Jeśli linia nie przecina conajmniej 2 punktów kończymy podziała
  if (intersectPoints.features.length < 2) return null;

  const slicedPolygon = polygonCut(getGeom(polygon), getGeom(line));

  return slicedPolygon;
};

export default slicePolygonByLine;
