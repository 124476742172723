import { mapPortalSelectors } from './';

const getPopupConfig = (state, configId) =>
  state.popup.get('configs').get(String(configId));
const getPopupConfigs = state => state.popup.get('configs').toJS();

const selectors = {
  getPopupConfigs,
  getPopupConfig,
  desktopGetPopupConfigs: (state, district, mapPortalCompositionId) =>
    state.popup.getIn(['configs', district, mapPortalCompositionId]),
  getCurrentPopupConfig: (state, prefix, portal) => {
    const configId = mapPortalSelectors.getCurrentComposition(
      state,
      prefix,
      portal
    )?.config;

    return getPopupConfig(state, configId);
  },
  desktopGetLayerDefaultAttributes: (state, layerId) =>
    state.popup.getIn(['layersDefaultAttributes', layerId]),
  getPopupLayerConfig: (state, layerConfigId) => {
    return state.popup
      .get('layersConfigs')
      .find(config => config.id === Number(layerConfigId));
  },
  getIsDownloadingPopupData: state => state.popup.get('isDownloadingPopupData'),
  getIsInformationModalMinimized: state =>
    state.popup.get('isInformationModalMinimized'),
  getMinimizedCoordinatesToIdentification: state =>
    state.popup.get('minimizedCoordinatesToIdentification'),
  getMinimizedPlotsListToIdentification: state =>
    state.popup.get('minimizedPlotsListToIdentification'),
  getPopUpLayerAttributes: state => state.popup.get('layerAttributes'),
  desktopGetPopupStructure: state => state.popup.get('popupStructure'),
  isLoadingPreviewMapLayers: state => {
    const statuses = state.popup.get('printCardLoadingLayerStatuses');
    return statuses.filter(({ isLoading }) => isLoading).size > 0;
  },
  getPopupCardTree: state => state.popup.get('popupCardTree'),
  getPlotListInformation: state => state.popup.get('plotListInformation'),
  getMultipleCoordinatesToIdentification: state =>
    state.popup.get('multipleCoordinatesToIdentification'),
  getCoordinatesToIdentification: state =>
    state.popup.get('coordinatesToIdentification'),
  getPopupLayerJoins: (
    state,
    district,
    mapPortalCompositionId,
    layerConfigId
  ) =>
    state.popup.getIn([
      'joins',
      district,
      mapPortalCompositionId,
      layerConfigId
    ]),
  getPopupLayerJoinsAttributes: (
    state,
    district,
    mapPortalCompositionId,
    layerConfigId
  ) =>
    state.popup.getIn([
      'joinsAttributes',
      district,
      mapPortalCompositionId,
      layerConfigId
    ]),
  getIsFetchingParcelsData: state => state.popup.get('isFetchingParcelsData'),
  getParcelsData: state => state.popup.get('parcelsData'),
  getIsQueryStringPopup: state => state.popup.get('isQueryStringPopup'),
  getDownloadAttachmentsToPrint: state =>
    state.popup.get('downloadAttachmentsToPrint'),
  getPopupUrl: state => state.popup.get('popupUrl'),
  getIsPreviewMapsEnabled: state => state.popup.get('isPreviewMapsEnabled'),
  getIsGeneratingDataToPrint: state =>
    state.popup.get('isGeneratingDataToPrint')
};

export default selectors;
