import { Map } from 'immutable';

import {
  OPEN_WMS_INFORMATION_MODAL,
  SET_COORDINATES_TO_WMS_IDENTIFICATION,
  CLEAR_COORDINATES_TO_WMS_IDENTIFICATION,
  CLOSE_WMS_INFORMATION_MODAL
} from '../constants/wmsPopupActionTypes';

const initialState = Map({
  isOpenWmsModal: false,
  coordinatesToWmsIdentification: Map({})
});

const openWmsModal = state => state.set('isOpenWmsModal', true);

const closeWmsModal = state => state.set('isOpenWmsModal', false);

const setCoordinatesToWmsIdentification = (state, { lat, lng }) =>
  state.set('coordinatesToWmsIdentification', Map({ lat, lng }));

const clearCoordinatesToWmsIdentification = state =>
  state.set('coordinatesToWmsIdentification', Map({}));

export default function reducer(state = initialState, action) {
  return (
    {
      [OPEN_WMS_INFORMATION_MODAL]: openWmsModal,
      [CLOSE_WMS_INFORMATION_MODAL]: closeWmsModal,
      [SET_COORDINATES_TO_WMS_IDENTIFICATION]: setCoordinatesToWmsIdentification,
      [CLEAR_COORDINATES_TO_WMS_IDENTIFICATION]: clearCoordinatesToWmsIdentification
    }[action.type] || (s => s)
  )(state, action);
}
