import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from '@/vendors/axiosBaseQuery';

import { endpointWithParams } from '@/utils/lib';
import handleApiError from '@/utils/handleApiError';

import { API_FINN_SERVICE } from '../consts';

import { TFinnServiceParams } from '@/types/api-types/finn';
import { TFinnSprawaResponse } from '@/types/responses/finn';

export const finnApi = createApi({
  reducerPath: 'finnApi',
  baseQuery: axiosBaseQuery(),
  tagTypes: [],
  endpoints: builder => ({
    postFinnService: builder.mutation<TFinnSprawaResponse, TFinnServiceParams>({
      query: ({ service, data }) => ({
        method: 'POST',
        url: endpointWithParams(API_FINN_SERVICE, {
          service
        }),
        data
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        } catch (error) {
          handleApiError(error, dispatch);
        }
      }
    })
  })
});

export const { usePostFinnServiceMutation } = finnApi;
