type TClickedParcelParams = {
  lat?: number;
  lon?: number;
};

export type TFetchClickedParcelAttributesParams = {
  prefix: string;
  params: TClickedParcelParams;
};

export type TFetchParcelsDataParams = {
  prefix: string;
  parcels: string[];
};

type TPlotsListRequestConfig = { search: string };

export type TFetchPlotsListParams = {
  url: string;
  params: TPlotsListRequestConfig | undefined;
  omitBaseUrl: boolean;
};

export const enum EParcelsTags {
  CLICKED_PARCEL = 'clickedParcel',
  PARCELS = 'parcels',
  NEIGHBORING_PARCELS = 'neighboringParcels',
  PLOTS_LIST = 'plotsList'
}
