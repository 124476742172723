const selectors = {
  getSketchId: state => state.queryParams.get('sketchId'),
  getMeasurementId: state => state.queryParams.get('measurementId'),
  getPmAndPid: state => ({
    pm: state.queryParams.get('pm'),
    pid: state.queryParams.get('pid')
  }),
  getObjectGeometry: state => state.queryParams.get('objectGeometry')
};

export default selectors;
