import length from '@turf/length';
import { parseLength } from '../';

/**
 * Oblicza długość przekazanej warstwy.
 *
 * @param {Layer} layer
 * @return {Object}
 */
const calculateLength = (layer, isInvestorTool) => {
  // Added isInvestorTool logic for precise measurement
  if (isInvestorTool) {
    const { _latlngs } = layer || {};
    const value = _latlngs[0].distanceTo(_latlngs[1]);
    return {
      value,
      valueString: parseLength(value)
    };
  }

  let value = null;
  if ('geometry' in layer) value = length(layer, { units: 'meters' });
  else value = length(layer.toGeoJSON(), { units: 'meters' });

  return {
    value, // unit: meters
    valueString: parseLength(value)
  };
};

export default calculateLength;
