import { Component, createRef } from 'react';
import PropTypes from 'prop-types';
import autoBind from '../../utils/autoBind';
import './Timer.less';

export default class Timer extends Component {
  constructor(props) {
    super(props);

    autoBind(this);

    this.timerValueContainerRef = createRef();
    this.timerValue = 0;
  }

  componentDidMount() {
    this.setTimer();
  }

  componentWillUnmount() {
    this.clearTimer();
  }

  setTimer() {
    const intervalTimeMs = 10;

    this.interval = setInterval(() => {
      this.timerValue += intervalTimeMs;
      this.timerValueContainerRef.current.innerText = this.getFormattedValue();
    }, intervalTimeMs);
  }

  clearTimer() {
    clearInterval(this.interval);
  }

  getFormattedValue = () => {
    var elapsed = Math.round(this.timerValue / 10);
    var seconds = (elapsed / 100).toFixed(2);
    return `${seconds} s`;
  };

  render() {
    return (
      <span className="time-span" aria-hidden="true">
        {this.props.title || 'Czas'}:{' '}
        <span ref={this.timerValueContainerRef}>
          {this.getFormattedValue()}
        </span>
      </span>
    );
  }
}

Timer.propTypes = {
  title: PropTypes.element
};
