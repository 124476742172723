export const FETCH_SELECTION_LAYERS_STARTED = 'FETCH_SELECTION_LAYERS_STARTED';
export const FETCH_SELECTION_LAYERS_SUCCESS = 'FETCH_SELECTION_LAYERS_SUCCESS';
export const FETCH_SELECTION_LAYERS_FAILED = 'FETCH_SELECTION_LAYERS_FAILED';

export const UPDATE_SELECTION_LAYERS_STARTED =
  'UPDATE_SELECTION_LAYERS_STARTED';
export const UPDATE_SELECTION_LAYERS_SUCCESS =
  'UPDATE_SELECTION_LAYERS_SUCCESS';
export const UPDATE_SELECTION_LAYERS_FAILED = 'UPDATE_SELECTION_LAYERS_FAILED';

export const ADD_PREDEFINED_SELECTION_STARTED =
  'ADD_PREDEFINED_SELECTION_STARTED';
export const ADD_PREDEFINED_SELECTION_SUCCESS =
  'ADD_PREDEFINED_SELECTION_SUCCESS';
export const ADD_PREDEFINED_SELECTION_FAILED =
  'ADD_PREDEFINED_SELECTION_FAILED';

export const FETCH_PREDEFINED_SELECTIONS_STARTED =
  'FETCH_PREDEFINED_SELECTIONS_STARTED';
export const FETCH_PREDEFINED_SELECTIONS_SUCCESS =
  'FETCH_PREDEFINED_SELECTIONS_SUCCESS';
export const FETCH_PREDEFINED_SELECTIONS_FAILED =
  'FETCH_PREDEFINED_SELECTIONS_FAILED';

export const DELETE_PREDEFINED_SELECTION_STARTED =
  'DELETE_PREDEFINED_SELECTION_STARTED';
export const DELETE_PREDEFINED_SELECTION_SUCCESS =
  'DELETE_PREDEFINED_SELECTION_SUCCESS';
export const DELETE_PREDEFINED_SELECTION_FAILED =
  'DELETE_PREDEFINED_SELECTION_FAILED';

export const EDIT_PREDEFINED_SELECTION_STARTED =
  'EDIT_PREDEFINED_SELECTION_STARTED';
export const EDIT_PREDEFINED_SELECTION_SUCCESS =
  'EDIT_PREDEFINED_SELECTION_SUCCESS';
export const EDIT_PREDEFINED_SELECTION_FAILED =
  'EDIT_PREDEFINED_SELECTION_FAILED';
export const IS_IDENTIFICATION_FOR_SINGLE_POINT =
  'IS_IDENTIFICATION_FOR_SINGLE_POINT';
